import styled from 'styled-components'

interface Props {
  width?: number
  height?: number
}

const Blank = styled.div`
  flex: 1;
  width: ${(props: Props) => props.width}px;
  min-width: ${(props: Props) => props.width}px;
  max-width: ${(props: Props) => props.width}px;
  height: ${(props: Props) => props.height}px;
  min-height: ${(props: Props) => props.height}px;
  max-height: ${(props: Props) => props.height}px;
`

export default Blank
