import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { PictureOutlined } from '@ant-design/icons'
import { UploadChangeParam } from 'antd/lib/upload'
import { pipe, prop, takeLast } from 'ramda'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import GqlUpload from '../GqlUpload'
import { MessageFormInput } from './ConversationInput'
import { UploadConfig } from './UploadPreviewList'

interface ContainerProps {
  isFocus: boolean
}

const AutoResizeTextAreaContainer = styled.div<ContainerProps>`
  height: auto;
  display: flex;
  align-items: flex-start;
  ${(props) => (props.isFocus ? 'border-color: #40a9ff' : null)};
  ${(props) =>
    props.isFocus ? 'box-shadow: 0 0 0 2px rgba(24,144,255,0.2)' : null};
`

const InputIconsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  > :not(:last-child) {
    margin-right: 8px;
  }
`

interface SendIconProps extends React.SVGProps<SVGSVGElement> {
  submitDisabled: boolean
  width: number
}

const SendIcon = (props: SendIconProps) => {
  const { submitDisabled, width = 24, ...restProps } = props
  const height = width
  const color = submitDisabled ? 'gray' : '#1890FF'
  const cursor = submitDisabled ? 'not-allowed' : 'pointer'
  return (
    <div style={{ cursor }}>
      <svg
        {...restProps}
        xmlns='http://www.w3.org/2000/svg'
        width={width}
        height={height}
        viewBox='0 0 24 24'
      >
        <path
          fill={color}
          fillRule='nonzero'
          d='M21.286 3.802a.5.5 0 0 1 .447.79L10.907 19.713l-.025-7.767 7.48-6.233-7.486 4.458v-.02L2.361 5.343l18.925-1.542z'
        />
      </svg>
    </div>
  )
}

interface RowConfig {
  minRow?: number
  maxRow?: number
}

const defaultRowConfig = {
  minRow: 1,
  maxRow: 11,
}

// @TODO 這部分欠缺「輸出文字長度超過原件寬度」的處理邏輯
// 之後可能用 antd 的元件做 work around: https://github.com/sovmedcare/sov/pull/811#issuecomment-567788609
const calculateRowsFromValue = (value?: string, rowConfig?: RowConfig) => {
  const minRow = (rowConfig && rowConfig.minRow) || defaultRowConfig.minRow
  const maxRow = (rowConfig && rowConfig.maxRow) || defaultRowConfig.maxRow
  if (value) {
    const breaks = value.match(/\n/g) ? (value.match(/\n/g) as any[]).length : 0
    return breaks < maxRow ? breaks + 1 : maxRow
  } else {
    return minRow
  }
}

const takeLastFromFileList = pipe<
  UploadChangeParam,
  UploadChangeParam['fileList'],
  UploadChangeParam['fileList']
>(prop('fileList'), takeLast(1))

interface Props extends FormComponentProps<MessageFormInput> {
  uploadConfig: UploadConfig
  submitDisabled?: boolean
  handleSubmit: () => void
  handleResize?: () => void
}

export const AutoResizeTextArea = (props: Props) => {
  const {
    form,
    uploadConfig,
    submitDisabled = false,
    handleSubmit,
    handleResize,
  } = props
  const [isFocus, setIsFocus] = useState(false)
  const rows = calculateRowsFromValue(form.getFieldValue('content'))

  useEffect(() => {
    if (handleResize) {
      handleResize()
    }
  }, [rows])

  const handleClickSendIcon = () => {
    if (!submitDisabled) {
      handleSubmit()
    }
  }

  return (
    <AutoResizeTextAreaContainer className='ant-input' isFocus={isFocus}>
      {form.getFieldDecorator('content', { initialValue: '' })(
        <textarea
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
          style={{ resize: 'none', border: 'none', width: '100%' }}
          rows={rows}
          placeholder='留言......'
          autoComplete='off'
        />
      )}
      <InputIconsContainer>
        {form.getFieldDecorator('image', {
          getValueFromEvent: takeLastFromFileList,
          initialValue: [],
        })(
          uploadConfig.apolloClient ? (
            <GqlUpload.Upload
              client={uploadConfig.apolloClient}
              fileType='image'
              uploadOptions={{
                needThumbnail: true,
                prefix: uploadConfig.namePrefix,
              }}
              accept='image/*'
              showUploadList={false}
            >
              <PictureOutlined
                style={{ fontSize: '24px', cursor: 'pointer' }}
              />
            </GqlUpload.Upload>
          ) : null
        )}
        {/* @TODO 尚未實作檔案上傳功能，等之後實作完再取消下方註解 */}
        {/* <Icon type='paper-clip' style={{ fontSize: '24px' }} /> */}
        <SendIcon
          width={24}
          submitDisabled={submitDisabled}
          onClick={() => handleClickSendIcon()}
        />
      </InputIconsContainer>
    </AutoResizeTextAreaContainer>
  )
}
