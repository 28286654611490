import { Button } from 'antd'
import styled from 'styled-components'

const CustomizedFooterButton = styled(Button)`
  color: rgba(0, 0, 0, 0.65);
  width: 100%;
`

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0;
`

const Title = styled.div`
  font-size: 20px;
  margin-bottom: 16px;
`

const Content = styled.div`
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
  text-align: center;
`

export { Content, CustomizedFooterButton, ModalBody, Title }
