import { gql } from '@apollo/client'
import { getEmptyText } from '@sov/common'
import React from 'react'

import { EntityNameFragment } from '../codegen/types'

interface Props {
  className?: string
  emptyText?: string
  entity?: EntityNameFragment
}

const EntityName = (props: Props) => {
  const { className, emptyText, entity } = props

  if (!entity) {
    return <div className={className}>{getEmptyText(emptyText)}</div>
  }

  return (
    <div className={className}>
      {entity.__typename === 'Employee' ? 'SOV 人員' : entity.name}
    </div>
  )
}

EntityName.fragments = {
  EntityName: gql`
    fragment EntityName on Entity {
      id
      name
      __typename
    }
  `,
}

export default EntityName
