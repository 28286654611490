import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import Layout from '../components/Layout'

const EmailVerificationSent = () => {
  const location = useLocation<any>()
  const email = location?.state?.email

  const getProtectedEmail = (email: string) => {
    const [username, address] = email.split('@')
    if (!username || !address) {
      return ''
    }
    const usernameArray = Array.from(username)
    const updatedArrayWithStar = usernameArray.map((username, index) =>
      index > 1 ? '*' : username
    )
    const protectedUsername = updatedArrayWithStar.join('')
    return `${protectedUsername}@${address}`
  }

  return (
    <Layout
      title='首次登入'
      description={
        <>
          <div>
            系統已寄送一封驗證信至{' '}
            <a href={`mailto: ${email}`}>{getProtectedEmail(email)}</a>，
          </div>
          <div>請點擊信件中的連結驗證您的帳號身份 。</div>
          <div style={{ marginTop: '48px' }}>
            沒有收到信？ 請
            <Link to='/emailVerification' style={{ color: '#00b8ff' }}>
              重新輸入電子信箱
            </Link>
          </div>
        </>
      }
      backLink='/'
    />
  )
}

export default EmailVerificationSent
