import './index.less'

import { MessageOutlined } from '@ant-design/icons'
import { gql } from '@apollo/client'
import { Link as CustomLink } from '@sov/ui'
import { Avatar, Badge, Popover } from 'antd'
import moment from 'moment'
import { any, isEmpty, map, pathOr } from 'ramda'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import {
  ImageMessage,
  MessageInboxItemFragment,
  MessageType,
  TextMessage,
} from '../../codegen/types'

const PopoverContainer = styled.div`
  width: 300px;
`

const PopoverTitleContainer = styled.div`
  padding: 16px 24px 16px 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e8;
`

const MessageRow = styled.div`
  padding: 7px 16px 10px 16px;
  border-bottom: 1px solid #e8e8e8;
  cursor: pointer;
  :hover {
    background-color: #e6f7ff;
  }
`

const MessageTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const DateContainer = styled.div`
  margin-left: 4px;
  font-size: 12px;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.45);
`
const BadgeContainer = styled.div`
  margin-left: auto;
`
const MessageContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
`

const Content = styled.div`
  font-size: 14px;
  margin-left: 8px;
  max-width: 220px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const PopoverFooterContainer = styled.div`
  text-align: right;
  padding: 6px 24px 8px 0px;
`

interface MessageInboxIconProps {
  showBadge: boolean
  handleTogglePopover?: () => void
}

const MessageInboxIcon = (props: MessageInboxIconProps) => {
  const { showBadge, handleTogglePopover = () => {} } = props
  return (
    <Badge dot={showBadge} offset={[-16, 24]}>
      <MessageOutlined className='icon' onClick={() => handleTogglePopover()} />
    </Badge>
  )
}

interface MessageInboxItemProps {
  node: MessageInboxItemFragment
  handleMessageOnClick?: (id: string) => void
}

const MessageInboxItem = (props: MessageInboxItemProps) => {
  const { node, handleMessageOnClick } = props
  const { id, latestMessage, patient, numberOfUnreadMessages } = node
  const { created, type, content, creator } = latestMessage as TextMessage &
    ImageMessage
  return (
    <MessageRow
      onClick={() => handleMessageOnClick && handleMessageOnClick(id)}
    >
      <MessageTitleContainer>
        <div onClick={(e) => e.stopPropagation()}>
          <CustomLink.PatientLink item={patient} />
        </div>
        <DateContainer>{moment(created).fromNow()}</DateContainer>
        <BadgeContainer>
          <Badge dot={numberOfUnreadMessages > 0} />
        </BadgeContainer>{' '}
      </MessageTitleContainer>
      <MessageContentContainer>
        <Avatar size={24} style={{ backgroundColor: '#1890ff' }}>
          {creator.name.slice(0, 1)}
        </Avatar>
        <Content>
          {type === MessageType.Image ? '傳送了一張圖片' : content}
        </Content>
      </MessageContentContainer>
    </MessageRow>
  )
}

MessageInboxItem.fragment = gql`
  fragment MessageInboxItem on ConversationsWithLatestMessageNode {
    id
    latestMessage {
      id
      type
      created
      conversation {
        id
      }
      creator {
        id
        name
        ... on Employee {
          role
        }
      }
      ... on TextMessage {
        content
      }
    }
    numberOfUnreadMessages
    patient {
      id
      name
    }
  }
`

interface MessageEdge {
  node: MessageInboxItemFragment
  cursor: string
}

interface MessageInboxListProps {
  messageEdges: MessageEdge[]
  handleMessageOnClick?: (id: string) => void
}

const MessageInboxList = (props: MessageInboxListProps) => {
  const { messageEdges, handleMessageOnClick } = props

  if (isEmpty(messageEdges)) {
    return (
      <PopoverContainer>
        <PopoverTitleContainer>
          <div>目前無消息</div>
        </PopoverTitleContainer>
      </PopoverContainer>
    )
  } else {
    return (
      <PopoverContainer>
        <PopoverTitleContainer>
          <div>最新</div>
          {/**
           * @todo 目前還沒有這個 API 等後端更新後補上 */}
          {/* <div>全部標示已讀</div> */}
        </PopoverTitleContainer>
        {map(
          (edge: MessageEdge) => (
            <MessageInboxItem
              node={edge.node}
              handleMessageOnClick={handleMessageOnClick}
            />
          ),
          messageEdges
        )}
        <PopoverFooterContainer>
          <Link to='/conversations'>查看全部</Link>
        </PopoverFooterContainer>
      </PopoverContainer>
    )
  }
}

interface MessageInboxProps {
  messageEdges?: MessageEdge[]
  isPopoverVisible?: boolean
  handleTogglePopover?: (visible?: boolean) => void
  handleMessageOnClick?: (id: string) => void
}
const MessageInbox = (props: MessageInboxProps) => {
  const {
    messageEdges = [],
    isPopoverVisible = false,
    handleTogglePopover,
    handleMessageOnClick,
  } = props

  const hasUnreadMessages = (edge: MessageEdge) =>
    pathOr(0, ['node', 'numberOfUnreadMessages'], edge) > 0
  const showBadge = any(hasUnreadMessages, messageEdges)

  return (
    <Popover
      arrowPointAtCenter
      content={
        <MessageInboxList
          messageEdges={messageEdges}
          handleMessageOnClick={handleMessageOnClick}
        />
      }
      onVisibleChange={handleTogglePopover}
      overlayClassName='message-inbox-icon-popover'
      placement='bottomRight'
      trigger='click'
      visible={isPopoverVisible}
    >
      <MessageInboxIcon
        showBadge={showBadge}
        handleTogglePopover={handleTogglePopover}
      />
    </Popover>
  )
}

MessageInbox.fragment = gql`
  fragment MessageInbox on ConversationsWithLatestMessageConnection {
    edges {
      node {
        ...MessageInboxItem
      }
      cursor
    }
  }
  ${MessageInboxItem.fragment}
`

export default MessageInbox
