import { gql } from '@apollo/client'
import { Modal } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { ModalProps } from 'antd/lib/modal'
import { always, cond, equals } from 'ramda'
import React from 'react'
import styled from 'styled-components'

import {
  OrderFormModalFragment,
  OrderType,
  PatientStatus,
  PrintStage,
  StageStatus,
  StageType,
} from '../../../../codegen/types'
import Instruction from './Instruction'
import OrderForm, {
  FormGeneralInitialValues,
  OrderFormValues,
} from './OrderForm'

const Container = styled.div`
  display: flex;
  flex-direction: row;
`

interface CustomizeImageBackgroundProps {
  hasPadding: boolean
}
const CustomizeImageBackground = styled.div<CustomizeImageBackgroundProps>`
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  padding: ${(props) => (props.hasPadding === true ? '16px' : 'initial')};
  width: 270px;
`
const WhiteBackground = styled.div`
  background-color: white;
  padding: 16px 24px;
  width: 380px;
`

interface GetFormTypeArg {
  patientStatus: PatientStatus
  orderType: OrderType
}

export enum FormType {
  /** 療程正常 */
  NormalPrint = 'NORMAL_PRINT',
  /** 重取模 */
  Remodel = 'REMODEL',
  /** 看報告 */
  Report = 'REPORT',
  /** 首次報告 */
  FirstEval = 'FIRSTEVAL',
}

type GetFormType = (arg: GetFormTypeArg) => FormType

const getFormType: GetFormType = (arg) => {
  const { patientStatus, orderType } = arg
  if (patientStatus !== PatientStatus.Initial) {
    return cond<OrderType, FormType>([
      [equals<OrderType>(OrderType.NormalPrint), always(FormType.NormalPrint)],
      [equals<OrderType>(OrderType.Remodel), always(FormType.Remodel)],
      [equals<OrderType>(OrderType.Report), always(FormType.Report)],
    ])(orderType)
  }
  if (
    patientStatus === PatientStatus.Initial &&
    orderType === OrderType.Report
  ) {
    return FormType.FirstEval
  }
  return FormType.NormalPrint
}

interface OrderFormModalProps {
  form: FormInstance<OrderFormValues>
  handleModalClose: ModalProps['onCancel']
  orderType: OrderType
  patientItem: OrderFormModalFragment
  isOrderCreateModal: boolean
  checkboxHandler: () => void
  modalFooter: JSX.Element[] | null
}

const OrderFormModal = (props: OrderFormModalProps) => {
  const {
    handleModalClose,
    orderType,
    patientItem,
    isOrderCreateModal,
    modalFooter,
    checkboxHandler,
    form,
  } = props

  const isNewPatient = patientItem.status === 'INITIAL'
  const isOnEval = patientItem.status === 'ON_EVAL'
  const isReportOrder = orderType === OrderType.Report

  const doctorName = patientItem.doctor?.name ?? ''
  const patientName = patientItem.name ?? ''
  const lastestPrintStage = patientItem.stages?.docs[0] as
    | PrintStage
    | undefined
  const lastestPrintStageStepNumber = lastestPrintStage?.serialNumber ?? 0
  const stepList = patientItem.currentEvalStage?.analysis?.steps ?? []

  const orderFormInitialValues: FormGeneralInitialValues = {
    stepNumber: 1,
    withReport: 1,
  }
  const formType = getFormType({
    patientStatus: patientItem.status,
    orderType: orderType,
  })
  return (
    <Modal
      bodyStyle={{
        padding: 0,
      }}
      closable={false}
      footer={modalFooter}
      onCancel={handleModalClose}
      width={585}
      visible={isOrderCreateModal}
    >
      {isOrderCreateModal && (
        <Container>
          <CustomizeImageBackground hasPadding={isNewPatient || isOnEval}>
            <Instruction
              lastestPrintStageStepNumber={lastestPrintStageStepNumber}
              stepList={stepList}
              isReportOrder={isReportOrder}
            />
          </CustomizeImageBackground>
          <WhiteBackground>
            <OrderForm
              doctorName={doctorName}
              form={form}
              patientName={patientName}
              formType={formType}
              initialValues={orderFormInitialValues}
              checkboxHandler={checkboxHandler}
            />
          </WhiteBackground>
        </Container>
      )}
    </Modal>
  )
}

OrderFormModal.fragments = {
  OrderFormModal: gql`
    fragment OrderFormModal on Patient {
      id
      name
      status
      doctor {
        id
        name
      }
      stages(query: { type: [${StageType.Print}], status: [${StageStatus.Completed}] }, page: 1, limit: 5, sort: "-shippingDate") {
        docs {
          id
          shippingDate
          ... on PrintStage {
            serialNumber
          }
        }
      }
      currentEvalStage {
        id
        analysis {
          steps {
            attachment
            ipr
            button
            retrieve
          }
        }
      }
    }
  `,
}

export default OrderFormModal
