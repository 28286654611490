import { Form } from '@ant-design/compatible'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { CheckOutlined } from '@ant-design/icons'
import { gql } from '@apollo/client'
import { Typography } from 'antd'
import React from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import {
  BasicFormFragment,
  UpdateAccountInput,
} from '../../../../../codegen/types'
import AccountAvatar from '../../../../../components/icon/AccountAvatar'

const { Text } = Typography
const FormItem = Form.Item
const FormStyle = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}
const FormContent = styled.div`
  padding: 24px 48px;
  display: flex;
`

const AvatarWithNicknameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const NicknameContainer = styled.div`
  margin-top: 32px;
  font-size: 16px;
`

const AccountFieldContainer = styled.div`
  margin-left: 88px;
  flex: 1;
`

interface VerificationStatusIconProps {
  isVerified: boolean
}

const VerificationStatusIcon = (props: VerificationStatusIconProps) => {
  const { pathname } = useLocation()

  const { isVerified } = props
  const defaultStyle = {
    marginLeft: '16px',
  }
  return isVerified ? (
    <span style={{ color: '#52c41a', ...defaultStyle }}>
      <CheckOutlined />
      已驗證
    </span>
  ) : (
    <a
      style={{ color: 'red', ...defaultStyle }}
      href={`${pathname}#account-settings`}
    >
      未驗證，前往驗證
    </a>
  )
}

interface Props extends FormComponentProps<BasicFormFragment> {
  account: BasicFormFragment
  handleSubmit: (payload: UpdateAccountInput) => void
}

const BasicForm = (props: Props) => {
  const { account, handleSubmit } = props

  const handleNicknameChange = async (value: string) => {
    try {
      if (!value) {
        return
      }
      const payload = {
        nickname: value,
      }
      await handleSubmit(payload)
    } catch (e) {
      console.log(e)
    }
  }

  const handleImageUpload = async (avatar: string) => {
    await handleSubmit({ avatar })
  }

  return (
    <Form>
      <FormContent>
        <AvatarWithNicknameContainer>
          <AccountAvatar
            size={118}
            src={account.avatar?.thumbnailPath ?? ''}
            entityType={account.entityType}
            handleImageUpload={handleImageUpload}
            canUpload
          />
          <NicknameContainer>
            <Text
              style={{ color: '#1890ff' }}
              editable={{ onChange: handleNicknameChange }}
            >
              {account.nickname}
            </Text>
          </NicknameContainer>
        </AvatarWithNicknameContainer>
        <AccountFieldContainer>
          <FormItem label='登記姓名' {...FormStyle}>
            <span>{account.entity?.name}</span>
          </FormItem>
          <FormItem label='電子信箱' {...FormStyle}>
            {account.email}
            <VerificationStatusIcon isVerified={account.isEmailVerified} />
          </FormItem>
          <FormItem label='聯絡電話' {...FormStyle}>
            {account.phone}
            <VerificationStatusIcon isVerified={account.isPhoneVerified} />
          </FormItem>
        </AccountFieldContainer>
      </FormContent>
    </Form>
  )
}

BasicForm.fragment = gql`
  fragment BasicForm on Account {
    id
    nickname
    avatar {
      id
      thumbnailPath
    }
    entityType
    entity {
      id
      name
    }
    email
    isEmailVerified
    phone
    isPhoneVerified
  }
`
export default Form.create<Props>()(BasicForm)
