import React from 'react'

const { useState } = React

export type Tip =
  | ''
  | '更新中...'
  | '新增中...'
  | '刪除中...'
  | '載入中...'
  | '保存中...'
interface UseLoadingLayerParams {
  loading: boolean
  tip: Tip
}
export const useLoadingLayer = (
  params: UseLoadingLayerParams = { loading: false, tip: '' }
) => {
  const [loading, setLoading] = useState(params.loading)
  const [tip, setTip] = useState<Tip>(params.tip)
  const setLoadingLayer = ({ loading, tip }: UseLoadingLayerParams) => {
    setLoading(loading)
    setTip(tip)
  }
  return { loading, tip, setLoadingLayer }
}
