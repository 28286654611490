import React from 'react'
import styled from 'styled-components'

const BlockTitleContainer = styled.div`
  padding: 0px 12px;
`

const TextContainer = styled.div`
  padding: 16px 0px 16px 24px;
  border-bottom: solid 1px #e0e0e0;
  font-size: 16px;
  font-weight: 600;
`

interface Props {
  title: string
}

export const BlockTitle = (props: Props) => {
  return (
    <BlockTitleContainer>
      <TextContainer>{props.title}</TextContainer>
    </BlockTitleContainer>
  )
}
