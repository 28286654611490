import { gql } from '@apollo/client'
import { last } from 'ramda'
import React, { useState } from 'react'
import styled from 'styled-components'

import {
  DentalModelViewerDocs,
  DentalModelViewerEvalStageInlineFragment,
  DentalModelViewerFragment,
  StageStatus,
  StageType,
} from '../../codegen/types'
import InstructionCardPreview from '../InstructionCardPreview'
import { Disabled } from './constant/color'
import { MeshColor } from './constant/meshColor'
import { HeaderHeight, PanelWidth } from './constant/size'
import Header from './Header'
import Sidebar from './Sidebar'
import { ItemType } from './Sidebar/SelectableItem'
import ToolBar from './Toolbar'
import useController from './useController'
import useViewerItems from './useViewerItems'
import { ViewerItemInfo, getCheckPointId } from './utils'
import Viewer from './Viewer'
import { MeshInfo } from './Viewer/Mesh'

export type Mode = string | 'treatment'

const ModelViewerWrapper = styled.div`
  overflow: hidden;
`

const SectionSideBar = styled(Sidebar)`
  width: ${PanelWidth}px;
`

const SectionViewer = styled.div`
  position: relative;
  flex: 1;
`
const ToolBarWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  > div {
    margin: 16px 0;
    width: max-content;
  }
`

const DisclaimerWrapper = styled.div`
  position: absolute;
  bottom: 3vw;
  right: 3vw;
`

const Main = styled.div`
  background-blend-mode: multiply;
  background-image: linear-gradient(to bottom, ${Disabled}, ${Disabled});
  display: flex;
  height: calc(100vh - ${HeaderHeight}px);
  width: 100vw;
`

// 模型組合
export interface ViewerItem {
  id: string
  meshes: MeshInfo[]
  color: MeshColor
  type: ItemType
  info: ViewerItemInfo
  percent?: number
}

const getSelectedItemIdsInitialValue = (
  paramStageInfo: DentalModelViewerDocs
): ViewerItem['id'][] => {
  if (paramStageInfo.type === StageType.Eval) {
    const evalStage = paramStageInfo as DentalModelViewerEvalStageInlineFragment
    const finalCheckPoint = last(evalStage.checkPoints ?? [])
    if (finalCheckPoint) {
      return [getCheckPointId(evalStage.id, finalCheckPoint.serialNumber)]
    } else {
      return []
    }
  }
  return [paramStageInfo.id]
}
const getModeInitialValue = (paramStageInfo: DentalModelViewerDocs): Mode => {
  if (paramStageInfo.type === StageType.Eval) {
    return paramStageInfo.id
  }
  return 'treatment'
}

interface Props {
  patient: DentalModelViewerFragment
  paramStageId: string
  getReportPreviewLink: (stageId: string) => string
  CurrentEvalStageButton?: () => JSX.Element
  handleChangeStageId?: (evalStageId: string) => void
}

const DentalModelViewer = (props: Props) => {
  const {
    patient,
    paramStageId,
    getReportPreviewLink,
    CurrentEvalStageButton,
    handleChangeStageId,
  } = props

  const patientStages = patient.stages?.docs ?? []
  const paramStageInfo = patientStages.find(
    (stage) => stage.id === paramStageId
  )!
  const currentEvalStageId = patient.currentEvalStage?.id

  /** 模式 (evalStageId or 'treatment') */
  const [mode, setMode] = useState<Mode>(getModeInitialValue(paramStageInfo))
  /** 已排序的項目列表 */
  const { viewerItems, handleChangeColor, handleUpdateViewerItems } =
    useViewerItems({ mode, stages: patientStages, currentEvalStageId })
  /** 控制列 */
  const { controller, dispatch } = useController({
    viewerItems,
    initialState: {
      selectedItemIds: getSelectedItemIdsInitialValue(paramStageInfo),
    },
  })

  const selectedItems = viewerItems.filter((item) =>
    controller.selectedItemIds.includes(item.id)
  )
  const selectedEvalStage = (
    mode === 'treatment'
      ? patientStages.find((stage) => stage.id === patient.currentEvalStage?.id)
      : patientStages.find((stage) => stage.id === mode)
  ) as DentalModelViewerEvalStageInlineFragment | undefined

  const handleChangeMode = (mode: Mode) => {
    setMode(mode)
    handleUpdateViewerItems(mode)
    if (mode === 'treatment') {
      currentEvalStageId && handleChangeStageId?.(currentEvalStageId)
    } else {
      handleChangeStageId?.(mode)
    }
  }
  const handleSelectItem = (itemId: ViewerItem['id']) => {
    dispatch({ type: 'selectItem', payload: itemId })
  }

  return (
    <ModelViewerWrapper>
      <Header
        patient={patient}
        mode={mode}
        handleChangeMode={handleChangeMode}
        CurrentEvalStageButton={CurrentEvalStageButton}
      />
      <Main>
        <SectionSideBar
          viewerItems={viewerItems}
          selectedItemIds={controller.selectedItemIds}
          selectedEvalStage={selectedEvalStage}
          disabled={controller.isPlayMode}
          getReportPreviewLink={getReportPreviewLink}
          handleSelectItem={handleSelectItem}
          handleChangeColor={handleChangeColor}
        />
        <SectionViewer>
          <Viewer
            visibleItems={selectedItems}
            controller={controller}
            dispatch={dispatch}
          />
          <ToolBarWrapper>
            <ToolBar
              viewerItems={viewerItems}
              controller={controller}
              dispatch={dispatch}
            />
          </ToolBarWrapper>
          <DisclaimerWrapper>
            內容聲明：上述報告為病患現有口內狀況的評估，以及電腦模擬後之預估療程，如病患超過
            3 個月未追蹤或未按時配戴以致療程延長，SOV 舒服美有權不提供後續服務
          </DisclaimerWrapper>
        </SectionViewer>
      </Main>
    </ModelViewerWrapper>
  )
}

DentalModelViewer.fragments = {
  DentalModelViewer: gql`
    fragment DentalModelViewer on Patient {
      ...ViewerHeader
      id
      status
      currentEvalStage {
        id
      }
      stages(query: {
        type: [${StageType.Mold}, ${StageType.Eval}, ${StageType.Design}],
        status: [${StageStatus.Completed}, ${StageStatus.Started}] }, limit: 100, sort: "-shippingDate") {
          docs {
            id
            type
            status
            shippingDate
            __typename
            ... on MoldStage {
              serialNumber
              files {
                teeth {
                  position
                  file {
                    id
                    path
                  }
                }
                upperJawModel {
                  id
                  path
                }
                lowerJawModel {
                  id
                  path
                }
              }
            }
            ... on EvalStage {
              ...Sidebar
              serialNumber
              checkPoints {
                goalStep
                serialNumber
                upper
                lower
                isFinal
                isCompleted
                files {
                  teeth {
                    position
                    file {
                      id
                      path
                    }
                  }
                  upperJawModel {
                    id
                    path
                  }
                  lowerJawModel {
                    id
                    path
                  }
                }
              }
            }
            ... on DesignStage {
              serialNumber
              step {
                upperStep
                lowerStep
              }
              instructionCard {
                ...InstructionCardPreview
              }
              files {
                teeth {
                  position
                  file {
                    id
                    path
                  }
                }
                upperJawModel {
                  id
                  path
                }
                lowerJawModel {
                  id
                  path
                }
              }
            }
          }
        }
    }
    ${Header.fragments.ViewerHeader}
    ${Sidebar.fragments.Sidebar}
    ${InstructionCardPreview.fragments.InstructionCardPreview}
  `,
}

export default DentalModelViewer
