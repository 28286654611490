import { Typography } from 'antd'
import React from 'react'
import styled from 'styled-components'

import copyImage from './copy.svg'

const CustomizedText = styled(Typography.Text)`
  color: inherit;

  & > .ant-typography-copy {
    margin-left: 16px;
  }
`

interface CopyableTextProps {
  children: JSX.Element | string
  copyText?: string
}

const CopyableText = (props: CopyableTextProps) => {
  const { copyText, children } = props

  const copyable = {
    icon: <img src={copyImage} />,
    text: copyText,
  }

  return <CustomizedText copyable={copyable}>{children}</CustomizedText>
}

export default CopyableText
