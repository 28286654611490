import { Form } from '@ant-design/compatible'
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form'
import { Input } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { SubmitButton } from '../../components/SubmitButton'

const FormItem = Form.Item

const LoginOptions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`

interface Props {
  form: WrappedFormUtils
  handleSubmit: () => Promise<void>
}
const LoginForm = (props: Props) => {
  const { form, handleSubmit } = props
  const { getFieldDecorator } = form

  const { t } = useTranslation()
  return (
    <Form>
      <FormItem style={{ marginTop: 44 }}>
        {getFieldDecorator('username', {
          rules: [
            {
              required: true,
              message: t(`validate.required`),
            },
            {
              type: 'email',
              message: t(`validate.email`),
            },
          ],
          initialValue: '',
        })(<Input placeholder='電子郵件或電話' onPressEnter={handleSubmit} />)}
      </FormItem>
      <FormItem>
        {getFieldDecorator('password', {
          rules: [
            {
              pattern: new RegExp('^[a-zA-Z0-9]'),
              message: t(`validate.password.required`),
            },
            {
              max: 20,
              message: t(`validate.password.max`),
            },
            {
              min: 4,
              message: t(`validate.password.min`),
            },
            {
              required: true,
              message: t(`validate.required`),
            },
          ],
          initialValue: '',
        })(
          <Input.Password
            placeholder='密碼'
            type='password'
            onPressEnter={handleSubmit}
          />
        )}
      </FormItem>
      <LoginOptions>
        {/* @todo 補上自動登入 */}
        {/* <span>自動登入</span> */}
        <Link to='/selectRecoverPasswordMethod' style={{ marginLeft: 'auto' }}>
          忘記密碼？
        </Link>
      </LoginOptions>
      <SubmitButton onClick={handleSubmit}>登入</SubmitButton>
    </Form>
  )
}

export default LoginForm
