import { Form } from '@ant-design/compatible'
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form'
import { Input, Radio } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { EntityType } from '../../../../codegen/types'
import { SubmitButton } from '../../components/SubmitButton'

const FormItem = Form.Item
const RadioGroup = Radio.Group

const formItemDefaultProps = {
  style: {
    marginBottom: '16px',
  },
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

export interface RegisterFormInput {
  entityType: EntityType
  name: string
  email: string
  phone: string
  clinicPhone: string
  clinicName: string
  clinicAddress: string
  hostname: string
}

const StyledRadioGroup = styled.div`
  text-align: left;
`

type RegisterFormProps = {
  handleFormOnFocus: () => void
  handleSubmit: () => Promise<void>
} & FormComponentProps<RegisterFormInput>

const RegisterForm = ({
  form,
  handleFormOnFocus,
  handleSubmit,
}: RegisterFormProps) => {
  const { getFieldDecorator } = form
  const { t } = useTranslation()
  return (
    <Form>
      {/*
        @ TODO
        目前填完表單後沒有直接新建 user，之後等 user 重構完需要針對不同的職位規劃欄位
      */}
      <FormItem {...formItemDefaultProps} label='身份'>
        {getFieldDecorator('entityType', {
          rules: [{ required: true, message: t(`validate.required`) }],
        })(
          <StyledRadioGroup>
            <RadioGroup>
              <Radio value={EntityType.Doctor}>
                {t(`account.entityType.${EntityType.Doctor}`)}
              </Radio>
              <Radio value={EntityType.Clinic}>
                {t(`account.entityType.${EntityType.Clinic}`)}
              </Radio>
            </RadioGroup>
          </StyledRadioGroup>
        )}
      </FormItem>
      <FormItem {...formItemDefaultProps} label='姓名'>
        {getFieldDecorator('name', {
          rules: [{ required: true, message: t(`validate.required`) }],
        })(<Input placeholder='姓名' type='text' />)}
      </FormItem>
      <FormItem {...formItemDefaultProps} label='信箱'>
        {getFieldDecorator('email', {
          rules: [
            { type: 'email', message: t(`validate.email`) },
            { required: true, message: t(`validate.required`) },
          ],
        })(
          <Input placeholder='信箱' type='mail' onFocus={handleFormOnFocus} />
        )}
      </FormItem>
      <FormItem {...formItemDefaultProps} label='個人電話'>
        {getFieldDecorator('phone', {
          rules: [{ required: true, message: t(`validate.required`) }],
        })(<Input placeholder='個人電話' type='tel' />)}
      </FormItem>
      <FormItem {...formItemDefaultProps} label='診所名稱'>
        {getFieldDecorator('clinicName', {
          rules: [{ required: true, message: t(`validate.required`) }],
        })(<Input placeholder='診所名稱' type='text' />)}
      </FormItem>
      <FormItem {...formItemDefaultProps} label='診所電話'>
        {getFieldDecorator('clinicPhone', {
          rules: [{ required: true, message: t(`validate.required`) }],
        })(<Input placeholder='診所電話' type='tel' />)}
      </FormItem>
      <FormItem {...formItemDefaultProps} label='診所地址'>
        {getFieldDecorator('clinicAddress', {
          rules: [{ required: true, message: t(`validate.required`) }],
        })(<Input placeholder='診所地址' type='text' />)}
      </FormItem>
      <SubmitButton onClick={handleSubmit}>送出</SubmitButton>
    </Form>
  )
}

export default RegisterForm
