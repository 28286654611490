import { Spin } from 'antd'
import { SpinProps } from 'antd/lib/spin'
import React from 'react'

import { Tip } from './hooks/general'

const style = {
  display: 'flex',
  justifyContent: 'center',
  background: 'rgba(255, 255, 255, .8)',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 3000,
} as React.CSSProperties

interface Props {
  className?: string
  loading: boolean
  tip?: Tip
  children?: React.ReactNode
  spinProps?: SpinProps
}

const LoadingLayer = (props: Props) => {
  const { loading, children, className } = props

  return (
    <>
      {loading ? (
        <div style={style} className={className}>
          <Spin
            style={{ marginTop: 40 }}
            size='large'
            tip='Loading...'
            {...props.spinProps}
          />
        </div>
      ) : (
        ''
      )}
      {children}
    </>
  )
}

export default LoadingLayer
