import React from 'react'
import { Link } from 'react-router-dom'

import inProgressImage from '../../../../assets/order/in-progress.png'
import { Content, ModalBody, Title } from '../..'

interface InProgressModalBodyProps {
  orderLinkUrl?: string
}

const InProgressModalBody = (props: InProgressModalBodyProps) => {
  const { orderLinkUrl } = props

  return (
    <ModalBody>
      <div>
        <img src={inProgressImage} />
      </div>
      <Title>訂單重複</Title>
      <Content>
        <div>此病患目前有一筆訂單正在進行中，</div>
        <div>
          若您需要編輯或刪除{' '}
          {orderLinkUrl ? (
            <Link to={orderLinkUrl}>原訂單項目</Link>
          ) : (
            '原訂單項目'
          )}
          ，請與我們聯繫。
        </div>
      </Content>
    </ModalBody>
  )
}

export default InProgressModalBody
