import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 270px;
  min-width: 270px;
  max-width: 270px;
  height: 200px;
  margin-left: 24px;
`
const Title = styled.div`
  text-align: center;
  font-size: 16px;
  font-weight: 600;
`

const Content = styled.p`
  margin-top: 8px;
  font-size: 12px;
  font-weight: normal;
  white-space: pre-line;
  color: rgba(0, 0, 0, 0.45);
`

const content =
  '隱形的笑容秘器，醫生診所的最佳夥伴。' +
  'SOV 數位隱形，採用專業牙體數位技術，協助診所牙醫，' +
  '為每位患者量身訂做一系列矯正牙套，矯正完成後患者就能擁有自信的笑容。\n\n' +
  '當您下單註冊送出資料，及表示同意 SOV 隱私條款及免責聲明條款。'

const AppDescription = () => {
  return (
    <Container>
      <Title>SOV 數位隱形矯正</Title>
      <Content>{content}</Content>
      <a href='https://sov.dental/'>對資料有疑問？請聯繫 SOV 舒服美</a>
    </Container>
  )
}

export default AppDescription
