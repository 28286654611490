import { gql, useQuery } from '@apollo/client'
import { ErrorHandling, FormQuery, MyStringParam } from '@sov/common'
import DentalModelViewer from '@sov/ui/src/components/DentalModelViewer'
import { Button, Row, Spin, Typography } from 'antd'
import moment from 'moment'
import { isEmpty } from 'ramda'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import {
  OrderType,
  StageModelQueryQuery,
  StageModelQueryQueryVariables,
} from '../../codegen/types'
import PatientConversationDrawer from '../../components/conversation/ConversationDrawer'
import useDrawer from '../../components/conversation/useDrawer'
import MainReport from '../../components/icon/MainReport.svg'
import MainReportActivity from '../../components/icon/MainReportActivity.svg'
import CurrentEvalStageModal from '../../components/modal/CurrentEvalStageModal'
import OrderModal from '../../components/modal/OrderModal'
import QuestionIcon from './QuestionIcon'
import TutorialModal from './TutorialModal'

const viewerTutorialSlideLatestReleaseDate = moment('2021-03-29')
  .startOf('day')
  .toISOString()
const viewerTutorialSlideLastSeenDateKey =
  'viewer-tutorial-slide-last-seen-date'
const viewerTutorialSlideLastSeenDate = window.localStorage.getItem(
  viewerTutorialSlideLastSeenDateKey
)
const isTutorialModalDefaultOpen = viewerTutorialSlideLastSeenDate
  ? moment(viewerTutorialSlideLastSeenDate).isBefore(
      viewerTutorialSlideLatestReleaseDate
    )
  : true

const IconContainer = styled.div`
  position: fixed;
  right: 24px;
  bottom: 24px;
`
const StyledButton = styled(Button)`
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.45);
  width: 140px;
  display: flex;
  align-items: center;
`

const stageModelQuery = gql`
  query StageModelQuery($id: ID!) {
    stage(id: $id) {
      patient {
        ...DentalModelViewer
        ...PatientConversation
        currentEvalStage {
          id
        }
      }
    }
  }
  ${DentalModelViewer.fragments.DentalModelViewer}
  ${PatientConversationDrawer.fragment.PatientConversation}
`

const queryString = {
  patientId: MyStringParam,
  stageId: MyStringParam,
}
interface CurrentEvalStageButtonProps {
  handleCurrentEvalStageModalOpen: () => void
  haveCurrentEvalStage: boolean
}
const CurrentEvalStageButton = (props: CurrentEvalStageButtonProps) => {
  const { handleCurrentEvalStageModalOpen, haveCurrentEvalStage } = props
  const [mainReportIconSrc, setMainReportIconSrc] = useState(MainReport)

  return (
    <StyledButton
      onMouseEnter={() => {
        setMainReportIconSrc(MainReportActivity)
      }}
      onMouseLeave={() => {
        setMainReportIconSrc(MainReport)
      }}
      onClick={() => {
        handleCurrentEvalStageModalOpen()
      }}
    >
      <img src={mainReportIconSrc} />
      <span>{haveCurrentEvalStage ? '修改' : '設定'}主要報告</span>
    </StyledButton>
  )
}

const StageModel = () => {
  const history = useHistory()
  const { formQuery, handleFormChange } = FormQuery.useFormQuery(queryString)
  const stageId = formQuery.stageId as string

  const drawer = useDrawer()

  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { data, loading, refetch } = useQuery<
    StageModelQueryQuery,
    StageModelQueryQueryVariables
  >(stageModelQuery, {
    errorPolicy: 'none',
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      id: stageId,
    },
  })

  const handleRefetch = async () => {
    await refetch()
  }
  const handleChangeStageId = (evalStageId: string) => {
    handleFormChange({ stageId: evalStageId })
  }

  const [isCurrentEvalStageModalVisible, setIsCurrentEvalStageModalVisible] =
    useState<boolean>(false)
  const [isTutorialModalOpen, setIsTutorialModalOpen] = useState(
    isTutorialModalDefaultOpen
  )
  const [shouldFetchPatientOrders, setShouldFetchPatientOrders] =
    useState(false)

  const handleTutorialModalOpen = () => {
    setIsTutorialModalOpen(true)
  }

  const handleTutorialModalClose = () => {
    window.localStorage.setItem(
      viewerTutorialSlideLastSeenDateKey,
      moment().toISOString()
    )
    setIsTutorialModalOpen(false)
  }

  const handleCurrentEvalStageModalOpen = () => {
    setIsCurrentEvalStageModalVisible(true)
  }

  const handleCurrentEvalStageModalClose = () => {
    handleRefetch()
    setIsCurrentEvalStageModalVisible(false)
  }
  const handleQueryStop = () => {
    setShouldFetchPatientOrders(false)
  }

  const orderAction = () => {
    setIsCurrentEvalStageModalVisible(false)
    setShouldFetchPatientOrders(true)
  }

  const stage = data?.stage
  const patient = stage?.patient
  const stages = patient?.stages?.docs ?? []

  if (loading) {
    return (
      <Row justify='center' align='middle' style={{ height: '100vh' }}>
        <Spin tip='3D Viewer 載入中 ...' size='large' />
      </Row>
    )
  }

  if (!stage || !patient || isEmpty(stages)) {
    return (
      <>
        <Row justify='center'>
          <Typography.Title level={2}>無資料，請返回上頁</Typography.Title>
        </Row>
        <Row justify='center'>
          <Button onClick={() => history.goBack()}>回上一頁</Button>
        </Row>
      </>
    )
  }
  const getReportPreviewLink = (stageId: string) => `stages/${stageId}/preview`
  return (
    <>
      <DentalModelViewer
        patient={patient}
        paramStageId={stageId}
        getReportPreviewLink={getReportPreviewLink}
        CurrentEvalStageButton={() => (
          <CurrentEvalStageButton
            handleCurrentEvalStageModalOpen={handleCurrentEvalStageModalOpen}
            haveCurrentEvalStage={!!patient.currentEvalStage?.id}
          />
        )}
        handleChangeStageId={handleChangeStageId}
      />
      <CurrentEvalStageModal
        isCurrentEvalStageModalVisible={isCurrentEvalStageModalVisible}
        handleCurrentEvalStageModalClose={handleCurrentEvalStageModalClose}
        patientId={patient.id}
        orderAction={orderAction}
      />
      <OrderModal
        orderType={OrderType.NormalPrint}
        patientId={patient.id}
        shouldFetchPatientOrders={shouldFetchPatientOrders}
        handleQueryStop={handleQueryStop}
      />
      <PatientConversationDrawer patient={patient} drawer={drawer} />
      <IconContainer>
        <QuestionIcon onClick={handleTutorialModalOpen} />
      </IconContainer>
      <TutorialModal
        handleModalClose={handleTutorialModalClose}
        isModalVisible={isTutorialModalOpen}
      />
    </>
  )
}

export default StageModel
