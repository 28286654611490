import Icon from '@ant-design/icons'
import React from 'react'

const Svg = () => (
  <svg
    width='41'
    height='40'
    viewBox='0 0 41 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='20.3318' cy='20' r='20' fill='black' fillOpacity='0.65' />
    <path
      // eslint-disable-next-line
      d='M19.2318 21.66H20.8718C20.3318 18.66 25.1118 18.04 25.1118 15.02C25.1118 12.72 23.3118 11.28 20.4918 11.28C18.3118 11.28 16.7518 12.2 15.4318 13.6L16.5118 14.6C17.6718 13.38 18.9318 12.78 20.2918 12.78C22.3318 12.78 23.3318 13.78 23.3318 15.16C23.3318 17.44 18.6118 18.34 19.2318 21.66ZM20.0918 26.6C20.7918 26.6 21.3718 26.08 21.3718 25.28C21.3718 24.48 20.7918 23.94 20.0918 23.94C19.3718 23.94 18.7918 24.48 18.7918 25.28C18.7918 26.08 19.3718 26.6 20.0918 26.6Z'
      fill='white'
    />
  </svg>
)

const QuestionIcon = (props) => <Icon {...props} component={Svg} />

export default QuestionIcon
