import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import emailIcon from '../../../static/images/entrance/email.png'
import phoneIcon from '../../../static/images/entrance/phone.png'
import Layout from '../components/Layout'
import { RecoverPasswordMethods } from './RecoverPassword'

interface SelectBlockProps {
  title: string
  image: string
}

interface SelectBlockIconProps {
  image: string
}

const SelectBlockContainer = styled.div`
  margin: auto;
  width: 288px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 46px;
`

const Block = styled.div`
  width: 140px;
  height: 184px;
  padding-top: 29px;
  background-color: white;
  text-align: center;
  box-shadow: 0 2px 8px 0 rgba(138, 153, 160, 0.2);
  border-radius: 4px;
  &:hover {
    transform: translateY(-5px);
  }
`

const SelectBlockIcon = styled.div<SelectBlockIconProps>`
  background-size: cover;
  background-image: url(${(props) => props.image});
  width: 90px;
  height: 90px;
  padding: 15px 18px;
  margin: auto;
`

const SelectBlockTitle = styled.div`
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
`

const SelectBlock = ({ title, image }: SelectBlockProps) => (
  <Block>
    <SelectBlockIcon image={image} />
    <SelectBlockTitle>{title}</SelectBlockTitle>
  </Block>
)

const SelectRecoverPasswordMethod = () => {
  return (
    <Layout
      title='忘記密碼？'
      description='請選擇其中一種驗證方式來重設密碼。'
      content={
        <SelectBlockContainer>
          <Link to={`/recoverPassword/${RecoverPasswordMethods.EMAIL}`}>
            <SelectBlock title='以電子郵件驗證' image={emailIcon} />
          </Link>
          <Link to={`/recoverPassword/${RecoverPasswordMethods.PHONE}`}>
            <SelectBlock title='以手機簡訊驗證' image={phoneIcon} />
          </Link>
        </SelectBlockContainer>
      }
      backLink='/'
    />
  )
}

export default SelectRecoverPasswordMethod
