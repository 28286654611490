import { gql, useQuery } from '@apollo/client'
import { ErrorHandling } from '@sov/common'
import { Col, Row } from 'antd'
import React, { useState } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import {
  OrderDetailQuery,
  OrderDetailQueryVariables,
} from '../../../codegen/types'
import ConversationDrawer from '../../../components/conversation/ConversationDrawer'
import Page from '../../../components/page'
import BreadcrumbCreator from '../../../components/page/BreadcrumbCreator'
import Title from '../../../components/page/Title'
import { defaultText } from '../../../utils/color'
import ButtonList from './ButtonList'
import EditOrderModal from './EditOrderModal'
import OrderDetailCard from './OrderDetailCard'
import PatientOrderListLink from './PatientOrderListLink'

interface OrderLinkProps {
  orderId: string
}

const OrderLink = (props: OrderLinkProps) => {
  const { orderId } = props

  /**
   * @todo: 顯示訂單編號
   * 等訂單編號 api 實作完成後，這邊改成顯示訂單編號
   */
  const serialNumber = '訂單詳情'

  return <Link to={`/orders/${orderId}`}>{serialNumber}</Link>
}

interface GetPageHeaderArgs {
  orderId: string
}

const getPageHeader = (args: GetPageHeaderArgs) => {
  const { orderId } = args

  return (
    <Row>
      <Col>
        <BreadcrumbCreator
          routes={[
            { key: 'Home' },
            { key: 'OrderList' },
            {
              key: 'OrderDetail',
              render: () => <OrderLink orderId={orderId} />,
            },
          ]}
        />
        <Title route={{ key: 'OrderDetail' }} />
      </Col>
    </Row>
  )
}

const Container = styled.div`
  color: ${defaultText};
  line-height: 24px;
`

const fragment = gql`
  fragment OrderDetailPage on Order {
    ...OrderDetailCard
    patient {
      ...PatientConversation
    }
  }
  ${OrderDetailCard.fragments.OrderDetailCard}
  ${ConversationDrawer.fragment.PatientConversation}
`

const orderDetailQuery = gql`
  query OrderDetail($id: ID!) {
    order(id: $id) {
      ...OrderDetailPage
    }
  }
  ${fragment}
`

interface RouteParams {
  orderId: string
}

const OrderDetailPage = () => {
  const {
    params: { orderId },
  } = useRouteMatch<RouteParams>()

  const variables = {
    id: orderId,
  }

  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { data } = useQuery<OrderDetailQuery, OrderDetailQueryVariables>(
    orderDetailQuery,
    {
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'none',
      onError: (error) => {
        toErrorPage(error.message)
      },
      variables,
    }
  )

  const [isModalVisible, setIsModalVisible] = useState(false)
  const handleModalClose = () => {
    setIsModalVisible(false)
  }

  const [isMessageDrawerOpen, setIsMessageDrawerOpen] = useState(false)
  const drawer = {
    isOpen: isMessageDrawerOpen,
    handleToggle: () => {
      setIsMessageDrawerOpen((isOpen) => !isOpen)
    },
  }

  const handleContactUsButtonClick = () => {
    setIsMessageDrawerOpen(true)
  }

  const handleOrderEditButtonClick = () => {
    setIsModalVisible(true)
  }

  const order = data?.order

  if (!order) {
    return null
  }

  const patient = order.patient

  return (
    <Page header={getPageHeader({ orderId })}>
      <>
        <Container>
          <Row gutter={16}>
            <Col span={12} offset={4}>
              <OrderDetailCard orderItem={order} />
            </Col>
            <Col span={4}>
              <ButtonList
                handleContactUsButtonClick={handleContactUsButtonClick}
                handleOrderEditButtonClick={handleOrderEditButtonClick}
              />
              <PatientOrderListLink patientId={patient.id} />
            </Col>
          </Row>
        </Container>
        <ConversationDrawer drawer={drawer} patient={patient} />
        <EditOrderModal
          handleModalClose={handleModalClose}
          isModalVisible={isModalVisible}
        />
      </>
    </Page>
  )
}

OrderDetailPage.fragements = {
  OrderDetailPage: fragment,
}

export default OrderDetailPage
