import { gql } from '@apollo/client'
import { Card } from 'antd'
import React from 'react'
import styled from 'styled-components'

const Style = styled.div`
  margin-top: 16px;
`

const Note = (props) => {
  const { note } = props

  return (
    <Style>
      <Card title='醫師備忘'>{note}</Card>
    </Style>
  )
}

Note.fragment = {
  PatientDetailDoctorInstruction: gql`
    fragment PatientDetailDoctorInstruction on Patient {
      doctorInstruction {
        note
      }
    }
  `,
}

export default Note
