import { ReloadOutlined } from '@ant-design/icons'
import { gql, useMutation, useQuery, useSubscription } from '@apollo/client'
import { Auth, getNotificationTypesFromCategory } from '@sov/common'
import { Notification } from '@sov/ui'
import { Button, Radio } from 'antd'
import { concat, equals, flatten, map, not, prop } from 'ramda'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  NotificationCategory,
  NotificationCreatedSubscription,
  NotificationCreatedSubscriptionVariables,
  NotificationListQuery,
  NotificationListQueryVariables,
  NotificationType,
  NotificationsQuery,
  Platform,
  ReadAllNotificationMutationMutation,
  ReadAllNotificationMutationMutationVariables,
  ReadNotificationMutationMutation,
  ReadNotificationMutationMutationVariables,
} from '../../codegen/types'
import {
  notificationCreatedSubscription,
  readAllNotificationMutation,
  readNotificationMutation,
} from '../../components/layout/Header/NotificationBell'
import Page from '../../components/page'
import BreadcrumbCreator from '../../components/page/BreadcrumbCreator'
import Title from '../../components/page/Title'
import { authContext } from '../../utils/context'
import NoScrollContainer from '../../utils/NoScrollContainer'

/* ----- query string ----- */
const notificationListQuery = gql`
  query NotificationList(
    $query: NotificationsQuery = {}
    $first: Int = 10
    $after: String
  ) {
    notifications(query: $query, first: $first, after: $after) {
      edges {
        node {
          ...NotificationItem
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${Notification.fragment}
`

/* ----- styled components ----- */
const ButtonsRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
`

/* ----- type and component ----- */
type Category = NotificationCategory | 'all'

export const visibleNotificationCategories = [
  NotificationCategory.Patient,
  NotificationCategory.Order,
  NotificationCategory.Report,
  NotificationCategory.Track,
]
const visibleNotificationTypes = flatten<NotificationType>(
  visibleNotificationCategories.map((category) =>
    getNotificationTypesFromCategory(category)
  )
)

const NotificationList = () => {
  const { t } = useTranslation()
  const [category, setCategory] = useState<Category>('all')
  const auth = useContext(authContext)
  const userId = Auth.utils.getUserEntityIdFromAuth(auth)

  const query: NotificationsQuery = {
    type:
      category === 'all'
        ? visibleNotificationTypes
        : getNotificationTypesFromCategory(category),
    receiver: [userId],
    platform: Platform.Crm,
  }
  const { loading, data, refetch, fetchMore } = useQuery<
    NotificationListQuery,
    NotificationListQueryVariables
  >(notificationListQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    fetchPolicy: 'network-only',
    variables: { query },
  })
  const [readAllNotification] = useMutation<
    ReadAllNotificationMutationMutation,
    ReadAllNotificationMutationMutationVariables
  >(readAllNotificationMutation)
  const [readNotification] = useMutation<
    ReadNotificationMutationMutation,
    ReadNotificationMutationMutationVariables
  >(readNotificationMutation)

  useSubscription<
    NotificationCreatedSubscription,
    NotificationCreatedSubscriptionVariables
  >(notificationCreatedSubscription, {
    variables: {
      receiver: userId,
      type: visibleNotificationTypes,
      platform: Platform.Crm,
    },
    onSubscriptionData: () => refetch(),
  })

  const notificationData = map(prop('node'), data?.notifications?.edges || [])
  const hasMoreNotification = loading
    ? true
    : data?.notifications?.pageInfo.hasNextPage ?? false

  const handleChangeCategory = (category: Category) => setCategory(category)
  const handleRefresh = () => {
    refetch()
  }
  const handleRead = async (id: string, read: boolean) => {
    if (read) return
    try {
      await readNotification({
        variables: { id },
        update: (cache, { data }) => {
          if (data) refetch()
        },
      })
    } catch (error) {
      console.log(error)
    }
  }
  const handleReadAll = async () => {
    if (!userId) return
    try {
      await readAllNotification({
        variables: { receiver: userId },
        update: (cache, { data }) => {
          if (data && data.readAllNotification?.statusCode === '200') {
            refetch()
          }
        },
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleFetchMore = () => {
    fetchMore({
      variables: {
        after: data?.notifications?.pageInfo.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const isNewResult = not(
          equals(
            prev.notifications?.pageInfo,
            fetchMoreResult?.notifications?.pageInfo
          )
        )
        if (fetchMoreResult?.notifications && isNewResult) {
          return {
            notifications: {
              edges: concat(
                prev.notifications?.edges || [],
                fetchMoreResult.notifications.edges
              ),
              pageInfo: fetchMoreResult.notifications.pageInfo,
              __typename: prev.notifications?.__typename,
            },
          }
        }
        return prev
      },
    })
  }

  const header = (
    <>
      <BreadcrumbCreator
        routes={[{ key: 'Home' }, { key: 'NotificationList' }]}
      />
      <Title route={{ key: 'NotificationList' }} />
    </>
  )

  return (
    <Page header={header}>
      <NoScrollContainer>
        <ButtonsRow>
          <Radio.Group
            value={category}
            onChange={(e) => handleChangeCategory(e.target.value)}
          >
            <Radio.Button value='all'>全部</Radio.Button>
            {visibleNotificationCategories.map((category) => (
              <Radio.Button key={category} value={category}>
                {t(`notification.category.${category}`)}
              </Radio.Button>
            ))}
          </Radio.Group>
          <div>
            <Button type='primary' onClick={() => handleReadAll()}>
              全部標示已讀
            </Button>
            <Button onClick={() => handleRefresh()} style={{ marginLeft: 8 }}>
              <ReloadOutlined /> 重新整理
            </Button>
          </div>
        </ButtonsRow>
        <Notification.List
          loading={loading}
          notificationList={notificationData}
          hasMore={hasMoreNotification}
          handleRead={handleRead}
          handleFetchMore={handleFetchMore}
        />
      </NoScrollContainer>
    </Page>
  )
}

export default NotificationList
