import { AntSorterType, TableQuery } from '@sov/common'
import { DateTimeInterval } from '@sov/ui'
import { Button, Table } from 'antd'
import { ColumnProps, TableProps } from 'antd/lib/table'
import moment from 'moment'
import React from 'react'

import { AppointmentFragment } from '../../../codegen/types'
import { sortByAppointmentStart } from './utils'

export type AppointmentListSorterField = 'appointment'

interface OrderTableProps {
  loading: boolean
  data: AppointmentFragment[]
  antdPagination: {
    page: number
    total: number
    limit: number
  }
  sortInfo?: AntSorterType<AppointmentListSorterField>
  handleTableChange: TableProps<AppointmentFragment>['onChange']
  handleSelect: (appointment: AppointmentFragment) => void
}

const AppointmentsTable = (props: OrderTableProps) => {
  const {
    loading,
    data,
    antdPagination,
    sortInfo,
    handleTableChange,
    handleSelect,
  } = props

  const columns: ColumnProps<AppointmentFragment>[] = [
    {
      title: '預計回診日',
      width: 200,
      key: 'appointment',
      dataIndex: 'appointment',
      align: 'center',
      sorter: sortByAppointmentStart,
      sortOrder: sortInfo?.field === 'appointment' ? sortInfo.order : null,
      render: (_text, record) =>
        record ? (
          <DateTimeInterval
            start={moment(record.startDate)}
            end={moment(record.endDate)}
          />
        ) : null,
    },
    {
      title: '備註',
      width: 300,
      key: 'description',
      dataIndex: 'description',
      align: 'center',
      render: (text, record) => <div>{record.description}</div>,
    },
    {
      title: '操作',
      width: 120,
      key: 'id',
      dataIndex: 'id',
      align: 'center',
      render: (_text, record) =>
        record.id ? (
          <Button type='primary' onClick={() => handleSelect(record)}>
            編輯約診
          </Button>
        ) : null,
    },
  ]

  return (
    <Table<AppointmentFragment>
      rowKey='id'
      columns={columns}
      dataSource={data}
      loading={loading}
      onChange={handleTableChange}
      pagination={TableQuery.getAntdPagination(antdPagination)}
    />
  )
}

export default AppointmentsTable
