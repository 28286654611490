import { Form } from '@ant-design/compatible'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { ReloadOutlined } from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'
import {
  DateIntervalParam,
  ErrorHandling,
  FormQuery,
  getDateIntervalQueryString,
  MyStringParam,
  TableQuery,
} from '@sov/common'
import { Button, Input } from 'antd'
import moment, { Moment } from 'moment'
import React from 'react'
import styled from 'styled-components'

import {
  InvoicesQueryQuery,
  InvoicesQueryQueryVariables,
  Payment,
  ShippingStatus,
} from '../../codegen/types'
import RangePicker from '../../components/form/RangePicker'
import Page from '../../components/page'
import BreadcrumbCreator from '../../components/page/BreadcrumbCreator'
import Title from '../../components/page/Title'
import InvoiceTable from '../../components/table/invoice/InvoiceTable'
import NoScrollContainer from '../../utils/NoScrollContainer'

const StyledForm = styled(Form)`
  display: flex;
  align-items: center;
`
const SearchRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`

const InvoiceListFormInput = {
  patientName: MyStringParam,
  payment: MyStringParam,
  shippingDateInterval: DateIntervalParam,
  creditDateInterval: DateIntervalParam,
}

/* ----- query string ----- */
const invoicesQuery = gql`
  query InvoicesQuery(
    $query: InvoiceQuery = {}
    $page: Int = 1
    $limit: Int = 100
    $sort: String = "-updated"
  ) {
    invoices(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        ...InvoiceTable
      }
      limit
      total
      page
    }
  }
  ${InvoiceTable.fragment}
`

/* ----- type and component ----- */
export type InvoiceListSorterField = 'shippingDate'

interface InvoiceFormInput {
  patientName?: string
  payment?: string
  shippingDateInterval?: [Moment, Moment]
  creditDateInterval?: [Moment, Moment]
}

interface InvoiceFormProps extends FormComponentProps<InvoiceFormInput> {
  formQuery: InvoiceFormInput
  handleSearch: (x: FormComponentProps['form']) => void
  handleClear: (x: FormComponentProps['form']) => void
}

const InvoiceForm = Form.create<InvoiceFormProps>()(
  (props: InvoiceFormProps) => {
    const { form, formQuery, handleSearch, handleClear } = props

    return (
      <SearchRow>
        <StyledForm layout='inline'>
          <Form.Item label='病患姓名'>
            {form.getFieldDecorator('patientName', {
              initialValue: formQuery?.patientName,
            })(<Input.Search style={{ width: 150 }} placeholder='病患姓名' />)}
          </Form.Item>
          {/* @TODO 暫時被移除
        <Form.Item label='付款狀態'>
          {form.getFieldDecorator('payment', {
            initialValue: formQuery?.payment
          })(
            <Select style={{ width: 150 }} placeholder='選擇狀態'>
              {Object.values(Payment).map(payment => (
                <Select.Option key={payment}>{t(`invoice.payment.${payment}`)}</Select.Option>
              ))}
            </Select>
          )}
        </Form.Item> */}
          <Form.Item label='出貨日期：'>
            {form.getFieldDecorator('shippingDateInterval', {
              initialValue: formQuery?.shippingDateInterval,
            })(
              <RangePicker
                defaultPickerType='date'
                ranges={{
                  本月: [moment().startOf('month'), moment().endOf('month')],
                  全年: [moment().startOf('year'), moment().endOf('year')],
                }}
              />
            )}
          </Form.Item>
          <Form.Item label='入帳日期：'>
            {form.getFieldDecorator('creditDateInterval', {
              initialValue: formQuery?.creditDateInterval,
            })(
              <RangePicker
                defaultPickerType='date'
                ranges={{
                  本月: [moment().startOf('month'), moment().endOf('month')],
                  全年: [moment().startOf('year'), moment().endOf('year')],
                }}
              />
            )}
          </Form.Item>
          <Button type='primary' onClick={() => handleSearch(form)}>
            搜尋
          </Button>
        </StyledForm>
        <Button onClick={() => handleClear(form)}>
          <ReloadOutlined /> 重新整理
        </Button>
      </SearchRow>
    )
  }
)

const InvoiceList = () => {
  const { paginateQuery, handleTableChange } = TableQuery.useTableQuery({
    limit: 30,
  })
  const { tableQuery, handleTableReset } = TableQuery.useTableQuery<
    any,
    InvoiceListSorterField
  >()
  const { formQuery, handleFormChange, handleFormReset } =
    FormQuery.useFormQuery(InvoiceListFormInput)

  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { data, loading } = useQuery<
    InvoicesQueryQuery,
    InvoicesQueryQueryVariables
  >(invoicesQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      query: {
        patientName: formQuery.patientName,
        payment: formQuery.payment as Payment,
        shippingDateInterval: getDateIntervalQueryString(
          formQuery.shippingDateInterval
        ),
        creditDateInterval: getDateIntervalQueryString(
          formQuery.creditDateInterval
        ),
        shippingStatus: [ShippingStatus.Shipped],
      },
      ...paginateQuery,
    },
  })

  const handleSearch = (form: FormComponentProps['form']) => {
    handleFormChange(form.getFieldsValue())
  }

  const handleClear = (form: FormComponentProps['form']) => {
    form.resetFields()
    handleTableReset()
    handleFormReset()
  }

  const InvoiceTableData = data?.invoices ? data.invoices.docs : []
  const antdPagination = {
    page: data?.invoices?.page || 1,
    total: data?.invoices?.total || 0,
    limit: data?.invoices?.limit || 0,
  }

  const header = (
    <>
      <BreadcrumbCreator routes={[{ key: 'Home' }, { key: 'InvoiceList' }]} />
      <Title
        route={{
          key: 'InvoiceList',
          renderSubtitle: () => <>統計所有 SOV 的出貨項目</>,
        }}
      />
    </>
  )

  return (
    <Page header={header}>
      <NoScrollContainer>
        <InvoiceForm
          formQuery={formQuery}
          handleSearch={handleSearch}
          handleClear={handleClear}
        />
        <InvoiceTable
          loading={loading}
          data={InvoiceTableData}
          sortInfo={tableQuery.sort}
          antdPagination={antdPagination}
          handleTableChange={handleTableChange}
        />
      </NoScrollContainer>
    </Page>
  )
}

export default InvoiceList
