import './index.less'

import { ErrorHandling } from '@sov/common'
import { Button } from 'antd'
import React from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'

const { getErrorFromLocation } = ErrorHandling

const ErrorPage = ({ location }: RouteComponentProps) => {
  const error = getErrorFromLocation(location)
  const { title, message: errorMessage, redirect } = error

  return (
    <div className='error-page' style={{ minHeight: 500 }}>
      <div className='img-block'>
        <div
          className='img-ele'
          style={{
            backgroundImage: `url(https://gw.alipayobjects.com/zos/rmsportal/wZcnGqRDyhPOEYFcZDnb.svg)`,
          }}
        />
      </div>
      <div className='content'>
        <h1>{title}</h1>
        <div className='desc'>{errorMessage}</div>
        <div className='actions'>
          <Link to={redirect.url}>
            <Button type='primary'>返回{redirect.name}</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ErrorPage
