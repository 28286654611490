import { CheckOutlined } from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'
import { ErrorHandling } from '@sov/common'
import Analysis from '@sov/ui/src/components/AnalysisPreview/Analysis'
import CheckPointList from '@sov/ui/src/components/AnalysisPreview/CheckPointList'
import StepList from '@sov/ui/src/components/AnalysisPreview/StepList'
import VersionList from '@sov/ui/src/components/AnalysisPreview/VersionList'
import PatientLink from '@sov/ui/src/components/Link/Patient'
import StageLink from '@sov/ui/src/components/Link/Stage'
import StageName from '@sov/ui/src/components/StageName'
import { Button, Card, Col, Row, Space, Tag } from 'antd'
import { isNil } from 'ramda'
import React, { useState } from 'react'
import { Link as RouteLink, useHistory, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import {
  EvalStageQueryQuery,
  EvalStageQueryQueryVariables,
  OrderType,
  PatientStatus,
  StageStatus,
  StageType,
  VersionListFragment,
} from '../../../codegen/types'
import PatientInfoCard from '../../../components/card/Patient'
import PatientConversationDrawer from '../../../components/conversation/ConversationDrawer'
import useDrawer from '../../../components/conversation/useDrawer'
import CurrentEvalStageModal from '../../../components/modal/CurrentEvalStageModal'
import OrderModal from '../../../components/modal/OrderModal'
import Page from '../../../components/page'
import BreadcrumbCreator from '../../../components/page/BreadcrumbCreator'
import Title from '../../../components/page/Title'

const StyledVersionList = styled(VersionList)`
  margin-top: 16px;
  padding: 0 24px;
`

const evalStageQuery = gql`
  query EvalStageQuery($id: ID!) {
    evalStage(id: $id) {
      id
      isCPMode
      externalVisible
      shouldShowCheckPoint
      stepsOverview {
        path
      }
      ...StageLink
      ...AnalysisPreviewAnalysis
      ...StepList
      ...CheckPointList
      status
      patient {
        ...PatientLink
        ...PatientInfoCard
        ...PatientConversation
        status
        currentEvalStage {
          id
        }
        stages(query: { type: [${StageType.Eval}], status: [${StageStatus.Completed}, ${StageStatus.Started}] }, limit: 20, sort: "-expectedShippingDate") {
          docs {
            id
            ... on EvalStage {
              ...VersionList
            }
          }
        }
      }
    }
  }
  ${StageLink.fragment}
  ${PatientLink.fragment}
  ${PatientInfoCard.fragment}
  ${PatientConversationDrawer.fragment.PatientConversation}
  ${Analysis.fragment}
  ${StepList.fragment}
  ${CheckPointList.fragments.CheckPointList}
  ${VersionList.fragments.VersionList}
`

const StageDetail = () => {
  const drawer = useDrawer()
  const {
    params: { stageId },
  } = useRouteMatch<{ stageId: string }>()
  const history = useHistory()

  const [confirmModalVisible, setConfirmModalVisible] = useState(false)
  const [shouldFetchPatientOrders, setShouldFetchPatientOrders] =
    useState(false)

  const { toErrorPage } = ErrorHandling.useErrorHandling()

  const { data, loading } = useQuery<
    EvalStageQueryQuery,
    EvalStageQueryQueryVariables
  >(evalStageQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    fetchPolicy: 'no-cache',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: { id: stageId },
  })

  const evalStage = data?.evalStage

  if (loading) {
    return <Page loading={loading} />
  }

  if (!evalStage) {
    return (
      <Page>
        <>無此資料</>
      </Page>
    )
  }
  const patient = evalStage.patient
  const currentEvalStage = patient.currentEvalStage
  const evalStages = (patient?.stages?.docs || []) as VersionListFragment[]

  const handleChange = (index: number) => {
    const stageId = evalStages[index].id
    history.push(`/stages/${stageId}`)
  }
  const handleCurrentEvalStageModalClose = () => {
    setConfirmModalVisible(false)
  }
  const handleQueryStop = () => {
    setShouldFetchPatientOrders(false)
  }

  const orderAction = () => {
    setConfirmModalVisible(false)
    setShouldFetchPatientOrders(true)
  }
  const header = (
    <>
      <Row justify='space-between'>
        <BreadcrumbCreator
          routes={[
            { key: 'Home' },
            { key: 'PatientList' },
            {
              key: 'PatientDetail',
              render: () => <PatientLink item={patient} />,
            },
            {
              key: 'StageDetail',
              render: () => (
                <StageLink to={`/stages/${evalStage.id}`} item={evalStage} />
              ),
            },
          ]}
        />
        <Space>
          {patient.status === PatientStatus.OnEval &&
            evalStage.status === StageStatus.Completed && (
              <Button
                type='primary'
                onClick={() => setConfirmModalVisible(true)}
              >
                <CheckOutlined />
                {currentEvalStage ? '修改' : '設定'}主要報告
              </Button>
            )}
          <Button type='default' disabled={isNil(evalStage.stepsOverview)}>
            <a href={evalStage.stepsOverview?.path}>下載齒列移動表</a>
          </Button>
          {evalStage.externalVisible && (
            <RouteLink to={`/stages/${stageId}/preview`}>
              <Button type='default'>列印預覽</Button>
            </RouteLink>
          )}
          <RouteLink to={`/viewer?patientId=${patient.id}&stageId=${stageId}`}>
            <Button type='primary'>查看 Viewer</Button>
          </RouteLink>
        </Space>
        <CurrentEvalStageModal
          isCurrentEvalStageModalVisible={confirmModalVisible}
          handleCurrentEvalStageModalClose={handleCurrentEvalStageModalClose}
          patientId={patient.id}
          orderAction={() => {
            orderAction()
          }}
        />
        <OrderModal
          orderType={OrderType.NormalPrint}
          patientId={patient.id}
          shouldFetchPatientOrders={shouldFetchPatientOrders}
          handleQueryStop={handleQueryStop}
        />
      </Row>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Title
          route={{
            key: 'StageDetail',
            render: () => <StageName item={evalStage} />,
          }}
        />
        <span>
          {stageId === currentEvalStage?.id && (
            <Tag color='green'>主要報告</Tag>
          )}
        </span>
      </div>
    </>
  )

  return (
    <Page header={header}>
      <Row gutter={16}>
        <Col span={18}>
          <div style={{ padding: '24px 0', background: 'white' }}>
            {evalStage.externalVisible ? (
              <>
                {evalStage.isCPMode && evalStage.shouldShowCheckPoint && (
                  <CheckPointList stage={evalStage} />
                )}
                <Analysis stage={evalStage} />
                {!evalStage.isCPMode && <StepList stage={evalStage} />}
              </>
            ) : (
              '本報告正由牙技師進行中，麻煩您耐心等候，有額外提醒可以利用右側的聊天室進行討論'
            )}
          </div>
        </Col>
        <Col span={6}>
          <Card title='基本資料'>
            <PatientInfoCard patient={patient} />
          </Card>
          <StyledVersionList
            currentId={stageId}
            list={evalStages}
            handleChange={handleChange}
          />
        </Col>
      </Row>
      <PatientConversationDrawer patient={patient} drawer={drawer} />
    </Page>
  )
}

export default StageDetail
