import { Auth } from '@sov/common'
import { Button } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import { includes } from 'ramda'
import React, { useContext, useEffect } from 'react'
import { Subject } from 'rxjs'
import { take } from 'rxjs/operators'

import { AllPrivilege } from '../../codegen/types'
import { authContext } from '../../utils/context'

const { getAuthPrivileges } = Auth.utils

type SubjectType = React.MouseEvent<HTMLElement, MouseEvent>
const useTakeOnce = ({ handleClick }) => {
  const subject = new Subject<SubjectType>()

  useEffect(() => {
    /** component unmount 要 unsubscribe subject */
    return () => {
      subject.unsubscribe()
    }
  }, [])

  subject.pipe(take(1)).subscribe(handleClick)

  return {
    handleTakeOnceClick: () => subject.next(),
  }
}

/** UI 更新前只會觸發一次點擊事件 */
export type OnceButtonProps = ButtonProps & {
  label: string
  requiredPrivilege?: AllPrivilege
}

const OnceButton = (props: OnceButtonProps) => {
  const auth = useContext(authContext)
  const privilegesOfUser = getAuthPrivileges(auth)
  const hasRequiredPrivilege = props.requiredPrivilege
    ? includes(props.requiredPrivilege, privilegesOfUser)
    : true

  if (!hasRequiredPrivilege) {
    return null
  }

  const { handleTakeOnceClick } = useTakeOnce({ handleClick: props.onClick })

  return (
    <Button {...props} onClick={handleTakeOnceClick}>
      {props.label}
    </Button>
  )
}

interface UpdateButtonProps {
  isVisible: boolean
  onClick: ButtonProps['onClick']
}

const UpdateButton = ({ isVisible, onClick }: UpdateButtonProps) => {
  if (!isVisible) {
    return null
  }

  return (
    <OnceButton
      label='更新資料'
      onClick={onClick}
      style={{ marginRight: 24 }}
      type='primary'
    />
  )
}

export { OnceButton, UpdateButton }
