import { Button, Form, Input, Modal } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { ModalProps } from 'antd/lib/modal'
import React, { FC } from 'react'

export interface FormFields {
  doctorInstruction: {
    note: string
  }
}

type InitialValues = FormFields
interface Props {
  form: FormInstance<FormFields>
  initialValues: InitialValues
  onCancel: ModalProps['onCancel']
  onOk: ModalProps['onOk']
  visible: boolean
}

const NoteEditModal: FC<Props> = (props) => {
  const { form, initialValues, onCancel, onOk, visible } = props

  return (
    <Modal
      footer={[
        <Button key='cancel' onClick={onCancel}>
          取消
        </Button>,
        <Button key='submit' type='primary' onClick={onOk}>
          儲存
        </Button>,
      ]}
      onCancel={onCancel}
      title='醫生備忘'
      visible={visible}
    >
      <Form form={form} initialValues={initialValues}>
        <Form.Item label='醫生備忘:' name={['doctorInstruction', 'note']}>
          <Input.TextArea autoSize placeholder='可以記錄你對病患的註記' />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default NoteEditModal
