import { gql } from '@apollo/client'
import { AntSorterType, TableQuery } from '@sov/common'
import { Badge } from 'antd'
import Table, { ColumnProps, TableProps } from 'antd/lib/table'
import moment from 'moment'
import { isEmpty, isNil, map } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { TrackTableListFragment } from '../../../../codegen/types'
import { PatientTrackSorterField } from '../../../../pages/patient/PatientTrackList'
import PhotoThumbnailList from './PhotoThumbnailList'

interface TrackTableListProps {
  loading: boolean
  data: TrackTableListFragment[]
  sortInfo?: AntSorterType<PatientTrackSorterField>
  antdPagination: {
    page: number
    total: number
    limit: number
  }
  handleTableChange: TableProps<TrackTableListFragment>['onChange']
}

const TrackTableList = (props: TrackTableListProps) => {
  const { loading, data, sortInfo, antdPagination, handleTableChange } = props
  const { t } = useTranslation()

  const columns: ColumnProps<TrackTableListFragment>[] = [
    {
      title: 'Step',
      width: 40,
      key: 'stage',
      render: (value, record) => <div>Step {record.stage.serialNumber}</div>,
    },
    {
      title: '戴牙套照片',
      width: 60,
      key: 'withBrace',
      render: (value, record) =>
        record.photo?.withBrace && (
          <PhotoThumbnailList photoMap={record.photo?.withBrace} />
        ),
    },
    {
      title: '未戴牙套照片',
      width: 60,
      key: 'withoutBrace',
      render: (value, record) =>
        record.photo?.withoutBrace && (
          <PhotoThumbnailList photoMap={record.photo?.withoutBrace} />
        ),
    },
    {
      title: '狀況',
      width: 40,
      key: 'mainIssues',
      render: (value, record) => (
        <div>
          {isEmpty(record.mainIssues)
            ? '無'
            : map(
                (issue) => <div>{t(`track.issue.${issue}`)}</div>,
                record.mainIssues
              )}
        </div>
      ),
    },
    {
      title: '額外狀況',
      width: 40,
      key: 'extraIssue',
      render: (value, record) => <div>{record.extraIssue || '無'}</div>,
    },
    {
      title: '上傳日期',
      width: 40,
      key: 'created',
      dataIndex: 'created',
      sorter: true,
      sortOrder: sortInfo?.field === 'created' ? sortInfo.order : undefined,
      render: (value, record) => (
        <div>{moment(record.created).format('YYYY-MM-DD')}</div>
      ),
    },
    {
      title: '技師回覆內容',
      width: 100,
      key: 'reply',
      dataIndex: 'reply',
      render: (value) => value,
    },
    {
      title: '狀態',
      width: 30,
      key: 'isReply',
      render: (value, record) =>
        isNil(record.reply) ? (
          <div>
            <Badge status='error' />
            未回覆
          </div>
        ) : (
          <div>
            <Badge status='success' />
            已回覆
          </div>
        ),
    },
  ]

  return (
    <Table<TrackTableListFragment>
      rowKey='id'
      columns={columns}
      dataSource={data}
      loading={loading}
      onChange={handleTableChange}
      pagination={TableQuery.getAntdPagination(antdPagination)}
    />
  )
}

/* ----- fragment ----- */
TrackTableList.fragment = gql`
  fragment TrackTableList on Track {
    id
    stage {
      id
      serialNumber
    }
    photo {
      withBrace {
        ...PhotoThumbnailList
      }
      withoutBrace {
        ...PhotoThumbnailList
      }
    }
    created
    mainIssues
    extraIssue
    reply
  }
  ${PhotoThumbnailList.fragments.PhotoThumbnailList}
`

export default TrackTableList
