import { gql } from '@apollo/client'

import {
  ConversationInfo,
  MessageInfo,
} from '../../../components/conversation/fragments'

export const conversationQuery = gql`
  query conversationQuery($id: ID!) {
    conversation(id: $id) {
      ...conversationInfo
    }
  }
  ${ConversationInfo}
`

export const conversationMessagesQuery = gql`
  query conversationMessagesQuery(
    $query: ConversationMessagesQueryInput
    $id: ID!
    $first: Int
    $after: String
  ) {
    conversationMessages(query: $query, id: $id, first: $first, after: $after) {
      edges {
        node {
          ...messageInfo
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${MessageInfo}
`

export const numberOfUnreadMessagesQuery = gql`
  query numberOfUnreadMessagesQuery($id: ID!) {
    numberOfUnreadMessages(id: $id)
  }
`

export const conversationsWithLatestMessageQuery = gql`
  query conversationsWithLatestMessageQuery(
    $query: ConversationsWithLatestMessageQuery = {}
    $first: Int
    $after: String
  ) {
    conversationsWithLatestMessage(
      query: $query
      first: $first
      after: $after
    ) {
      edges {
        node {
          id
          latestMessage {
            ...messageInfo
          }
          numberOfUnreadMessages
          patient {
            id
            name
          }
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${MessageInfo}
`
