const Emphasize = 'rgba(0, 0, 0, 0.85)'
const Default = 'rgba(0, 0, 0, 0.65)'
const Secondary = 'rgba(0, 0, 0, 0.45)'
const Disabled = 'rgba(0, 0, 0, 0.25)'
const ClearBlue = '#1890ff'
const LightBlue = '#bae7ff'
const PaleGrey = '#f0f2f5'
const BorderGrey = '#ededed'
const DividerGrey = '#d9d9d9'
const White = '#ffffff'
const Purple = '#897fd1'
const Grey = '#9097b2'
const Green = '#98e9c5'
const Yellow = '#f9eaa1'
const Black = '#000000'

const hexToRGBA = (hex: string, alpha?: number) => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  } else {
    return `rgb(${r}, ${g}, ${b})`
  }
}

export {
  Black,
  BorderGrey,
  ClearBlue,
  Default,
  Disabled,
  DividerGrey,
  Emphasize,
  Green,
  Grey,
  hexToRGBA,
  LightBlue,
  PaleGrey,
  Purple,
  Secondary,
  White,
  Yellow,
}
