import { List } from 'antd'
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

import { NotificationItemFragment } from '../../codegen/types'
import NotificationItem from './NotificationItem'

const Footer = styled.div`
  text-align: center;
  font-size: 12px;
  padding: 6px 0;
  color: rgba(0, 0, 0, 0.45);
  background-color: #f0f2f5;
`

interface NotificationListProps {
  loading?: boolean
  hasMore?: boolean
  popoverMode?: boolean
  notificationList: NotificationItemFragment[]
  handleFetchMore?: () => void
  handleRead?: (id: string, read: boolean) => Promise<void>
}

const NotificationList = (props: NotificationListProps) => {
  const {
    loading = false,
    hasMore = false,
    popoverMode = false,
    notificationList,
    handleFetchMore,
    handleRead,
  } = props

  const footerRef = useRef<HTMLDivElement>(null)

  const handleScroll = () => {
    const elementBottom = footerRef.current?.getBoundingClientRect().bottom
    if (
      !loading &&
      handleFetchMore &&
      hasMore &&
      elementBottom &&
      elementBottom < window.innerHeight
    ) {
      handleFetchMore()
    }
  }

  useEffect(() => {
    document.addEventListener('wheel', handleScroll)
    return () => {
      document.removeEventListener('wheel', handleScroll)
    }
  }, [loading])

  return (
    <List
      loading={loading}
      dataSource={notificationList}
      renderItem={(notification) => (
        <NotificationItem notification={notification} handleRead={handleRead} />
      )}
      footer={
        popoverMode ? null : (
          <Footer ref={footerRef}>
            {hasMore === true ? '下拉加載更多通知' : '沒有更多通知'}
          </Footer>
        )
      }
    />
  )
}

export default NotificationList
