import { useApolloClient } from '@apollo/client'
import { GqlUpload } from '@sov/ui'
import { Form, Modal, Progress } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { UploadChangeParam } from 'antd/lib/upload'
import { UploadFile } from 'antd/lib/upload/interface'
import { identity, ifElse, isNil, map, pipe, prop, takeLast } from 'ramda'
import React, { MouseEvent, useState } from 'react'
import styled from 'styled-components'

import { File, FileInfoFragment, PhotosInput } from '../../../codegen/types'
import PatientPhotoCard, {
  PatientPhotoCardProps,
} from '../../card/PatientPhotoCard'
import {
  PhotoInfo,
  extraOralPhotos,
  intraOralPhotos,
  xRayPhotos,
} from './patientPhotoMapping'

const takeLastFromFileList = pipe<
  UploadChangeParam,
  UploadChangeParam['fileList'],
  UploadChangeParam['fileList']
>(prop('fileList'), takeLast(1))
export const mapItemPhotosToResponses = ifElse(
  isNil,
  identity,
  map<UploadFile<File>, string>((photo) => photo?.[0]?.response.id || null)
)

const PhotoListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 600px;
`

type PhotoUploadProps = PatientPhotoCardProps

const PhotoUpload = (props: PhotoUploadProps) => {
  const {
    title,
    aspect,
    placeholder,
    file,
    width,
    onPreviewClick,
    onDownloadClick,
    onDeleteClick,
    ...restProps
  } = props
  const client = useApolloClient()

  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)

  const handleLoading = (val: boolean) => {
    setLoading(val)
  }
  const handleProgress = (val: number) => {
    setProgress(val)
  }

  return (
    <GqlUpload.Upload
      {...restProps}
      client={client}
      fileType='image'
      accept='image/*'
      uploadOptions={{ needThumbnail: true, prefix: 'patient' }}
      cropOption={{ aspect, shape: 'rect', modalTitle: title }}
      showUploadList={false}
      handleLoading={handleLoading}
      handleProgress={handleProgress}
    >
      <PatientPhotoCard
        title={title}
        aspect={aspect}
        placeholder={placeholder}
        file={file}
        width={width}
        onPreviewClick={onPreviewClick}
        onDownloadClick={onDownloadClick}
        onDeleteClick={onDeleteClick}
      >
        {loading && <Progress percent={progress} size='small' />}
      </PatientPhotoCard>
    </GqlUpload.Upload>
  )
}

export interface FormPhotoFields {
  photos: {
    [key in keyof PhotosInput]: UploadFile<File>
  }
}
type FormPhotoInitialValues = Partial<FormPhotoFields>

interface PhotoFieldProps {
  disabled?: boolean
  /** pass by Form.Item */
  fileList?: UploadFile<FileInfoFragment>[]
  photoInfo: PhotoInfo
  setFieldsValue: FormInstance<FormPhotoFields>['setFieldsValue']
  showPreview?: (imageUrl?: string) => void
  width: number
}

/** show readOnly card or upload card base on `disabled` props */
const PhotoField = (props: PhotoFieldProps) => {
  const {
    disabled,
    fileList,
    photoInfo,
    setFieldsValue,
    showPreview,
    width,
    ...restProps
  } = props

  const file = fileList?.[0]?.response

  const onPreviewClick = (e: MouseEvent) => {
    showPreview?.(file?.path)
    e.stopPropagation()
  }
  const onDownloadClick = (e: MouseEvent) => {
    window.open(file?.path)
    e.stopPropagation()
  }
  const onDeleteClick = (e: MouseEvent) => {
    setFieldsValue({ photos: { [photoInfo.key]: undefined } })
    e.stopPropagation()
  }

  const patientPhotoCardProps: PatientPhotoCardProps = {
    title: photoInfo.title,
    aspect: photoInfo.aspect,
    placeholder: photoInfo.placeholder,
    width,
    file,
    onPreviewClick,
    onDownloadClick,
    onDeleteClick,
  }

  if (disabled) {
    return <PatientPhotoCard {...patientPhotoCardProps} />
  }

  const photoUploadProps = {
    ...patientPhotoCardProps,
    ...restProps,
  }

  return <PhotoUpload {...photoUploadProps} />
}

interface Props {
  form: FormInstance<FormPhotoFields>
  initialValues?: FormPhotoInitialValues
}

const PatientFormPhoto = (props: Props) => {
  const { form, initialValues } = props
  const { setFieldsValue } = form

  const [previewImageSrc, setPreviewImageSrc] = useState<string>()

  const showPreview = (imageUrl?: string) => setPreviewImageSrc(imageUrl)
  const closePreview = () => setPreviewImageSrc(undefined)

  return (
    <Form form={form} initialValues={initialValues}>
      <Form.Item label='口外照'>
        <PhotoListContainer>
          {extraOralPhotos.map((photoInfo) => (
            <Form.Item
              key={photoInfo.key}
              name={['photos', photoInfo.key]}
              valuePropName='fileList'
              getValueFromEvent={takeLastFromFileList}
            >
              <PhotoField
                photoInfo={photoInfo}
                setFieldsValue={setFieldsValue}
                showPreview={showPreview}
                width={180}
              />
            </Form.Item>
          ))}
        </PhotoListContainer>
      </Form.Item>

      <Form.Item label='口內照'>
        <PhotoListContainer>
          {intraOralPhotos.map((photoInfo) => (
            <Form.Item
              key={photoInfo.key}
              name={['photos', photoInfo.key]}
              valuePropName='fileList'
              getValueFromEvent={takeLastFromFileList}
            >
              <PhotoField
                photoInfo={photoInfo}
                setFieldsValue={setFieldsValue}
                showPreview={showPreview}
                width={180}
              />
            </Form.Item>
          ))}
        </PhotoListContainer>
      </Form.Item>

      <Form.Item label='Ceph'>
        <PhotoListContainer>
          <Form.Item
            key={xRayPhotos[0].key}
            name={['photos', 'ceph']}
            valuePropName='fileList'
            getValueFromEvent={takeLastFromFileList}
          >
            <PhotoField
              photoInfo={xRayPhotos[0]}
              setFieldsValue={setFieldsValue}
              showPreview={showPreview}
              width={180}
            />
          </Form.Item>
        </PhotoListContainer>
      </Form.Item>

      <Form.Item label='Pano'>
        <PhotoListContainer>
          <Form.Item
            key={xRayPhotos[1].key}
            name={['photos', 'pano']}
            valuePropName='fileList'
            getValueFromEvent={takeLastFromFileList}
          >
            <PhotoField
              photoInfo={xRayPhotos[1]}
              setFieldsValue={setFieldsValue}
              showPreview={showPreview}
              width={376}
            />
          </Form.Item>
        </PhotoListContainer>
      </Form.Item>

      <Modal
        title='預覽圖'
        visible={!isNil(previewImageSrc)}
        onCancel={closePreview}
        footer={null}
        maskClosable
        centered
      >
        <img src={previewImageSrc} style={{ width: '100%', height: '100%' }} />
      </Modal>
    </Form>
  )
}

export default PatientFormPhoto
