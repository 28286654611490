import { ApolloQueryResult } from '@apollo/client'
import { Drawer } from 'antd'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'

import {
  AppointmentFragment,
  AppointmentsQueryQuery,
  AppointmentsQueryQueryVariables,
} from '../../codegen/types'
import AppointmentCard from './AppointmentCard'

const StyledAppointmentCard = styled(AppointmentCard)`
  padding: 16px 12px;
  margin-top: 6px;
  border-left: 4px solid #52c41a;

  button {
    display: none;
  }

  .appointment-card-header {
    display: flex;

    .name {
      font-size: 16px;
      font-weight: 600;
      margin-right: 16px;
    }
    .date {
      line-height: 20px;
      flex: 1;
    }
  }

  .appointment-card-body {
    .field {
      flex: 1;
      margin-top: 10px;

      .field-title {
        margin-right: 8px;
        color: rgba(0, 0, 0, 0.45);
      }
    }
  }

  &:hover {
    background-color: #e6f7ff;

    button {
      display: initial;
    }
  }
`

interface Props {
  date?: moment.Moment
  appointments: AppointmentFragment[]
  visible: boolean
  onClose: () => void
  handleSelect: (appointment: AppointmentFragment) => void
  reset: () => void
  refetch: (
    variables?: AppointmentsQueryQueryVariables | undefined
  ) => Promise<ApolloQueryResult<AppointmentsQueryQuery>>
}

const AppointmentDrawer = (props: Props) => {
  const { date, appointments, visible, onClose, handleSelect, reset, refetch } =
    props

  return (
    <Drawer
      width={520}
      title={`${moment(date).format('MM / DD (dddd)')} 約診資料`}
      placement='right'
      closable
      onClose={onClose}
      visible={visible}
    >
      <div>若無主動編輯約診時間，則以 - 表示</div>
      {appointments.map((appointment) => {
        return (
          <StyledAppointmentCard
            key={appointment.id}
            appointment={appointment}
            handleSelect={handleSelect}
            reset={reset}
            refetch={refetch}
          />
        )
      })}
    </Drawer>
  )
}

export default AppointmentDrawer
