import { evolve } from 'ramda'

import { FormDoctorInstructionFields } from './DoctorInstruction'
import { FormGeneralFields, treatArchesTransformer } from './General'
import { FormIntraOralFields } from './IntraOral'
import { FormMalocclusionsFields } from './Malocclusions'
import { FormPhotoFields, mapItemPhotosToResponses } from './Photo'
import { FormProfileFields } from './Profile'
import { FormSymptomFields } from './Symptoms'

export type PatientForm = FormGeneralFields &
  FormPhotoFields &
  FormProfileFields &
  FormIntraOralFields &
  FormSymptomFields &
  FormMalocclusionsFields &
  FormDoctorInstructionFields

export const payloadTransformer = evolve({
  photos: mapItemPhotosToResponses,
  treatArches: treatArchesTransformer,
})
