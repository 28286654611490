import React, { ReactNode } from 'react'
import styled from 'styled-components'

import BackLink from './BackLink'
import Description from './Description'
import Title from './Title'

const FormContainer = styled.div`
  margin-top: 24px;
`

interface Props {
  title?: string
  description?: ReactNode
  content?: ReactNode
  backLink?: string | ReactNode
}

const Layout = (props: Props) => {
  const { title, description, content, backLink } = props
  return (
    <>
      <Title>{title} </Title>
      <Description>{description}</Description>
      <FormContainer>{content}</FormContainer>
      <BackLink backLink={backLink} />
    </>
  )
}

export default Layout
