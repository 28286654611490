import { Progress } from 'antd'
import React from 'react'
import styled from 'styled-components'

import { PatientStatus } from '../../../codegen/types'
import { getEstimatedMonth } from '../../../utils'

// ----- constant -----
const color = {
  disabled: 'rgba(0, 0, 0, 0.25)',
  secondary: 'rgba(0, 0, 0, 0.45)',
  default: 'rgba(0, 0, 0, 0.65)',
  emphasize: 'rgba(0, 0, 0, 0.85)',
}
export const avatarSize = 100

// ----- styled components -----
export const PatientName = styled.span`
  font-size: 24px;
  margin-right: 16px;
  color: ${color.emphasize};
`
const PatientInfoContainer = styled.div<{ rtl?: boolean }>`
  font-size: 14px;
  min-width: 80px;
  ${(props) => props.rtl && 'text-align: right'}
`
const PatientInfoTitle = styled.div<{ dense?: boolean }>`
  color: ${color.secondary};
  ${(props) => !props.dense && 'margin-bottom: 8px;'}
`
const PatientInfoValue = styled.div`
  color: ${color.default};
`
const PatientInfoValueEmphasize = styled.div`
  color: ${color.default};
  margin-top: 0;
  line-height: 1.33;
  font-size: 24px;
`
const ProgressContainer = styled.div`
  display: flex;
`
export const VerticalDivider = styled.div`
  width: 1px;
  height: 50px;
  margin: 0 32px;
  background-color: ${color.disabled};
`
export const RowRightFields = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
export const ContentRow = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
`
export const Container = styled.div`
  width: calc(100% - ${avatarSize}px);
`
export const ContainerWithAvatar = styled.div`
  padding: 24px 0;
  display: flex;
  align-items: flex-start;
`

// ----- custom components -----
interface PatientInfoProps {
  title: string
  value: string | React.ReactElement
  emphasize?: boolean
  dense?: boolean
  rtl?: boolean
}

export const PatientInfo = (props: PatientInfoProps) => {
  const { title, value, emphasize, dense, rtl } = props
  return (
    <PatientInfoContainer rtl={rtl}>
      <PatientInfoTitle dense={dense}>{title}</PatientInfoTitle>
      {typeof value === 'string' ? (
        emphasize === true ? (
          <PatientInfoValueEmphasize>{value || '--'}</PatientInfoValueEmphasize>
        ) : (
          <PatientInfoValue>{value || '--'}</PatientInfoValue>
        )
      ) : (
        value
      )}
    </PatientInfoContainer>
  )
}

interface TreatmentProgressProps {
  steps: number
  finishedSteps: number
  patientStatus: PatientStatus
}

export const TreatmentProgress = (props: TreatmentProgressProps) => {
  const { steps = 1, finishedSteps = 0, patientStatus } = props
  const isInProgress = patientStatus === PatientStatus.OnPrint
  const completedPercent = (finishedSteps / steps) * 100
  const stepsLeft = steps - finishedSteps
  // @TODO 確切的預估計算還需討論
  const estimatedMonthLeft = getEstimatedMonth(stepsLeft)

  return (
    <ProgressContainer>
      {patientStatus === PatientStatus.Initial && <>新病患</>}
      {patientStatus === PatientStatus.OnEval && <>評估中</>}
      {patientStatus === PatientStatus.OnPrint && (
        <>
          <PatientInfoValue>
            {estimatedMonthLeft === 0
              ? '已完成'
              : `預估剩餘 ${isInProgress ? estimatedMonthLeft : '--'} 個月`}
          </PatientInfoValue>
          <Progress
            style={{ width: '250px', paddingLeft: '16px' }}
            percent={completedPercent}
            showInfo={false}
          />
        </>
      )}
      {patientStatus === PatientStatus.Completed && <>已完結</>}
      {patientStatus === PatientStatus.Inactive && <>已完結</>}
    </ProgressContainer>
  )
}
