import React from 'react'

import { TabKey, accountSubRoutes } from './TabConfig'

interface Props {
  accountId: string
  tabKey: TabKey
}

const TabContent = (props: Props) => {
  const { accountId, tabKey } = props
  const Component = accountSubRoutes[tabKey].component

  return <Component accountId={accountId} tabKey={tabKey} />
}

export default TabContent
