import { test } from 'ramda'
import React from 'react'
import { Canvas } from 'react-three-fiber'
import styled from 'styled-components'

import { JawPosition } from '../../../codegen/types'
import { MESH_TYPE } from '../constant/controller'
import { MeshColor } from '../constant/meshColor'
import { CANVAS_HEIGHT, CANVAS_WIDTH, ZOOM } from '../constant/size'
import { ControllerAction, ControllerState } from '../useController'
import { getMeshType } from '../utils'
import Axes from './Axes'
import Controls from './Controls'
import Lights from './Lights'
import Mesh from './Mesh'
import { ViewerItem } from '..'

const Container = styled.div`
  height: 100%;
`

interface ViewerProps {
  controller: ControllerState
  visibleItems: ViewerItem[]
  dispatch: React.Dispatch<ControllerAction>
}

const Viewer = (props: ViewerProps) => {
  const { visibleItems, controller, dispatch } = props
  const {
    cameraView,
    isCameraViewChanging,
    visibleType,
    isAxesVisible,
    viewMethod,
    location,
  } = controller

  const handleCameraChangingFinish = () => {
    dispatch({ type: 'setCameraFinish' })
  }

  return (
    <Container>
      <Canvas
        camera={{
          position: [0, 0, 75],
          left: -CANVAS_WIDTH / ZOOM,
          right: CANVAS_WIDTH / ZOOM,
          top: CANVAS_HEIGHT / ZOOM,
          bottom: -CANVAS_HEIGHT / ZOOM,
          zoom: ZOOM,
        }}
        orthographic
        pixelRatio={window.devicePixelRatio}
      >
        <Axes isAxesVisible={isAxesVisible} />
        <Controls
          cameraView={cameraView}
          isCameraViewChanging={isCameraViewChanging}
          handleCameraChangingFinish={handleCameraChangingFinish}
          viewMethod={viewMethod}
        />
        <Lights />
        {visibleItems
          .filter((item) => item.meshes.every((mesh) => mesh.geometry))
          .map((item) =>
            item.meshes
              .filter((mesh) => {
                // 無 geometry 則不顯示
                if (!mesh.geometry) return false

                // 如果是上顎牙齒，則不能 "只顯示下顎" 且 "只顯示牙肉"
                if (test(/FDI_(1|2)/, mesh.position)) {
                  return location !== 'LOWER' && visibleType !== 'JAW'
                }

                // 如果是下顎牙齒，則不能 "只顯示上顎" 且 "只顯示牙肉"
                if (test(/FDI_(3|4)/, mesh.position)) {
                  return location !== 'UPPER' && visibleType !== 'JAW'
                }

                // 如果是上顎牙肉，則不能 "只顯示上顎" 且 "只顯示牙肉"
                if (mesh.position === JawPosition.Upper) {
                  return location !== 'LOWER' && visibleType !== 'TEETH'
                }

                // 如果是下顎牙肉，則不能 "只顯示下顎" 且 "只顯示牙肉"
                if (mesh.position === JawPosition.Lower) {
                  return location !== 'UPPER' && visibleType !== 'TEETH'
                }

                return true
              })
              .map((mesh) => (
                <Mesh
                  key={mesh.url}
                  color={
                    getMeshType(mesh) === MESH_TYPE.JAW
                      ? MeshColor.Pink
                      : item.color
                  }
                  meshInfo={mesh}
                  viewMethod={viewMethod}
                />
              ))
          )}
      </Canvas>
    </Container>
  )
}

export default Viewer
