import { gql, useQuery } from '@apollo/client'
import { ErrorHandling } from '@sov/common'
import { Row, Spin } from 'antd'
import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { DeepNonNullable } from 'utility-types'

import {
  PatientDetailQueryQuery,
  PatientDetailQueryQueryVariables,
  StageStatus,
  StageType,
} from '../../../codegen/types'
import EvalStages from './EvalStages'
import Note from './Note'
import PrintStages from './PrintStages'

export const patientDetailQuery = gql`
  query PatientDetailQuery(
    $id: ID!
    $query: StagesQuery = {}
    $page: Int = 1
    $limit: Int = 100
    $sort: String = "-expecedShippingDate"
  ) {
    patient(id: $id) {
      id
      name
      ...PatientDetailCurrentEvalStage
      ...PatientDetailDoctorInstruction
      ...PatientDetailPrintStagePatient
      stages(query: $query, page: $page, limit: $limit, sort: $sort) {
        docs {
          id
          __typename
          ... on EvalStage {
            ...PatientDetailEvalStage
          }
          ... on PrintStage {
            ...PatientDetailPrintStage
          }
        }
      }
    }
  }
  ${PrintStages.fragments.PatientDetailPrintStage}
  ${PrintStages.fragments.PatientDetailPrintStagePatient}
  ${EvalStages.fragments.PatientDetailCurrentEvalStage}
  ${EvalStages.fragments.PatientDetailEvalStage}
  ${Note.fragment.PatientDetailDoctorInstruction}
`

type StageItemType =
  DeepNonNullable<PatientDetailQueryQuery>['patient']['stages']['docs'][0]

interface ContentProps {
  patient: PatientDetailQueryQuery['patient']
}

const Content = (props: ContentProps) => {
  const { patient } = props
  const stages = patient?.stages?.docs ?? []
  const currentEvalStage = patient?.currentEvalStage
  const evalStages = stages.filter(
    (stage): stage is Extract<StageItemType, { __typename: 'EvalStage' }> =>
      stage.__typename === 'EvalStage'
  )
  const printStages = stages.filter(
    (stage): stage is Extract<StageItemType, { __typename: 'PrintStage' }> =>
      stage.__typename === 'PrintStage'
  )

  return (
    <>
      <PrintStages patient={patient} stages={printStages} />
      <Row style={{ marginBottom: 16 }} />
      <EvalStages
        patient={patient}
        stages={evalStages}
        currentEvalStage={currentEvalStage}
      />
      <Row style={{ marginBottom: 16 }} />
      <Note note={patient?.doctorInstruction.note} />
    </>
  )
}

interface RouterProps {
  patientId: string
}

const PatientDetail = () => {
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const match = useRouteMatch<RouterProps>()
  const {
    params: { patientId },
  } = match
  const { data, loading } = useQuery<
    PatientDetailQueryQuery,
    PatientDetailQueryQueryVariables
  >(patientDetailQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    fetchPolicy: 'no-cache',
    variables: {
      id: patientId,
      query: {
        status: [StageStatus.Completed, StageStatus.Started],
        type: [StageType.Eval, StageType.Print],
      },
      page: 1,
      limit: 100,
      sort: '-expectedShippingDate',
    },
  })

  if (loading) {
    return <Spin />
  }

  const patient = data?.patient

  return <Content patient={patient} />
}

export default PatientDetail
