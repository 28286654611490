import { gql, useMutation, useQuery } from '@apollo/client'
import { ErrorHandling } from '@sov/common'
import { Button, Form, message } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import { cond } from 'ramda'
import React, { useState } from 'react'

import {
  CreateOrdersMutation,
  CreateOrdersMutationVariables,
  OrderModalQuery,
  OrderModalQueryVariables,
  OrderType,
  PatientStatus,
} from '../../../codegen/types'
import HintModal from './HintModal'
import InProgressModalBody from './HintModal/InProgressModalBody'
import WithoutCurrentReportModalBody from './HintModal/WithoutCurrentReportModalBody'
import OrderFormModal from './OrderFormModal'
import CompletedModalBody from './OrderFormModal/CompletedModalBody'
import LoadingModalBody from './OrderFormModal/LoadingModalBody'
import { OrderFormValues, getCreatePayload } from './OrderFormModal/OrderForm'
import { CustomizedFooterButton } from '..'

const orderModalQuery = gql`
  query OrderModal($patientId: ID!) {
    patient(id: $patientId) {
      id
      canCreateOrder
      ...OrderFormModal
      orders(
        query: { isLatest: true }
        page: 1
        limit: 1
        sort: "-createdDate"
      ) {
        docs {
          id
        }
      }
    }
  }
  ${OrderFormModal.fragments.OrderFormModal}
`

const createOrdersMutation = gql`
  mutation CreateOrders($payloads: [CreateOrderInput!]!) {
    createOrders(payloads: $payloads) {
      id
      displayId
    }
  }
`

export type ModalType =
  | 'empty'
  | 'orderInProgress'
  | 'orderForm'
  | 'orderSubmitting'
  | 'orderCreated'
  | 'onEvalWithoutCurrentEvalStage'

export type CreatedOrderInfo = NonNullable<
  CreateOrdersMutation['createOrders']
>[0]

interface ModelVisibleArgs {
  orderType: OrderType
  patientStatus?: PatientStatus
  modalType: ModalType
}

type GetIsHintModalVisible = (args: {
  modalType: ModalType
  orderType: OrderType
}) => boolean
const getIsHintModalVisible: GetIsHintModalVisible = (args) => {
  const { modalType, orderType } = args

  if (modalType === 'orderInProgress') {
    return true
  }

  if (modalType === 'orderSubmitting') {
    return true
  }
  if (modalType === 'orderCreated') {
    return true
  }

  if (
    modalType === 'onEvalWithoutCurrentEvalStage' &&
    (orderType === OrderType.NormalPrint || orderType === OrderType.Remodel)
  ) {
    return true
  }

  return false
}

type GetIsOrderModalVisible = (args: ModelVisibleArgs) => boolean
const getIsOrderModalVisible: GetIsOrderModalVisible = (args) => {
  const { orderType, patientStatus, modalType } = args
  const isReportOrder = orderType === OrderType.Report
  const isOrderCreatable = modalType === 'orderForm'

  if (
    modalType === 'orderInProgress' ||
    modalType === 'orderSubmitting' ||
    modalType === 'orderCreated'
  ) {
    return false
  }
  if (patientStatus === 'INITIAL' && isReportOrder) {
    return true
  }

  if (modalType === 'onEvalWithoutCurrentEvalStage' && isReportOrder) {
    return true
  }
  if (patientStatus === 'ON_EVAL' && isOrderCreatable) {
    return true
  }

  if (patientStatus === 'ON_PRINT' && isOrderCreatable) {
    return true
  }

  return false
}

const getModalType = (args: {
  canCreateOrder: boolean
  hasCurrentEvalStage: boolean
  patientStatus: PatientStatus
}): ModalType => {
  const { canCreateOrder, hasCurrentEvalStage, patientStatus } = args
  if (!canCreateOrder) {
    return 'orderInProgress'
  }

  if (patientStatus === PatientStatus.OnEval && !hasCurrentEvalStage) {
    return 'onEvalWithoutCurrentEvalStage'
  }

  return 'orderForm'
}

interface GetHintModalArgs {
  modalType: ModalType
  orderId?: string
  createdOrderInfo?: CreatedOrderInfo
}
type GetHintModal = (args: GetHintModalArgs) => React.ReactNode
const getHintModal: GetHintModal = (args) => {
  const { modalType, orderId, createdOrderInfo } = args
  const orderLinkUrl = orderId && `/orders/${orderId}`

  return cond<ModalType, JSX.Element>([
    [
      (modalType) => modalType === 'orderInProgress',
      () => <InProgressModalBody orderLinkUrl={orderLinkUrl} />,
    ],
    [
      (modalType) => modalType === 'onEvalWithoutCurrentEvalStage',
      () => <WithoutCurrentReportModalBody />,
    ],
    [
      (modalType) => modalType === 'orderSubmitting',
      () => <LoadingModalBody />,
    ],
    [
      (modalType) => modalType === 'orderCreated',
      () => <CompletedModalBody createdOrderInfo={createdOrderInfo} />,
    ],
  ])(modalType)
}

interface ModalFooterCloseButtonProps {
  render: (closeBtnText: string) => JSX.Element
  modalType: ModalType
}
const ModalFooterCloseButton = (props: ModalFooterCloseButtonProps) => {
  const { render, modalType } = props
  let closeBtnText = ''
  switch (modalType) {
    case 'orderCreated':
      closeBtnText = '關閉'
      break
    case 'orderInProgress':
      closeBtnText = '我知道了'
      break
    case 'orderForm':
      closeBtnText = '取消'
      break
    default:
      closeBtnText = '關閉'
  }
  return render(closeBtnText)
}

interface GetModalFooterArgs {
  handleModalClose: ButtonProps['onClick']
  handleOkClick: ButtonProps['onClick']
  modalType: ModalType
  isSubmitButtonEnable: boolean
  orderType: OrderType
}
const getModalFooter = (args: GetModalFooterArgs) => {
  const {
    handleModalClose,
    modalType,
    handleOkClick,
    isSubmitButtonEnable,
    orderType,
  } = args

  if (modalType === 'empty' || modalType === 'orderSubmitting') {
    return null
  }
  if (
    orderType === OrderType.Report &&
    modalType === 'onEvalWithoutCurrentEvalStage'
  ) {
    return [
      <ModalFooterCloseButton
        key='close'
        modalType={modalType}
        render={(text) => (
          <Button key='close' onClick={handleModalClose}>
            {text}
          </Button>
        )}
      />,
      <Button
        key='submit'
        type='primary'
        onClick={handleOkClick}
        disabled={!isSubmitButtonEnable}
      >
        確定下單
      </Button>,
    ]
  }
  if (modalType === 'orderForm') {
    return [
      <ModalFooterCloseButton
        key='close'
        modalType={modalType}
        render={(text) => (
          <Button key='close' onClick={handleModalClose}>
            {text}
          </Button>
        )}
      />,
      <Button
        key='submit'
        type='primary'
        onClick={handleOkClick}
        disabled={!isSubmitButtonEnable}
      >
        確定下單
      </Button>,
    ]
  }

  return [
    <ModalFooterCloseButton
      key='close'
      modalType={modalType}
      render={(val) => (
        <CustomizedFooterButton key='close' onClick={handleModalClose}>
          {val}
        </CustomizedFooterButton>
      )}
    />,
  ]
}

interface OrderModalProps {
  handleQueryStop: () => void
  shouldFetchPatientOrders: boolean
  orderType: OrderType
  patientId: string
}

const OrderModal = (props: OrderModalProps) => {
  const { handleQueryStop, orderType, patientId, shouldFetchPatientOrders } =
    props

  const [modalType, setModalType] = useState<ModalType>('empty')
  const [createdOrderInfo, setCreatedOrderInfo] = useState<CreatedOrderInfo>()
  // 只有新病患的modal會有這個checkbox
  const [isSubmitButtonEnable, setIsSubmitButtonEnable] = useState(false)

  const [form] = Form.useForm<OrderFormValues>()
  const handleModalClose = () => {
    setModalType('empty')
    handleQueryStop()
    form.resetFields()
  }

  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const [create] = useMutation<
    CreateOrdersMutation,
    CreateOrdersMutationVariables
  >(createOrdersMutation)

  const result = useQuery<OrderModalQuery, OrderModalQueryVariables>(
    orderModalQuery,
    {
      errorPolicy: 'none',
      onCompleted: (data) => {
        if (data?.patient) {
          const { patient } = data
          const modalType = getModalType({
            canCreateOrder: patient.canCreateOrder,
            hasCurrentEvalStage: !!patient.currentEvalStage,
            patientStatus: patient.status,
          })
          setModalType(modalType)
          setIsSubmitButtonEnable(!(patient.status === 'INITIAL'))
        }
      },
      onError: (error) => {
        toErrorPage(error.message)
      },
      variables: {
        patientId,
      },
      skip: !shouldFetchPatientOrders,
    }
  )

  const handleSubmit = async () => {
    try {
      if (modalType === 'orderSubmitting') return

      const orderFormValues = (await form.validateFields()) as OrderFormValues
      const createPayload = getCreatePayload({
        orderFormValues,
        orderType,
        patientId: result.data?.patient?.id as string,
      })

      try {
        setModalType('orderSubmitting')
        await create({
          variables: {
            payloads: createPayload,
          },
          update: async (cache, { data }) => {
            await result.refetch()
            if (data?.createOrders) {
              message.info(`已新增訂單`)
              setModalType('orderCreated')
              setCreatedOrderInfo(data.createOrders?.[0])
            }
          },
        })
      } catch (error) {
        message.error(error.message)
        setModalType('empty')
      }
    } catch (error) {
      if (error?.Message) console.error(error.Message)
    }
  }

  const patient = result.data?.patient
  const patientStatus = patient?.status
  const checkboxHandler = () => {
    setIsSubmitButtonEnable((prev) => !prev)
  }
  const isHintModalVisible = getIsHintModalVisible({ modalType, orderType })
  const isOrderCreateModal = getIsOrderModalVisible({
    modalType,
    patientStatus,
    orderType,
  })
  const hintModalBody = getHintModal({
    modalType,
    orderId: patient?.orders?.docs[0]?.id,
    createdOrderInfo,
  })
  const modalFooter = getModalFooter({
    handleModalClose,
    modalType,
    isSubmitButtonEnable,
    handleOkClick: handleSubmit,
    orderType,
  })

  return (
    <>
      {patient && (
        <OrderFormModal
          handleModalClose={handleModalClose}
          orderType={orderType}
          patientItem={patient}
          checkboxHandler={checkboxHandler}
          isOrderCreateModal={isOrderCreateModal}
          modalFooter={modalFooter}
          form={form}
        />
      )}
      <HintModal
        modalFooter={modalFooter}
        isModalVisible={isHintModalVisible}
        hintModalBody={hintModalBody}
      />
    </>
  )
}

export default OrderModal
