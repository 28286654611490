import { DatePicker, Tag } from 'antd'
import { RangePickerProps } from 'antd/lib/date-picker'
import moment, { Moment } from 'moment'
import { identity } from 'ramda'
import React, { useState } from 'react'
import styled from 'styled-components'

const ToggleTag = styled(Tag)`
  cursor: pointer;
`

const monthToDateInterval = (month: Moment): [Moment, Moment] => [
  moment(month).startOf('month'),
  moment(month).endOf('month'),
]
const dateIntervalToMonth = ([from]: [Moment, Moment]) => from

const monthFormat = 'YYYY/MM'
const rangeFormat = 'YYYY/MM/DD'

interface Props {
  defaultPickerType: string
  value?: any
  ranges?: RangePickerProps['ranges']
  onChange?: (value?: (moment.Moment | null)[] | moment.Moment | null) => void
}

export const RangePicker = (props: Props) => {
  const { value, onChange = identity, defaultPickerType, ...restProps } = props
  const [pickerType, setPickerType] = useState(defaultPickerType)

  const togglePickerType = () => {
    if (pickerType === 'month') {
      setPickerType('date')
      onChange(monthToDateInterval(value))
    }
    if (pickerType === 'date') {
      setPickerType('month')
      onChange(dateIntervalToMonth(value))
    }
  }

  return pickerType === 'month' ? (
    <DatePicker.MonthPicker
      style={{ width: 250 }}
      value={value}
      placeholder={monthFormat}
      renderExtraFooter={() => (
        <ToggleTag onClick={togglePickerType}>根據日期選取</ToggleTag>
      )}
      onChange={onChange}
      {...restProps}
    />
  ) : (
    <DatePicker.RangePicker
      style={{ width: 250 }}
      value={value}
      placeholder={[rangeFormat, rangeFormat]}
      format={rangeFormat}
      renderExtraFooter={() => (
        <ToggleTag onClick={togglePickerType}>根據月份選取</ToggleTag>
      )}
      onChange={onChange}
      {...restProps}
    />
  )
}

export default RangePicker
