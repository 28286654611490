import { CheckOutlined } from '@ant-design/icons'
import { gql } from '@apollo/client'
import React, { useEffect, useRef, useState } from 'react'
// import processString from 'react-process-string'
import styled from 'styled-components'

import { Step, StepListFragment } from '../../codegen/types'
import {
  PageBottom,
  PageBreak,
  PageContainerHorizontalMargin,
  PageTitle,
  PageTop,
} from './Page'

// interface HighLightContainerProps {
//   type: 'button' | 'ipr'
// }

// const HighLightContainer = styled.span<HighLightContainerProps>`
//   color: ${props => (props.type === 'button' ? 'red' : 'blue')};
// `

// interface HightLightProps {
//   text: string
// }

// const HighLight = (props: HightLightProps) => {
//   const { text } = props

//   const type = text.toUpperCase().includes('IPR') ? 'ipr' : 'button'

//   return <HighLightContainer type={type}>{text}</HighLightContainer>
// }

/**
 * 搜尋條件：文字中包含有「...IPR，」或「...Button，」，並忽略大小寫
 * 目前 safari 不支援 negative look behind ?<=
 * @todo: 尋找 safari 也可以適用的 regex 或用其他方式解決
 */
// const regex = /((?<=，?)[^，]+IPR)|((?<=，?)[^，|\s]+Button)/gi
// const stringReplaceConfig = [
//   {
//     regex,
//     fn: (key, result) => <HighLight key={key} text={result[0]} />
//   }
// ]

interface ConvertedStepDomProps {
  text: string
}

/**
 * 在資料庫中有發現目前Step中的文字並非全部依照這邊定義的規範所撰寫
 * 需要先和寫報告的人確認完規範並且可能需要做完 migration 之後再把此功能加回才能正確顯示
 * @todo: 確認規格後再補上文字改變顏色功能
 */
const ConvertedStepDom = (props: ConvertedStepDomProps) => {
  const { text } = props

  /**
   * 把一段文字根據不同搜尋條件，把符合條件的字串轉成「指定的 react dom」，不符合條件的維持原字串
   * 這邊的執行結果會如下：
   * '46.47舌側黏著Button， 43遠心、44近遠心及45近心各做0.3mm的IPR，46.47往頰側調整角度'
   * -> (
   *   <>
   *     <HighLight key={key} text='46.47舌側黏著Button' />,
   *     <HighLight key={key} text='43遠心、44近遠心及45近心各做0.3mm的IPR' />,
   *     46.47往頰側調整角度
   *   </>
   * )
   */
  // const processedText = processString(stringReplaceConfig)(text)

  return <>{text}</>
}

const getPageHeigthtThreshold = () => {
  const getIsUsingMac = () => {
    return window?.navigator.userAgent.includes('Mac')
  }

  /** unit: mm */
  const a4Height = 297
  const mmPerInch = 25.4
  const dpiOfMac = 72
  const dpiOfWindows = 96
  const dpi = getIsUsingMac() ? dpiOfMac : dpiOfWindows
  const offset = 0
  const pageHeigthtThreshold = (a4Height / mmPerInch) * dpi + offset

  return pageHeigthtThreshold
}

const pageHeigthtThreshold = getPageHeigthtThreshold()

const StepListContainer = styled.div`
  margin-top: 16px;
  font-size: 16px;
`

const TableRow = styled.div`
  display: flex;
  padding: 0 5%;

  & > :first-child {
    flex: 0 0 100px;
  }
  & > :not(:first-child):not(:last-child) {
    flex: 1;
  }
  & > :last-child {
    flex: 0 0 120px;
  }

  & > span {
    padding: 10px 12px;
  }
`

const CheckIcon = () => {
  return <CheckOutlined style={{ color: '#1890ff' }} />
}

interface StepProps {
  index: number
  setAccumulateHeight: (func: (accumulateHeight: number) => number) => void
  step: Step
}

const StepRow = (props: StepProps) => {
  const { index, setAccumulateHeight, step } = props

  const stepRowRef = useRef<any>()
  const [isLoaded, setIsLoaded] = useState(false)
  const [isPageBreak, setIsPageBreak] = useState(false)

  useEffect(() => {
    if (stepRowRef.current?.clientHeight && !isLoaded) {
      setIsLoaded(true)
      const stepRowHeight = stepRowRef.current.clientHeight
      setAccumulateHeight((accumulateHeight) => {
        const updatedHeight = accumulateHeight + stepRowHeight
        if (updatedHeight >= pageHeigthtThreshold) {
          setIsPageBreak(true)
          return 0
        }

        return updatedHeight
      })
    }
  }, [stepRowRef.current])

  return (
    <>
      <TableRow ref={stepRowRef}>
        <span>STEP {index + 1}</span>
        <span>
          <ConvertedStepDom text={step.upperStep} />
        </span>
        <span>
          <ConvertedStepDom text={step.lowerStep} />
        </span>
        <span>
          {step.button && (
            <div>
              <CheckIcon /> Button
            </div>
          )}
          {step.ipr && (
            <div>
              <CheckIcon /> IPR
            </div>
          )}
          {step.attachment && (
            <div>
              <CheckIcon /> Atta
            </div>
          )}
          {step.retrieve && (
            <div>
              <CheckIcon /> 重取模
            </div>
          )}
        </span>
      </TableRow>
      {isPageBreak && <PageBreak previewInHTML={false} />}
    </>
  )
}

interface Props {
  stage: StepListFragment
}

const StepList = (props: Props) => {
  const { stage } = props
  const steps = stage.analysis?.steps ?? []

  const [, setAccumulateHeight] = useState(0)

  return (
    <>
      <PageTop previewInHTML />
      <PageContainerHorizontalMargin>
        <PageTitle>
          預計治療內容
          (以下為簡略摘要，實際移動數量，須待醫師確認最終矯正結果後可進行計算）
        </PageTitle>
      </PageContainerHorizontalMargin>
      <StepListContainer>
        <TableRow>
          <span />
          <span>上顎</span>
          <span>下顎</span>
          <span>指示</span>
        </TableRow>
        {steps.map((step, index) => {
          return (
            <StepRow
              key={`step-${index}`}
              index={index}
              setAccumulateHeight={setAccumulateHeight}
              step={step}
            />
          )
        })}
      </StepListContainer>
      <PageBottom previewInHTML />
    </>
  )
}

StepList.fragment = gql`
  fragment StepList on EvalStage {
    analysis {
      steps {
        upperStep
        lowerStep
        attachment
        ipr
        button
        retrieve
      }
    }
  }
`

export default StepList
