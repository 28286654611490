import { QuestionCircleFilled } from '@ant-design/icons'
import { TeethStatusInput } from '@sov/ui'
import { Form, Radio, Tooltip } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { isNil } from 'ramda'
import React from 'react'

import {
  CreatePatientInput,
  ImpactedToothInstruction,
  Instruction,
  Offset,
  ResidualRootInstruction,
  ToothStatusType,
  TreatStatus,
  UneruptedWisdomToothInstruction,
} from '../../../codegen/types'
import { formItemLayout, subFormItemLayout } from './layouts'

export type FormIntraOralFields = Pick<CreatePatientInput, 'intraOral'>
type FormIntraOralInitialValues = Partial<FormIntraOralFields>

interface Props {
  form: FormInstance<FormIntraOralFields>
  initialValues?: FormIntraOralInitialValues
  disabled?: boolean
}

const PatientFormIntraOral = (props: Props) => {
  const { form, initialValues, disabled } = props

  const teethStatusTypeConfig = [
    {
      type: ToothStatusType.Missing,
      label: (
        <Tooltip placement='right' title='病患現況缺少的牙齒。'>
          Missing teeth
          <QuestionCircleFilled
            style={{
              marginLeft: '10px',
              fontSize: '16px',
              color: 'rgba(0, 0, 0, 0.2)',
            }}
          />
        </Tooltip>
      ),
      disabledBy: [ToothStatusType.WillExtract],
      activeIconStyle: {
        border: '1px solid rgba(0,0,0,0.25)',
        color: 'rgba(0, 0, 0, 0.25)',
      },
    },
    {
      type: ToothStatusType.DontMove,
      label: (
        <Tooltip
          placement='right'
          title='因牙橋、牙冠、植牙、牙周等問題，判定為不能移動的牙齒。'
        >
          {`Don't move teeth`}
          <QuestionCircleFilled
            style={{
              marginLeft: '10px',
              fontSize: '16px',
              color: 'rgba(0, 0, 0, 0.2)',
            }}
          />
        </Tooltip>
      ),
      disabledBy: [ToothStatusType.Missing, ToothStatusType.WillExtract],
      activeIconStyle: { backgroundColor: '#e5e5e5' },
    },
    {
      type: ToothStatusType.DontPutAttachment,
      label: (
        <Tooltip
          placement='right'
          title='因牙齒現況或為金屬、陶瓷假牙，判定為無法黏貼 Attachment 的牙齒。'
        >
          {`Don't put attachment`}
          <QuestionCircleFilled
            style={{
              marginLeft: '10px',
              fontSize: '16px',
              color: 'rgba(0, 0, 0, 0.2)',
            }}
          />
        </Tooltip>
      ),
      disabledBy: [
        ToothStatusType.DontMove,
        ToothStatusType.Missing,
        ToothStatusType.WillExtract,
      ],
    },
    {
      type: ToothStatusType.WillExtract,
      label: (
        <Tooltip placement='right' title='在治療前欲拔除的牙齒。'>
          Will extract teeth
          <QuestionCircleFilled
            style={{
              marginLeft: '10px',
              fontSize: '16px',
              color: 'rgba(0, 0, 0, 0.2)',
            }}
          />
        </Tooltip>
      ),
      disabledBy: [ToothStatusType.Missing],
    },
  ]

  return (
    <Form {...formItemLayout} form={form} initialValues={initialValues}>
      <Form.Item
        wrapperCol={{ span: 24 }}
        label='牙位狀態'
        name={['intraOral', 'teethStatus']}
        valuePropName='teethStatus'
      >
        <TeethStatusInput
          teethStatusTypeConfig={teethStatusTypeConfig}
          disabled={disabled}
        />
      </Form.Item>

      <Form.Item
        label='是否有TMD'
        name={['intraOral', 'temporomandibularJointDisordersTreatStatus']}
      >
        <Radio.Group disabled={disabled}>
          <Radio value={TreatStatus.No}>No</Radio>
          <Radio value={TreatStatus.Treated}>Treated</Radio>
          <Radio value={TreatStatus.Untreat}>Untreat</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label='是否有牙周問題'
        name={['intraOral', 'periodontalDiseaseTreatStatus']}
      >
        <Radio.Group disabled={disabled}>
          <Radio value={TreatStatus.No}>No</Radio>
          <Radio value={TreatStatus.Treated}>Treated</Radio>
          <Radio value={TreatStatus.Untreat}>Untreat</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label='是否有蛀牙(殘根)'
        name={['intraOral', 'residualRoot', 'hasResidualRoot']}
      >
        <Radio.Group disabled={disabled}>
          <Radio value={false}>No</Radio>
          <Radio value>Yes</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {(form) => (
          <Form.Item
            {...subFormItemLayout}
            label='是否拔除'
            name={['intraOral', 'residualRoot', 'instruction']}
            hidden={
              form.getFieldValue([
                'intraOral',
                'residualRoot',
                'hasResidualRoot',
              ]) !== true
            }
          >
            <Radio.Group disabled={disabled}>
              <Radio value={ResidualRootInstruction.Extract}>Extract</Radio>
              <Radio value={ResidualRootInstruction.Restore}>Restore</Radio>
              <Radio value={ResidualRootInstruction.Maintain}>Maintain</Radio>
            </Radio.Group>
          </Form.Item>
        )}
      </Form.Item>

      <Form.Item
        label='是否有阻生齒'
        name={['intraOral', 'impactedTooth', 'hasImpactedTooth']}
      >
        <Radio.Group disabled={disabled}>
          <Radio value={false}>No</Radio>
          <Radio value>Yes</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {(form) => (
          <Form.Item
            {...subFormItemLayout}
            label='是否拔除'
            name={['intraOral', 'impactedTooth', 'instruction']}
            hidden={
              form.getFieldValue([
                'intraOral',
                'impactedTooth',
                'hasImpactedTooth',
              ]) !== true
            }
          >
            <Radio.Group disabled={disabled}>
              <Radio value={ImpactedToothInstruction.Extract}>Extract</Radio>
              <Radio value={ImpactedToothInstruction.DontMove}>
                {`Don't move`}
              </Radio>
            </Radio.Group>
          </Form.Item>
        )}
      </Form.Item>

      <Form.Item
        label='是否有未萌發智齒'
        name={['intraOral', 'uneruptedWisdomTooth', 'hasUneruptedWisdomTooth']}
      >
        <Radio.Group disabled={disabled}>
          <Radio value={false}>No</Radio>
          <Radio value>Yes</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {(form) => (
          <Form.Item
            {...subFormItemLayout}
            label='是否拔除'
            name={['intraOral', 'uneruptedWisdomTooth', 'instruction']}
            hidden={
              form.getFieldValue([
                'intraOral',
                'uneruptedWisdomTooth',
                'hasUneruptedWisdomTooth',
              ]) !== true
            }
          >
            <Radio.Group disabled={disabled}>
              <Radio value={UneruptedWisdomToothInstruction.Extract}>
                Extract
              </Radio>
              <Radio value={UneruptedWisdomToothInstruction.DontDistalize}>
                {`Don't distalize`}
              </Radio>
            </Radio.Group>
          </Form.Item>
        )}
      </Form.Item>

      <Form.Item
        label='上顎門齒中線對齊顏面中線'
        name={['intraOral', 'maxillarMidlineToFacialMidline', 'offset']}
      >
        <Radio.Group disabled={disabled}>
          <Radio value={Offset.Centered}>Centered</Radio>
          <Radio value={Offset.ShiftedRight}>Shifted right</Radio>
          <Radio value={Offset.ShiftedLeft}>Shifted left</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {(form) => {
          const offset = form.getFieldValue([
            'intraOral',
            'maxillarMidlineToFacialMidline',
            'offset',
          ])
          return (
            <Form.Item
              {...subFormItemLayout}
              label='是否調整上顎中線位置'
              name={[
                'intraOral',
                'maxillarMidlineToFacialMidline',
                'instruction',
              ]}
              hidden={isNil(offset) || offset === Offset.Centered}
            >
              <Radio.Group disabled={disabled}>
                <Radio value={Instruction.Maintain}>Maintain</Radio>
                <Radio value={Instruction.Improve}>Improve</Radio>
                <Radio value={Instruction.Idealize}>Idealize</Radio>
              </Radio.Group>
            </Form.Item>
          )
        }}
      </Form.Item>

      <Form.Item
        label='下顎門齒中線對齊上顎中線'
        name={['intraOral', 'mandibularMidlineToMaxillarMidline', 'offset']}
      >
        <Radio.Group disabled={disabled}>
          <Radio value={Offset.Centered}>Centered</Radio>
          <Radio value={Offset.ShiftedRight}>Shifted right</Radio>
          <Radio value={Offset.ShiftedLeft}>Shifted left</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {(form) => {
          const offset = form.getFieldValue([
            'intraOral',
            'mandibularMidlineToMaxillarMidline',
            'offset',
          ])
          return (
            <Form.Item
              {...subFormItemLayout}
              label='是否調整下顎中線位置'
              name={[
                'intraOral',
                'mandibularMidlineToMaxillarMidline',
                'instruction',
              ]}
              hidden={isNil(offset) || offset === Offset.Centered}
            >
              <Radio.Group disabled={disabled}>
                <Radio value={Instruction.Maintain}>Maintain</Radio>
                <Radio value={Instruction.Improve}>Improve</Radio>
                <Radio value={Instruction.Idealize}>Idealize</Radio>
              </Radio.Group>
            </Form.Item>
          )
        }}
      </Form.Item>
    </Form>
  )
}

export default PatientFormIntraOral
