import React from 'react'

import Layout from '../components/Layout'

const InvalidResetPasswordUrl = () => (
  <Layout
    title='網址失效'
    description={
      <>
        <div>更改密碼的網址已經失效</div>
        <div>請重新寄送驗證信</div>
      </>
    }
    backLink='/'
  />
)

export default InvalidResetPasswordUrl
