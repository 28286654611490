import { QuestionCircleOutlined } from '@ant-design/icons'
import { gql } from '@apollo/client'
import { AntSorterType, getEmptyText, TableQuery } from '@sov/common'
import {
  DisplayPatientBrand,
  DisplayPatientStatus,
  TablePatientInfo,
} from '@sov/ui'
import StageLink from '@sov/ui/src/components/Link/Stage'
import { Space, Table, Tooltip } from 'antd'
import { ColumnProps, TableProps } from 'antd/lib/table'
import moment from 'moment'
import { isEmpty, isNil } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  PatientsQueryQuery,
  PatientTableFragment,
  StageStatus,
  StageType,
} from '../../../codegen/types'
import {
  GetCurrentEvalStageClickHandler,
  GetOrderClickHandler,
} from '../../../pages/patient/PatientList'
import ActionButtons from './ActionButton'

type ColumnItem = PatientTableFragment
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`
interface Props {
  cursor: Pick<
    NonNullable<PatientsQueryQuery['patients']>,
    'limit' | 'page' | 'total'
  >
  data: ColumnItem[]
  getOrderClickHandler: GetOrderClickHandler
  handleTableChange: TableProps<ColumnItem>['onChange']
  loading?: boolean
  sortInfo?: AntSorterType<'nextAppointmentDate'>
  getCurrentEvalStageClickHandler: GetCurrentEvalStageClickHandler
}

const PatientTable = (props: Props) => {
  const {
    cursor,
    data,
    getOrderClickHandler,
    handleTableChange,
    loading,
    sortInfo,
    getCurrentEvalStageClickHandler,
  } = props
  const { t } = useTranslation()

  const columns: ColumnProps<ColumnItem>[] = [
    {
      title: '病患',
      key: 'patient',
      align: 'center',
      width: 160,
      render: (_value, record) => <TablePatientInfo patient={record} />,
    },
    {
      title: '品牌',
      key: 'brand',
      align: 'center',
      width: 60,
      render: (_text, record) => (
        <DisplayPatientBrand value={record.payment.brand} />
      ),
    },
    {
      title: '客源',
      key: 'source',
      align: 'center',
      width: 60,
      render: (_text, record) => (
        <div>{t(`patient.source.${record.payment.source}`)}</div>
      ),
    },
    {
      title: '主要報告',
      key: 'currentEvalStage',
      align: 'center',
      width: 60,
      render: (_text, record) =>
        record.currentEvalStage && (
          <StageLink
            style={{ color: '#1890ff' }}
            to={`/patients/${record.id}`}
            item={record.currentEvalStage}
          />
        ),
    },
    {
      title: (
        <>
          目前配戴&nbsp;
          <Tooltip title='顯示病患端 APP 登錄的配戴紀錄'>
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      key: 'currentSubStage',
      align: 'center',
      width: 70,
      render: (_text, record) => {
        if (!record.currentSubStage) {
          return <div>{getEmptyText()}</div>
        }
        return (
          <div>
            {record.currentSubStage.stage.serialNumber}-
            {record.currentSubStage.serialNumber}
          </div>
        )
      },
    },
    {
      title: '下次約診',
      align: 'center',
      key: 'nextAppointmentDate',
      dataIndex: 'nextAppointmentDate',
      sortOrder:
        sortInfo?.field === 'nextAppointmentDate' ? sortInfo.order : undefined,
      sorter: true,
      width: 60,
      render: (_text, record) =>
        record.nextAppointmentDate ? (
          <div>
            {moment(record.nextAppointmentDate).format('YYYY-MM-DD HH:mm')}
          </div>
        ) : (
          '無約診'
        ),
    },
    {
      title: '狀態',
      align: 'center',
      key: 'status',
      width: 60,
      render: (_text, record) => <DisplayPatientStatus value={record.status} />,
    },
    {
      title: '操作',
      align: 'center',
      key: 'action',
      width: 180,
      render: (_text, record) => {
        const patientId = record.id

        const orderButtonCLickHandler = getOrderClickHandler(patientId)
        const handleCurrentEvalStageClick = getCurrentEvalStageClickHandler({
          patientId,
        })
        // 判斷是否有最近一筆訂單
        const latestOrder = record.orders?.docs?.[0]
        // 判斷是否為新病患
        const patientStatus = record.status
        const latestOrderDisplayId = latestOrder?.displayId
        const haveCurrentEvalStage = !isNil(record.currentEvalStage)
        const haveCompletedEvalStage = !isEmpty(record.stages?.docs)
        return (
          <ButtonContainer>
            <Space size={16}>
              <ActionButtons.OrderButton
                orderButtonCLickHandler={orderButtonCLickHandler}
                latestOrder={latestOrder}
                canCreateOrder={record.canCreateOrder}
                patientId={patientId}
                patientStatus={patientStatus}
                latestOrderDisplayId={latestOrderDisplayId}
              />
              <ActionButtons.CurrentEvalStageButton
                haveCurrentEvalStage={haveCurrentEvalStage}
                handleCurrentEvalStageClick={handleCurrentEvalStageClick}
                patientStatus={patientStatus}
                patientId={record.id}
                currentEvalStageId={record.currentEvalStage?.id}
                haveCompletedEvalStage={haveCompletedEvalStage}
              />
              <ActionButtons.ViewDetailButton
                patientId={patientId}
              ></ActionButtons.ViewDetailButton>
            </Space>
          </ButtonContainer>
        )
      },
    },
  ]
  return (
    <Table<ColumnItem>
      rowKey='id'
      columns={columns}
      dataSource={data}
      loading={loading}
      onChange={handleTableChange}
      pagination={TableQuery.getAntdPagination(cursor)}
    />
  )
}

PatientTable.fragment = {
  PatientTable: gql`
    fragment PatientTable on Patient {
      id
      ...TablePatientInfo
      name
      status
      nextAppointmentDate
      canCreateOrder
      payment {
        brand
        source
      }
      photos {
        frontFace {
          id
          thumbnailPath
        }
      }
      currentEvalStage {
        ...StageLink
      }
      currentSubStage {
        stage {
          serialNumber
        }
        serialNumber
      }
      stages(query: { type: [${StageType.Eval}], status: [${StageStatus.Completed}] }, limit: 20, sort: "-expectedShippingDate") {
        docs {
          id
        }
      }
      clinic {
        id
        name
      }
      doctor {
        id
        name
      }
      orders(query: { isLatest: true }, page: 1, limit: 1, sort: "-updated") {
        docs {
          ...OrderButton
        }
      }
    }
    ${TablePatientInfo.fragment}
    ${StageLink.fragment}
    ${ActionButtons.OrderButton.fragments.OrderButton}
  `,
}

export default PatientTable
