import { Form, Radio } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { isNil } from 'ramda'
import React from 'react'

import {
  CephAnalysis,
  CreatePatientInput,
  FaceRatio,
  Instruction,
  LipsEstheticLineRelation,
  OcculusalMuscle,
  OcculusalPlaneStatus,
  Offset,
  SmileLine,
} from '../../../codegen/types'
import { formItemLayout, subFormItemLayout } from './layouts'

export type FormProfileFields = Pick<CreatePatientInput, 'profile'>
type FormProfileInitialValues = Partial<FormProfileFields>

interface Props {
  form: FormInstance<FormProfileFields>
  initialValues?: FormProfileInitialValues
  disabled?: boolean
}

const PatientFormProfile = (props: Props) => {
  const { form, initialValues, disabled = false } = props

  return (
    <Form {...formItemLayout} form={form} initialValues={initialValues}>
      <Form.Item
        label='下顎位置是否對齊顏面中線'
        name={['profile', 'mandibularMidlineToFacialMidline', 'offset']}
      >
        <Radio.Group disabled={disabled}>
          <Radio value={Offset.Centered}>Centered</Radio>
          <Radio value={Offset.ShiftedRight}>ShiftedRight</Radio>
          <Radio value={Offset.ShiftedLeft}>ShiftedLeft</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {(form) => {
          const offset = form.getFieldValue([
            'profile',
            'mandibularMidlineToFacialMidline',
            'offset',
          ])
          return (
            <Form.Item
              {...subFormItemLayout}
              label='是否調整上顎中線位置'
              name={[
                'profile',
                'mandibularMidlineToFacialMidline',
                'instruction',
              ]}
              hidden={isNil(offset) || offset === Offset.Centered}
            >
              <Radio.Group disabled={disabled}>
                <Radio value={Instruction.Maintain}>Maintain</Radio>
                <Radio value={Instruction.Improve}>Improve</Radio>
                <Radio value={Instruction.Idealize}>Idealize</Radio>
              </Radio.Group>
            </Form.Item>
          )
        }}
      </Form.Item>

      <Form.Item
        label='咬合平面是否歪斜'
        name={['profile', 'occulusalPlane', 'occulusalPlaneStatus']}
      >
        <Radio.Group disabled={disabled}>
          <Radio value={OcculusalPlaneStatus.Skew}>Skew</Radio>
          <Radio value={OcculusalPlaneStatus.Level}>Level</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {(form) => {
          const status = form.getFieldValue([
            'profile',
            'occulusalPlane',
            'occulusalPlaneStatus',
          ])
          return (
            <Form.Item
              {...subFormItemLayout}
              label='是否調整咬合平面'
              name={['profile', 'occulusalPlane', 'instruction']}
              hidden={isNil(status) || status !== OcculusalPlaneStatus.Skew}
            >
              <Radio.Group disabled={disabled}>
                <Radio value={Instruction.Maintain}>Maintain</Radio>
                <Radio value={Instruction.Improve}>Improve</Radio>
                <Radio value={Instruction.Idealize}>Idealize</Radio>
              </Radio.Group>
            </Form.Item>
          )
        }}
      </Form.Item>

      <Form.Item
        label='上下唇是否超出 E-line'
        name={['profile', 'estheticLine', 'lipsEstheticLineRelation']}
      >
        <Radio.Group disabled={disabled}>
          <Radio value={LipsEstheticLineRelation.Over}>Over</Radio>
          <Radio value={LipsEstheticLineRelation.On}>On</Radio>
          <Radio value={LipsEstheticLineRelation.In}>In</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {(form) => {
          const relation = form.getFieldValue([
            'profile',
            'estheticLine',
            'lipsEstheticLineRelation',
          ])
          return (
            <Form.Item
              {...subFormItemLayout}
              label='是否內縮調整'
              name={['profile', 'estheticLine', 'instruction']}
              hidden={
                isNil(relation) || relation !== LipsEstheticLineRelation.Over
              }
            >
              <Radio.Group disabled={disabled}>
                <Radio value={Instruction.Maintain}>Maintain</Radio>
                <Radio value={Instruction.Improve}>Improve</Radio>
                <Radio value={Instruction.Idealize}>Idealize</Radio>
              </Radio.Group>
            </Form.Item>
          )
        }}
      </Form.Item>

      <Form.Item label='Ceph 分析' name={['profile', 'cephAnalysis']}>
        <Radio.Group disabled={disabled}>
          <Radio value={CephAnalysis.Protrusion}>Protrusion</Radio>
          <Radio value={CephAnalysis.Normal}>Normal</Radio>
          <Radio value={CephAnalysis.BoneLess}>Bone less</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item label='下臉部 1/3 比例' name={['profile', 'faceRatio']}>
        <Radio.Group disabled={disabled}>
          <Radio value={FaceRatio.Long}>Long</Radio>
          <Radio value={FaceRatio.Normal}>Normal</Radio>
          <Radio value={FaceRatio.Short}>Short</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item label='咬肌狀況' name={['profile', 'occulusalMuscle']}>
        <Radio.Group disabled={disabled}>
          <Radio value={OcculusalMuscle.Strong}>Strong</Radio>
          <Radio value={OcculusalMuscle.Normal}>Normal</Radio>
          <Radio value={OcculusalMuscle.Weak}>Weak</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item label='笑齦狀況' name={['profile', 'hasGummySmile']}>
        <Radio.Group disabled={disabled}>
          <Radio value={false}>No</Radio>
          <Radio value>Yes</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item label='微笑曲線' name={['profile', 'smileLine']}>
        <Radio.Group disabled={disabled}>
          <Radio value={SmileLine.Great}>Great</Radio>
          <Radio value={SmileLine.Ugly}>Ugly</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item label='齒頰間隙' name={['profile', 'hasBuccalCorridor']}>
        <Radio.Group disabled={disabled}>
          <Radio value={false}>No</Radio>
          <Radio value>Yes</Radio>
        </Radio.Group>
      </Form.Item>
    </Form>
  )
}

export default PatientFormProfile
