import { Form } from '@ant-design/compatible'
import { gql } from '@apollo/client'
import { getEmptyText } from '@sov/common'
import { InstructionCardPreview } from '@sov/ui/src'
import { Button, Card, Radio, Row } from 'antd'
import { takeLast } from 'ramda'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import {
  PatientDetailPrintStageFragment,
  PatientDetailPrintStagePatientFragment,
  PrintStageInfoFragment,
  PrintStagePatientInfoFragment,
  StageStatus,
} from '../../../codegen/types'

const RadioGroupContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

interface ContentProps {
  className?: string
  patient: PrintStagePatientInfoFragment | undefined
  stages: PrintStageInfoFragment[]
}

const Content = (props: ContentProps) => {
  const { patient, stages } = props
  const [current, setCurrent] = useState(stages[stages.length - 1])

  return (
    <>
      <RadioGroupContainer>
        <Radio.Group
          onChange={(e) => setCurrent(e.target.value)}
          value={current}
        >
          {stages.map((stage, index) => (
            <Radio.Button key={index} value={stage}>
              Step {stage.serialNumber}
            </Radio.Button>
          ))}
        </Radio.Group>
      </RadioGroupContainer>
      <Row style={{ display: 'flex' }}>
        <Form
          style={{ flex: 2, marginRight: 20 }}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
        >
          <Form.Item label='編號'>Step {current.serialNumber}</Form.Item>
          <Form.Item label='治療內容'>
            <div>
              上顎：
              {current.status === StageStatus.Completed
                ? current.designStage?.step?.upperStep ?? getEmptyText()
                : '進行中'}
            </div>
            <div>
              下顎：
              {current.status === StageStatus.Completed
                ? current.designStage?.step?.lowerStep ?? getEmptyText()
                : '進行中'}
            </div>
          </Form.Item>
          {current.status === StageStatus.Completed &&
            current.designStage?.status === StageStatus.Completed &&
            current.designStage?.hasFilesForViewer && (
              <Form.Item label='Viewer'>
                <Link
                  to={`/viewer?patientId=${patient?.id}&stageId=${current.designStage?.id}`}
                >
                  <Button type='primary'>檢視 Viewer</Button>
                </Link>
              </Form.Item>
            )}
        </Form>
        <Form
          style={{ flex: 3 }}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
        >
          <Form.Item label='指示卡'>
            {current.status === StageStatus.Completed ? (
              current?.instructionCard ? (
                <InstructionCardPreview
                  instructionItem={current.instructionCard}
                />
              ) : (
                getEmptyText('該 Step 沒有指示卡')
              )
            ) : (
              '尚未產生指示'
            )}
          </Form.Item>
        </Form>
      </Row>
    </>
  )
}

Content.fragments = {
  PrintStageInfo: gql`
    fragment PrintStageInfo on PrintStage {
      id
      status
      serialNumber
      instructionCard {
        ...InstructionCardPreview
      }
      designStage {
        id
        status
        step {
          retrieve
          upperStep
          lowerStep
        }
        hasFilesForViewer
      }
    }
    ${InstructionCardPreview.fragments.InstructionCardPreview}
  `,
  PrintStagePatientInfo: gql`
    fragment PrintStagePatientInfo on Patient {
      id
    }
  `,
}

interface PrintStagesProps {
  patient: PatientDetailPrintStagePatientFragment | undefined
  stages: PatientDetailPrintStageFragment[]
}

const PrintStages = (props: PrintStagesProps) => {
  const { patient, stages } = props
  const latestPrintStages = takeLast(
    3,
    stages.sort((a, b) => a.serialNumber - b.serialNumber)
  )

  return (
    <Card
      title='治療指示'
      extra={<Link to={`/patients/${patient?.id}/stages`}>所有 Step</Link>}
    >
      {latestPrintStages.length > 0 ? (
        <Content patient={patient} stages={latestPrintStages} />
      ) : (
        '尚未開始療程'
      )}
    </Card>
  )
}

PrintStages.fragments = {
  PatientDetailPrintStage: gql`
    fragment PatientDetailPrintStage on PrintStage {
      id
      serialNumber
      ...PrintStageInfo
    }
    ${Content.fragments.PrintStageInfo}
  `,
  PatientDetailPrintStagePatient: gql`
    fragment PatientDetailPrintStagePatient on Patient {
      id
      ...PrintStagePatientInfo
    }
    ${Content.fragments.PrintStagePatientInfo}
  `,
}

export default PrintStages
