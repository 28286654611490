import React from 'react'
import { Link } from 'react-router-dom'

import { PatientStatus } from '../../../../../codegen/types'
import MainReport from '../../../../icon/MainReport.svg'
import { ActionButtonContainer } from '../'

type CurrentEvalStageButtonProps = {
  haveCurrentEvalStage: boolean
  handleCurrentEvalStageClick: () => void
  patientStatus: PatientStatus
  patientId: string
  currentEvalStageId?: string
  haveCompletedEvalStage: boolean
}
const CurrentEvalStageButton = (props: CurrentEvalStageButtonProps) => {
  const {
    haveCurrentEvalStage,
    handleCurrentEvalStageClick,
    patientStatus,
    patientId,
    currentEvalStageId,
    haveCompletedEvalStage,
  } = props
  if (patientStatus === PatientStatus.Initial || !haveCompletedEvalStage) {
    return <></>
  }
  return patientStatus !== PatientStatus.OnEval ? (
    <Link
      to={`/viewer?patientId=${patientId}&stageId=${currentEvalStageId}`}
      target='_blank'
    >
      <ActionButtonContainer isButtonDisabled={false}>
        <img src={MainReport} />
        <span>檢視主要報告</span>
      </ActionButtonContainer>
    </Link>
  ) : (
    <ActionButtonContainer
      isButtonDisabled={false}
      onClick={() => {
        handleCurrentEvalStageClick()
      }}
    >
      <img src={MainReport} />
      <span>{haveCurrentEvalStage ? '修改' : '設定'}主要報告</span>
    </ActionButtonContainer>
  )
}

export default CurrentEvalStageButton
