import { Space } from 'antd'
import React from 'react'
import styled from 'styled-components'

import { defaultText, secondaryText } from '../../../../../utils/color'
import smileImage from './smile.svg'

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
`

const IconImage = styled.img`
  width: 40px;
`

const Icon = () => {
  return (
    <IconContainer>
      <IconImage src={smileImage} />
    </IconContainer>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 134px;
`

const Title = styled.div`
  color: ${defaultText};
  font-size: 14px;
  text-align: center;
`

const Content = styled.div`
  color: ${secondaryText};
  font-size: 11px;
  text-align: center;
`

const FinishedHint = () => {
  return (
    <Container>
      <Space direction='vertical' size={16}>
        <Icon />
        <Title>已完成病患的全部療程</Title>
        <Content>臨床若需修改，請醫生重新取模</Content>
      </Space>
    </Container>
  )
}

export default FinishedHint
