import React from 'react'
import styled from 'styled-components'

import entranceBackgroundImg from '../../static/images/entrance/img_login_page_Bg.png'
import sovIcon from '../../static/images/entrance/sov_icon.png'

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  background-image: url(${entranceBackgroundImg});
`

const Center = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 360px;
`

const AppTitle = styled.div`
  margin-top: 200px;
  font-size: 32px;
  font-family: Avenir-Roman;
  color: #1890ff;
`

const Footer = styled.div`
  margin-bottom: 24px;
  margin-top: auto;
  font-size: 14px;
  font-family: PingFangTC;
  color: rgba(0, 0, 0, 0.25);
`

const SovIcon = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  width: 35px;
  height: 35px;
  background-size: cover;
  background-image: url(${sovIcon});
`

const Layout = ({ children }) => (
  <Container>
    <AppTitle>SOV Dental Pro</AppTitle>
    <SovIcon />
    <Center>{children}</Center>
    <Footer>
      <div>© 2020 舒服美牙體技術所 all rights reserved,</div>
      <div>本所專營數位隱形矯正裝置製作</div>
    </Footer>
  </Container>
)

export default Layout
