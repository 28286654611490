import React, { useEffect, useRef } from 'react'
import { extend, useFrame, useThree } from 'react-three-fiber'

import { CAMERA_VIEW, VIEW_METHOD } from '../../constant/controller'
import { OrbitControls } from './OrbitControl'

extend({ OrbitControls })

const cameraViewOptions = {
  LEFT: [125, 0, 0],
  FRONT: [0, 0, 125],
  RIGHT: [-125, 0, 0],
  BACK: [0, 0, -125],
  TOP: [0, 125, 0],
  BOTTOM: [0, -125, 0],
}

export interface ControlsProps {
  cameraView: CAMERA_VIEW
  isCameraViewChanging: boolean
  handleCameraChangingFinish: () => void
  viewMethod: VIEW_METHOD
}

const Controls = (props: ControlsProps) => {
  const {
    cameraView,
    isCameraViewChanging,
    handleCameraChangingFinish,
    viewMethod,
  } = props

  const controlRef = useRef<any>()
  const { camera, gl } = useThree()
  useFrame(() => controlRef.current?.update())

  useEffect(() => {
    if (isCameraViewChanging) {
      const cameraPosition = cameraViewOptions[cameraView]
      controlRef.current?.reset()
      controlRef.current?.object.position.set(...cameraPosition)
      handleCameraChangingFinish()
    }
  }, [isCameraViewChanging])

  useEffect(() => {
    controlRef.current?.reset()
  }, [viewMethod])

  // @ts-ignore
  return <orbitControls ref={controlRef} args={[camera, gl.domElement]} />
}

export default Controls
