import { Layout } from 'antd'
import React, { FC } from 'react'
import styled from 'styled-components'

import LayoutHeader from './Header'
import LayoutSider from './Sider'

const Container = styled(Layout)`
  display: flex;
`

const StyledLayout = styled(Layout)`
  height: 100vh;
  margin-left: 80px;
  overflow: hidden;
`

const MainLayout: FC = (props) => {
  const { children } = props

  return (
    <Container>
      <LayoutSider collapsed />
      <StyledLayout>
        <LayoutHeader />
        {children}
      </StyledLayout>
    </Container>
  )
}

export default MainLayout
