import { Button, Carousel, Modal } from 'antd'
import { CarouselProps } from 'antd/lib/carousel'
import React, { useRef, useState } from 'react'
import styled from 'styled-components'

import tutorialStep1 from './tutorial-step-1.png'
import tutorialStep2 from './tutorial-step-2.png'
import tutorialStep3 from './tutorial-step-3.png'
import tutorialStep4 from './tutorial-step-4.png'
import TutorialImage from './TutorialImage'

const CustomziedCarousel = styled(Carousel)`
  .slick-dots {
    li,
    li.slick-active {
      width: 10px;
      height: 10px;
      margin-right: 5px;
      margin-left: 5px;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 50%;

      button {
        width: 10px;
        height: 10px;
        background: transparent;
      }
    }
    li.slick-active {
      background: #1890ff;
    }
  }
  .slick-dots-bottom {
    bottom: -32px;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Title = styled.div`
  margin-top: 26px;
  font-size: 20px;
  font-weight: 500;
`
const Content = styled.div`
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-weight: 400;
`

const FooterButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

interface StepInfo {
  title: string
  content: string
}

const stepInfo: StepInfo[] = [
  {
    title: '階段列表輕鬆瀏覽',
    content: '在 Viewer 可以更輕鬆檢視不同階段的模型，觀看變化。',
  },
  {
    title: '顯示與切換目前報告版本',
    content:
      '一目瞭然病患對象與報告版本，還可以點選切換到你想看的任何一份模型。',
  },
  {
    title: '即時詢問',
    content:
      '關於此病患任何療程上的問題，即時使用專屬聊天室討論報告與療程內容。',
  },
  {
    title: '主要治療報告確認',
    content:
      '一旦報告內容與模型全部確認沒問題，即可將此報告列為未來主要的治療方向。',
  },
]

const stepImages = [tutorialStep1, tutorialStep2, tutorialStep3, tutorialStep4]

interface TutorialModalProps {
  handleModalClose: () => void
  isModalVisible: boolean
}

const TutorialModal = (props: TutorialModalProps) => {
  const { handleModalClose, isModalVisible } = props
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)

  const handleCancel = () => {
    handleModalClose()
  }

  const hanldeAfterClose = () => {
    setCurrentSlideIndex(0)
  }

  /**
   * @todo 標上正確的 type
   * ant-design/react/slick/types.d.ts 定義的 Slider 不符合 runtime slider 的 object
   * 目前沒有找到正確的 type 可用
   */
  const slider = useRef<any>()

  const handleCarouselAfterChange: CarouselProps['afterChange'] = (
    currentSlideIndex: number
  ) => {
    setCurrentSlideIndex(currentSlideIndex)
  }

  /**
   * @todo: 尋找快速點擊時 UI 和狀態不一致的原因
   * 當使用者快速點擊按鈕「上一步」或「下一步」的時候
   * 可能會有一瞬間出現文字已經改變，但是 slide 圖片還沒切換，但是又會馬上變回正常
   * 推測應該是目前的實作沒有將 react-slick 直接操作 dom 與 react 的 state 同步造成的
   */
  const handlePreviousStep = () => {
    /** api 參考 ant design carousel Methods: https://ant.design/components/carousel/#Methods */
    slider.current?.prev()
  }

  const handleNextStep = () => {
    /** api 參考 ant design carousel Methods: https://ant.design/components/carousel/#Methods */
    slider.current?.next()
  }

  return (
    <Modal
      afterClose={hanldeAfterClose}
      closable={false}
      footer={null}
      onCancel={handleCancel}
      visible={isModalVisible}
      width={720}
    >
      <CustomziedCarousel ref={slider} afterChange={handleCarouselAfterChange}>
        {stepImages.map((stepImage, index) => (
          <div key={`step-image-${index}`}>
            <TutorialImage src={stepImage} />
          </div>
        ))}
      </CustomziedCarousel>
      <TextContainer>
        <Title>{stepInfo[currentSlideIndex].title}</Title>
        <Content>{stepInfo[currentSlideIndex].content}</Content>
      </TextContainer>
      <FooterButtonContainer>
        {currentSlideIndex !== 0 ? (
          <Button onClick={handlePreviousStep} type='link'>
            上一步
          </Button>
        ) : (
          <div />
        )}
        {currentSlideIndex !== stepInfo.length - 1 ? (
          <Button onClick={handleNextStep} type='primary'>
            下一步
          </Button>
        ) : (
          <Button onClick={handleCancel} type='primary'>
            關閉教學
          </Button>
        )}
      </FooterButtonContainer>
    </Modal>
  )
}

export default TutorialModal
