import { Form } from '@ant-design/compatible'
import { ExclamationCircleFilled, LoadingOutlined } from '@ant-design/icons'
import { useRequest } from '@sov/ui'
import { Input, Tooltip } from 'antd'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import Layout from '../components/Layout'
import { SubmitButton } from '../components/SubmitButton'

const ErrorText = styled.div`
  height: 24px;
  text-align: left;
  display: flex;
  align-items: center;
`

const FormItem = Form.Item

const EmailVerification = Form.create()((props) => {
  const { form } = props
  const { getFieldDecorator, getFieldValue } = form as any
  const history = useHistory()
  const request = useRequest()
  const [errorMessage, setErrorMessag] = useState('')
  const [loading, setLoading] = useState(false)
  const handleSubmit = async (email: string) => {
    setLoading(true)
    setErrorMessag('')
    const res = await request.post(`/auth/sendVerificationEmail`, {
      email,
      hostname: window.location.origin,
    })

    if (res && res.status === 200) {
      history.push({
        pathname: 'emailVerificationSent',
        state: { email },
      })
    } else {
      setLoading(false)
      setErrorMessag(res.message)
    }
  }

  return (
    <Layout
      title='首次登入'
      description={
        <span>請輸入您申請時註冊的 email 信箱，系統將寄送驗證信。</span>
      }
      content={
        <>
          <ErrorText>
            <span style={{ color: '#f5222d', marginRight: '4px' }}>
              {errorMessage}
            </span>
            {errorMessage && (
              <Tooltip title='已註冊卻無法登入？請聯繫我們' placement='right'>
                <ExclamationCircleFilled style={{ opacity: 0.45 }} />
              </Tooltip>
            )}
          </ErrorText>
          <Form>
            <FormItem style={{ marginTop: '8px' }}>
              {getFieldDecorator('email')(
                <Input suffix={loading && <LoadingOutlined spin />} />
              )}
            </FormItem>
            <SubmitButton onClick={() => handleSubmit(getFieldValue('email'))}>
              送出
            </SubmitButton>
          </Form>
        </>
      }
      backLink='/'
    />
  )
})

export default EmailVerification
