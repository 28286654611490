import PlaceholderCeph from '../../../assets/patient/ceph.png'
import PlaceholderFrontFace from '../../../assets/patient/frontFace.png'
import PlaceholderFrontFaceWithTeeth from '../../../assets/patient/frontFaceWithTeeth.png'
import PlaceholderFrontInside from '../../../assets/patient/frontInside.png'
import PlaceholderLeftInside from '../../../assets/patient/leftInside.png'
import PlaceholderLowerJawInside from '../../../assets/patient/lowerJawInside.png'
import PlaceholderPano from '../../../assets/patient/pano.png'
import PlaceholderRightInside from '../../../assets/patient/rightInside.png'
import PlaceholderSideFace from '../../../assets/patient/sideFace.png'
import PlaceholderSideFace45 from '../../../assets/patient/sideFace45.png'
import PlaceholderSideFace45WithTeeth from '../../../assets/patient/sideFace45WithTeeth.png'
import PlaceholderSideFaceWithTeeth from '../../../assets/patient/sideFaceWithTeeth.png'
import PlaceholderUpperJawInside from '../../../assets/patient/upperJawInside.png'

export interface PhotoInfo {
  key: string
  title: string
  aspect: number
  placeholder: string
}

export const extraOralPhotos: PhotoInfo[] = [
  {
    key: 'frontFace',
    title: '正面照',
    aspect: 1 / 1,
    placeholder: PlaceholderFrontFace,
  },
  {
    key: 'sideFace45',
    title: '45度側面照',
    aspect: 1 / 1,
    placeholder: PlaceholderSideFace45,
  },
  {
    key: 'sideFace',
    title: '側面照',
    aspect: 1 / 1,
    placeholder: PlaceholderSideFace,
  },
  {
    key: 'frontFaceWithTeeth',
    title: '正面露齒照',
    aspect: 1 / 1,
    placeholder: PlaceholderFrontFaceWithTeeth,
  },
  {
    key: 'sideFace45WithTeeth',
    title: '45度側面露齒照',
    aspect: 1 / 1,
    placeholder: PlaceholderSideFace45WithTeeth,
  },
  {
    key: 'sideFaceWithTeeth',
    title: '側面露齒照',
    aspect: 1 / 1,
    placeholder: PlaceholderSideFaceWithTeeth,
  },
]

export const intraOralPhotos: PhotoInfo[] = [
  {
    key: 'rightInside',
    title: '右面觀',
    aspect: 4 / 3,
    placeholder: PlaceholderRightInside,
  },
  {
    key: 'frontInside',
    title: '正面觀',
    aspect: 4 / 3,
    placeholder: PlaceholderFrontInside,
  },
  {
    key: 'leftInside',
    title: '左面觀',
    aspect: 4 / 3,
    placeholder: PlaceholderLeftInside,
  },
  {
    key: 'upperJawInside',
    title: '上顎咬合面',
    aspect: 4 / 3,
    placeholder: PlaceholderUpperJawInside,
  },
  {
    key: 'lowerJawInside',
    title: '下顎咬合面',
    aspect: 4 / 3,
    placeholder: PlaceholderLowerJawInside,
  },
]

export const xRayPhotos: PhotoInfo[] = [
  {
    key: 'ceph',
    title: 'Ceph 片',
    aspect: 4 / 3,
    placeholder: PlaceholderCeph,
  },
  {
    key: 'pano',
    title: 'Pano 片',
    aspect: 2 / 1,
    placeholder: PlaceholderPano,
  },
]
