import { gql, useMutation, useQuery } from '@apollo/client'
import { ChangePasswordModal, useLogout } from '@sov/ui'
import { Button, message } from 'antd'
import { GraphQLError } from 'graphql'
import { map, path } from 'ramda'
import React, { useContext, useState } from 'react'
import styled from 'styled-components'

import {
  AccountSettingsQueryQuery,
  AccountSettingsQueryQueryVariables,
  UpdateAccountInfoMutation,
  UpdateAccountInfoMutationVariables,
} from '../../../../../codegen/types'
import { authContext } from '../../../../../utils/context'
import AuthIcon from '../../components/AuthIcon'

const FormContainer = styled.div`
  background-color: white;
  margin-bottom: 16px;
`

const FormTitle = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  padding: 16px 24px;
`

const FormContent = styled.div`
  padding: 24px;
`

const FormRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  padding: 27px 24px 27px 32px;
  :hover {
    background-color: #e6f7ff;
  }
`

const RowContent = styled.div`
  margin-left: 16px;
  flex: 1;
`

const accountSettingsQuery = gql`
  query AccountSettingsQuery($id: ID!) {
    account(id: $id) {
      id
      email
      isEmailVerified
      phone
      isPhoneVerified
      providers {
        facebook {
          id
        }
        google {
          id
        }
        line {
          id
        }
      }
    }
  }
`
const updateAccountSettingsMutation = gql`
  mutation UpdateAccountSettings($id: ID!, $payload: UpdateAccountInput!) {
    updateAccount(id: $id, payload: $payload) {
      id
      email
      isEmailVerified
      phone
      isPhoneVerified
      providers {
        facebook {
          id
        }
        google {
          id
        }
        line {
          id
        }
      }
    }
  }
`

type Platform = 'facebook' | 'google' | 'line'

const AccountSettingsForm = () => {
  const logout = useLogout()
  const [modalVisible, setModalVisible] = useState(false)
  const auth = useContext(authContext)
  if (!auth) {
    return null
  }

  const [update] = useMutation<
    UpdateAccountInfoMutation,
    UpdateAccountInfoMutationVariables
  >(updateAccountSettingsMutation)

  const { data, loading } = useQuery<
    AccountSettingsQueryQuery,
    AccountSettingsQueryQueryVariables
  >(accountSettingsQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    variables: {
      id: auth.id,
    },
  })
  if (loading) {
    return null
  }

  const account = data?.account
  if (!account) {
    return null
  }

  const handleChangePassword = async (value: string) => {
    try {
      // @TODO 這邊 type 沒有保護到，若是更改 schema 或者 form 欄位不容易察覺到問題
      await update({
        variables: {
          id: auth.id,
          payload: {
            password: value,
          },
        },
        update: (cache, { data }) => {
          if (data) {
            message.success('更新密碼成功！')
            setModalVisible(false)
          }
        },
      })
    } catch (error) {
      const e = error as GraphQLError
      message.error(`更新帳戶失敗: ${e.message}`)
    }
  }
  const getSocialBindUrl = (platform: Platform) => {
    const redirect = window.location.href
    const url = `${process.env.API_HOST}/auth/${platform}?redirect=${redirect}&type=bind`
    return url
  }

  const getSocialUnbindUrl = (platform: Platform) => {
    const redirect = window.location.href
    const url = `${process.env.API_HOST}/auth/${platform}?redirect=${redirect}&type=unbind`
    return url
  }

  const getProviderFormRow = (platform: Platform) => {
    if (path(['providers', platform, 'id'], account)) {
      return (
        <FormRowContainer>
          <AuthIcon name={platform} />
          <RowContent>已連結 {platform} 帳號</RowContent>
          <a style={{ color: '#e02020' }} href={getSocialUnbindUrl(platform)}>
            取消綁定
          </a>
        </FormRowContainer>
      )
    } else {
      return (
        <FormRowContainer>
          <AuthIcon name={platform} disabled />
          <RowContent>尚未連結 {platform} 帳號</RowContent>
          <Button type='primary' href={getSocialBindUrl(platform)}>
            連結帳號
          </Button>
        </FormRowContainer>
      )
    }
  }

  return (
    <>
      <ChangePasswordModal
        visible={modalVisible}
        onSubmit={handleChangePassword}
        onCancel={() => setModalVisible(false)}
      />
      <FormContainer>
        <FormTitle>登入方式</FormTitle>
        <FormContent>
          <FormRowContainer>
            <AuthIcon
              name='sov'
              disabled={!account.email || !account.isEmailVerified}
            />
            <RowContent>
              {account.email && account.isEmailVerified
                ? `已建立帳號 ${account.email}`
                : '尚未建立'}
            </RowContent>
            <a onClick={() => setModalVisible(true)}>更改密碼</a>
          </FormRowContainer>
          <FormRowContainer>
            <AuthIcon
              name='phone'
              disabled={!account.phone || !account.isPhoneVerified}
            />
            <RowContent>
              {account.phone && account.isPhoneVerified
                ? `已連結手機號碼 ${account.phone}`
                : '尚未連結手機號碼'}
            </RowContent>
            <Button type='primary'>連結帳號</Button>
          </FormRowContainer>
          {map(getProviderFormRow, ['line', 'facebook', 'google'])}
        </FormContent>
      </FormContainer>
      <FormContainer>
        <FormTitle>登出</FormTitle>
        <FormContent>
          <Button type='primary' danger onClick={() => logout()}>
            點此登出
          </Button>
        </FormContent>
      </FormContainer>
    </>
  )
}

export default AccountSettingsForm
