import { ApolloClient } from '@apollo/client'
import { UploadFile } from 'antd/lib/upload/interface'
import { isNil, map } from 'ramda'
import React from 'react'
import styled from 'styled-components'

import { ImageCardFragment } from '../../codegen/types'
import { IconType, ImageCard } from './ImageCard'

export interface UploadConfig {
  apolloClient?: ApolloClient<any>
  namePrefix: string
}

const PreviewListContainer = styled.div`
  padding: 8px 0;
`

interface PreviewListProps {
  imageList?: UploadFile<ImageCardFragment>[]
  handleRemoveImage: (id: string) => void
}

export const UploadPreviewList = (props: PreviewListProps) => {
  const { imageList, handleRemoveImage } = props

  return (
    <PreviewListContainer>
      {map(
        (image) => (
          <ImageCard
            key={image.uid}
            imageFile={image.response}
            loading={isNil(image.response)}
            iconType={IconType.REMOVE}
            handleIconOnClick={() => handleRemoveImage(image.uid)}
          />
        ),
        imageList || []
      )}
    </PreviewListContainer>
  )
}
