import { Form, Radio } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React from 'react'

import { SymptomsInput } from '../../../codegen/types'
import { formItemLayout } from './layouts'

export interface FormSymptomFields {
  symptoms: Pick<
    SymptomsInput,
    | 'anteriorCrowding'
    | 'posteriorCrowding'
    | 'openBite'
    | 'deepBite'
    | 'highCanine'
    | 'anteriorCrossbite'
    | 'posteriorCrossbite'
    | 'bimaxillaryProtrusion'
    | 'vShapeArch'
  >
}
type FormSymptomInitialValues = Partial<FormSymptomFields>

interface Props {
  form: FormInstance<FormSymptomFields>
  initialValues?: FormSymptomInitialValues
  disabled?: boolean
}

const symptomsOptions = [
  { label: 'Anterior Crowding', value: 'anteriorCrowding' },
  { label: 'Posterior Crowding', value: 'posteriorCrowding' },
  { label: 'Open Bite', value: 'openBite' },
  { label: 'Deep Bite', value: 'deepBite' },
  { label: 'High Canine', value: 'highCanine' },
  { label: 'Anterior Crossbite', value: 'anteriorCrossbite' },
  { label: 'Posterior Crossbite', value: 'posteriorCrossbite' },
  { label: 'Bimaxillary Protrusion', value: 'bimaxillaryProtrusion' },
  { label: 'V Shape Arch', value: 'vShapeArch' },
]

const PatientFormSymptoms = (props: Props) => {
  const { form, initialValues, disabled } = props

  return (
    <Form {...formItemLayout} form={form} initialValues={initialValues}>
      {symptomsOptions.map((symptom) => (
        <Form.Item
          key={symptom.value}
          name={['symptoms', symptom.value]}
          label={symptom.label}
        >
          <Radio.Group disabled={disabled}>
            <Radio value>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
      ))}
    </Form>
  )
}

export default PatientFormSymptoms
