import { gql } from '@apollo/client'
import { Row, Space, Tooltip } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { DentalModelViewerEvalStageInlineFragment } from '../../../codegen/types'
import { BorderGrey } from '../constant/color'
import { MeshColor } from '../constant/meshColor'
import Clip from '../icons/Clip'
import Printer from '../icons/Printer'
import SelectableItem from './SelectableItem'
import { ViewerItem } from '..'

const SidebarWrapper = styled.div<{ disabled: boolean }>`
  background-color: white;
  overflow: scroll;
  ${(props) => props.disabled && 'opacity: 0.25'}
`
const InfoRow = styled(Row)`
  border: 1px solid ${BorderGrey};
  padding: 8px 24px;
  span {
    font-size: 16px;
  }
`
const StepsOverviewLink = styled.a<{ disabled: boolean }>`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`

interface SidebarProps {
  className?: string
  disabled: boolean
  selectedItemIds: ViewerItem['id'][]
  viewerItems: ViewerItem[]
  selectedEvalStage?: DentalModelViewerEvalStageInlineFragment
  getReportPreviewLink: (stageId: string) => string
  handleSelectItem: (itemId: ViewerItem['id']) => void
  handleChangeColor: (id: string, color: MeshColor) => void
}

const Sidebar = (props: SidebarProps) => {
  const {
    className,
    disabled,
    selectedItemIds,
    viewerItems,
    selectedEvalStage,
    getReportPreviewLink,
    handleSelectItem,
    handleChangeColor,
  } = props
  const stepsOverviewLink = selectedEvalStage?.stepsOverview?.path

  return (
    <SidebarWrapper className={className} disabled={disabled}>
      {selectedEvalStage && (
        <InfoRow justify='space-between'>
          <span>報告內容</span>
          <Space>
            <Tooltip title='下載齒列移動表'>
              <StepsOverviewLink
                href={stepsOverviewLink}
                disabled={!stepsOverviewLink}
              >
                <Clip />
              </StepsOverviewLink>
            </Tooltip>
            <Tooltip title='列印報告'>
              <Link to={getReportPreviewLink(selectedEvalStage.id)}>
                <Printer />
              </Link>
            </Tooltip>
          </Space>
        </InfoRow>
      )}
      <InfoRow justify='space-between'>
        <span>模型列表</span>
      </InfoRow>
      {viewerItems.map((item, index) => (
        <SelectableItem
          key={index}
          item={item}
          isActive={selectedItemIds.includes(item.id)}
          handleSelect={() => handleSelectItem(item.id)}
          handleChangeColor={(color: MeshColor) =>
            disabled ? {} : handleChangeColor(item.id, color)
          }
        />
      ))}
    </SidebarWrapper>
  )
}

Sidebar.fragments = {
  Sidebar: gql`
    fragment Sidebar on EvalStage {
      stepsOverview {
        path
      }
    }
  `,
}

export default Sidebar
