import { exhaustiveCheck } from '@sov/common'
// import produce from 'immer'
import { append, mergeDeepRight } from 'ramda'
import { Reducer, useReducer } from 'react'

import { ViewerItem } from '.'
import {
  ALL_OPTION,
  CAMERA_VIEW,
  MESH_LOCATION_WITH_ALL,
  MESH_TYPE_WITH_ALL,
  VIEW_METHOD,
} from './constant/controller'

export interface ControllerState {
  /** 視角 (六個視角) */
  cameraView: CAMERA_VIEW
  /** 正在調整視角 */
  isCameraViewChanging: boolean
  /** 上下顎顯示 */
  location: MESH_LOCATION_WITH_ALL
  /** 牙齒牙肉顯示 */
  visibleType: MESH_TYPE_WITH_ALL
  /** 軸線顯示 */
  isAxesVisible: boolean
  /** 標準 vs 張口模式 */
  viewMethod: VIEW_METHOD

  /** 目前檢視項目（建模、設計、CP） */
  selectedItemIds: ViewerItem['id'][]

  /** 是否播放模式 */
  isPlayMode: boolean
  /** 是否播放中 */
  isPlaying: boolean
  /** 有無下一個 */
  hasNext: boolean
  /** 有無上一個 */
  hasPrev: boolean
}

interface ActionSetCameraView {
  type: 'setCameraView'
  payload: CAMERA_VIEW
}
interface ActionSetCameraFinish {
  type: 'setCameraFinish'
}
interface ActionSetLocation {
  type: 'setLocation'
  payload: MESH_LOCATION_WITH_ALL
}
interface ActionSetVisibleType {
  type: 'setVisibleType'
  payload: MESH_TYPE_WITH_ALL
}
interface ActionToggleAxesVisible {
  type: 'toggleAxesVisible'
}
interface ActionSetViewMethod {
  type: 'setViewMethod'
  payload: VIEW_METHOD
}
interface ActionStart {
  type: 'start'
}
interface ActionFinish {
  type: 'finish'
}
interface ActionNext {
  type: 'next'
}
interface ActionPrev {
  type: 'prev'
}
interface ActionPlay {
  type: 'play'
}
interface ActionPause {
  type: 'pause'
}
interface ActionSelectItem {
  type: 'selectItem'
  payload: ViewerItem['id']
}

export type ControllerAction =
  | ActionSetCameraView
  | ActionSetCameraFinish
  | ActionSetLocation
  | ActionSetVisibleType
  | ActionToggleAxesVisible
  | ActionSetViewMethod
  | ActionStart
  | ActionFinish
  | ActionNext
  | ActionPrev
  | ActionPlay
  | ActionPause
  | ActionSelectItem

interface UseControllerArgs {
  viewerItems: ViewerItem[]
  initialState?: Partial<ControllerState>
}

const useController = (args: UseControllerArgs) => {
  const { initialState, viewerItems } = args

  const initialController: ControllerState = {
    cameraView: CAMERA_VIEW.FRONT,
    isCameraViewChanging: false,
    location: ALL_OPTION.ALL,
    visibleType: ALL_OPTION.ALL,
    isAxesVisible: false,
    viewMethod: VIEW_METHOD.NORMAL,

    selectedItemIds: [],
    isPlayMode: false,
    isPlaying: false,
    hasNext: false,
    hasPrev: false,
  }

  const reducer: Reducer<ControllerState, ControllerAction> = (
    controller,
    action
  ) => {
    const currentIndex = viewerItems.findIndex(
      (item) => item.id === controller.selectedItemIds[0]
    )
    switch (action.type) {
      case 'setCameraView':
        return {
          ...controller,
          cameraView: action.payload,
          isCameraViewChanging: true,
        }
      case 'setCameraFinish':
        return {
          ...controller,
          isCameraViewChanging: false,
        }
      case 'setLocation':
        return {
          ...controller,
          location: action.payload,
        }
      case 'setVisibleType':
        return {
          ...controller,
          visibleType: action.payload,
        }
      case 'toggleAxesVisible':
        return {
          ...controller,
          isAxesVisible: !controller.isAxesVisible,
        }
      case 'setViewMethod':
        return {
          ...controller,
          viewMethod: action.payload,
        }
      case 'start':
        return {
          ...controller,
          isPlayMode: true,
          selectedItemIds: [viewerItems[0].id],
          isPlaying: true,
          hasNext: viewerItems.length > 1,
        }
      case 'finish':
        return {
          ...controller,
          isPlayMode: false,
          isPlaying: false,
          hasNext: false,
          hasPrev: false,
        }
      case 'next':
        /** 若為最後一個，則不改變 state */
        if (currentIndex + 1 === viewerItems.length) return controller
        return {
          ...controller,
          selectedItemIds: [viewerItems[currentIndex + 1].id],
          hasNext: currentIndex + 1 < viewerItems.length - 1,
          hasPrev: currentIndex + 1 > 0,
        }
      case 'prev':
        /** 若為第一個，則不改變 state */
        if (currentIndex === 0) return controller
        return {
          ...controller,
          selectedItemIds: [viewerItems[currentIndex - 1].id],
          hasNext: currentIndex - 1 < viewerItems.length - 1,
          hasPrev: currentIndex - 1 > 0,
        }
      case 'play':
        return {
          ...controller,
          isPlaying: true,
        }
      case 'pause':
        return {
          ...controller,
          isPlaying: false,
        }
      case 'selectItem':
        return {
          ...controller,
          selectedItemIds: controller.selectedItemIds.includes(action.payload)
            ? controller.selectedItemIds.filter((id) => id !== action.payload)
            : append(action.payload, controller.selectedItemIds),
        }
      default:
        exhaustiveCheck(action)
        return controller
    }
  }

  // const reducerWithImmer = produce(reducer)
  const [controller, dispatch] = useReducer(
    reducer,
    mergeDeepRight(initialController, initialState)
  )

  return {
    controller,
    dispatch,
  }
}

export default useController
