import { gql } from '@apollo/client'
import { Avatar, Col, Row } from 'antd'
import React from 'react'

import { Gender, TablePatientInfoFragment } from '../../codegen/types'
import { ClinicIcon, DoctorIcon } from '../icon'
import ClinicLink from '../Link/Clinic'
import DoctorLink from '../Link/Doctor'
import PatientLink from '../Link/Patient'
import AvatarFemale from './female.svg'
import AvatarMale from './male.svg'

/* 

Table / PatientInfo
https://www.figma.com/file/apE4aCn8QWPQZOwRWzbOk5/CRM-ERP-Library?node-id=97%3A1954

*/

interface Props {
  patient: TablePatientInfoFragment
  avatar?: boolean
}

const TablePatientInfo = (props: Props) => {
  const { patient, avatar = true } = props
  const defaultAvatar =
    patient.gender === Gender.Female ? AvatarFemale : AvatarMale

  return (
    <Row wrap={false}>
      {avatar && (
        <Col flex='40px' style={{ marginRight: 8 }}>
          <Avatar
            size={40}
            src={patient.photos.frontFace?.thumbnailPath ?? defaultAvatar}
          />
        </Col>
      )}
      <Col flex='auto' style={{ textAlign: 'left' }}>
        <PatientLink
          item={patient}
          style={{ fontSize: '16px', lineHeight: '24px' }}
        />
        <Row gutter={4}>
          <Col>
            <Row wrap={false}>
              <ClinicIcon />
              <ClinicLink
                item={patient.clinic}
                style={{ color: 'rgba(0, 0, 0, 0.45)', lineHeight: '16px' }}
              />
            </Row>
          </Col>
          <Col>
            <Row wrap={false}>
              <DoctorIcon />
              <DoctorLink
                item={patient.doctor}
                style={{ color: 'rgba(0, 0, 0, 0.45)', lineHeight: '16px' }}
              />
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

TablePatientInfo.fragment = gql`
  fragment TablePatientInfo on Patient {
    ...PatientLink
    photos {
      # 口外照(外部照片)
      # 正面照
      frontFace {
        id
        filename
        path
        thumbnailPath
      }
    }
    gender
    clinic {
      ...ClinicLink
    }
    doctor {
      ...DoctorLink
    }
  }
  ${PatientLink.fragment}
  ${ClinicLink.fragment}
  ${DoctorLink.fragment}
`

export { TablePatientInfo }
