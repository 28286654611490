import React from 'react'
import { Link } from 'react-router-dom'

import Layout from '../components/Layout'

const ResetPasswordEmailSent = () => (
  <Layout
    title='密碼重設信已寄出'
    description={
      <>
        <div>系統已寄送一封驗證信至您的信箱</div>
        <div>請點擊信件中的連結驗證您的帳號身份</div>
        <div style={{ marginTop: '48px' }}>
          沒有收到信？ 請
          <Link to='/recoverPassword/email' style={{ color: '#00b8ff' }}>
            {' '}
            重新輸入電子信箱
          </Link>
        </div>
      </>
    }
    backLink='/'
  />
)

export default ResetPasswordEmailSent
