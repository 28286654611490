import { gql, useMutation, useQuery } from '@apollo/client'
import { ErrorHandling } from '@sov/common'
import { Modal } from 'antd'
import { isEmpty } from 'ramda'
import React, { useState } from 'react'

import {
  CurrentEvalStageModalStageInfoFragment,
  PatientCurrentEvalStageModalQuery,
  PatientCurrentEvalStageModalQueryVariables,
  UpdateCurrentEvalStageMutationMutation,
  UpdateCurrentEvalStageMutationMutationVariables,
} from '../../../codegen/types'
import CurrentEvalStageModalBody from './ModalBody'

const patientCurrentEvalStageModal = gql`
  query PatientCurrentEvalStageModal($id: ID!) {
    patient(id: $id) {
      ...CurrentEvalStageModalBody
    }
  }
  ${CurrentEvalStageModalBody.fragments.CurrentEvalStageModalBody}
`
const updateCurrentEvalStageMutation = gql`
  mutation updateCurrentEvalStageMutation($id: ID!, $stageId: ID!) {
    selectPatientCurrentEvalStage(id: $id, stageId: $stageId) {
      id
    }
  }
`

interface CurrentEvalStageModalProps {
  isCurrentEvalStageModalVisible: boolean
  patientId: string
  orderAction: () => void
  handleCurrentEvalStageModalClose: () => void
}
const CurrentEvalStageModal = (props: CurrentEvalStageModalProps) => {
  const {
    isCurrentEvalStageModalVisible,
    patientId,
    orderAction,
    handleCurrentEvalStageModalClose,
  } = props
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const [selectedEvalStage, setSelectedStage] =
    useState<CurrentEvalStageModalStageInfoFragment>()

  const { data, loading, refetch } = useQuery<
    PatientCurrentEvalStageModalQuery,
    PatientCurrentEvalStageModalQueryVariables
  >(patientCurrentEvalStageModal, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    skip: isEmpty(patientId),
    variables: {
      id: patientId,
    },
  })
  const [updatePatient] = useMutation<
    UpdateCurrentEvalStageMutationMutation,
    UpdateCurrentEvalStageMutationMutationVariables
  >(updateCurrentEvalStageMutation)
  const handleUpdate = async () => {
    await updatePatient({
      variables: {
        id: data?.patient?.id as string,
        stageId: selectedEvalStage?.id as string,
      },
      update: async (cache, { data }) => {
        if (data) {
          await refetch()
        }
      },
    })
  }

  const handleModalClose = () => {
    handleCurrentEvalStageModalClose()
    setSelectedStage(undefined)
  }

  return (
    <Modal
      bodyStyle={{
        padding: 0,
      }}
      closable={false}
      width={480}
      onCancel={handleModalClose}
      visible={isCurrentEvalStageModalVisible}
      destroyOnClose
      footer={null}
      maskClosable={false}
    >
      <CurrentEvalStageModalBody
        patientItem={data?.patient}
        loading={loading}
        selectedEvalStage={selectedEvalStage}
        setSelectedStage={setSelectedStage}
        orderAction={orderAction}
        handleCurrentEvalStageModalClose={handleCurrentEvalStageModalClose}
        handleUpdate={handleUpdate}
      />
    </Modal>
  )
}

export default CurrentEvalStageModal
