import { DownloadOutlined } from '@ant-design/icons'
import { useApolloClient } from '@apollo/client'
import { GqlUpload } from '@sov/ui'
import { Avatar, Spin } from 'antd'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { EntityType, File } from '../../../codegen/types'

const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  transition: 0.5s ease;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  border-radius: 50%;
  z-index: 1000;
  cursor: pointer;
  text-align: center;
  :hover {
    opacity: 1;
  }
`

const IconContainer = styled.div`
  position: relative;
  top: 50%;
  color: white;
`

interface Props {
  entityType: EntityType
  src: string
  size?: number
  handleImageUpload?: (avatar: string) => Promise<void>
  canUpload?: boolean
}

const AccountAvatar = (props: Props) => {
  const avatars = import('./images')

  const {
    size,
    src,
    entityType,
    canUpload = false,
    handleImageUpload,
    ...restProps
  } = props

  const [uploading, setUploading] = useState(false)
  const [imageSrc, setImageSrc] = useState(src)
  const [defaultImages, setDefaultImages] = useState({})

  const client = useApolloClient()

  const handleUploadImage = async (
    params: UploadChangeParam<UploadFile<File>>
  ) => {
    setUploading(true)
    if (params.file.response) {
      setImageSrc(params.file.response.thumbnailPath ?? '')
      handleImageUpload && (await handleImageUpload(params.file.response.id))
      setUploading(false)
    }
  }

  useEffect(() => {
    avatars.then((res) => setDefaultImages(res.default))
  }, [])

  return canUpload ? (
    <GqlUpload.Upload
      client={client}
      fileType='image'
      uploadOptions={{ needThumbnail: true, prefix: 'account' }}
      accept='image/*'
      onChange={handleUploadImage}
      showUploadList={false}
    >
      <Spin spinning={uploading}>
        <ImageOverlay>
          <IconContainer>
            <DownloadOutlined />
            <div>上傳照片</div>
          </IconContainer>
        </ImageOverlay>
        <Avatar
          size={size}
          src={imageSrc || defaultImages[entityType]}
          {...restProps}
        />
      </Spin>
    </GqlUpload.Upload>
  ) : (
    <Avatar
      size={size}
      src={imageSrc || defaultImages[entityType]}
      {...restProps}
    />
  )
}

export default AccountAvatar
