import React, { useEffect, useRef, useState } from 'react'
import { useThree } from 'react-three-fiber'
import { BufferGeometry } from 'three'

import { JawPosition, ToothPosition } from '../../../codegen/types'
import { LightBlue } from '../constant/color'
import { MESH_TYPE, VIEW_METHOD } from '../constant/controller'
import { MeshColor } from '../constant/meshColor'
import { CANVAS_HEIGHT, CANVAS_WIDTH, ZOOM } from '../constant/size'
import { getMeshLocation, getMeshType } from '../utils'

export interface MeshInfo {
  url: string
  position: ToothPosition | JawPosition
  geometry?: BufferGeometry
}

interface MeshProps {
  color: MeshColor
  meshInfo: MeshInfo
  viewMethod: VIEW_METHOD
}

const Mesh = (props: MeshProps) => {
  const { color, meshInfo, viewMethod } = props

  const meshType = getMeshType(meshInfo)

  const meshRef = useRef<any>()
  const [isHover, setIsHover] = useState(false)
  const { camera } = useThree()

  useEffect(() => {
    if (meshRef.current) {
      if (viewMethod === 'NORMAL') {
        meshRef.current.position.set(0, 0, 0)
        meshRef.current.rotation.set(0, 0, 0)
        // @ts-ignore
        camera.left = -CANVAS_WIDTH / ZOOM
        // @ts-ignore
        camera.right = CANVAS_WIDTH / ZOOM
        // @ts-ignore
        camera.top = CANVAS_HEIGHT / ZOOM
        // @ts-ignore
        camera.bottom = -CANVAS_HEIGHT / ZOOM
        camera.updateProjectionMatrix()
      } else if (viewMethod === 'SPLIT') {
        if (getMeshLocation(meshInfo) === 'UPPER') {
          meshRef.current.position.set(0, 0, -70)
          meshRef.current.rotation.set(-Math.PI, 0, 0)
        } else {
          meshRef.current.position.set(0, 0, 0)
        }
        // @ts-ignore
        camera.left = (-CANVAS_WIDTH / ZOOM) * 2
        // @ts-ignore
        camera.right = (CANVAS_WIDTH / ZOOM) * 2
        // @ts-ignore
        camera.top = (CANVAS_HEIGHT / ZOOM) * 2 + 10
        // @ts-ignore
        camera.bottom = (-CANVAS_HEIGHT / ZOOM) * 2 + 10
        camera.position.y = 500
        camera.updateProjectionMatrix()
      }
    }
  }, [viewMethod])

  return (
    <mesh
      onPointerOut={() => setIsHover(false)}
      onPointerMove={() => setIsHover(true)}
      geometry={meshInfo?.geometry}
      ref={meshRef}
      scale={[1, 1, 1]}
      userData={meshInfo}
    >
      <meshPhongMaterial
        attach='material'
        color={meshType === MESH_TYPE.TEETH && isHover ? LightBlue : color}
      />
    </mesh>
  )
}

export default Mesh
