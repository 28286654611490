import { Form } from '@ant-design/compatible'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { gql } from '@apollo/client'
import { isEmpty, map } from 'ramda'
import React from 'react'
import styled from 'styled-components'

import {
  ClinicFormFragment,
  Country,
  District,
} from '../../../../../codegen/types'

const FormContainer = styled.div`
  padding: 24px;
  > div:not(:last-child) {
    border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  }
`

const ClinicInfoContainer = styled.div`
  padding: 16px 0px;
  > div:not(:first-child):not(:last-child) {
    margin-bottom: 16px;
  }
`

const ClinicName = styled.div`
  font-size: 16px;
  margin-bottom: 8px;
`

const FieldRow = styled.div`
  display: flex;
`

const FieldTitle = styled.div`
  text-align: right;
`

const FieldContent = styled.div`
  margin-left: 16px;
`

interface ClinicFields {
  name: string
  location: {
    address: string
    country: Country
    district: District
  }
  phone: string
  email: string
}

interface Props extends FormComponentProps<ClinicFormFragment> {
  account: ClinicFormFragment
}

const ClinicForm = (props: Props) => {
  const { account } = props
  const { entity } = account

  if (entity?.__typename !== 'Doctor' && entity?.__typename !== 'Clinic') {
    return null
  }

  let clinics: ClinicFields[] = []

  if (entity?.__typename === 'Doctor') {
    // @todo 待討論，先暫時顯示醫生所屬診所的第一個
    clinics = entity.clinics && !isEmpty(entity.clinics) ? entity.clinics : []
  } else if (entity?.__typename === 'Clinic') {
    clinics = [entity]
  }

  const getClinicInfo = (clinic: ClinicFields) => {
    return (
      <ClinicInfoContainer>
        <ClinicName>{clinic.name}</ClinicName>
        <FieldRow>
          <FieldTitle>診所地址:</FieldTitle>
          <FieldContent>{clinic?.location.address}</FieldContent>
        </FieldRow>
        <FieldRow>
          <FieldTitle>診所電話:</FieldTitle>
          <FieldContent>{clinic?.phone}</FieldContent>
        </FieldRow>
        <FieldRow>
          <FieldTitle>診所 email:</FieldTitle>
          <FieldContent>{clinic?.email || '-'}</FieldContent>
        </FieldRow>
      </ClinicInfoContainer>
    )
  }
  return <FormContainer>{map(getClinicInfo, clinics)}</FormContainer>
}

ClinicForm.fragment = gql`
  fragment ClinicForm on Account {
    entity {
      id
      ... on Clinic {
        name
        location {
          address
          country
          district
        }
        phone
        email
      }
      ... on Doctor {
        clinics {
          name
          location {
            address
            country
            district
          }
          phone
          email
        }
      }
    }
  }
`
export default Form.create<Props>()(ClinicForm)
