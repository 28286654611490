import FacebookDisabled from './off_facebook.png'
import GoogleDisabled from './off_google.png'
import LineDisabled from './off_line.png'
import PhoneDisabled from './off_phone.png'
import SovDisabled from './off_sov.png'
import Facebook from './on_facebook.png'
import Google from './on_google.png'
import Line from './on_line.png'
import Phone from './on_phone.png'
import Sov from './on_sov.png'

export default {
  facebook: {
    normal: Facebook,
    gray: FacebookDisabled,
  },
  google: {
    normal: Google,
    gray: GoogleDisabled,
  },
  line: {
    normal: Line,
    gray: LineDisabled,
  },
  phone: {
    normal: Phone,
    gray: PhoneDisabled,
  },
  sov: {
    normal: Sov,
    gray: SovDisabled,
  },
}
