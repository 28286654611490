import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  color: black;
  font-size: 18px;
  margin: 0 60px;
`

const Disclaimer = () => {
  return (
    <Container>
      內容聲明：上述報告為病患現有口內狀況的評估，以及電腦模擬後之預估療程，如病患超過
      3 個月未追蹤或未按時配戴以致療程延長，SOV 舒服美有權不提供後續服務
    </Container>
  )
}

export default Disclaimer
