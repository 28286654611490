import { gql } from '@apollo/client'
import { AntSorterType, TableQuery } from '@sov/common'
import { DateTimeInterval } from '@sov/ui'
import { Badge, Table } from 'antd'
import { BadgeProps } from 'antd/lib/badge'
import { ColumnProps, TableProps } from 'antd/lib/table'
import moment from 'moment'
import { cond, join, map, values } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import {
  OrderStatus,
  PatientOrderTableFragment,
  StageOrderContent,
  StageType,
} from '../../../codegen/types'
import {
  OrderListFilter,
  OrderListSorterField,
} from '../../../pages/patient/PatientOrderList'
import { sortByAppointmentStart } from '../order/OrderTable'

const stageOrderLabel = {
  [StageType.Eval]: '份報告',
  [StageType.Print]: '組 step',
}

interface OrderTableProps {
  loading: boolean
  data: PatientOrderTableFragment[]
  antdPagination: {
    page: number
    total: number
    limit: number
  }
  sortInfo?: AntSorterType<OrderListSorterField>
  filterInfo?: OrderListFilter
  handleTableChange: TableProps<PatientOrderTableFragment>['onChange']
}

const OrderTable = (props: OrderTableProps) => {
  const {
    loading,
    data,
    antdPagination,
    sortInfo,
    filterInfo,
    handleTableChange,
  } = props
  const { t } = useTranslation()

  const getStageOrderString = (stageOrderItem: StageOrderContent) => {
    if (stageOrderItem.stageType === StageType.Accessory) {
      return join(
        '+',
        stageOrderItem.items?.map((item) => t(`stage.accessoryCode.${item}`)) ??
          []
      )
    }
    return `${stageOrderItem.number} ${
      stageOrderLabel[stageOrderItem.stageType]
    }`
  }

  const columns: ColumnProps<PatientOrderTableFragment>[] = [
    // @TODO 暫時拔除
    // {
    //   title: '訂單成立日期',
    //   width: 50,
    //   key: 'created',
    //   dataIndex: 'created',
    //   render: (text, record) => <div>{moment(record.created).format('YYYY-MM-DD')}</div>
    // },
    {
      title: '編號',
      width: 90,
      key: 'displayId',
      dataIndex: 'displayId',
      align: 'center',
      render: (_text, record) => (
        <Link to={`/orders/${record.id}`}>{_text}</Link>
      ),
    },
    {
      title: '訂單種類',
      width: 120,
      key: 'type',
      dataIndex: 'type',
      align: 'center',
      render: (text, record) => <div>{t(`order.type.${record.type}`)}</div>,
    },
    {
      title: '訂單項目',
      width: 205,
      key: 'stageOrder',
      dataIndex: 'stageOrder',
      align: 'center',
      render: (text, record) => (
        <div>{join('+', map(getStageOrderString, record.stageOrder))}</div>
      ),
    },
    {
      title: '預計回診日',
      width: 200,
      key: 'appointment',
      dataIndex: 'appointment',
      align: 'center',
      sorter: sortByAppointmentStart,
      sortOrder: sortInfo?.field === 'appointment' ? sortInfo.order : null,
      render: (_text, record) =>
        record.appointment ? (
          <DateTimeInterval
            start={moment(record.appointment.startDate)}
            end={moment(record.appointment.endDate)}
          />
        ) : null,
    },
    {
      title: '備註',
      width: 300,
      key: 'description',
      dataIndex: 'description',
      align: 'center',
      render: (text, record) => <div>{record.description}</div>,
    },
    {
      title: '目前狀態',
      width: 120,
      key: 'status',
      dataIndex: 'status',
      align: 'center',
      filters: map(
        (status) => ({ text: t(`order.status.${status}`), value: status }),
        values(OrderStatus)
      ),
      filteredValue: filterInfo?.status ?? [],
      render: (text, record) => {
        const orderStatus = record.status
        console.log(record.created)

        const badgeStatus = cond<OrderStatus, BadgeProps['status']>([
          [(status) => status === OrderStatus.Completed, () => 'success'],
          [(status) => status === OrderStatus.InProgress, () => 'processing'],
          [(status) => status === OrderStatus.Pending, () => 'error'],
        ])(orderStatus)

        return (
          <div>
            <Badge status={badgeStatus} />
            {t(`order.status.${orderStatus}`)}
          </div>
        )
      },
    },
    {
      title: '操作',
      width: 120,
      key: 'id',
      dataIndex: 'id',
      align: 'center',
      render: (_text, record) =>
        record.id ? <Link to={`/orders/${record.id}`}>檢視詳情</Link> : null,
    },
  ]

  return (
    <Table<PatientOrderTableFragment>
      rowKey='id'
      columns={columns}
      dataSource={data}
      loading={loading}
      onChange={handleTableChange}
      pagination={TableQuery.getAntdPagination(antdPagination)}
    />
  )
}

OrderTable.fragment = gql`
  fragment PatientOrderTable on Order {
    id
    displayId
    created
    type
    stageOrder {
      stageType
      number
      items
    }
    description
    appointment {
      id
      startDate
      endDate
    }
    status
  }
`

export default OrderTable
