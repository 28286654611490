import { blue } from '@ant-design/colors'
import {
  ClockCircleOutlined,
  DownOutlined,
  FileTextOutlined,
  LeftOutlined,
} from '@ant-design/icons'
import { gql } from '@apollo/client'
import { Dropdown, Menu, Row, Space, Tag, Typography } from 'antd'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import {
  PatientStatus,
  StageStatus,
  StageType,
  ViewerHeaderDocs,
  ViewerHeaderEvalStageInlineFragment,
  ViewerHeaderFragment,
} from '../../../codegen/types'
import StageName from '../../StageName'
import { TablePatientInfo } from '../../table/PatientInfo'
import { HeaderHeight } from '../constant/size'
import { Mode } from '..'

const BackLink = styled(Link)`
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: black;
  }
`
const Container = styled(Row)`
  align-items: center;
  height: ${HeaderHeight}px;
  padding: 0 24px;
  box-shadow: 0px -1px 6px rgba(0, 0, 0, 0.12), 0px 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 1;
`
const DropdownTrigger = styled(Space)`
  margin-right: 32px;
  cursor: pointer;
`
const StyledMenu = styled(Menu)`
  padding: 8px;
  .ant-dropdown-menu-item:hover {
    background-color: ${blue[0]};
  }
`

interface HeaderTitleProps {
  evalStages: ViewerHeaderDocs[]
  mode: Mode
  currentEvalStageId?: string
  CurrentEvalStageButton?: () => JSX.Element
}

const HeaderTitle = (props: HeaderTitleProps) => {
  const { mode, evalStages, currentEvalStageId, CurrentEvalStageButton } = props

  if (mode === 'treatment') {
    return (
      <Row align='middle'>
        <Typography.Title level={4} style={{ margin: 0 }}>
          從頭開始看療程
        </Typography.Title>
      </Row>
    )
  }

  const selectedEvalStage = evalStages.find((stage) => stage.id === mode)
  const isCurrentEvalStage = currentEvalStageId === mode

  return (
    <Space align='center'>
      <Typography.Title level={4} style={{ margin: 0 }}>
        {selectedEvalStage && <StageName item={selectedEvalStage} />}
      </Typography.Title>
      {isCurrentEvalStage ? (
        <Tag color='green' style={{ marginLeft: 8 }}>
          主要報告
        </Tag>
      ) : (
        CurrentEvalStageButton && <CurrentEvalStageButton />
      )}
    </Space>
  )
}

interface Props {
  patient: ViewerHeaderFragment
  mode: Mode
  handleChangeMode: (mode: Mode) => void
  CurrentEvalStageButton?: () => JSX.Element
}

const ViewerHeader = (props: Props) => {
  const { patient, mode, handleChangeMode, CurrentEvalStageButton } = props

  const currentEvalStageId = patient.currentEvalStage?.id
  const evalStages =
    (patient.evalStages?.docs as ViewerHeaderEvalStageInlineFragment[]) ?? []
  const restEvalStages = evalStages.filter((stage) => {
    if (!stage.hasFilesForViewer) return false
    if (mode === 'treatment') return stage.type === StageType.Eval
    return stage.type === StageType.Eval && stage.id !== mode
  })

  const selectedEvalStage = evalStages.find((stage) =>
    mode === 'treatment' ? stage.id === currentEvalStageId : stage.id === mode
  )!
  const canRenderCurrentEvalStageButton =
    patient.status === PatientStatus.OnEval &&
    selectedEvalStage.status === StageStatus.Completed

  const handleSelect = (mode: Mode) => {
    handleChangeMode(mode)
  }

  const menu = (
    <StyledMenu>
      <Menu.Item key='treatment' onClick={() => handleSelect('treatment')}>
        <Space>
          <ClockCircleOutlined style={{ fontSize: 18 }} />
          <Space size={0} direction='vertical'>
            <Typography.Text>從頭開始看療程</Typography.Text>
            <Typography.Text type='secondary'>
              此版本會顯示過程中的所有的模型
            </Typography.Text>
          </Space>
        </Space>
      </Menu.Item>
      {restEvalStages.map((stage) => (
        <Menu.Item key={stage.id} onClick={() => handleSelect(stage.id)}>
          <Space>
            <FileTextOutlined style={{ fontSize: 18 }} />
            <Space size={0} direction='vertical'>
              <Typography.Text>
                <StageName item={stage} />
              </Typography.Text>
              <Typography.Text type='secondary'>
                完成日期:
                {stage.shippingDate
                  ? moment(stage.shippingDate).format('YYYY-MM-DD')
                  : '--'}
              </Typography.Text>
            </Space>
          </Space>
        </Menu.Item>
      ))}
    </StyledMenu>
  )

  return (
    <Container align='middle'>
      <BackLink to={`/patients/${patient.id}`}>
        <LeftOutlined />
      </BackLink>
      <Row justify='space-between' align='middle' style={{ flex: 1 }}>
        <TablePatientInfo patient={patient} />
        <HeaderTitle
          mode={mode}
          evalStages={evalStages}
          currentEvalStageId={currentEvalStageId}
          CurrentEvalStageButton={
            canRenderCurrentEvalStageButton ? CurrentEvalStageButton : undefined
          }
        />
        <Dropdown overlay={menu} trigger={['click']}>
          <div onClick={(e) => e.preventDefault()}>
            <DropdownTrigger align='center'>
              <div>切換</div>
              <DownOutlined />
            </DropdownTrigger>
          </div>
        </Dropdown>
      </Row>
    </Container>
  )
}

ViewerHeader.fragments = {
  ViewerHeader: gql`
    fragment ViewerHeader on Patient {
      ...TablePatientInfo
      id
      status
      name
      currentEvalStage {
        id
      }
      evalStages: stages(query: { type: [${StageType.Eval}], status: [${StageStatus.Completed}, ${StageStatus.Started}] }, page: 1, limit: 50, sort: "-created") {
        docs {
          ...StageName
          __typename
          id
          shippingDate
          status
          ... on EvalStage {
            hasFilesForViewer
          }
        }
      }
    }
    ${StageName.fragment}
    ${TablePatientInfo.fragment}
  `,
}

export default ViewerHeader
