import React from 'react'

import PageBottom from './PageBottom'
import PageTop from './PageTop'

interface PageBreakProps {
  /** 在網頁預覽時可否看到預覽樣式 */
  previewInHTML: boolean
}

const PageBreak = (props: PageBreakProps) => {
  const { previewInHTML } = props

  return (
    <>
      <PageBottom previewInHTML={previewInHTML} />
      <PageTop previewInHTML={previewInHTML} />
    </>
  )
}

export default PageBreak
