import { blue } from '@ant-design/colors'
import {
  CloseOutlined,
  PauseCircleFilled,
  PlayCircleFilled,
  StepBackwardOutlined,
  StepForwardOutlined,
} from '@ant-design/icons'
import { Divider, Row, Space, Tooltip, Typography } from 'antd'
import { equals, map } from 'ramda'
import React, { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'

import {
  ALL_OPTION,
  CAMERA_VIEW,
  MESH_LOCATION,
  MESH_LOCATION_WITH_ALL,
  MESH_TYPE,
  MESH_TYPE_WITH_ALL,
  VIEW_METHOD,
} from '../constant/controller'
import { getName } from '../Sidebar/SelectableItem'
import { ControllerAction, ControllerState } from '../useController'
import icons from './icon'
import Steps from './Steps'
import { ViewerItem } from '..'

const {
  Hourglass,
  Axes,
  Back,
  BothJaw,
  Bottom,
  BottomJaw,
  Front,
  Left,
  Normal,
  PartAll,
  PartTeeth,
  Right,
  Split,
  Top,
  TopJaw,
} = icons

const i18nCameraView = {
  LEFT: '右側咬合',
  RIGHT: '左側咬合',
  FRONT: '前視角',
  BACK: '後視角',
  TOP: '上顎咬合',
  BOTTOM: '下顎咬合',
}

const WithTipActiveStyle = css`
  background-color: #f5f6fa;
  border: solid 1px rgba(0, 0, 0, 0.1);
`

const WithTip = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2px 6px;
  border-radius: 4px;
  transition: background ease 0.2s;
  cursor: pointer;

  &:hover {
    border: solid 1px #1890ff;
    opacity: 0.8;
  }

  span {
    font-size: 12px;
    margin-top: 4px;
  }

  ${(props) => props.isActive && WithTipActiveStyle}
`

const methodTranslate = {
  SPLIT: '張口模式',
  NORMAL: '標準模式',
}

interface ViewControlToolProps {
  cameraView: CAMERA_VIEW
  handleChangeCameraView: (type: CAMERA_VIEW) => void
}

const ViewControlTool = (props: ViewControlToolProps) => {
  const { cameraView, handleChangeCameraView } = props

  return (
    <Space size={0}>
      {map((viewType) => {
        const isSelected = viewType === cameraView
        return (
          <WithTip
            isActive={isSelected}
            key={viewType}
            onClick={() => handleChangeCameraView(viewType)}
          >
            {viewType === CAMERA_VIEW.BACK && <Back />}
            {viewType === CAMERA_VIEW.BOTTOM && <Bottom />}
            {viewType === CAMERA_VIEW.FRONT && <Front />}
            {viewType === CAMERA_VIEW.LEFT && <Left />}
            {viewType === CAMERA_VIEW.RIGHT && <Right />}
            {viewType === CAMERA_VIEW.TOP && <Top />}
            <span>{i18nCameraView[viewType]}</span>
          </WithTip>
        )
      }, Object.values(CAMERA_VIEW))}
    </Space>
  )
}

interface LocationControlToolProps {
  handleChangeLocation: (location: MESH_LOCATION_WITH_ALL) => void
  visibleLocation: MESH_LOCATION_WITH_ALL
}

const LocationControlTool = (props: LocationControlToolProps) => {
  const { handleChangeLocation, visibleLocation } = props
  const isSelected = equals(visibleLocation)
  return (
    <Space>
      <WithTip
        isActive={isSelected(ALL_OPTION.ALL)}
        onClick={() => handleChangeLocation(ALL_OPTION.ALL)}
      >
        <BothJaw />
        <span>上下顎</span>
      </WithTip>
      <WithTip
        isActive={isSelected(MESH_LOCATION.UPPER)}
        onClick={() => handleChangeLocation(MESH_LOCATION.UPPER)}
      >
        <TopJaw />
        <span>上顎</span>
      </WithTip>
      <WithTip
        isActive={isSelected(MESH_LOCATION.LOWER)}
        onClick={() => handleChangeLocation(MESH_LOCATION.LOWER)}
      >
        <BottomJaw />
        <span>下顎</span>
      </WithTip>
    </Space>
  )
}

interface TypeControlToolProps {
  handleChangeVisibleType: (type: MESH_TYPE_WITH_ALL) => void
  visibleType: MESH_TYPE_WITH_ALL
}

const TypeControlTool = (props: TypeControlToolProps) => {
  const { handleChangeVisibleType, visibleType } = props
  const isSelected = (type: MESH_TYPE_WITH_ALL) => equals(visibleType, type)
  return (
    <Space>
      <WithTip
        isActive={isSelected(ALL_OPTION.ALL)}
        onClick={() => handleChangeVisibleType(ALL_OPTION.ALL)}
      >
        <PartAll />
        <span>牙齒牙肉</span>
      </WithTip>
      <WithTip
        isActive={isSelected(MESH_TYPE.TEETH)}
        onClick={() => handleChangeVisibleType(MESH_TYPE.TEETH)}
      >
        <PartTeeth />
        <span>顯示牙齒</span>
      </WithTip>
    </Space>
  )
}

interface ViewMethodToolProps {
  handleChangeViewMethod: (method: VIEW_METHOD) => void
  viewMethod: VIEW_METHOD
}

const ViewMethodTool = (props: ViewMethodToolProps) => {
  const { handleChangeViewMethod, viewMethod } = props

  return (
    <Space>
      {map((method) => {
        const isSelected = viewMethod === method
        return (
          <WithTip
            isActive={isSelected}
            key={method}
            onClick={() => handleChangeViewMethod(method)}
          >
            {method === VIEW_METHOD.NORMAL && <Normal />}
            {method === VIEW_METHOD.SPLIT && <Split />}
            <span>{methodTranslate[method]}</span>
          </WithTip>
        )
      }, Object.values(VIEW_METHOD))}
    </Space>
  )
}

const ToolBarWrapper = styled(Space)`
  background-color: white;
  padding: 8px 16px;
  border-radius: 8px;
  min-height: 68px;
  * > svg {
    width: 24px;
    height: 24px;
  }
  .ant-steps-item-content {
    width: 0 !important;
    margin: 0 !important;
  }
`

const StyledSteps = styled(Steps)`
  width: 800px;
`

interface ToolBarProps {
  className?: string
  viewerItems: ViewerItem[]
  controller: ControllerState
  dispatch: React.Dispatch<ControllerAction>
}

type PlayingInterval = {
  interval: number
  index: number
}

const ToolBar = (props: ToolBarProps) => {
  const { className, viewerItems, controller, dispatch } = props
  const {
    cameraView,
    visibleType,
    isAxesVisible,
    viewMethod,
    location,
    isPlayMode,
    isPlaying,
    hasNext,
    hasPrev,
    selectedItemIds,
  } = controller

  const playingInterval = useRef<PlayingInterval>()
  const currentIndex = viewerItems.findIndex(
    (item) => item.id === selectedItemIds[0]
  )

  const setPlayingIndex = (index: number) => {
    if (playingInterval.current) {
      playingInterval.current.index = index
    }
  }
  const handleChangeCameraView = (cameraView: CAMERA_VIEW) => {
    dispatch({ type: 'setCameraView', payload: cameraView })
  }
  const handleChangeLocation = (location: MESH_LOCATION_WITH_ALL) => {
    dispatch({ type: 'setLocation', payload: location })
  }
  const handleChangeVisibleType = (visibleType: MESH_TYPE_WITH_ALL) => {
    dispatch({ type: 'setVisibleType', payload: visibleType })
  }
  const handleToggleAxes = () => {
    dispatch({ type: 'toggleAxesVisible' })
  }
  const handleChangeViewMethod = (viewMethod: VIEW_METHOD) => {
    dispatch({ type: 'setViewMethod', payload: viewMethod })
  }

  const handlePause = () => {
    dispatch({ type: 'pause' })
  }
  const handlePlay = () => {
    dispatch({ type: 'play' })
  }
  const handleStart = () => {
    dispatch({ type: 'start' })
  }
  const handleFinish = () => {
    handlePause()
    dispatch({ type: 'finish' })
  }
  const handleNext = () => {
    handlePause()
    if (hasNext) {
      dispatch({ type: 'pause' })
      dispatch({ type: 'next' })
    }
  }
  const handlePrev = () => {
    handlePause()
    if (hasPrev) {
      dispatch({ type: 'pause' })
      dispatch({ type: 'prev' })
    }
  }

  useEffect(() => {
    if (isPlaying) {
      playingInterval.current = {
        /** setInterval 中不可直接使用 state */
        interval: window.setInterval(() => {
          const playIndex = playingInterval.current?.index ?? 0
          if (playIndex + 1 < viewerItems.length) {
            dispatch({ type: 'next' })
            setPlayingIndex((playingInterval.current?.index ?? 0) + 1)
          } else {
            handlePause()
          }
        }, 2000),
        index: currentIndex,
      }
    } else {
      if (playingInterval.current) {
        window.clearInterval(playingInterval.current.interval)
        playingInterval.current = undefined
      }
    }
  }, [isPlaying])

  if (isPlayMode) {
    const currentItem = viewerItems.find(
      (item) => item.id === selectedItemIds[0]
    )!
    const info = currentItem.info
    const name = getName(
      currentItem.type,
      info.serialNumber,
      'goalStep' in info && info.isFinal
    )
    return (
      <Tooltip title={name} visible placement='bottom'>
        <ToolBarWrapper className={className} align='center'>
          <Row
            align='middle'
            style={{ cursor: 'pointer' }}
            onClick={handleFinish}
          >
            <CloseOutlined />
            <Typography.Text style={{ marginLeft: '8px' }}>
              關閉播放模式
            </Typography.Text>
          </Row>
          <Divider style={{ margin: 0 }} type='vertical' />
          <Space>
            <StepBackwardOutlined
              style={{
                opacity: hasPrev ? 1 : 0.25,
                cursor: hasPrev ? 'pointer' : 'not-allowed',
              }}
              onClick={handlePrev}
            />
            {isPlaying ? (
              <PauseCircleFilled
                style={{ color: blue.primary }}
                onClick={handlePause}
              />
            ) : (
              <PlayCircleFilled
                style={{ color: blue.primary }}
                onClick={handlePlay}
              />
            )}
            <StepForwardOutlined
              style={{
                opacity: hasNext ? 1 : 0.25,
                cursor: hasNext ? 'pointer' : 'not-allowed',
              }}
              onClick={handleNext}
            />
          </Space>
          <StyledSteps current={currentIndex} total={viewerItems.length} />
        </ToolBarWrapper>
      </Tooltip>
    )
  }

  return (
    <ToolBarWrapper className={className} align='center'>
      <Row align='middle' style={{ cursor: 'pointer' }} onClick={handleStart}>
        <Hourglass />
        <Typography.Text style={{ marginLeft: '8px' }}>
          觀看階段變化
        </Typography.Text>
      </Row>
      <ViewControlTool
        cameraView={cameraView}
        handleChangeCameraView={handleChangeCameraView}
      />
      <Divider style={{ margin: 0 }} type='vertical' />
      <LocationControlTool
        visibleLocation={location}
        handleChangeLocation={handleChangeLocation}
      />
      <Divider style={{ margin: 0 }} type='vertical' />
      <TypeControlTool
        visibleType={visibleType}
        handleChangeVisibleType={handleChangeVisibleType}
      />
      <Divider style={{ margin: 0 }} type='vertical' />
      <WithTip isActive={isAxesVisible} onClick={handleToggleAxes}>
        <Axes />
        <span>軸線</span>
      </WithTip>
      <Divider style={{ margin: 0 }} type='vertical' />
      <ViewMethodTool
        viewMethod={viewMethod}
        handleChangeViewMethod={handleChangeViewMethod}
      />
    </ToolBarWrapper>
  )
}

export default ToolBar
