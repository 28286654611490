import React from 'react'

import { Auxiliary, AuxiliaryType } from '../../../codegen/types'
import { getOffsetX, getOffsetY, isTopTeeth } from '../../InstructionUtils'
import config from '../../InstructionUtils/config'

interface Props {
  item: Auxiliary
}

const Attachment = (props: Props) => {
  const { item } = props
  const { toothPosition, auxiliaryPosition, type } = item

  // type 不對或者資料有問題則回傳 null
  if (type !== AuxiliaryType.Attachment) {
    return null
  }
  const width = 8
  const height = 8
  const xOffset = getOffsetX(toothPosition, auxiliaryPosition, width)
  const yOffset = getOffsetY(toothPosition, auxiliaryPosition, height)
  const x = config.tooth[toothPosition].centerＸ - width / 2 + xOffset
  const y =
    config[isTopTeeth(toothPosition) ? 'top' : 'bottom'].cy -
    height / 2 +
    yOffset
  return <rect x={x} y={y} width={width} height={height} className='red' />
}

export default Attachment
