import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Image = styled.img`
  width: 100%;
`

export interface TutorialImageProps {
  src: string
}

const TutorialImage = (props: TutorialImageProps) => {
  const { src } = props

  return (
    <Container>
      <Image src={src} />
    </Container>
  )
}

export default TutorialImage
