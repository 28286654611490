import React from 'react'
import styled from 'styled-components'

import brace from './brace.svg'

const BraceIconContainer = styled.div`
  margin-right: 8px;
`

interface ImageProps {
  imageWidth?: number
}

const Image = styled.img<ImageProps>`
  height: ${(props) => (props.imageWidth ? `${props.imageWidth}px` : '24px')};
  width: ${(props) => (props.imageWidth ? `${props.imageWidth}px` : '24px')};
`

type BraceIconProps = ImageProps

const BraceIcon = (props: BraceIconProps) => {
  const { imageWidth } = props

  return (
    <BraceIconContainer>
      <Image imageWidth={imageWidth} src={brace} />
    </BraceIconContainer>
  )
}

export default BraceIcon
