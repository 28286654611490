import { ReloadOutlined } from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'
import { ErrorHandling, TableQuery } from '@sov/common'
import { Button } from 'antd'
import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'

import {
  PatientStagesQueryQuery,
  PatientStagesQueryQueryVariables,
  StageStatus,
  StageType,
} from '../../codegen/types'
import StageTable from '../../components/table/patient/StageTable'
import NoScrollContainer from '../../utils/NoScrollContainer'

/* ----- styled components ----- */
const TableTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 24px;
  display: inline-block;
`
const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
`

/* ----- query string ----- */
const patientStagesQuery = gql`
  query PatientStagesQuery(
    $id: ID!
    $query: StagesQuery = {}
    $page: Int = 1
    $limit: Int = 100
    $sort: String = "-updated"
  ) {
    patient(id: $id) {
      id
      stages(query: $query, page: $page, limit: $limit, sort: $sort) {
        docs {
          ...StageTable
        }
        limit
        total
        page
      }
    }
  }
  ${StageTable.fragment}
`

/* ----- types and component ----- */
interface Params {
  patientId: string
}

type Props = {
  isSidebarCollapsed: boolean
} & RouteComponentProps<Params>

const PatientStageList = (props: Props) => {
  const { patientId } = props.match.params

  const { paginateQuery, handleTableChange, handleTableReset } =
    TableQuery.useTableQuery({ limit: 10, sort: '-serialNumber' })
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { data, loading } = useQuery<
    PatientStagesQueryQuery,
    PatientStagesQueryQueryVariables
  >(patientStagesQuery, {
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      id: patientId,
      query: {
        status: [StageStatus.Completed, StageStatus.Started],
        type: [StageType.Print],
      },
      ...paginateQuery,
      sort: '-serialNumber',
    },
  })

  const stages = data?.patient?.stages?.docs || []
  const antdPagination = {
    page: data?.patient?.stages?.page || 1,
    total: data?.patient?.stages?.total || 0,
    limit: data?.patient?.stages?.limit || 0,
  }

  return (
    <NoScrollContainer>
      <TitleRow>
        <TableTitle>療程列表</TableTitle>
        <Button onClick={handleTableReset}>
          <ReloadOutlined /> 重新整理
        </Button>
      </TitleRow>
      <StageTable
        loading={loading}
        data={stages}
        antdPagination={antdPagination}
        handleTableChange={handleTableChange}
      />
    </NoScrollContainer>
  )
}

export default PatientStageList
