import { blue, grey } from '@ant-design/colors'
import React from 'react'
import styled from 'styled-components'

const StepsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Dot = styled.div<{ isActive: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => (props.isActive ? blue.primary : grey[0])};
`

const Bar = styled.div<{ isActive: boolean }>`
  height: 3px;
  flex: 1;
  margin: 0 4px;
  background-color: ${(props) => (props.isActive ? blue.primary : grey[0])};
`

interface StepsProps {
  className?: string
  current: number
  total: number
}

const Steps = (props: StepsProps) => {
  const { className, current, total } = props

  return (
    <StepsWrapper className={className}>
      {new Array(total).fill(null).map((_, index) => {
        return (
          <>
            <Dot isActive={index <= current} />
            {index + 1 !== total && <Bar isActive={index + 1 <= current} />}
          </>
        )
      })}
    </StepsWrapper>
  )
}

export default Steps
