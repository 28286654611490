import React from 'react'
import styled from 'styled-components'

import { dotHeight, dotMargin } from './DotWithLine'
import VerticalLine, { defaultHeightOfVerticalLine } from './VerticalLine'

const heightPerTimeLineItem =
  defaultHeightOfVerticalLine * 2 + dotHeight + dotMargin * 2

const TimelinePlaceholderContainer = styled.div`
  width: 15%;
`

interface TimelinePlaceholderProps {
  number: number
}

const TimelinePlaceholder = (props: TimelinePlaceholderProps) => {
  const { number } = props

  if (number === 0) {
    return null
  }

  const totalHeightOfVerticalLine = heightPerTimeLineItem * number

  return (
    <TimelinePlaceholderContainer>
      <VerticalLine height={totalHeightOfVerticalLine} />
    </TimelinePlaceholderContainer>
  )
}

export default TimelinePlaceholder
