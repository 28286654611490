import { TFunction } from 'i18next'

import { Entity, Role } from './types'

interface EntityWithRole extends Entity {
  __typename?: 'Employee' | 'Doctor' | 'Clinic' | 'Patient'
  role?: Role
}
export const getEntityTitle = (entity: EntityWithRole, t: TFunction) => {
  switch (entity.__typename) {
    case 'Employee':
      return t(`role.${entity.role}`)
    case 'Doctor':
      return '醫生'
    case 'Patient':
      return '病患'
    default:
      return ''
  }
}
