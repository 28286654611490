import { DatePicker, TimePicker } from 'antd'
import zhTWLocale from 'antd/es/date-picker/locale/zh_TW'
import { PickerDateProps } from 'antd/lib/date-picker/generatePicker'
import { TimeRangePickerProps } from 'antd/lib/time-picker'
import moment, { Moment } from 'moment'
import business from 'moment-business'
import React, { useState } from 'react'
import styled, { createGlobalStyle } from 'styled-components'

type DatePickerProps = PickerDateProps<Moment>

const { RangePicker: TimeRangePicker } = TimePicker

const dateFormat = 'YYYY-MM-DD'
const timeFormat = 'HH:mm'

const GlobalStyle = createGlobalStyle`
  /** 
   * 覆寫 antd 預設 style
   * 這邊要被覆寫的 dom 不是指定 component 的 children
   * 無法使用一般的方式覆寫
   * 改成使用 createGlobalStyle 來覆寫
   */
  .ant-picker-cell-disabled::before {
    background: transparent;
  }
`

const FooterContainer = styled.div`
  color: #f5222d;
  font-size: 11px;
  line-height: 18px;
  margin: 8px 4px;
`

const primary = '#1890ff'

const CustomizedDatePicker = styled(DatePicker)<DatePickerProps>`
  width: 100%;
  margin-bottom: 8px;
`

const CustomizedDate = styled.div`
  /** 覆寫 antd 預設 style */
  .ant-picker-cell-disabled &.ant-calendar-date {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.25);
    text-decoration: line-through;
  }
  .ant-picker-cell-disabled.ant-picker-cell-today &.ant-calendar-date {
    color: ${primary};
    font-weight: normal;
  }
  .ant-picker-cell-disabled.ant-picker-cell-today &.ant-calendar-date::before {
    border: none;
  }
  .ant-picker-cell-selected &.ant-calendar-date {
    background: ${primary};
    color: white;
  }
`

interface ExtraFooterProp {
  defaultDisabledDay: number
}

const ExtraFooter = (prop: ExtraFooterProp) => {
  const { defaultDisabledDay } = prop
  return (
    <FooterContainer>
      *訂單的製作準備時間為 {defaultDisabledDay}{' '}
      個工作天，如遇到緊急狀況，請額外備註。
    </FooterContainer>
  )
}

const defaultStartHour = 14
const defaultStartMinute = 0
const defaultStartDateTime = business
  /**
   * @todo: 等 moment-business 更新成正確的 type 之後移除 @ts-ignore
   * 目前版本 moment-business 和 antd 所需的 moment 版本的 type 對不起來，這邊先跳過 type 檢查
   */
  // @ts-ignore
  .addWeekDays(moment(), 8)
  .set('hour', defaultStartHour)
  .set('minute', defaultStartMinute)

const defaultEndHour = 14
const defaultEndMinute = 30
const defaultEndDateTime = business
  /**
   * @todo: 等 moment-business 更新成正確的 type 之後移除 @ts-ignore
   * 目前版本 moment-business 和 antd 所需的 moment 版本的 type 對不起來，這邊先跳過 type 檢查
   */
  // @ts-ignore
  .addWeekDays(moment(), 8)
  .set('hour', defaultEndHour)
  .set('minute', defaultEndMinute)

interface CustomizedRangePickerProps {
  onChange?: (dateTimeInterval: [Moment, Moment]) => void
  value?: [Moment, Moment]
  disabledDay?: number
}

const CustomizedRangePicker = (props: CustomizedRangePickerProps) => {
  const { onChange, value, disabledDay = 7 } = props

  const [dateValue, setDateValue] = useState<DatePickerProps['value']>()
  const [isTimeRangePickerDisabled, setIsTimeRangePickerDisabled] =
    useState(true)

  const disabledDate: DatePickerProps['disabledDate'] = (dateValue) => {
    if (!dateValue) {
      return false
    }
    /**
     * @todo: 等 moment-business 更新成正確的 type 之後移除 @ts-ignore
     * 目前版本 moment-business 和 antd 所需的 moment 版本的 type 對不起來，這邊先跳過 type 檢查
     */
    // @ts-ignore
    return dateValue.valueOf() < business.addWeekDays(moment(), disabledDay)
  }

  const handleDateChange: DatePickerProps['onChange'] = (value) => {
    setDateValue(value)

    // @ts-ignore
    const defaultRangeTimeValue: [Moment, Moment] = value
      ? [
          value
            .clone()
            .set('hour', defaultStartHour)
            .set('minute', defaultStartMinute),
          value
            .clone()
            .set('hour', defaultEndHour)
            .set('minute', defaultEndMinute),
        ]
      : [defaultStartDateTime, defaultEndDateTime]

    onChange?.(defaultRangeTimeValue)
    setIsTimeRangePickerDisabled(false)
  }

  const handleTimeChange: TimeRangePickerProps['onChange'] = (value) => {
    const selectedStartDateTime = value?.[0]
    const startHour = selectedStartDateTime?.hour() ?? defaultStartHour
    const startMinute = selectedStartDateTime?.minute() ?? defaultStartMinute
    const startDateTime =
      selectedStartDateTime
        ?.clone()
        .set('hour', startHour)
        .set('minute', startMinute) ?? defaultStartDateTime
    const selectedEndDateTime = value?.[1]
    const endHour = selectedEndDateTime?.hour() ?? defaultEndHour
    const endMinute = selectedEndDateTime?.minute() ?? defaultEndMinute
    const endDateTime =
      selectedEndDateTime
        ?.clone()
        .set('hour', endHour)
        .set('minute', endMinute) ?? defaultEndDateTime
    // @ts-ignore
    const updatedDateInterval: [Moment, Moment] = [startDateTime, endDateTime]
    onChange?.(updatedDateInterval)
  }

  const dateRender: DatePickerProps['dateRender'] = (current) => {
    return (
      <CustomizedDate className='ant-calendar-date'>
        {current.date()}
      </CustomizedDate>
    )
  }

  return (
    <>
      <CustomizedDatePicker
        dateRender={dateRender}
        // @ts-ignore
        defaultPickerValue={defaultStartDateTime}
        disabledDate={disabledDate}
        format={dateFormat}
        locale={zhTWLocale}
        onChange={handleDateChange}
        renderExtraFooter={() => (
          <ExtraFooter defaultDisabledDay={disabledDay} />
        )}
        showNow={false}
        showToday={false}
        value={dateValue}
      />
      <TimeRangePicker
        disabled={isTimeRangePickerDisabled}
        format={timeFormat}
        locale={zhTWLocale}
        onChange={handleTimeChange}
        value={value}
      />
      <GlobalStyle />
    </>
  )
}

export default CustomizedRangePicker
