import React from 'react'
import styled, { css } from 'styled-components'

import { Step } from '../../../../../../codegen/types'
import { getHasStepInstruction } from '../getHasStepInstruction'
import InstructionStep from '../InstructionStep'
import DotWithLine from './DotWithLine'
import TimelinePlaceholder from './TimelinePlaceholder'

const Container = styled.div``

const FinalStepTag = styled.div`
  background-color: #faad14;
  border-radius: 8px;
  color: white;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  margin-bottom: 4px;
  padding: 2px 4px;
  position: relative;

  &:after {
    content: '';
    height: 4px;
    width: 4px;
    position: absolute;
    bottom: -2px;
    left: 50%;
    background-color: #faad14;
    transform: rotate(45deg);
  }
`

const stepInstructionPlaceholder = css`
  &:after {
    content: '';
    height: 23px;
  }
`

interface StepNumberContainerProps {
  isFinalStep: boolean
}

const StepNumberContainer = styled.div<StepNumberContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${(props) => props.isFinalStep && stepInstructionPlaceholder}
`

const StepNumber = styled.div`
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`

const TimelineItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const InstructionContainer = styled.div`
  margin-left: 25px;
`

const EmptyInstruction = styled.div`
  font-size: 14px;
  line-height: 24px;
`

export type StepInstruction = Pick<
  Step,
  'attachment' | 'ipr' | 'button' | 'retrieve'
>

export type StepInfo = StepInstruction & {
  stepNumber: number
}

interface UpcomingStepListTimelineProps {
  upcomingStepInfoList: StepInfo[]
  totalStepNumber: number
}

const UpcomingStepListTimeline = (props: UpcomingStepListTimelineProps) => {
  const { upcomingStepInfoList, totalStepNumber } = props

  const numberOfPlaceholder =
    upcomingStepInfoList.length >= 3 ? 0 : 3 - upcomingStepInfoList.length

  return (
    <Container>
      {upcomingStepInfoList.map((filteredStepInfo, index) => {
        const hasStepInstruction = getHasStepInstruction(filteredStepInfo)

        const isFinalStep = filteredStepInfo.stepNumber === totalStepNumber

        return (
          <TimelineItem key={`filteredStep-${index}`}>
            <DotWithLine />
            <StepNumberContainer isFinalStep={isFinalStep}>
              {isFinalStep && <FinalStepTag>最後 Step</FinalStepTag>}
              <StepNumber>Step {filteredStepInfo.stepNumber}</StepNumber>
            </StepNumberContainer>
            <InstructionContainer>
              {hasStepInstruction ? (
                <>
                  {filteredStepInfo?.ipr && <InstructionStep label='IPR' />}
                  {filteredStepInfo?.attachment && (
                    <InstructionStep label='ATTA' />
                  )}
                  {filteredStepInfo?.button && (
                    <InstructionStep label='BUTTON' />
                  )}
                  {filteredStepInfo?.retrieve && (
                    <InstructionStep label='重取模' />
                  )}
                </>
              ) : (
                <EmptyInstruction>無臨床指示</EmptyInstruction>
              )}
            </InstructionContainer>
          </TimelineItem>
        )
      })}
      <TimelinePlaceholder number={numberOfPlaceholder} />
    </Container>
  )
}

export default UpcomingStepListTimeline
