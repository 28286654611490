import { Form } from '@ant-design/compatible'
import { LeftOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'
import { ErrorHandling } from '@sov/common'
import { Badge, Button, Calendar, Col, Row, Spin } from 'antd'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import { isNil } from 'ramda'
import React, { useState } from 'react'
import styled from 'styled-components'

import {
  AppointmentFragment,
  AppointmentsQueryQuery,
  AppointmentsQueryQueryVariables,
} from '../../codegen/types'
import Page from '../../components/page'
import BreadcrumbCreator from '../../components/page/BreadcrumbCreator'
import Title from '../../components/page/Title'
import AppointmentCard from './AppointmentCard'
import AppointmentDrawer from './AppointmentDrawer'
import AppointmentModal from './AppointmentModal'

// @ts-ignore
const moment = extendMoment(Moment)

const appointmentsQuery = gql`
  query AppointmentsQuery(
    $query: AppointmentsQuery = {}
    $page: Int
    $limit: Int
    $sort: String
  ) {
    appointments(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        ...Appointment
      }
      limit
      total
      page
    }
  }
  ${AppointmentCard.fragment.Appointment}
`

export const removeAppointmentMutation = gql`
  mutation RemoveAppointment($id: ID!) {
    removeAppointment(id: $id) {
      ...Appointment
    }
  }
  ${AppointmentCard.fragment.Appointment}
`

const Container = styled.div`
  padding: 24px 12px;
  background-color: white;

  .ant-fullcalendar-header {
    display: flex;
  }
`

interface MonthSelectorProps {
  className?: string
  value: Moment.Moment
  onChange: (value: Moment.Moment) => void
}

const MonthSelector = styled((props: MonthSelectorProps) => {
  const { className, value, onChange } = props
  return (
    <div className={className} style={{ fontSize: 20 }}>
      <LeftOutlined onClick={() => onChange(moment(value.subtract(1, 'M')))} />
      <span>{moment(value).format('YYYY 年 MM 月')}</span>
      <RightOutlined onClick={() => onChange(moment(value.add(1, 'M')))} />
    </div>
  )
})`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 32px;
  color: black;

  .anticon {
    margin-left: 16px;
    margin-right: 16px;
    cursor: pointer;
  }
`

interface CalendarProps {
  loading: boolean
  appointments: AppointmentFragment[]
  current: Moment.Moment
  setCurrent: React.Dispatch<React.SetStateAction<Moment.Moment>>
  setSelectedDate: React.Dispatch<
    React.SetStateAction<Moment.Moment | undefined>
  >
}

const CustomizedCalendar = React.memo<CalendarProps>(
  (props) => {
    const { loading, appointments, current, setCurrent, setSelectedDate } =
      props
    return (
      <Calendar
        value={current}
        headerRender={() => {
          return (
            <MonthSelector
              value={current}
              onChange={(val) => setCurrent(val)}
            />
          )
        }}
        dateCellRender={(date) => {
          return (
            <>
              {loading ? (
                <Spin />
              ) : (
                appointments
                  .filter((appointment) =>
                    moment(appointment.startDate).isSame(moment(date), 'day')
                  )
                  .map((appointment, index) => {
                    const text = moment(appointment.startDate).isSame(
                      moment(appointment.startDate).startOf('d')
                    )
                      ? `${appointment.patient.name} -`
                      : `${appointment.patient.name} ${moment(
                          appointment.startDate
                        ).format('HH:mm')}`

                    return (
                      <div key={index}>
                        <Badge status='success' text={text} />
                      </div>
                    )
                  })
              )}
            </>
          )
        }}
        onSelect={(date) => setSelectedDate(date)}
      />
    )
  },
  (prevProps, nextProps) =>
    prevProps.appointments === nextProps.appointments &&
    prevProps.loading === nextProps.loading
)

const AppointmentCalendar = () => {
  const { toErrorPage } = ErrorHandling.useErrorHandling()

  // 日期基準
  const today = moment()
  const [current, setCurrent] = useState<Moment.Moment>(today)

  // 選定日期
  const [selectedDate, setSelectedDate] = useState<Moment.Moment>()
  const [selectedAppointment, setSelectedAppointment] =
    useState<AppointmentFragment>()
  const [modalVisible, setModalVisible] = useState<boolean>(false)

  const { data, loading, refetch } = useQuery<
    AppointmentsQueryQuery,
    AppointmentsQueryQueryVariables
  >(appointmentsQuery, {
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      query: {
        startDateInterval: [
          moment(current).startOf('M'),
          moment(current).endOf('M'),
        ],
      },
      page: 1,
      limit: 1000,
    },
  })

  const reset = () => {
    setSelectedDate(undefined)
    setSelectedAppointment(undefined)
    setModalVisible(false)
  }

  const appointments = data?.appointments?.docs ?? []

  const header = (
    <Row style={{ display: 'flex' }}>
      <Col style={{ flex: 1 }}>
        <BreadcrumbCreator
          routes={[{ key: 'Home' }, { key: 'AppointmentCalendar' }]}
        />
        <Title route={{ key: 'AppointmentCalendar' }} />
      </Col>
      <Col>
        <Button
          type='primary'
          onClick={() => setModalVisible(true)}
          style={{ float: 'right' }}
        >
          <PlusOutlined />
          新增約診
        </Button>
      </Col>
    </Row>
  )

  return (
    <Page header={header}>
      <Container>
        <CustomizedCalendar
          loading={loading}
          appointments={appointments}
          current={current}
          setCurrent={setCurrent}
          setSelectedDate={setSelectedDate}
        />
        <AppointmentDrawer
          date={selectedDate}
          appointments={
            appointments.filter((appointment) =>
              moment(appointment.startDate).isSame(selectedDate, 'd')
            ) ?? []
          }
          visible={!isNil(selectedDate)}
          onClose={() => setSelectedDate(undefined)}
          handleSelect={(appointment) => {
            setSelectedAppointment(appointment)
            setModalVisible(true)
          }}
          reset={reset}
          refetch={refetch}
        />
        <AppointmentModal
          item={selectedAppointment}
          visible={modalVisible}
          reset={reset}
          refetch={refetch}
        />
      </Container>
    </Page>
  )
}

export default Form.create()(AppointmentCalendar)
