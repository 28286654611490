import {
  DeleteOutlined,
  DownloadOutlined,
  ZoomInOutlined,
} from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import React, { FC } from 'react'
import styled from 'styled-components'

import { FileInfoFragment } from '../../codegen/types'
import { PhotoInfo } from '../form/patient/patientPhotoMapping'

export type PatientPhotoCardProps = Pick<
  PhotoInfo,
  'title' | 'aspect' | 'placeholder'
> & {
  /** show before receive file */
  file?: FileInfoFragment
  /** card width, default to be `180` */
  width?: number
  /** show preview button when receive this prop */
  onPreviewClick?: AntdIconProps['onClick']
  /** show download button when receive this prop */
  onDownloadClick?: AntdIconProps['onClick']
  /** show delete button when receive this prop */
  onDeleteClick?: AntdIconProps['onClick']
}

const Title = styled.div`
  visibility: hidden;
  font-size: 14px;
  line-height: 1;
  z-index: 1;
`

const ButtonList = styled.div`
  visibility: hidden;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  z-index: 1;

  .anticon {
    font-size: 18px;
    color: white;

    &.anticon-delete {
      color: red;
    }
  }
`

interface PhotoContainerProps {
  imageUrl: string
  width: number
  aspect: number
}

const PhotoContainer = styled.div<PhotoContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${(props) => props.width}px;
  height: ${(props) => props.width / props.aspect}px;
  margin: 0 16px 16px 0;
  padding: 8px;
  color: white;
  cursor: pointer;

  &:after {
    content: '';
    background-image: url(${(props) => props.imageUrl});
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &:hover {
    ${Title},
    ${ButtonList} {
      visibility: initial;
    }

    &:after {
      filter: brightness(0.8);
    }
  }
`

const defaultWidth = 180

const PatientPhotoCard: FC<PatientPhotoCardProps> = (props) => {
  const {
    title,
    aspect,
    placeholder,
    file,
    width = defaultWidth,
    onPreviewClick,
    onDownloadClick,
    onDeleteClick,
    children,
  } = props

  const imageUrl = file?.thumbnailPath ?? placeholder

  return (
    <PhotoContainer aspect={aspect} imageUrl={imageUrl} width={width}>
      {file && (
        <>
          {title && <Title>{title}</Title>}
          <ButtonList>
            {onPreviewClick && <ZoomInOutlined onClick={onPreviewClick} />}
            {onDownloadClick && <DownloadOutlined onClick={onDownloadClick} />}
            {onDeleteClick && <DeleteOutlined onClick={onDeleteClick} />}
          </ButtonList>
        </>
      )}
      {children}
    </PhotoContainer>
  )
}

export default PatientPhotoCard
