import 'moment/locale/zh-tw'

import { Form } from '@ant-design/compatible'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { LoadingOutlined } from '@ant-design/icons'
import { gql, useQuery, useSubscription } from '@apollo/client'
import { ErrorHandling } from '@sov/common'
import { Input, Spin } from 'antd'
import {
  addIndex,
  adjust,
  assocPath,
  filter,
  findIndex,
  head,
  map,
  prepend,
} from 'ramda'
import React, { useContext, useEffect, useState } from 'react'
import { Subject } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import styled from 'styled-components'

import {
  LatestMessageBlockFragment,
  LatestMessageListQueryQuery,
  LatestMessageListQueryQueryVariables,
  OnMessageAddedSubscriptionSubscription,
  OnMessageAddedSubscriptionSubscriptionVariables,
  SubscriptionType,
} from '../../../codegen/types'
import { onMessageAddedSubscription } from '../../../graphql/message/subscription/onMessageAdded'
import { authContext } from '../../../utils/context'
import LatestMessageBlock from './LatestMessageBlock'

interface MessageEdge {
  node: LatestMessageBlockFragment
  cursor: string
}

const { Search } = Input
const mapIndex = addIndex<MessageEdge>(map)

const latestMessageListQuery = gql`
  query LatestMessageListQuery(
    $query: ConversationsWithLatestMessageQuery = {}
    $first: Int
    $after: String
  ) {
    conversationsWithLatestMessage(
      query: $query
      first: $first
      after: $after
    ) {
      edges {
        node {
          ...LatestMessageBlock
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${LatestMessageBlock.fragment}
`

interface LatestMessageListProps {
  selectedConversation: string
  handleBlockClick: (conversation: string) => void
}

interface LatestMessageFormInput {
  patientName: string
}

type LatestMessageFormProps = {
  handleChange: (form: FormComponentProps['form']) => void
  subject: Subject<any>
} & FormComponentProps<LatestMessageFormInput>

const LatestMessageListContainer = styled.div`
  width: 260px;
  overflow: scroll;
  overscroll-behavior: none;
  height: calc(100vh - 232px);
  border-right: 1px solid #e8e8e8;
`

const PatientSearchForm = Form.create<LatestMessageFormProps>()(
  ({ form, handleChange, subject }: LatestMessageFormProps) => {
    const { getFieldDecorator } = form

    useEffect(() => subject.unsubscribe(), [])

    subject.pipe(debounceTime(500)).subscribe(() => handleChange(form))

    return (
      <Form>
        {getFieldDecorator('patientName')(
          <Search
            placeholder='搜尋病患'
            onChange={(value) => subject.next(value)}
            style={{ width: 200, margin: '10px 0', marginLeft: '25px' }}
          />
        )}
      </Form>
    )
  }
)

const LatestMessageList = ({
  selectedConversation,
  handleBlockClick,
}: LatestMessageListProps) => {
  const $Sub = new Subject()
  const auth = useContext(authContext)
  if (!auth) {
    return null
  }
  const [patientSearch, setPatientSearch] = useState('')
  const [realTimeEdges, setRealTimeEdges] = useState<MessageEdge[]>([])

  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const {
    data: conversationsWithLatestMessageData,
    loading: conversationsWithLatestMessageLoading,
    fetchMore: fetchMoreConversationsWithLatestMessage,
    loading,
  } = useQuery<
    LatestMessageListQueryQuery,
    LatestMessageListQueryQueryVariables
  >(latestMessageListQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      first: 10,
      query: {
        patientName: patientSearch,
      },
    },
    skip: !auth,
    onCompleted: (data) => {
      if (
        data?.conversationsWithLatestMessage &&
        !conversationsWithLatestMessageLoading
      ) {
        const edges = data.conversationsWithLatestMessage.edges
        const realTimeEdges = map((edge) => {
          const isSelected = selectedConversation === edge.node.id
          return isSelected
            ? assocPath(['node', 'numberOfUnreadMessages'], 0, edge)
            : edge
        }, edges)
        setRealTimeEdges(realTimeEdges)
        if (!selectedConversation) {
          handleBlockClick(realTimeEdges[0]?.node.id)
        }
      }
    },
  })

  const {
    loading: conversationWithLatestMessageLoading,
    refetch: refetchLatestMessage,
  } = useQuery<
    LatestMessageListQueryQuery,
    LatestMessageListQueryQueryVariables
  >(latestMessageListQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      first: 1,
      query: {
        patientName: patientSearch,
      },
    },
    skip: !auth,
    onCompleted: (data) => {
      if (
        data?.conversationsWithLatestMessage &&
        !conversationWithLatestMessageLoading
      ) {
        const newEdges = data.conversationsWithLatestMessage.edges
        if (head(newEdges)) {
          const conversationWithLatestMessageEdge = head<MessageEdge>(newEdges)
          const conversationWithLatestMessageNode =
            conversationWithLatestMessageEdge!.node
          const isSelected =
            selectedConversation === conversationWithLatestMessageNode.id
          const realTimeNewLatestMessageEdge = isSelected
            ? assocPath(
                ['node', 'numberOfUnreadMessages'],
                0,
                conversationWithLatestMessageEdge
              )
            : conversationWithLatestMessageEdge
          const filterOldLatestCommetsEdge = filter<MessageEdge>(
            (edge) => conversationWithLatestMessageNode.id !== edge.node.id,
            realTimeEdges
          )
          const newLatestEdges = prepend(
            realTimeNewLatestMessageEdge,
            filterOldLatestCommetsEdge
          )
          setRealTimeEdges(newLatestEdges as MessageEdge[])
        }
      }
    },
  })

  useSubscription<
    OnMessageAddedSubscriptionSubscription,
    OnMessageAddedSubscriptionSubscriptionVariables
  >(onMessageAddedSubscription, {
    variables: {
      payload: {
        subscriptionType: SubscriptionType.All,
      },
    },
    fetchPolicy: 'network-only',
    onSubscriptionData: (data) => {
      if (data?.subscriptionData?.data?.onMessageAdded) {
        refetchLatestMessage()
      }
    },
  })

  useEffect(() => {
    const selectedEdgeIndex = findIndex(
      (edge) => edge.node.id === selectedConversation,
      realTimeEdges
    )
    const newRealTimeEdge = adjust(
      selectedEdgeIndex,
      (edge) => assocPath(['node', 'numberOfUnreadMessages'], 0, edge),
      realTimeEdges
    )
    setRealTimeEdges(newRealTimeEdge)
  }, [selectedConversation])

  const handleScroll = (e) => {
    const list = e.target
    const isAtBottom = list.scrollHeight - list.clientHeight === list.scrollTop
    if (
      isAtBottom &&
      conversationsWithLatestMessageData &&
      conversationsWithLatestMessageData.conversationsWithLatestMessage &&
      conversationsWithLatestMessageData.conversationsWithLatestMessage.pageInfo
        .hasNextPage
    ) {
      fetchMoreConversationsWithLatestMessage({
        variables: {
          after:
            conversationsWithLatestMessageData.conversationsWithLatestMessage
              .pageInfo.endCursor,
          first: 10,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (
            fetchMoreResult &&
            fetchMoreResult.conversationsWithLatestMessage &&
            previousResult.conversationsWithLatestMessage
          ) {
            const { pageInfo, edges: moreEdges } =
              fetchMoreResult.conversationsWithLatestMessage
            const newEdges = [...realTimeEdges, ...moreEdges]
            const result = {
              conversationsWithLatestMessage: {
                __typename:
                  previousResult.conversationsWithLatestMessage.__typename,
                edges: newEdges,
                pageInfo,
              },
            }
            setRealTimeEdges(newEdges)
            return result
          }
          return previousResult
        },
      })
    }
  }

  const handleChange = (form: FormComponentProps['form']) => {
    const { getFieldValue } = form
    const patientName = getFieldValue('patientName')
    setPatientSearch(patientName)
  }

  return (
    <LatestMessageListContainer onScroll={handleScroll}>
      <PatientSearchForm handleChange={handleChange} subject={$Sub} />
      {conversationsWithLatestMessageData?.conversationsWithLatestMessage && (
        <>
          {loading && (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              style={{ display: 'block', marginBottom: '10px' }}
            />
          )}
          {mapIndex(
            (edge, index) => (
              <LatestMessageBlock
                node={edge.node}
                key={index}
                handleClick={handleBlockClick}
                selectedConversation={selectedConversation}
              />
            ),
            realTimeEdges
          )}
        </>
      )}
    </LatestMessageListContainer>
  )
}
export default LatestMessageList
