import React, { CSSProperties } from 'react'

import icons from './images'

interface Props {
  name: 'facebook' | 'google' | 'line' | 'phone' | 'sov'
  style?: CSSProperties
  disabled?: boolean
}
const AuthIcon = (props: Props) => {
  const { name, style = {}, disabled } = props
  const data = icons[name]
  const { normal, gray } = data
  const defaultStyle = { width: '48px', height: '48px' }
  return disabled ? (
    <img style={{ ...defaultStyle, ...style }} src={gray} />
  ) : (
    <img style={{ ...defaultStyle, ...style }} src={normal} />
  )
}

export default AuthIcon
