import { Form } from '@ant-design/compatible'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { gql } from '@apollo/client'
import { Auth } from '@sov/common'
import { Input, Radio } from 'antd'
import { always, cond, equals } from 'ramda'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { AccountFormInfoFragment, EntityType, Role } from '../../codegen/types'
import {
  ClinicSelect,
  DoctorSelect,
  EmployeeSelect,
} from '../../components/select'
import { authContext } from '../../utils/context'

const { isInRoles } = Auth.utils
interface FromEmailInfo {
  name?: string
  email?: string
  phone?: string
}

export interface AccountFormFields
  extends Pick<
    AccountFormInfoFragment,
    'email' | 'nickname' | 'phone' | 'entityType'
  > {
  entity: string
}

type Props = FormComponentProps<AccountFormFields> & {
  item?: AccountFormInfoFragment
  fromEmailInfo?: FromEmailInfo
}

const FormItem = Form.Item

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
}

type BindingFormProps = FormComponentProps<AccountFormFields> & {
  item?: AccountFormInfoFragment
  disabled: boolean
}

const Binding = ({ form, item, disabled }: BindingFormProps) => {
  const { getFieldValue, getFieldDecorator } = form
  const entityType = getFieldValue('entityType')

  const SelectInput = cond([
    [equals(EntityType.Employee), always(EmployeeSelect)],
    [equals(EntityType.Doctor), always(DoctorSelect)],
    [equals(EntityType.Clinic), always(ClinicSelect)],
  ])(entityType)

  return entityType ? (
    <FormItem {...formItemLayout} label='關聯使用者'>
      {getFieldDecorator('entity', {
        initialValue: item?.entity?.id,
        rules: [
          {
            required: true,
            message: '必填欄位',
          },
        ],
      })(<SelectInput showSearch disabled={disabled} />)}
    </FormItem>
  ) : null
}

const AccountForm = ({ form, item, fromEmailInfo }: Props) => {
  const { t } = useTranslation()
  const auth = useContext(authContext)

  const { getFieldDecorator, getFieldValue, resetFields } = form

  useEffect(() => {
    resetFields(['entity'])
  }, [getFieldValue('entityType')])

  const isGodOrManager = isInRoles([Role.God, Role.Manager], auth)

  return (
    <>
      <FormItem {...formItemLayout} label='帳號(電子信箱)'>
        {getFieldDecorator('email', {
          rules: [
            {
              type: 'email',
              message: '需要 Email 格式',
            },
            {
              required: true,
              message: '必填欄位',
            },
          ],
          initialValue: fromEmailInfo ? fromEmailInfo.email : item?.email,
        })(<Input disabled={Boolean(item)} />)}
      </FormItem>
      <FormItem {...formItemLayout} label='使用者名稱'>
        {getFieldDecorator('nickname', {
          rules: [
            {
              required: true,
              message: '必填欄位',
            },
          ],
          initialValue: fromEmailInfo ? fromEmailInfo.name : item?.nickname,
        })(<Input disabled={!isGodOrManager} />)}
      </FormItem>
      <FormItem {...formItemLayout} label='手機'>
        {getFieldDecorator('phone', {
          rules: [
            {
              required: true,
              message: '必填欄位',
            },
          ],
          initialValue: fromEmailInfo ? fromEmailInfo.phone : item?.phone,
        })(<Input />)}
      </FormItem>
      <FormItem {...formItemLayout} label='使用者類別'>
        {getFieldDecorator('entityType', {
          rules: [
            {
              required: true,
              message: '必填欄位',
            },
          ],
          initialValue: item?.entityType,
        })(
          <Radio.Group disabled={Boolean(item)}>
            <Radio key={EntityType.Employee} value={EntityType.Employee}>
              {t('account.entityType.EMPLOYEE')}
            </Radio>
            <Radio key={EntityType.Doctor} value={EntityType.Doctor}>
              {t('account.entityType.DOCTOR')}
            </Radio>
            <Radio key={EntityType.Clinic} value={EntityType.Clinic}>
              {t('account.entityType.CLINIC')}
            </Radio>
          </Radio.Group>
        )}
      </FormItem>
      <Binding form={form} item={item} disabled={!isGodOrManager} />
    </>
  )
}

AccountForm.fragments = {
  AccountFormInfo: gql`
    fragment AccountFormInfo on Account {
      id
      email
      phone
      nickname
      entityType
      entity {
        id
        name
        ... on Employee {
          role
        }
      }
    }
  `,
}

export default AccountForm
