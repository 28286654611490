import Button, { ButtonProps } from 'antd/lib/button'
import React, { useState } from 'react'

export const SubmitButton = (props: ButtonProps) => {
  const { children, onClick } = props
  const [isSubmitting, setIsSubmitting] = useState(false)
  const handleOnClick = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setIsSubmitting(true)
    if (onClick) {
      await onClick(e)
      setIsSubmitting(false)
    }
  }

  return (
    <Button
      {...props}
      disabled={isSubmitting}
      type='primary'
      style={{ width: '100%' }}
      onClick={handleOnClick}
    >
      {children}
    </Button>
  )
}
