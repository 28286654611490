import { Form } from '@ant-design/compatible'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { ApolloQueryResult, gql, useMutation } from '@apollo/client'
import { message, Modal } from 'antd'
import { evolve } from 'ramda'
import React from 'react'

import {
  AppointmentFragment,
  AppointmentsQueryQuery,
  AppointmentsQueryQueryVariables,
  CreateAppointmentMutation,
  CreateAppointmentMutationVariables,
  UpdateAppointmentMutation,
  UpdateAppointmentMutationVariables,
} from '../../codegen/types'
import AppointmentForm from '../../components/form/appointment'
import AppointmentCard from './AppointmentCard'

export const createAppointmentMutation = gql`
  mutation CreateAppointment($payload: CreateAppointmentInput!) {
    createAppointment(payload: $payload) {
      ...Appointment
    }
  }
  ${AppointmentCard.fragment.Appointment}
`

export const updateAppointmentMutation = gql`
  mutation UpdateAppointment($id: ID!, $payload: UpdateAppointmentInput!) {
    updateAppointment(id: $id, payload: $payload) {
      ...Appointment
    }
  }
  ${AppointmentCard.fragment.Appointment}
`

type Props = FormComponentProps & {
  item: AppointmentFragment | undefined
  visible: boolean
  reset: () => void
  refetch: (
    variables?: AppointmentsQueryQueryVariables | undefined
  ) => Promise<ApolloQueryResult<AppointmentsQueryQuery>>
}

const AppointmentModal = (props: Props) => {
  const { form, item, visible, reset, refetch } = props
  const [createAppointment] = useMutation<
    CreateAppointmentMutation,
    CreateAppointmentMutationVariables
  >(createAppointmentMutation)
  const [updateAppointment] = useMutation<
    UpdateAppointmentMutation,
    UpdateAppointmentMutationVariables
  >(updateAppointmentMutation)

  const create = async () => {
    const payload = evolve<any>({
      startDate: (moment) => moment.toISOString(),
      endDate: (moment) => moment.toISOString(),
    })(form.getFieldsValue()) as any

    await createAppointment({
      variables: {
        payload,
      },
      update: async (_cache, { data }) => {
        if (data) {
          reset()
          form.resetFields()
          await refetch()
          await message.info('已新增約診')
        }
      },
    })
  }

  const update = async () => {
    if (!item?.id) return

    const payload = evolve<any>({
      startDate: (moment) => moment.toISOString(),
      endDate: (moment) => moment.toISOString(),
    })(form.getFieldsValue()) as any

    await updateAppointment({
      variables: {
        id: item.id,
        payload,
      },
      update: async (_cache, { data }) => {
        if (data) {
          reset()
          form.resetFields()
          await refetch()
          await message.info('已更新約診')
        }
      },
    })
  }

  const handleSubmit = () => {
    form.validateFields(async (err, fieldsValue) => {
      if (err) {
        console.error(err, fieldsValue)
        return
      }

      if (item) {
        await update()
      } else {
        await create()
      }
    })
  }

  const handleCancel = () => {
    reset()
    form.resetFields()
  }

  return (
    <Modal
      zIndex={1001}
      title={item ? '編輯約診' : '新增約診'}
      visible={visible}
      onOk={handleSubmit}
      onCancel={handleCancel}
      okText={item ? '更新' : '新增'}
      cancelText='取消'
    >
      <AppointmentForm form={form} item={item} />
    </Modal>
  )
}

export default Form.create<any>()(AppointmentModal)
