import React from 'react'
import styled from 'styled-components'

import TooltipIcon from '../../../../icon/TooltipIcon'
import BraceIcon from './BraceIcon'
import FinishedHint from './FinishedHint'
import FirstEvaluation from './FirstEvaluation'
import FirstStepInstruction from './FirstStepInstruction'
import UpcomingStepListTimeline, {
  StepInstruction,
} from './UpcomingStepListTimeline'

const maxRenderedStepNumber = 3

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`

const LatestDelivery = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 16px;
  font-size: 11px;
  line-height: 18px;
`

const HintContainer = styled.div`
  color: rgba(0, 0, 0, 0.45);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 30px;
`

const Hint = styled.div``

const TooltipIconContainer = styled.span`
  margin-left: 8px;
`

interface InstructionProps {
  lastestPrintStageStepNumber: number
  stepList: StepInstruction[]
  isReportOrder: boolean
}

const Instruction = (props: InstructionProps) => {
  const { lastestPrintStageStepNumber, stepList, isReportOrder } = props

  const restSteps = stepList.length - lastestPrintStageStepNumber

  const restStepNumber = restSteps >= 3 ? 3 : restSteps

  const stepInfoList = stepList.map((step, index) => {
    return {
      ...step,
      stepNumber: index + 1,
    }
  })
  const filteredStepInfoList = stepInfoList.filter(
    (stepInfo, index) =>
      index + 1 - lastestPrintStageStepNumber > 0 &&
      index + 1 - lastestPrintStageStepNumber <= maxRenderedStepNumber
  )
  const upcomingStepInfoList = filteredStepInfoList.reverse()
  const withoutUpcomingStepInfoList = upcomingStepInfoList.length === 0

  if (isReportOrder) {
    return <FirstEvaluation />
  }
  if (lastestPrintStageStepNumber === 0) {
    const firstStepInfo = upcomingStepInfoList[upcomingStepInfoList.length - 1]

    return <FirstStepInstruction stepInfo={firstStepInfo} />
  }

  return (
    <Container>
      <LatestDelivery>
        <BraceIcon />
        最後出貨: Step {lastestPrintStageStepNumber}
      </LatestDelivery>
      {withoutUpcomingStepInfoList ? (
        <FinishedHint />
      ) : (
        <>
          <UpcomingStepListTimeline
            upcomingStepInfoList={upcomingStepInfoList}
            totalStepNumber={stepInfoList.length}
          />
          <HintContainer>
            <Hint>後續 {restStepNumber} 個 Step</Hint>
            <TooltipIconContainer>
              <TooltipIcon
                textList={[
                  '此為主要報告中的預估項目，',
                  '實際情況以臨床指示為主。',
                ]}
              />
            </TooltipIconContainer>
          </HintContainer>
        </>
      )}
    </Container>
  )
}

export default Instruction
