import { TableProps } from 'antd/lib/table'
import { TablePaginationConfig } from 'antd/lib/table/interface'
import { complement, isNil, mergeDeepRight, pickBy } from 'ramda'
import { useQueryParams, withDefault } from 'use-query-params'

import {
  AntSorterType,
  decodeSorter,
  encodeSorter,
  FiltersParam,
  MyNumberParam,
  SorterParam,
} from './paramTransformers'

interface GetAntdPaginationParams {
  page: number
  total: number
  limit: number
}
export const getAntdPagination = ({
  page,
  total,
  limit,
}: GetAntdPaginationParams): TablePaginationConfig => ({
  current: page || 1,
  total,
  pageSize: limit,
  showSizeChanger: false,
  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} 項結果`,
})

export interface Cursor {
  limit?: number
  page?: number
  sort?: string
}
const getSortQueryString = <SorterField>(
  sort: AntSorterType<SorterField> | undefined
): string | undefined => (sort && encodeSorter(sort)) ?? undefined

const defaultPaginateQuery = {
  page: 1,
  limit: 100,
  sort: getSortQueryString({ field: 'updated', order: 'descend' }),
}

export const useTableQuery = <FilterType, SorterField extends string>(
  defaultCursor?: Cursor,
  defaultFilter?: FilterType
) => {
  const [tableQuery, setTableQuery] = useQueryParams({
    page: withDefault(
      MyNumberParam,
      defaultCursor?.page || defaultPaginateQuery.page
    ),
    sort: withDefault(
      SorterParam<SorterField>(),
      decodeSorter(defaultCursor?.sort || defaultPaginateQuery.sort),
      false
    ),
    filters: withDefault(FiltersParam<FilterType>(), defaultFilter),
  })

  const handleTableReset = () => {
    setTableQuery({}, 'push')
  }

  const handleTableChange: TableProps<any>['onChange'] = (
    { current },
    filters,
    sort
  ) => {
    setTableQuery({
      page: current || 1,
      // @ts-ignore
      sort,
      // @ts-ignore
      filters,
    })
  }

  const paginateState = pickBy<Cursor, Cursor>(complement(isNil), {
    page: tableQuery.page,
    sort: getSortQueryString<SorterField>(tableQuery.sort),
    limit: defaultCursor?.limit,
  })

  const paginateQuery = mergeDeepRight(defaultPaginateQuery, paginateState)

  return {
    tableQuery, // antd type liked, for component
    paginateQuery, // graphql query type liked, for query
    handleTableReset,
    handleTableChange,
  }
}
