import { gql } from '@apollo/client'
import { StageName } from '@sov/ui'
import { Card, Row, Tag } from 'antd'
import moment from 'moment'
import { last } from 'ramda'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import {
  PatientDetailCurrentEvalStageFragment,
  PatientDetailEvalStageFragment,
  PatientDetailQueryQuery,
  StageStatus,
} from '../../../codegen/types'

interface StageCardProp {
  className?: string
  patient: PatientDetailQueryQuery['patient']
  stage: PatientDetailEvalStageFragment
  masked?: boolean
}

const StageCard = styled((props: StageCardProp) => {
  const { patient, stage, className } = props
  const steps = stage.analysis?.steps ?? []
  const finalCheckPoint = last(stage.checkPoints)
  const expectedLength = stage.isCPMode
    ? finalCheckPoint?.goalStep ?? 0
    : steps.length ?? 0

  return (
    <Card
      className={className}
      actions={
        stage.externalVisible
          ? [
              ...(finalCheckPoint?.hasFilesForViewer
                ? [
                    <Link
                      key='viewer'
                      to={`/viewer?patientId=${patient?.id}&stageId=${stage.id}`}
                    >
                      Viewer
                    </Link>,
                  ]
                : []),
              <Link key='link' to={`/stages/${stage.id}`}>
                <span>檢視詳情</span>
              </Link>,
            ]
          : []
      }
    >
      <div className='masked'>
        <div style={{ display: 'flex' }}>
          <h3 style={{ flex: 1 }}>
            <StageName item={stage} />
          </h3>
          <div>
            {stage.status === StageStatus.Started && (
              <Tag color='blue'>進行中</Tag>
            )}
            {stage.status === StageStatus.Completed && (
              <Tag color='green'>
                {moment(stage.shippingDate).format('YYYY-MM-DD')}
              </Tag>
            )}
          </div>
        </div>
        <div style={{ marginBottom: '10px' }}>
          預估療程 {expectedLength} steps
        </div>
        <div style={{ marginBottom: '10px' }}>
          預估時間 {expectedLength * 1.5} 個月
        </div>
      </div>
    </Card>
  )
})`
  max-width: 300px;
  min-width: 300px;
  margin-right: 16px;
  margin-bottom: 12px;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(191, 191, 191, 0.5);
  background: ${(props) =>
    props.masked ? 'rgba(0, 0, 0, 0.12)' : 'rgba(0, 0, 0, 0)'};
`

interface Props {
  patient: PatientDetailQueryQuery['patient']
  stages: PatientDetailEvalStageFragment[]
  currentEvalStage: PatientDetailCurrentEvalStageFragment['currentEvalStage']
}

const EvalStages = (props: Props) => {
  const { patient, stages, currentEvalStage } = props
  const currentStage = stages.filter(
    (stage) => stage.id === currentEvalStage?.id
  )?.[0]
  const restStages = stages.filter((stage) => stage.id !== currentEvalStage?.id)
  const onPrint = Boolean(currentStage)

  return (
    <Card title='報告'>
      <div style={{ display: 'flex', width: '100%' }}>
        {stages.length === 0 && '目前沒有任何報告'}
        {currentStage && (
          <div style={{ maxWidth: 300, minWidth: 300, marginRight: 40 }}>
            <div>
              <div>主要報告</div>
              <Row style={{ marginTop: 16 }} />
              <StageCard patient={patient} stage={currentStage} />
            </div>
          </div>
        )}
        <div style={{ minWidth: 0, flex: 1 }}>
          {restStages.length > 0 && (
            <div style={{ position: 'relative' }}>
              <div>過去報告</div>
              <Row style={{ marginTop: 16 }} />
              <div
                style={{ width: '100%', display: 'flex', overflow: 'scroll' }}
              >
                {restStages.map((stage, index) => {
                  return (
                    <StageCard
                      key={index}
                      patient={patient}
                      stage={stage}
                      masked={onPrint}
                    />
                  )
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </Card>
  )
}

EvalStages.fragments = {
  PatientDetailEvalStage: gql`
    fragment PatientDetailEvalStage on EvalStage {
      id
      status
      ...StageName
      analysis {
        steps {
          upperStep
          lowerStep
        }
      }
      hasFilesForViewer
      shippingDate
      isCPMode
      externalVisible
      checkPoints {
        goalStep
        hasFilesForViewer
      }
    }
    ${StageName.fragment}
  `,
  PatientDetailCurrentEvalStage: gql`
    fragment PatientDetailCurrentEvalStage on Patient {
      currentEvalStage {
        id
      }
    }
  `,
}

export default EvalStages
