import 'moment/locale/zh-tw'

import './index.less'
import './utils/i18n'

import { ApolloProvider } from '@apollo/client'
import { getApolloClient, keyOfGitCommitHash } from '@sov/common'
import { openNotification, useAuth } from '@sov/ui'
import { notification } from 'antd'
import moment from 'moment'
import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter, Route } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'

import fragmentTypes from './codegen/fragmentTypes'
import LoginLayout from './components/layout/Login'
import { authContext } from './utils/context'
import AppRoute from './utils/routes/AppRoute'
import EntranceRoute from './utils/routes/EntranceRoute'

const { localStorageKey } = keyOfGitCommitHash

moment.locale('zh-tw')

// Global config of notification
notification.config({
  top: 70,
})

const clientStoredHashUpdatedCallback = (updatedHash: string) => {
  const clientStoredHash =
    window.localStorage.getItem(localStorageKey) || undefined
  const notificationConfirmCallback = () => {
    window.location.reload()
    window.localStorage.setItem(localStorageKey, updatedHash)
  }
  if (updatedHash !== clientStoredHash) {
    openNotification({
      localStorageKeyOfClientStoredHash: localStorageKey,
      notificationConfirmCallback,
    })
  }
}

const Index = () => {
  // 產生 context
  const { user, init } = useAuth()

  const AuthProvider = authContext.Provider

  const client = getApolloClient({
    clientStoredHashUpdatedCallback,
    envSetting: {
      apiHost: process.env.API_HOST as string,
      socketHost: process.env.SOCKET_HOST as string,
      nodeEnv: process.env.NODE_ENV as string,
    },
    possibleTypes: fragmentTypes.possibleTypes,
    user,
  })

  if (init) {
    return (
      <ApolloProvider client={client}>
        <BrowserRouter>
          <QueryParamProvider ReactRouterRoute={Route}>
            <AuthProvider value={user}>
              {user ? (
                <AppRoute />
              ) : (
                <LoginLayout>
                  <EntranceRoute />
                </LoginLayout>
              )}
            </AuthProvider>
          </QueryParamProvider>
        </BrowserRouter>
      </ApolloProvider>
    )
  }

  return null
}

render(<Index />, document.getElementById('root'))

export default Index
