import { Form } from '@ant-design/compatible'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { gql, useMutation } from '@apollo/client'
import { MyStringParam } from '@sov/common'
import { Button, message } from 'antd'
import { GraphQLError } from 'graphql'
import { includes } from 'ramda'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useQueryParams } from 'use-query-params'

import {
  AllPrivilege,
  CreateAccountFromEmailInput,
  CreateAccountFromEmailMutation,
  CreateAccountFromEmailMutationVariables,
} from '../../../codegen/types'
import { OnceButton } from '../../../components/button/OnceButton'
import { useLoadingLayer } from '../../../utils/hooks/general'
import LoadingLayer from '../../../utils/LoadingLayer'
import FormAccount, { AccountFormFields } from '../Form'

const FormItem = Form.Item

type Props = FormComponentProps<AccountFormFields>

export const createAccountFromEmailMutation = gql`
  mutation CreateAccountFromEmail($payload: CreateAccountFromEmailInput!) {
    createAccountFromEmail(payload: $payload) {
      id
    }
  }
`

const AccountCreateFromEmail = ({ form }: Props) => {
  const history = useHistory()
  const { loading, tip, setLoadingLayer } = useLoadingLayer()
  const [queryParams] = useQueryParams({
    name: MyStringParam,
    email: MyStringParam,
    phone: MyStringParam,
  })

  const [create] = useMutation<
    CreateAccountFromEmailMutation,
    CreateAccountFromEmailMutationVariables
  >(createAccountFromEmailMutation)

  const handleSubmit = () => {
    const { validateFieldsAndScroll } = form

    validateFieldsAndScroll(async (err, formValues) => {
      if (err) {
        console.error(err, formValues)
        return
      }

      try {
        setLoadingLayer({ loading: true, tip: '新增中...' })
        const hostname = window.location.origin
        const payload = {
          ...formValues,
          hostname,
        } as CreateAccountFromEmailInput

        await create({
          variables: {
            payload,
          },
          update: async (cache, { data }) => {
            setLoadingLayer({ loading: false, tip: '' })
            if (data) {
              message.info('已新增使用者')
              history.push('/')
            }
          },
        })
      } catch (error) {
        const e = error as GraphQLError
        setLoadingLayer({ loading: false, tip: '' })
        const isDuplicatedAccountEmailError = includes(
          '此帳號已存在',
          e.message
        )
        if (isDuplicatedAccountEmailError) {
          message.error('使用者 Email 重複，請填寫其他 Email')
        } else {
          message.error(`新增使用者失敗: ${e.message}`)
        }
      }
    })
  }

  return (
    <div style={{ overflow: 'scroll', height: '100%', padding: '30px' }}>
      <h2 style={{ marginLeft: '50px' }}>從 email 新增使用者</h2>
      <LoadingLayer loading={loading} tip={tip}>
        <Form style={{ marginTop: '20px' }}>
          <FormAccount form={form} fromEmailInfo={queryParams} />
          <FormItem
            wrapperCol={{ span: 16, offset: 6 }}
            style={{ marginTop: 24 }}
          >
            <OnceButton
              label='新增'
              onClick={handleSubmit}
              requiredPrivilege={AllPrivilege.AccountCreate}
              type='primary'
            />
            <Button style={{ marginLeft: 24 }}>
              <Link to='/'>回首頁</Link>
            </Button>
          </FormItem>
        </Form>
      </LoadingLayer>
    </div>
  )
}

export default Form.create<Props>()(AccountCreateFromEmail)
