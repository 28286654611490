import Icon from '@ant-design/icons'
import React from 'react'

const Svg = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      // eslint-disable-next-line
      d='M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4C15 4.55228 14.5523 5 14 5H10C9.44772 5 9 4.55228 9 4ZM7.04667 3.5C7.30679 3.5 7.5 3.73989 7.5 4C7.5 4.26011 7.30679 4.5 7.04667 4.5H7C6.17157 4.5 5.5 5.17157 5.5 6V19C5.5 19.8284 6.17157 20.5 7 20.5H17C17.8284 20.5 18.5 19.8284 18.5 19V6C18.5 5.17157 17.8284 4.5 17 4.5H16.9533C16.6932 4.5 16.5 4.26011 16.5 4C16.5 3.73989 16.6932 3.5 16.9533 3.5H17C18.3807 3.5 19.5 4.61929 19.5 6V19C19.5 20.3807 18.3807 21.5 17 21.5H7C5.61929 21.5 4.5 20.3807 4.5 19V6C4.5 4.61929 5.61929 3.5 7 3.5H7.04667ZM11.5 8.5V14.7929L9.85355 13.1464C9.65829 12.9512 9.34171 12.9512 9.14645 13.1464C8.95118 13.3417 8.95118 13.6583 9.14645 13.8536L11.6317 16.3388C11.827 16.5341 12.1435 16.5341 12.3388 16.3388L14.8241 13.8536C15.0193 13.6583 15.0193 13.3417 14.8241 13.1464C14.6288 12.9512 14.3122 12.9512 14.117 13.1464L12.5 14.7634V8.5C12.5 8.22386 12.2761 8 12 8C11.7239 8 11.5 8.22386 11.5 8.5Z'
      fill='currentColor'
    />
  </svg>
)

const CreateOrderIcon = (props) => <Icon {...props} component={Svg} />

export default CreateOrderIcon
