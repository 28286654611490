import { gql } from '@apollo/client'
import { AntSorterType, getEmptyText, TableQuery } from '@sov/common'
import { DisplayPatientBrand, Link, StageName } from '@sov/ui'
import { Space, Table } from 'antd'
import { ColumnProps, TableProps } from 'antd/lib/table'
import moment from 'moment'
import { map } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { HomeDeliveryType, InvoiceTableFragment } from '../../../codegen/types'
import { InvoiceListSorterField } from '../../../pages/invoice/InvoiceList'

interface Props {
  loading?: boolean
  data: InvoiceTableFragment[]
  antdPagination: {
    page: number
    total: number
    limit: number
  }
  sortInfo?: AntSorterType<InvoiceListSorterField>
  handleTableChange: TableProps<InvoiceTableFragment>['onChange']
}

function InvoiceTable(props: Props) {
  const { data, loading, sortInfo, antdPagination, handleTableChange } = props

  const columns: ColumnProps<InvoiceTableFragment>[] = [
    {
      title: '病患',
      width: 60,
      key: 'patient',
      render: (text, record) =>
        record.__typename === 'InvoiceWithStage' ? (
          <Link.PatientLink item={record.patient} />
        ) : (
          <div>無</div>
        ),
    },
    {
      title: '品牌',
      key: 'brand',
      width: 80,
      render: (_text, record) =>
        record.__typename === 'InvoiceWithStage' ? (
          <DisplayPatientBrand value={record.patient.payment.brand} />
        ) : null,
    },
    {
      title: '出貨編號',
      width: 60,
      key: 'stage',
      render: (text, record) => {
        return (
          record.__typename === 'InvoiceWithStage' && (
            <StageName
              item={record.stage}
              showType={record.stage.__typename !== 'PrintStage'}
              isExternal
            />
          )
        )
      },
    },
    {
      title: '出貨日期',
      width: 100,
      dataIndex: 'shippingDate',
      key: 'shippingDate',
      sorter: true,
      sortOrder:
        sortInfo?.field === 'shippingDate' ? sortInfo.order : undefined,
      render: (text) => (
        <div>{text ? moment(text).format('YYYY-MM-DD') : getEmptyText()}</div>
      ),
    },
    {
      title: '出貨品項與數量',
      width: 180,
      key: 'products',
      dataIndex: 'products',
      render: (text, record) => (
        <div>
          {map(
            (item) => (
              <div key={item.productId}>
                {item.name} ( * {item.count})
              </div>
            ),
            record.products!
          )}
        </div>
      ),
    },
    {
      title: '配送方式',
      width: 180,
      key: 'homeDelivery',
      render: (text, record) => {
        const { t } = useTranslation()
        return (
          <Space direction='vertical'>
            <div>
              {t(`invoice.homeDeliveryType.${record.homeDeliveryType}`)}
            </div>
            {record.homeDeliveryType !== HomeDeliveryType.Self &&
              `宅配單號: ${record.homeDeliveryId}`}
          </Space>
        )
      },
    },
    // {
    //   title: '轉介費',
    //   width: 60,
    //   dataIndex: 'middlemanFee',
    //   key: 'middlemanFee',
    //   render: (text, record) => <div>{record.middlemanFee > 0 && record.middlemanFee}</div>
    // },
    // @TODO 暫時被拔除
    // {
    //   title: '付款狀態',
    //   width: 100,
    //   dataIndex: 'payment',
    //   key: 'payment',
    //   /** @todo
    //    * invoicesQuery.payment 非 array type，這邊暫時用 antd table 本身的 filter
    //    * 待改成 array type 後，再用 routerQuery 進行 filter
    //    */
    //   filters: Object.values(Payment).map(payment => ({ text: t(`invoice.payment.${payment}`), value: payment })),
    //   onFilter: (value, record) => record.payment === value,
    //   filterMultiple: false,
    //   render: (text, record) => (
    //     <div>
    //       <Badge status={record.payment === Payment.Completed ? 'success' : 'warning'} />
    //       {t(`invoice.payment.${record.payment}`)}
    //     </div>
    //   )
    // }
  ]
  return (
    <Table
      rowKey='id'
      columns={columns}
      dataSource={data}
      loading={loading}
      onChange={handleTableChange}
      pagination={TableQuery.getAntdPagination(antdPagination)}
    />
  )
}

InvoiceTable.fragment = gql`
  fragment InvoiceTable on Invoice {
    id
    shippingDate
    payment
    homeDeliveryId
    homeDeliveryType
    products {
      productId
      serialNumber
      name
      spec
      cost
      count
    }
    ... on InvoiceWithStage {
      patient {
        payment {
          brand
        }
        ...PatientLink
      }
      stage {
        __typename
        ...StageName
      }
    }
  }
  ${StageName.fragment}
  ${Link.PatientLink.fragment}
`

export default InvoiceTable
