import { ReloadOutlined } from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'
import { ErrorHandling, TableQuery } from '@sov/common'
import { Button } from 'antd'
import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'

import {
  PatientInvoicesQueryQuery,
  PatientInvoicesQueryQueryVariables,
  ShippingStatus,
} from '../../codegen/types'
import PatientTableInvoiceList from '../../components/table/patient/InvoiceList'
import NoScrollContainer from '../../utils/NoScrollContainer'

/* ----- styled components ----- */
const TableTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 24px;
  display: inline-block;
`
const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
`

/* ----- query string ----- */
const patientInvoicesQuery = gql`
  query PatientInvoicesQuery(
    $id: ID!
    $query: InvoiceQuery = {}
    $page: Int = 1
    $limit: Int = 100
    $sort: String = "-updated"
  ) {
    patient(id: $id) {
      id
      invoices(query: $query, page: $page, limit: $limit, sort: $sort) {
        docs {
          ...PatientInvoiceTable
        }
        limit
        total
        page
      }
    }
  }
  ${PatientTableInvoiceList.fragment}
`

/* ----- types and component ----- */
interface Params {
  patientId: string
}

type Props = {
  isSidebarCollapsed: boolean
} & RouteComponentProps<Params>

const List = ({ match }: Props) => {
  const { patientId } = match.params
  const { paginateQuery, handleTableChange, handleTableReset } =
    TableQuery.useTableQuery({ limit: 10 })

  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { data, loading } = useQuery<
    PatientInvoicesQueryQuery,
    PatientInvoicesQueryQueryVariables
  >(patientInvoicesQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      id: patientId,
      query: {
        shippingStatus: [ShippingStatus.Shipped],
      },
      ...paginateQuery,
    },
    skip: !patientId,
  })

  const invoices = data?.patient?.invoices?.docs || []
  const antdPagination = {
    page: data?.patient?.invoices?.page || 1,
    total: data?.patient?.invoices?.total || 0,
    limit: data?.patient?.invoices?.limit || 0,
  }

  return (
    <NoScrollContainer>
      <TitleRow>
        <TableTitle>出貨列表</TableTitle>
        <Button onClick={handleTableReset}>
          <ReloadOutlined /> 重新整理
        </Button>
      </TitleRow>
      <PatientTableInvoiceList
        data={invoices}
        loading={loading}
        antdPagination={antdPagination}
        handleTableChange={handleTableChange}
      />
    </NoScrollContainer>
  )
}

export default List
