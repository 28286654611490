import React from 'react'
import styled from 'styled-components'

import check from './check.png'

const CheckIcon = () => {
  return <img src={check} />
}

const Container = styled.div`
  display: flex;
  align-items: center;
`
const Label = styled.div`
  font-size: 11px;
  line-height: 18px;
  margin-left: 5px;
`

interface Props {
  label: string
}

const InstructionStep = (props: Props) => {
  const { label } = props

  return (
    <Container>
      <CheckIcon />
      <Label>{label}</Label>
    </Container>
  )
}

export default InstructionStep
