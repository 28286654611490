import { Breadcrumb } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

import { RouteKey, routeMap } from '../../../utils/routes'

interface Props {
  routes: {
    key: RouteKey
    render?: () => JSX.Element
  }[]
}

const BreadcrumbCreator = (props: Props) => {
  const { routes } = props

  return (
    <Breadcrumb style={{ marginBottom: 8 }}>
      {routes.map(({ key, render }) => {
        return (
          <Breadcrumb.Item key={key}>
            {render ? (
              render()
            ) : (
              <Link to={routeMap[key].path}>{routeMap[key].title}</Link>
            )}
          </Breadcrumb.Item>
        )
      })}
    </Breadcrumb>
  )
}

export default BreadcrumbCreator
