import { Image } from 'antd'
import React from 'react'
import styled from 'styled-components'

import FirstEvaluationLeft from '../../../../../assets/order/FirstEvaluationLeft.png'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const FirstEvaluation = () => {
  return (
    <Container>
      <Image src={FirstEvaluationLeft} width='100%' />
    </Container>
  )
}

export default FirstEvaluation
