import useFetch from 'use-http'

const useRequest = () => {
  const options = {
    url: `${process.env.API_HOST}`,
    credentials: 'include',
    headers: {
      'Access-Control-Allow-Origin': window.location.origin,
      'Access-Control-Allow-Credentials': 'true',
    },
  }

  const request = useFetch(options as any)
  return request
}

export { useRequest }
