import styled from 'styled-components'

const defaultHeightOfVerticalLine = 60

interface VerticalLineProps {
  height?: number
}

const VerticalLine = styled.div<VerticalLineProps>`
  /** 參考 https://stackoverflow.com/a/53551089/5235017 */
  background-image: linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
  background-size: 3px 100%;
  background-repeat: no-repeat;
  background-position: center center;
  height: ${(props) => props.height ?? defaultHeightOfVerticalLine}px;
`

export { defaultHeightOfVerticalLine }

export default VerticalLine
