import useFetch from 'use-http'

const useLogout = () => {
  const url = `${process.env.API_HOST}/auth/logout`
  const options = {
    credentials: 'include',
    headers: {
      'Access-Control-Allow-Origin': window.location.origin,
      'Access-Control-Allow-Credentials': 'true',
    },
  }
  const request = useFetch(url, options as any)

  const logout = async () => {
    await request.post()
    window.location.replace('/login')
  }
  return logout
}

export { useLogout }
