import { Layout } from 'antd'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { LAYOUT_HEADER_HEIGHT } from '../../../utils/constant'
import { authContext } from '../../../utils/context'
import AccountAvatar from '../../icon/AccountAvatar'
import MessageInbox from './MessageInbox'
import NotificationBell from './NotificationBell'

const { Header } = Layout

const StyledHeader = styled(Header)`
  display: flex;
  align-items: center;
  width: calc(100vw - 80px);
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  line-height: 1.5;
  height: ${LAYOUT_HEADER_HEIGHT}px;
  padding: 0;

  .icon {
    font-size: 16px;
    width: 48px;
    padding: 24px 16px;
    cursor: pointer;
    color: grey;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  .anticon {
    font-size: 16px;
    width: 48px;
    padding: 24px 16px;
    cursor: pointer;
    color: grey;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  .avatar-nickname {
    margin-left: 6px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .avatar {
    margin-right: 12px;
    width: 24px;
    height: 24px;
  }

  .nickname {
    margin-right: 64px;
    color: rgba(0, 0, 0, 0.65);
  }
`

const LayoutHeader = () => {
  const auth = useContext(authContext)

  return (
    <StyledHeader>
      {auth && (
        <>
          <div style={{ flex: 1 }} />
          <MessageInbox />
          <NotificationBell />
          <Link to='/settings' className='avatar-nickname'>
            <div className='avatar'>
              <AccountAvatar
                entityType={auth.entityType}
                size={24}
                src={auth.avatar?.thumbnailPath ?? ''}
              />
            </div>
            <span className='nickname'>{auth.nickname}</span>
          </Link>
        </>
      )}
    </StyledHeader>
  )
}

export default LayoutHeader
