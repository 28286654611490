// import { getApolloContext } from '@apollo/react-hooks'
// import { GqlUpload } from '@sov/ui'
import { Checkbox, Form, Input, InputNumber } from 'antd'
import { FormInstance, FormProps } from 'antd/lib/form/Form'
// import { UploadFile, UploadProps } from 'antd/lib/upload/interface'
import { Moment } from 'moment'
import { always, cond, equals } from 'ramda'
import React from 'react'
import styled from 'styled-components'

import {
  CreateOrderInput,
  OrderType,
  StageOrderContentInput,
  StageType,
} from '../../../../../codegen/types'
import TooltipIcon from '../../../../icon/TooltipIcon'
import { FormType } from '../index'
import CustomizedRangePicker from './CustomizedRangePicker'

/**
 * @todo: 取消註解
 * 等訂單資料庫與 graphQL schem 新增 attachFiles 並遷移完成後再取消此處註解
 */
// const maxFileNumber = 5

// const CustomizedUploadItemContainer = styled.div`
//   /** 覆寫 antd 預設 style */
//   .ant-upload-list-item:hover .ant-upload-list-item-info {
//     background-color: #e8f4ff;
//   }
// `

// const CustomizedUploadItem = props => {
//   const { originNode } = props
//   return <CustomizedUploadItemContainer>{originNode}</CustomizedUploadItemContainer>
// }

// const customUploadItem: UploadProps['itemRender'] = (originNode, file, currentFileList) => {
//   return <CustomizedUploadItem originNode={originNode} file={file} currentFileList={currentFileList} />
// }

const OrderFormContainer = styled.div`
  .ant-legacy-form-item {
    margin-bottom: 24px;
  }
  .ant-legacy-form-item-label,
  .ant-legacy-form-item-control {
    line-height: normal;
  }
`

const AppointmentDateHint = styled.div`
  color: #f5222d;
  font-size: 11px;
  line-height: 18px;
  margin-top: 8px;
`

/**
 * @todo: 取消註解
 * 等訂單資料庫與 graphQL schem 新增 attachFiles 並遷移完成後再取消此處註解
 */
// const UploadContainer = styled.div`
//   display: flex;
// `

// const UploadedFileCount = styled.span`
//   color: rgba(0, 0, 0, 0.45);
//   margin-left: 8px;
// `

const TooltipIconContainer = styled.span`
  margin-left: 8px;
`

const CheckboxContainer = styled.span`
  ${TooltipIconContainer};
  line-height: 24px;
`

const formItemLayout: FormProps = {
  labelCol: { span: 8 },
  labelAlign: 'left',
  wrapperCol: { span: 16 },
}
export interface OrderFormValues {
  stepNumber: number
  description: string
  appointmentDateInterval: Moment[]
  withReport: number
}

export type FormGeneralInitialValues = Partial<OrderFormValues>

interface GetCreatePayloadArgs {
  orderFormValues: OrderFormValues
  orderType: OrderType
  patientId: string
}

type GetCreatePayload = (args: GetCreatePayloadArgs) => CreateOrderInput[]

const getCreatePayload: GetCreatePayload = (args) => {
  const { orderFormValues, orderType, patientId } = args

  const stageOrder = cond<OrderType, StageOrderContentInput[]>([
    [
      equals<OrderType>(OrderType.Remodel),
      always([
        {
          stageType: StageType.Print,
          number: orderFormValues.stepNumber,
          items: [],
        },
        {
          stageType: StageType.Eval,
          number: orderFormValues.withReport,
          items: [],
        },
      ]),
    ],
    [
      equals<OrderType>(OrderType.NormalPrint),
      always([
        {
          stageType: StageType.Print,
          number: orderFormValues.stepNumber,
          items: [],
        },
      ]),
    ],
    [
      equals<OrderType>(OrderType.Report),
      always([
        {
          stageType: StageType.Eval,
          number: orderFormValues.withReport,
          items: [],
        },
      ]),
    ],
  ])(orderType)
  return [
    {
      patient: patientId,
      stageOrder,
      type: orderType,
      description: orderFormValues.description,
      appointmentDateInterval: orderFormValues.appointmentDateInterval,
    },
  ]
}

interface OrderFormProps {
  form: FormInstance<OrderFormValues>
  doctorName: string
  patientName: string
  initialValues: FormGeneralInitialValues
  formType: FormType
  checkboxHandler: () => void
}

const OrderForm = (props: OrderFormProps) => {
  const {
    doctorName,
    form,
    patientName,
    formType,
    initialValues,
    checkboxHandler,
  } = props

  /**
   * @todo: 取消註解
   * 等訂單資料庫與 graphQL schem 新增 attachFiles 並遷移完成後再取消此處註解
   */
  // const [currentFileList, setCurrentFileList] = useState<UploadFile[]>([])

  // const clientContext = useContext(getApolloContext())

  // const handleFileListChange: UploadProps['onChange'] = info => {
  //   setCurrentFileList(info.fileList)
  // }
  const shouldShowReportNumberField =
    formType === FormType.Report || formType === FormType.Remodel
  const shouldShowStepNumberField =
    formType === FormType.Remodel || formType === FormType.NormalPrint
  const shouldShowCheckboxField = formType === FormType.FirstEval
  const reportOrderDisabledDay = 5
  const printOrderDisabledDay = 7
  const disabledDay =
    formType === FormType.FirstEval || formType === FormType.Report
      ? reportOrderDisabledDay
      : printOrderDisabledDay

  return (
    <OrderFormContainer>
      <Form {...formItemLayout} form={form} initialValues={initialValues}>
        <Form.Item label='病患'>{patientName}</Form.Item>
        <Form.Item label='醫師'>{doctorName}</Form.Item>
        {shouldShowStepNumberField && (
          <Form.Item label='Step 數量'>
            <Form.Item
              noStyle
              name='stepNumber'
              rules={[
                {
                  required: true,
                  message: '需填入需要的數量',
                },
              ]}
            >
              <InputNumber min={1} />
            </Form.Item>
            <TooltipIconContainer>
              <TooltipIcon
                textList={[
                  '為了更接近病患實際狀況，',
                  '建議每次訂單數量 1 個Step。',
                ]}
              />
            </TooltipIconContainer>
          </Form.Item>
        )}
        {formType === FormType.FirstEval && (
          <Form.Item name='withReport' hidden />
        )}
        {shouldShowReportNumberField && (
          <Form.Item label='報告數量'>
            <Form.Item
              noStyle
              name='withReport'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber disabled />
            </Form.Item>
            <TooltipIconContainer>
              <TooltipIcon
                textList={['療程開始後，每次取模會提供一份比對報告。']}
              />
            </TooltipIconContainer>
          </Form.Item>
        )}
        <Form.Item
          label='預計回診日'
          name='appointmentDateInterval'
          style={{ marginBottom: 0 }}
        >
          <CustomizedRangePicker disabledDay={disabledDay} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <AppointmentDateHint>
            *
            填寫正確的回診日會自動為您新增約診紀錄，並以填寫日期為優先出貨安排。（實際出貨日將受實際狀況影響）
          </AppointmentDateHint>
        </Form.Item>

        <Form.Item label='其他備註' name='description'>
          <Input.TextArea
            placeholder={
              !shouldShowStepNumberField
                ? '如有特殊指示與治療方向的要求，請填寫備註說明。'
                : '此次訂單的牙套 Step 數、病患的其他需求（需要寄家裡、出國...等等）'
            }
            rows={3}
          />
        </Form.Item>
        {/**
         * @todo: 取消註解
         * 等訂單資料庫與 graphQL schem 新增 attachFiles 並遷移完成後再取消此處註解
         */}
        {/* {clientContext.client && (
          <Form.Item label='附件補充'>
            <UploadContainer>
              {getFieldDecorator('attachFiles')(
                <GqlUpload.Upload
                  buttonProps={{
                    children: <span>上傳附件</span>
                  }}
                  client={clientContext.client}
                  disabled={currentFileList.length >= maxFileNumber}
                  fileList={currentFileList}
                  fileType='any'
                  itemRender={customUploadItem}
                  onChange={handleFileListChange}
                  uploadOptions={{ prefix: 'order' }}
                />
              )}
              <UploadedFileCount>{`(${currentFileList.length}/${maxFileNumber})`}</UploadedFileCount>
            </UploadContainer>
          </Form.Item>
        )} */}
        {shouldShowCheckboxField && (
          <Form.Item label='' wrapperCol={{ span: 24 }}>
            <Checkbox onChange={checkboxHandler}>
              <CheckboxContainer>
                我同意在病患的首次評估報告中，
                <br />
                SOV 舒服美收取數位評估費用$2500
              </CheckboxContainer>
            </Checkbox>
          </Form.Item>
        )}
      </Form>
    </OrderFormContainer>
  )
}

export { getCreatePayload }

export default OrderForm
