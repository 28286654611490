import styled, { css } from 'styled-components'

const pageBottomStyle = css`
  padding-bottom: 54px;
`

interface PageBottomProps {
  /** 在網頁預覽時可否看到預覽樣式 */
  previewInHTML: boolean
}

const PageBottom = styled.div<PageBottomProps>`
  @media screen {
    ${(props) => props.previewInHTML && pageBottomStyle}
  }

  @media print {
    page-break-after: always;
  }
`

export default PageBottom
