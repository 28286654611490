import { LeftOutlined } from '@ant-design/icons'
import { gql } from '@apollo/client'
import StageName from '@sov/ui/src/components/StageName'
import { Button, Checkbox, Col, Radio, Row, Spin } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import moment from 'moment'
import { isEmpty, isNil } from 'ramda'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import {
  CurrentEvalStageModalBodyFragment,
  CurrentEvalStageModalStageInfoFragment,
  StageNameFragment,
  StageStatus,
  StageType,
} from '../../../codegen/types'
import { defaultText, secondaryText } from '../../../utils/color'
import CreateOrderIcon from '../../icon/CreateOrderIcon'
import goalSuccess from './GoalSuccess.png'
import linkIcon from './LinkIcon.svg'
import linkIconActivity from './LinkIconActivity.svg'
import reportIcon from './Report.svg'
import settingGoal from './SettingGoal.png'
import unsetGoal from './UnsetGoal.png'

const ModalBodyContainer = styled.div`
  min-height: 300px;
`
const EvalStageScrollContainer = styled.div`
  overflow: scroll;
  width: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
  height: 160px;
`
const TopImage = styled.img`
  width: 164px;
  height: 164px;
  margin-top: 40px;
`
const StyledRadioGroup = styled(Radio.Group)`
  width: 100%;
`

const EvalStageRowContainer = styled.div`
  height: 64px;
  margin: 0 36.5px;
  display: flex;
  align-items: center;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
  justify-content: space-between;
`
const EvalStageUpdatedContainer = styled.div`
  color: ${secondaryText};
  font-size: 12px;
`
const TitleSection = styled.div`
  color: ${defaultText};
  font-size: 20px;
  height: 28px;
  font-weight: 500;
`
const InformationSection = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: ${secondaryText};
  padding: 0 24px;
`

const StyledCheckbox = styled(Checkbox)`
  font-size: 14px;
  color: ${defaultText};
  margin: 24px auto 16px auto;
`

const ActionButton = styled(Button)`
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 24px;
  color: ${secondaryText};
`
const LinkButton = styled(Button)`
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${secondaryText};
  border-radius: 4px;
  justify-self: end;
  margin-right: 8px;
`
const EmptyStageListContainer = styled.div`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.25);
  height: 160px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

const LinkImg = styled.img`
  margin-right: 8px;
`
const FooterContainer = styled.div`
  padding: 10px 24px;

  border-top: 1px solid rgba(0, 0, 0, 0.1);
`

const StyledPrimaryButton = styled(Button)`
  border-radius: 4px;
`
const StyledOutlineButton = styled(Button)`
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.45);
`

enum ModalActionType {
  SETTING,
  CREATING,
}

const ViewerLinkButton = () => {
  const [linkIconSrc, setLinkIconSrc] = useState(linkIcon)

  return (
    <LinkButton
      onMouseEnter={() => {
        setLinkIconSrc(linkIconActivity)
      }}
      onMouseLeave={() => {
        setLinkIconSrc(linkIcon)
      }}
    >
      <LinkImg src={linkIconSrc} />
      前往 Viewer
    </LinkButton>
  )
}
interface StepOneModalBodyProps {
  evalStageList: CurrentEvalStageModalStageInfoFragment[]
  setSelectedStage: React.Dispatch<
    React.SetStateAction<CurrentEvalStageModalStageInfoFragment | undefined>
  >
  selectedEvalStage?: CurrentEvalStageModalStageInfoFragment
  nextPageHandler: () => void
  handleCurrentEvalStageModalClose: () => void
  currentEvalStage?: StageNameFragment
  modalActionType: ModalActionType
  patientId?: string
}

const StepOneModalBody = (props: StepOneModalBodyProps) => {
  const {
    evalStageList,
    setSelectedStage,
    currentEvalStage,
    modalActionType,
    patientId,
    nextPageHandler,
    handleCurrentEvalStageModalClose,
  } = props
  const [canNextPage, setCanNextPage] = useState<boolean>(false)
  return (
    <>
      <Row justify='center'>
        <TopImage
          src={
            modalActionType === ModalActionType.SETTING
              ? settingGoal
              : unsetGoal
          }
        />
      </Row>

      <Row justify='center' style={{ marginTop: '8px', marginBottom: '16px' }}>
        <TitleSection>
          {modalActionType === ModalActionType.SETTING
            ? '修改主要報告'
            : '尚未設定主要報告'}
        </TitleSection>
      </Row>
      <Row justify='center'>
        {modalActionType === ModalActionType.SETTING ? (
          <>
            目前設定主要報告版本：
            {currentEvalStage && <StageName item={currentEvalStage} />}
          </>
        ) : (
          '請設定主要報告，如需觀看詳細內容可以前往 Viewer。'
        )}
      </Row>
      <Row>
        <Col span={24}>
          {evalStageList.length > 0 ? (
            <EvalStageScrollContainer>
              <StyledRadioGroup
                name='evalStage'
                onChange={(e) => {
                  setSelectedStage(e.target.value)
                  setCanNextPage(true)
                }}
              >
                {evalStageList.map((evalStage) => (
                  <Row key={evalStage.id} align='middle'>
                    <Col span={24}>
                      <EvalStageRowContainer>
                        <Radio value={evalStage}>
                          <StageName item={evalStage} />
                        </Radio>

                        <EvalStageUpdatedContainer>
                          上傳日期：
                          {moment(evalStage?.updated).format('YYYY-MM-DD')}
                        </EvalStageUpdatedContainer>
                        <Link
                          to={`/viewer?patientId=${patientId}&stageId=${evalStage?.id}`}
                          target='_blank'
                        >
                          <ViewerLinkButton />
                        </Link>
                      </EvalStageRowContainer>
                    </Col>
                  </Row>
                ))}
              </StyledRadioGroup>
            </EvalStageScrollContainer>
          ) : (
            <Row justify='center'>
              <Col>
                <EmptyStageListContainer>
                  <Row justify='center'>
                    <img src={reportIcon} />
                  </Row>
                  <Row justify='center'>無其他報告</Row>
                </EmptyStageListContainer>
              </Col>
            </Row>
          )}
          <FooterContainer>
            <Row justify='end'>
              <Col>
                <StyledOutlineButton
                  key='prev'
                  type='default'
                  onClick={() => {
                    handleCurrentEvalStageModalClose()
                  }}
                  style={{ marginRight: '24px' }}
                >
                  取消
                </StyledOutlineButton>
              </Col>
              <Col>
                <StyledPrimaryButton
                  key='next'
                  type='primary'
                  onClick={() => {
                    nextPageHandler()
                  }}
                  disabled={!canNextPage}
                >
                  下一頁
                </StyledPrimaryButton>
              </Col>
            </Row>
          </FooterContainer>
        </Col>
      </Row>
    </>
  )
}

interface StepTwoModalBodyProps {
  selectedEvalStage?: CurrentEvalStageModalStageInfoFragment
  modalActionType: ModalActionType
  prevPageHandler: () => void
  nextPageHandler: () => void
  handleUpdate: () => void
}

const StepTwoModalBody = (props: StepTwoModalBodyProps) => {
  const {
    selectedEvalStage,
    modalActionType,
    prevPageHandler,
    nextPageHandler,
    handleUpdate,
  } = props
  const [canNextPage, setCanNextPage] = useState<boolean>(false)

  if (isNil(selectedEvalStage)) {
    return null
  }
  const handleCheckBoxChange = (event: CheckboxChangeEvent) => {
    if (event.target.checked) setCanNextPage(true)
    else setCanNextPage(false)
  }
  return (
    <>
      <Row justify='center'>
        <TopImage
          src={
            modalActionType === ModalActionType.SETTING
              ? settingGoal
              : unsetGoal
          }
        />
      </Row>
      <Row justify='center'>
        <TitleSection>再次確認主要報告版本</TitleSection>
      </Row>
      <Row justify='center' style={{ marginTop: '16px', marginBottom: '8px' }}>
        您選擇的版本是 <StageName item={selectedEvalStage} />
      </Row>
      <Row>
        <InformationSection>
          報告為預定的治療方向及最終結果展現，隱形矯正牙套會以此報告內容進行療程設計與製作，但治療過程中可能因為牙齒環境、患者自身因素，等實際狀況而有所變更甚至中止。
          <br />
          <br />
          如果主要報告版本與內容確認無誤，請按下確認按鈕。
        </InformationSection>
      </Row>
      <Row justify='center'>
        <StyledCheckbox onChange={handleCheckBoxChange}>
          病患與醫生皆同意且確認以此報告的治療內容
        </StyledCheckbox>
      </Row>
      <FooterContainer>
        <Row justify='space-between'>
          <Col>
            <StyledOutlineButton
              key='prev'
              type='default'
              onClick={() => {
                prevPageHandler()
              }}
              icon={<LeftOutlined />}
            >
              上一步
            </StyledOutlineButton>
          </Col>

          <Col>
            <StyledPrimaryButton
              key='next'
              type='primary'
              onClick={() => {
                handleUpdate()
                nextPageHandler()
              }}
              disabled={!canNextPage}
            >
              確認
            </StyledPrimaryButton>
          </Col>
        </Row>
      </FooterContainer>
    </>
  )
}

interface FinalStepModalBodyProps {
  currentEvalStage?: CurrentEvalStageModalStageInfoFragment
  orderAction: () => void
  canCreateOrder?: boolean
  handleCurrentEvalStageModalClose: () => void
}
const FinalStepModalBody = (props: FinalStepModalBodyProps) => {
  const {
    currentEvalStage,
    orderAction,
    canCreateOrder,
    handleCurrentEvalStageModalClose,
  } = props
  return (
    <>
      <Row justify='center'>
        <TopImage src={goalSuccess} />
      </Row>
      <Row justify='center'>
        <TitleSection>主要報告版本已確認</TitleSection>
      </Row>
      <Row justify='center' style={{ marginTop: '16px', marginBottom: '8px' }}>
        主要報告版本：
        {!isNil(currentEvalStage) && <StageName item={currentEvalStage} />}
      </Row>
      <Row>
        <InformationSection>
          主要報告版本確認後，隱形矯正牙套會以此報告內容進行療程設計與製作。要接著下單病患的
          Step 1牙套嗎？
        </InformationSection>
      </Row>
      <Row justify='center' style={{ marginTop: '24px' }}>
        {canCreateOrder === true && (
          <ActionButton onClick={orderAction} style={{ marginBottom: '16px' }}>
            <CreateOrderIcon
              style={{ color: secondaryText, marginRight: '12px' }}
            />
            下單
          </ActionButton>
        )}

        <ActionButton
          onClick={handleCurrentEvalStageModalClose}
          style={{ marginBottom: '24px' }}
        >
          關閉
        </ActionButton>
      </Row>
    </>
  )
}

interface ModalBodyProps {
  patientItem?: CurrentEvalStageModalBodyFragment
  loading: boolean
  selectedEvalStage?: CurrentEvalStageModalStageInfoFragment
  setSelectedStage: React.Dispatch<
    React.SetStateAction<CurrentEvalStageModalStageInfoFragment | undefined>
  >
  orderAction: () => void
  handleCurrentEvalStageModalClose: () => void
  handleUpdate: () => void
}

const CurrentEvalStageModalBody = (props: ModalBodyProps) => {
  const {
    patientItem,
    loading,
    selectedEvalStage,
    setSelectedStage,
    orderAction,
    handleUpdate,
    handleCurrentEvalStageModalClose,
  } = props

  const [pageIndex, setPageIndex] = useState<number>(0)
  const evalStageList = patientItem?.stages
    ?.docs as CurrentEvalStageModalStageInfoFragment[]
  const currentEvalStage = patientItem?.currentEvalStage
  const modalActionType = !isNil(currentEvalStage)
    ? ModalActionType.SETTING
    : ModalActionType.CREATING
  const evalStageListExcludeCurrent = evalStageList?.filter(
    (stage) => stage?.id !== currentEvalStage?.id
  ) as CurrentEvalStageModalStageInfoFragment[]
  const canCreateOrder = patientItem?.canCreateOrder
  const nextPageHandler = () => {
    setPageIndex((prev) => prev + 1)
  }
  const prevPageHandler = () => {
    setPageIndex((prev) => prev - 1)
  }

  if (isNil(evalStageList) || isEmpty(evalStageList)) {
    return null
  }

  if (loading) {
    return (
      <ModalBodyContainer>
        <Spin />
      </ModalBodyContainer>
    )
  }

  return (
    <ModalBodyContainer>
      {pageIndex === 0 && (
        <StepOneModalBody
          evalStageList={
            modalActionType === ModalActionType.SETTING
              ? evalStageListExcludeCurrent
              : evalStageList
          }
          setSelectedStage={setSelectedStage}
          nextPageHandler={nextPageHandler}
          handleCurrentEvalStageModalClose={handleCurrentEvalStageModalClose}
          currentEvalStage={currentEvalStage}
          modalActionType={modalActionType}
          patientId={patientItem?.id}
        />
      )}
      {pageIndex === 1 && (
        <StepTwoModalBody
          selectedEvalStage={selectedEvalStage}
          nextPageHandler={nextPageHandler}
          prevPageHandler={prevPageHandler}
          handleUpdate={handleUpdate}
          modalActionType={modalActionType}
        />
      )}
      {pageIndex === 2 && (
        <FinalStepModalBody
          currentEvalStage={currentEvalStage}
          orderAction={orderAction}
          canCreateOrder={canCreateOrder}
          handleCurrentEvalStageModalClose={handleCurrentEvalStageModalClose}
        />
      )}
    </ModalBodyContainer>
  )
}

CurrentEvalStageModalBody.fragments = {
  CurrentEvalStageModalBody: gql`
    fragment CurrentEvalStageModalBody on Patient{
      id
      canCreateOrder
      currentEvalStage {
      ...CurrentEvalStageModalStageInfo
      }
      stages(query: { type: [${StageType.Eval}], status: [${StageStatus.Completed}] }, limit: 20, sort: "-expectedShippingDate") {
        docs {
        ...CurrentEvalStageModalStageInfo
        }
      }
    }
  fragment CurrentEvalStageModalStageInfo on Stage{
    __typename
    shippingDate
    updated
    ...StageName
  }
  ${StageName.fragment}
  `,
}

export default CurrentEvalStageModalBody
