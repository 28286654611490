import styled from 'styled-components'

import CurrentEvalStageButton from './CurrentEvalStageButton'
import OrderButton from './OrderButton'
import ViewDetailButton from './ViewDetailButton'

interface ActionButtonContainerProps {
  isButtonDisabled: boolean
}

export const ActionButtonContainer = styled.div<ActionButtonContainerProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: ${(props) => (props.isButtonDisabled ? 0.4 : 1)};

  span {
    color: rgba(0, 0, 0, 0.65);
  }
`

const ActionButtons = {
  CurrentEvalStageButton: CurrentEvalStageButton,
  OrderButton: OrderButton,
  ViewDetailButton: ViewDetailButton,
}

export default ActionButtons
