import { Tag } from 'antd'
import { T, always, cond, equals } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { PatientBrand } from '../../codegen/types'

type Props = {
  mode?: 'tag' | 'text'
  value: PatientBrand
}

const DisplayPatientBrand = (props: Props) => {
  const { mode = 'tag', value } = props
  const { t } = useTranslation()

  if (mode === 'tag') {
    const color = cond<PatientBrand, string>([
      [equals<PatientBrand>(PatientBrand.Smilux), always('green')],
      [equals<PatientBrand>(PatientBrand.Sov), always('purple')],
      [equals<PatientBrand>(PatientBrand.Unknown), always('red')],
      [T, always('')],
    ])(value)

    return (
      <Tag color={color} style={{ margin: 0 }}>
        {t(`patient.brand.${value}`)}
      </Tag>
    )
  } else if (mode === 'text') {
    return <>{t(`patient.brand.${value}`)}</>
  } else {
    return null
  }
}

export { DisplayPatientBrand }
