import { Layout } from 'antd'
import React from 'react'

import heroImage from '../../static/images/entrance/img_login_page_Hero.jpg'

interface Props {
  children: JSX.Element
}

const LoginLayout = (props: Props) => {
  const { children } = props

  return (
    <Layout style={{ display: 'flex', flexDirection: 'row' }}>
      <Layout
        style={{
          color: 'white',
          textAlign: 'center',
          backgroundImage: `url(${heroImage})`,
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div style={{ marginTop: '50vh' }}>
          <div style={{ fontSize: '48px' }}>SOV 矯正系統</div>
          <div style={{ fontSize: '32px', fontWeight: 300 }}>
            醫生診所的最佳夥伴
          </div>
        </div>
      </Layout>
      <Layout style={{ width: '50vw' }}>{children}</Layout>
    </Layout>
  )
}

export default LoginLayout
