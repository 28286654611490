import { useQuery } from '@apollo/client'
import { ErrorHandling } from '@sov/common'
import { Select as AntdSelect } from 'antd'
import { SelectProps, SelectValue } from 'antd/lib/select'
import { equals, filter, find, map, propEq, union, values } from 'ramda'
import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  ClinicsQuery,
  ClinicsSelectQueryQuery,
  ClinicsSelectQueryQueryVariables,
  DoctorsQuery,
  DoctorsSelectQueryQuery,
  DoctorsSelectQueryQueryVariables,
  EmployeesQuery,
  EmployeesSelectQueryQuery,
  PatientsQuery,
  PatientsSelectQueryQuery,
  PatientsSelectQueryQueryVariables,
  Role,
} from '../../codegen/types'
import {
  clinicsSelectQuery,
  doctorsSelectQuery,
  employeesSelectQuery,
  patientsSelectQuery,
} from './query'

export interface SelectItem {
  id: string
  name: string
}

interface EmployeeSelectItem {
  id: string
  name: string
  role: Role
}

type PatientSelectProps = SelectProps<SelectItem> & {
  handleItemSelect?: (SelectItem: SelectItem) => void
  initItem?: SelectItem
  query?: PatientsQuery
}
type ClinicSelectProps = SelectProps<string> & {
  handleItemSelect?: (SelectItem: SelectItem) => void
  initItem?: SelectItem
  query?: ClinicsQuery
}
type DoctorSelectProps = SelectProps<SelectItem> & {
  handleItemSelect?: (SelectItem: SelectItem) => void
  initItem?: SelectItem
  query?: DoctorsQuery
}

type EmployeesSelectProps = SelectProps<SelectItem> & {
  handleItemSelect?: (selectedItem: EmployeeSelectItem) => void
  initItem?: EmployeeSelectItem
  initItems?: EmployeeSelectItem[]
  query?: EmployeesQuery
}

const CustomSelect = (props) => (
  <AntdSelect
    notFoundContent='無符合資料'
    optionFilterProp='children'
    placeholder='輸入關鍵字'
    showSearch
    style={{ width: 200, marginRight: 8 }}
    {...props}
  />
)

const PatientSelect = memo(
  ({
    handleItemSelect,
    initItem,
    query = {},
    ...restProps
  }: PatientSelectProps) => {
    const { toErrorPage } = ErrorHandling.useErrorHandling()
    const result = useQuery<
      PatientsSelectQueryQuery,
      PatientsSelectQueryQueryVariables
    >(patientsSelectQuery, {
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'none',
      onError: (error) => {
        toErrorPage(error.message)
      },
      variables: {
        query,
        page: 1,
        limit: 500,
        sort: '-updated',
      },
    })
    const fetchedItems = result.data?.patients ? result.data.patients.docs : []
    const handleSelect = (id: SelectValue) => {
      if (fetchedItems) {
        const SelectItem = find(propEq('id', id), fetchedItems)
        if (SelectItem && handleItemSelect) {
          handleItemSelect(SelectItem)
        }
      }
    }
    const items = initItem ? union(fetchedItems, [initItem]) : fetchedItems
    return (
      <CustomSelect onSelect={handleSelect} {...restProps}>
        {map(
          (item) => (
            <AntdSelect.Option value={item.id} key={item.id}>
              {item.name}
            </AntdSelect.Option>
          ),
          items
        )}
      </CustomSelect>
    )
  },
  (prevProps, nextProps) => equals(prevProps, nextProps)
)

const ClinicSelect = memo(
  ({
    handleItemSelect,
    initItem,
    query = {},
    ...restProps
  }: ClinicSelectProps) => {
    const { toErrorPage } = ErrorHandling.useErrorHandling()
    const result = useQuery<
      ClinicsSelectQueryQuery,
      ClinicsSelectQueryQueryVariables
    >(clinicsSelectQuery, {
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'none',
      onError: (error) => {
        toErrorPage(error.message)
      },
      variables: {
        query,
        page: 1,
        limit: 500,
        sort: '-updated',
      },
    })
    const fetchedItems = result.data?.clinics ? result.data.clinics.docs : []
    const handleSelect = (id: SelectValue) => {
      if (fetchedItems) {
        const SelectItem = find(propEq('id', id), fetchedItems)
        if (SelectItem && handleItemSelect) {
          handleItemSelect(SelectItem)
        }
      }
    }
    const items = initItem ? union(fetchedItems, [initItem]) : fetchedItems
    return (
      <CustomSelect onSelect={handleSelect} {...restProps}>
        {map(
          (item) => (
            <AntdSelect.Option value={item.id} key={item.id}>
              {item.name}
            </AntdSelect.Option>
          ),
          items
        )}
      </CustomSelect>
    )
  },
  (prevProps, nextProps) => equals(prevProps, nextProps)
)

const DoctorSelect = ({
  handleItemSelect,
  initItem,
  query = {},
  ...restProps
}: DoctorSelectProps) => {
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const result = useQuery<
    DoctorsSelectQueryQuery,
    DoctorsSelectQueryQueryVariables
  >(doctorsSelectQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      query,
      page: 1,
      limit: 500,
      sort: '-updated',
    },
  })
  const fetchedItems = result.data?.doctors ? result.data.doctors.docs : []
  const handleSelect = (id: SelectValue) => {
    if (fetchedItems) {
      const SelectItem = find(propEq('id', id), fetchedItems)
      if (SelectItem && handleItemSelect) {
        handleItemSelect(SelectItem)
      }
    }
  }
  const items = initItem ? union(fetchedItems, [initItem]) : fetchedItems
  return (
    <CustomSelect onSelect={handleSelect} {...restProps}>
      {map(
        (item) => (
          <AntdSelect.Option value={item.id} key={item.id}>
            {item.name}
          </AntdSelect.Option>
        ),
        items
      )}
    </CustomSelect>
  )
}

const EmployeeSelect = ({
  handleItemSelect,
  initItem,
  initItems,
  query = {},
  ...restProps
}: EmployeesSelectProps) => {
  const { t } = useTranslation()
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { data, loading } = useQuery<
    EmployeesSelectQueryQuery,
    ClinicsSelectQueryQueryVariables
  >(employeesSelectQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      query,
      page: 1,
      limit: 500,
      sort: '-updated',
    },
  })
  const fetchedItems = data?.employees ? data.employees.docs : []
  const handleSelect = (id: SelectValue) => {
    if (fetchedItems) {
      const selectedItem = find(propEq('id', id), fetchedItems)
      if (selectedItem && handleItemSelect) {
        handleItemSelect(selectedItem)
      }
    }
  }
  const items = initItem
    ? union(fetchedItems, [initItem])
    : initItems
    ? union(fetchedItems, initItems)
    : fetchedItems

  if (loading) {
    return null
  }

  return (
    <CustomSelect onSelect={handleSelect} {...restProps}>
      {map((role) => {
        // 根據角色分群組且必須要有 employee
        const itemsByRole = filter((x) => {
          return x.role === role
        }, items)

        return (
          itemsByRole.length > 0 && (
            <AntdSelect.OptGroup key={role} label={t(`role.${role}`)}>
              {map(
                (item) => (
                  <AntdSelect.Option value={item.id} key={item.id}>
                    {item.name}
                  </AntdSelect.Option>
                ),
                itemsByRole
              )}
            </AntdSelect.OptGroup>
          )
        )
      }, values(Role))}
    </CustomSelect>
  )
}

export { PatientSelect, EmployeeSelect, ClinicSelect, DoctorSelect }
