import { StepInfo } from './UpcomingStepListTimeline'

type GetHasStepInstruction = (stepInfo: StepInfo) => boolean

const getHasStepInstruction: GetHasStepInstruction = (stepInfo) => {
  return (
    stepInfo?.attachment ||
    stepInfo?.button ||
    stepInfo?.ipr ||
    stepInfo?.retrieve
  )
}

export { getHasStepInstruction }
