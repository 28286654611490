import { Skeleton, Spin } from 'antd'
import React, { FC } from 'react'
import styled from 'styled-components'

import { LAYOUT_HEADER_HEIGHT } from '../../utils/constant'
import Blank from '../layout/Blank'

const StyledPage = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${LAYOUT_HEADER_HEIGHT}px);
  overflow: scroll;
`
const StyledHeader = styled.div`
  background-color: white;
  border-bottom: 1px solid #e8e8e8;
  padding: 24px;
`
const StyledContent = styled.div`
  flex: 1;
  margin: 24px;
`

interface Props {
  header?: JSX.Element
  isPageHeaderFixed?: boolean
  loading?: boolean
}

const Page: FC<Props> = (props) => {
  const { header, isPageHeaderFixed = false, loading, children } = props

  if (loading) {
    return (
      <StyledPage>
        <StyledHeader>
          <Skeleton title={false} />
          <Blank height={8} />
        </StyledHeader>
        <StyledContent>
          <Spin />
        </StyledContent>
      </StyledPage>
    )
  }

  if (isPageHeaderFixed) {
    return (
      <>
        <StyledHeader>{header}</StyledHeader>
        <StyledPage>
          <StyledContent>{children}</StyledContent>
        </StyledPage>
      </>
    )
  }

  return (
    <StyledPage>
      <StyledHeader>{header}</StyledHeader>
      <StyledContent>{children}</StyledContent>
    </StyledPage>
  )
}

export default Page
