import { gql, useMutation, useQuery } from '@apollo/client'
import { message } from 'antd'
import { GraphQLError } from 'graphql'
import React, { useContext } from 'react'
import styled from 'styled-components'

import {
  AccountInfoQueryQuery,
  AccountInfoQueryQueryVariables,
  EntityType,
  UpdateAccountInfoMutation,
  UpdateAccountInfoMutationVariables,
  UpdateAccountInput,
} from '../../../../../codegen/types'
import { authContext } from '../../../../../utils/context'
import BasicForm from './BasicForm'
import ClinicForm from './ClinicForm'

const PageContainer = styled.div`
  /* padding: 24px; */
`

const FormContainer = styled.div`
  background-color: white;
  margin-bottom: 16px;
`

const FormTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  padding: 16px 24px;
`

const accountInfoQuery = gql`
  query AccountInfoQuery($id: ID!) {
    account(id: $id) {
      ...BasicForm
      ...ClinicForm
    }
  }
  ${BasicForm.fragment}
  ${ClinicForm.fragment}
`

const updateAccountInfoMutation = gql`
  mutation UpdateAccountInfo($id: ID!, $payload: UpdateAccountInput!) {
    updateAccount(id: $id, payload: $payload) {
      ...BasicForm
      ...ClinicForm
    }
  }
  ${BasicForm.fragment}
  ${ClinicForm.fragment}
`

const AccountInfoForm = () => {
  const auth = useContext(authContext)
  if (!auth) {
    return null
  }

  const [update] = useMutation<
    UpdateAccountInfoMutation,
    UpdateAccountInfoMutationVariables
  >(updateAccountInfoMutation)

  const { data, loading } = useQuery<
    AccountInfoQueryQuery,
    AccountInfoQueryQueryVariables
  >(accountInfoQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    variables: {
      id: auth.id,
    },
  })
  if (loading) {
    return null
  }

  const handleSubmit = async (payload: UpdateAccountInput) => {
    try {
      // @TODO 這邊 type 沒有保護到，若是更改 schema 或者 form 欄位不容易察覺到問題
      await update({
        variables: {
          id: auth.id,
          payload,
        },
        update: (cache, { data }) => {
          if (data) {
            message.success('更新帳戶成功！')
          }
        },
      })
    } catch (error) {
      const e = error as GraphQLError
      message.error(`更新帳戶失敗: ${e.message}`)
    }
  }
  const account = data?.account
  if (!account) {
    return null
  }
  return (
    <PageContainer>
      <FormContainer>
        <FormTitle>基本資料</FormTitle>
        <BasicForm account={account} handleSubmit={handleSubmit} />
      </FormContainer>
      {(account.entityType === EntityType.Clinic ||
        account.entityType === EntityType.Doctor) && (
        <FormContainer>
          <FormTitle>配合診所</FormTitle>
          <ClinicForm account={account} />
        </FormContainer>
      )}
    </PageContainer>
  )
}

export default AccountInfoForm
