import { gql } from '@apollo/client'

import { MessageInfo } from '../../../components/conversation/fragments'

export const onMessageAddedSubscription = gql`
  subscription onMessageAddedSubscription($payload: SubscriptionPayload!) {
    onMessageAdded(payload: $payload) {
      cursor
      node {
        ...messageInfo
      }
    }
  }
  ${MessageInfo}
`
