import { gql, useQuery } from '@apollo/client'
import { Card } from 'antd'
import { map } from 'ramda'
import React from 'react'

const GET_EMPLOYEES = gql`
  {
    employees {
      docs {
        id
        name
      }
    }
  }
`

const List = () => {
  const { data, loading } = useQuery(GET_EMPLOYEES)

  if (loading) {
    return <div>Loading...</div>
  }

  const { employees } = data
  const { docs: items } = employees

  return (
    <div>
      <h1>SOV CRM</h1>
      <div
        style={{
          background: '#ECECEC',
          padding: '16px',
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        {map(
          (item) => (
            <Card
              size='small'
              key={item.id}
              title={item.name}
              bordered={false}
              style={{ width: 300 }}
            >
              <p>{item.id}</p>
              <p>{item.name}</p>
            </Card>
          ),
          items
        )}
      </div>
    </div>
  )
}

export default List
