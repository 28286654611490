import { CloseOutlined } from '@ant-design/icons'
import React, { useState } from 'react'
import styled from 'styled-components'

import { Patient } from '../../codegen/types'
import images from './images'

const PatientComplaintContainer = styled.div`
  padding: 16px 24px;
  cursor: pointer;
`
const PatientComplaintTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
`

const PatientComplaintContent = styled.div`
  margin-top: 16px;
  display: flex;
`
interface PatientMainComplaintProps {
  patient: Pick<Patient, 'id' | 'name' | 'chiefComplaint'>
}

const PatientMainComplaint = (props: PatientMainComplaintProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const { patient } = props
  const toggleCollapse = () => {
    setIsCollapsed((prevState) => !prevState)
  }
  return (
    <PatientComplaintContainer onClick={toggleCollapse}>
      <PatientComplaintTitle>病患：{patient.name}</PatientComplaintTitle>
      {!isCollapsed && (
        <PatientComplaintContent>
          <div style={{ flexShrink: 0 }}>主訴：</div>
          <div style={{ whiteSpace: 'pre-wrap' }}>{patient.chiefComplaint}</div>
        </PatientComplaintContent>
      )}
    </PatientComplaintContainer>
  )
}

const BlockContainer = styled.div`
  color: white;
  background-image: url(${images.headerBackground});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0px 0px 16px 16px;
`

const CloseButton = styled(CloseOutlined)`
  position: absolute;
  top: 16px;
  left: 16px;
`

const TitleContainer = styled.div`
  padding: 16px;
  width: 100%;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  min-height: 55px;
  text-align: center;
`

interface ConversationDrawerTitleProps {
  patient: Pick<Patient, 'id' | 'name' | 'chiefComplaint'>
  onClose: () => void
}

export const ConversationDrawerTitle = (
  props: ConversationDrawerTitleProps
) => {
  const { patient, onClose } = props

  return (
    <BlockContainer>
      <TitleContainer>
        <CloseButton type='close' onClick={onClose} />
        討論區
      </TitleContainer>
      <PatientMainComplaint patient={patient} />
    </BlockContainer>
  )
}
