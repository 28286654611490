import React from 'react'

import {
  Auxiliary,
  AuxiliaryPosition,
  AuxiliaryType,
} from '../../../codegen/types'
import {
  StrippingType,
  getIndex,
  getNeighborPosition,
  getQuadrant,
  getStrippingType,
  isTopTeeth,
  isValidAuxiliaryPosition,
  isValidAuxiliaryType,
} from '../../InstructionUtils'
import config from '../../InstructionUtils/config'

interface Props {
  item: Auxiliary
  handleRemoveStripping: (item: Auxiliary) => void
}

/* 顯示 stripping */
const Stripping = (props: Props) => {
  const { item, handleRemoveStripping } = props
  const { type, toothPosition, auxiliaryPosition, value } = item
  const [quadrant, index] = [
    getQuadrant(toothPosition),
    getIndex(toothPosition),
  ]
  // type 不對或者資料有問題則回傳 null
  const validTypes: (
    | AuxiliaryType.StrippingAll
    | AuxiliaryType.StrippingHalf
  )[] = [AuxiliaryType.StrippingAll, AuxiliaryType.StrippingHalf]
  const validPositions: (AuxiliaryPosition.Near | AuxiliaryPosition.Far)[] = [
    AuxiliaryPosition.Near,
    AuxiliaryPosition.Far,
  ]
  if (
    !isValidAuxiliaryType(type, validTypes) ||
    !isValidAuxiliaryPosition(auxiliaryPosition, validPositions) ||
    typeof quadrant !== 'number' ||
    typeof index !== 'number' ||
    typeof value !== 'number'
  ) {
    return null
  }

  // icon 約略大小
  const width = 9
  const height = 22

  const baseX = config.tooth[toothPosition].centerＸ
  const neighborPosition = getNeighborPosition(toothPosition, auxiliaryPosition)
  const neighborX = neighborPosition
    ? config.tooth[neighborPosition].centerＸ
    : config.xBoundery[toothPosition]
  // 計算出兩齒中間的座標
  const middleX = (baseX + neighborX) / 2
  // 距離中心點需要微調的 x 軸 offset
  const xOffset = isTopTeeth(toothPosition) ? width : -1 * width
  const cx = middleX + xOffset
  const middleY = isTopTeeth(toothPosition) ? config.top.cy : config.bottom.cy
  // 距離中心點需要微調的 y 軸 offset
  const yOffset = isTopTeeth(toothPosition) ? -5 : 5
  const cy = middleY + yOffset
  // 根據上下排/遠近心等資訊去翻轉以及移動 icon
  const translate = `translate(${cx}, ${cy})`
  const rotate = isTopTeeth(toothPosition)
    ? `rotate(180, ${cx}, ${cy})`
    : `rotate(0, ${cx}, ${cy})`
  // 預設大小太大，略為縮小
  const scale = 'scale(0.75)'

  const textHeight = 10
  // 調整 text 的位置，根據 cx, cy 去微調
  const textX = isTopTeeth(toothPosition) ? middleX - 10 : cx
  const textY = isTopTeeth(toothPosition)
    ? cy - height
    : cy + height + textHeight

  // 根據相關資訊取得 stripping 要顯示的圖案部位，分為 全部/左半部/右半部
  const strippingType = getStrippingType(type, toothPosition, auxiliaryPosition)

  return (
    <g
      onClick={() => handleRemoveStripping(item)}
      className='blue'
      key={`${type}-${toothPosition}-${auxiliaryPosition}-${value}`}
    >
      <g transform={rotate + translate + scale} className='stripping-editable'>
        {(strippingType === StrippingType.Full ||
          strippingType === StrippingType.Left) && (
          <path fill='#1890FF' d='M12 3L12 23 4 23z' />
        )}
        {(strippingType === StrippingType.Full ||
          strippingType === StrippingType.Right) && (
          <path fill='#1890FF' d='M12 3L20 23 12 23z' />
        )}
        <path stroke='#FFF' strokeLinecap='square' d='M12 2L12 25' />
      </g>
      <text x={textX} y={textY} fill='black'>
        {value}
      </text>
    </g>
  )
}

export default Stripping
