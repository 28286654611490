import { Form, Input, Radio } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { RadioProps } from 'antd/lib/radio'
import React, { FC } from 'react'

import { DoctorInstructionInput, Need, ToothType } from '../../../codegen/types'
import { formItemLayout, subFormItemLayout } from './layouts'

export interface FormDoctorInstructionFields {
  doctorInstruction: Pick<
    DoctorInstructionInput,
    | 'ipr'
    | 'tads'
    | 'otherOrthdontalTools'
    | 'functionalAppliance'
    | 'maintainSpace'
    | 'changeProsthesisToTemp'
    | 'extractToothWhenNoSpace'
  >
}
type FormDoctorInstructionInitialValues = Partial<FormDoctorInstructionFields>

const NeedRadioGroup: FC<RadioProps> = (props) => (
  <Radio.Group {...props}>
    <Radio value={Need.No}>No</Radio>
    <Radio value={Need.Yes}>Yes</Radio>
    <Radio value={Need.OnlyIfNeeded}>Only If Needed</Radio>
  </Radio.Group>
)

type PatientFormPage = 'create' | 'detailForm'

interface Props {
  form: FormInstance<FormDoctorInstructionFields>
  initialValues?: FormDoctorInstructionInitialValues
  page: PatientFormPage
}

const PatientFormDoctorInstruction = (props: Props) => {
  const { form, initialValues, page } = props

  return (
    <Form {...formItemLayout} form={form} initialValues={initialValues}>
      <Form.Item label='IPR' name={['doctorInstruction', 'ipr']}>
        <NeedRadioGroup />
      </Form.Item>

      <Form.Item label='tads' name={['doctorInstruction', 'tads']}>
        <NeedRadioGroup />
      </Form.Item>

      <Form.Item
        label='otherOrthdontalTools'
        name={['doctorInstruction', 'otherOrthdontalTools']}
      >
        <NeedRadioGroup />
      </Form.Item>

      <Form.Item
        label='functionalAppliance'
        name={['doctorInstruction', 'functionalAppliance']}
      >
        <NeedRadioGroup />
      </Form.Item>

      <Form.Item
        label='是否保留空間'
        name={['doctorInstruction', 'maintainSpace', 'isMaintainingSpace']}
      >
        <Radio.Group>
          <Radio value>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {(form) => (
          <Form.Item
            {...subFormItemLayout}
            label='空間保留在'
            name={['doctorInstruction', 'maintainSpace', 'note']}
            hidden={
              !form.getFieldValue([
                'doctorInstruction',
                'maintainSpace',
                'isMaintainingSpace',
              ])
            }
          >
            <Input.TextArea autoSize={{ minRows: 3 }} />
          </Form.Item>
        )}
      </Form.Item>

      <Form.Item
        label='是否將假牙換成臨時假牙'
        name={['doctorInstruction', 'changeProsthesisToTemp', 'need']}
      >
        <NeedRadioGroup />
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {(form) => (
          <Form.Item
            {...subFormItemLayout}
            label='備註'
            name={[
              'doctorInstruction',
              'changeProsthesisToTemp',
              'instruction',
            ]}
            hidden={
              form.getFieldValue([
                'doctorInstruction',
                'changeProsthesisToTemp',
                'need',
              ]) !== Need.No
            }
          >
            <Input.TextArea autoSize={{ minRows: 3 }} />
          </Form.Item>
        )}
      </Form.Item>

      <Form.Item
        label='若空間不足需要拔牙，是否可拔牙'
        name={['doctorInstruction', 'extractToothWhenNoSpace', 'intention']}
      >
        <NeedRadioGroup />
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {(form) => (
          <Form.Item
            {...subFormItemLayout}
            label='可以拔的牙齒種類'
            name={['doctorInstruction', 'extractToothWhenNoSpace', 'toothType']}
            hidden={
              form.getFieldValue([
                'doctorInstruction',
                'extractToothWhenNoSpace',
                'intention',
              ]) !== Need.Yes
            }
          >
            <Radio.Group>
              <Radio value={ToothType.Wisdom}>Wisdom</Radio>
              <Radio value={ToothType.Incisor}>Incisor</Radio>
              <Radio value={ToothType.Premolar}>Premolar</Radio>
            </Radio.Group>
          </Form.Item>
        )}
      </Form.Item>

      {page === 'create' && (
        <Form.Item label='醫師備忘' name={['doctorInstruction', 'note']}>
          <Input.TextArea
            autoSize={{ minRows: 3 }}
            placeholder='可以記錄你對病患的註記'
          />
        </Form.Item>
      )}
    </Form>
  )
}

export default PatientFormDoctorInstruction
