import qs from 'qs'

// @TODO 待確定這部分的估算邏輯，並且考慮抽到 common
export const getEstimatedMonth = (stepCount: number) => stepCount * 1.5

export const logout = () => {
  // window.location.replace('/')
  window.localStorage.removeItem('token')
  window.localStorage.removeItem('user')
}

// construct query params by url query
export const getObjectFromQueryString = (query) => qs.parse(query.slice(1))

// construct url query params
export const queryParams = (params) =>
  qs.stringify(params, { skipNulls: true, encode: false })
