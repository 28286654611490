import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { Content, ModalBody, Title } from '../../..'
import { CreatedOrderInfo } from '../..'
import completeImage from './complete.png'

interface Props {
  createdOrderInfo?: CreatedOrderInfo
}

const CompletedModalBody: FC<Props> = (props) => {
  const id = props.createdOrderInfo?.id
  const displayId = props.createdOrderInfo?.displayId

  return (
    <ModalBody>
      <div>
        <img src={completeImage} />
      </div>
      <Title>訂單已送出</Title>
      <Content>
        <div>
          我們已收到您的訂單 <Link to={`/orders/${id}`}>#{displayId}</Link>
          ，將盡快為您處理。{' '}
        </div>
        <div>
          您可以至 <Link to='/orders'>訂單列表</Link> 查詢訂單狀況。
        </div>
      </Content>
    </ModalBody>
  )
}

export default CompletedModalBody
