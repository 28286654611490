import { gql } from '@apollo/client'
import { getStageName } from '@sov/common'
import { Button, Popover } from 'antd'
import { join } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { InstructionPopoverFragment } from '../../codegen/types'
import InstructionCardPreview from '../InstructionCardPreview'
import StageName from '../StageName'

interface InstructionPopoverProps {
  handleRemove?: () => void
  instructionItem?: InstructionPopoverFragment
  withEditLink?: boolean
}

const InstructionPopover = (props: InstructionPopoverProps) => {
  const { handleRemove, instructionItem, withEditLink = false } = props
  const { t } = useTranslation()

  if (!instructionItem) {
    return null
  }

  const stageItem = instructionItem.stage
  return (
    <>
      <Popover
        title={`指示卡 ${join('', getStageName(t, stageItem))}`}
        content={
          <InstructionCardPreview
            instructionItem={instructionItem}
            handleRemove={handleRemove}
          />
        }
      >
        <Button size='small' type='dashed'>
          預覽
        </Button>
      </Popover>
      {withEditLink && (
        <Button size='small' style={{ marginLeft: 16 }}>
          <Link to={`/stages/${stageItem.id}/instruction`}>編輯</Link>
        </Button>
      )}
    </>
  )
}

InstructionPopover.fragments = {
  InstructionPopover: gql`
    fragment InstructionPopover on InstructionCard {
      stepCode
      instruction
      stage {
        ...StageName
      }
      ...InstructionCardPreview
    }
    ${StageName.fragment}
    ${InstructionCardPreview.fragments.InstructionCardPreview}
  `,
}

export default InstructionPopover
