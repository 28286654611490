import { Image } from 'antd'
import gql from 'graphql-tag'
import { omit } from 'ramda'
import React from 'react'

import { PhotoThumbnailListFragment } from '../../../../codegen/types'

/* ----- types and component ----- */
export type PhotoCategory = 'withBrace' | 'withoutBrace'

export type PhotoMapType = PhotoThumbnailListFragment

interface PhotoThumbnailListProps {
  photoMap: PhotoMapType
}

const PhotoThumbnailList = (props: PhotoThumbnailListProps) => {
  const { photoMap } = props

  const photoList = Object.values(omit(['__typename'])(photoMap))

  return (
    <Image.PreviewGroup>
      {photoList.map((photo, index) => (
        <Image
          style={{ marginRight: 4 }}
          key={index}
          width={36}
          height={36}
          src={photo?.thumbnailPath}
          preview={{
            src: photo?.path,
          }}
        />
      ))}
    </Image.PreviewGroup>
  )
}

PhotoThumbnailList.fragments = {
  PhotoThumbnailList: gql`
    fragment PhotoThumbnailList on TrackPhotoBase {
      frontLoose {
        ...TrackPhoto
      }
      frontTight {
        ...TrackPhoto
      }
      leftTight {
        ...TrackPhoto
      }
      rightTight {
        ...TrackPhoto
      }
    }
    fragment TrackPhoto on File {
      id
      path
      thumbnailPath
    }
  `,
}

export default PhotoThumbnailList
