import { Form } from '@ant-design/compatible'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { useLogin } from '@sov/ui'
import React from 'react'
import { Link } from 'react-router-dom'

import Layout from '../components/Layout'
import ExtraInformation from './ExtraInformation'
import LoginForm from './form/login'

const Login = (props: FormComponentProps) => {
  const { form } = props
  const { validateFields } = form
  const login = useLogin()

  const handleSubmit = async () => {
    validateFields(async (errors, values) => {
      if (!errors) {
        const payload = values as any
        await login(payload)
      }
    })
  }

  return (
    <>
      <Layout
        title='登入'
        description={
          <div style={{ color: '#1890ff' }}>
            <ExclamationCircleFilled style={{ marginRight: '4px' }} />
            第一次登入？請{' '}
            <Link to='/emailVerification'>
              <u>點選這裡</u>
            </Link>
          </div>
        }
        content={<LoginForm form={form} handleSubmit={handleSubmit} />}
      />
      <ExtraInformation />
    </>
  )
}

export default Form.create()(Login)
