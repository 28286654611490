import React from 'react'
import { Redirect } from 'react-router-dom'

import AccountDetail from '../../pages/account/AccountDetail'
import AccountCreateFromEmail from '../../pages/account/createFromEmail'
import AppointmentCalendar from '../../pages/appointment/AppointmentCalendar'
import EmailVerification from '../../pages/auth/EmailVerification/EmailVerification'
import EmailVerificationResult from '../../pages/auth/EmailVerification/EmailVerificationResult'
import EmailVerificationSent from '../../pages/auth/EmailVerification/EmailVerificationSent'
import Login from '../../pages/auth/Login/Login'
import Register from '../../pages/auth/Register/Register'
import RegisterComplete from '../../pages/auth/Register/RegisterComplete'
import InvalidResetPasswordUrl from '../../pages/auth/ResetPassword/InvalidResetPasswordUrl'
import RecoverPassword from '../../pages/auth/ResetPassword/RecoverPassword'
import RecoverPasswordSMSCheck from '../../pages/auth/ResetPassword/RecoverPasswordSMSCheck'
import ResetPassword from '../../pages/auth/ResetPassword/ResetPassword'
import ResetPasswordComplete from '../../pages/auth/ResetPassword/ResetPasswordComplete'
import ResetPasswordEmailSent from '../../pages/auth/ResetPassword/ResetPasswordEmailSent'
import SelectRecoverPasswordMethod from '../../pages/auth/ResetPassword/SelectRecoverPasswordMethod'
import ConversationList from '../../pages/conversation/ConversationList'
import Setting from '../../pages/employee/List'
import ErrorPage from '../../pages/error/index'
import InvoiceList from '../../pages/invoice/InvoiceList'
import NotificationIndex from '../../pages/notification/List'
import OrderDetail from '../../pages/order/OrderDetail'
import OrderList from '../../pages/order/OrderList'
import StageDetail from '../../pages/stage/StageDetail'
import StageDetailPreview from '../../pages/stage/StageDetail/Preview'
import StageModel from '../../pages/viewer'
import {
  patientRouteWithMenuKeys,
  patientRouteWithoutMenuKeys,
  patientWithMenuRoutes,
  patientWithoutMenuRoutes,
} from './PatientRoutes'

export const authRouteKeys = [
  'Login',
  'Register',
  'RegisterCompleted',
  'SelectRecoverPasswordMethod',
  'RecoverPassword',
  'RecoverPasswordSMSCheck',
  'ResetPassword',
  'ResetPasswordEmailSent',
  'ResetPasswordComplete',
  'InvalidResetPasswordUrl',
  'EmailVerification',
  'EmailVerificationSent',
  'EmailVerificationResult',
] as const

// 定義所有 route
export const routeKeys = [
  'Home',
  'Error',
  'Setting',
  // 病患相關
  ...patientRouteWithMenuKeys,
  ...patientRouteWithoutMenuKeys,
  // 工單相關
  ...['StageDetail', 'StageDetailPreview', 'StageModel'],
  // 出貨相關
  ...['InvoiceList', 'InvoiceDetail'],
  // 訂單相關
  ...['OrderList', 'OrderDetail'],
  // 約診相關
  ...['AppointmentCalendar'],
  // 通知相關
  ...['NotificationList'],
  // 帳戶相關
  ...['AccountCreateFromEmail', 'AccountDetail'],
  // 聊天室相關
  ...['ConversationList'],
  // 註冊相關
  ...authRouteKeys,
] as const

// 提供 route 做 type checking
export type RouteKey = typeof routeKeys[number]
export type AuthRouteKey = typeof authRouteKeys[number]

// 限制 route mapping 的形狀
export type Route<T extends string> = Record<
  T,
  {
    readonly title: string
    readonly subTitle?: string
    readonly path: string
    readonly isLayoutVisible?: boolean
    readonly component: any
  }
>

export const authRouteMap: Route<AuthRouteKey> = {
  Register: {
    title: '註冊',
    path: '/register',
    component: Register,
  },
  RegisterCompleted: {
    title: '註冊完成',
    path: '/registerComplete',
    component: RegisterComplete,
  },
  RecoverPassword: {
    title: '復原密碼',
    path: '/recoverPassword/:method',
    component: RecoverPassword,
  },
  ResetPassword: {
    title: '重設密碼',
    path: '/resetPassword/:accountId/:token',
    component: ResetPassword,
  },
  ResetPasswordEmailSent: {
    title: '重設密碼(已寄信)',
    path: '/resetPasswordEmailSent',
    component: ResetPasswordEmailSent,
  },
  RecoverPasswordSMSCheck: {
    title: '確認手機驗證碼',
    path: '/recoverPasswordSMSCheck/:phone',
    component: RecoverPasswordSMSCheck,
  },
  ResetPasswordComplete: {
    title: '重設密碼',
    path: '/resetPasswordComplete',
    component: ResetPasswordComplete,
  },
  SelectRecoverPasswordMethod: {
    title: '選擇復原密碼方式',
    path: '/selectRecoverPasswordMethod',
    component: SelectRecoverPasswordMethod,
  },
  InvalidResetPasswordUrl: {
    title: '錯誤重設密碼網址',
    path: '/invalidResetPasswordUrl',
    component: InvalidResetPasswordUrl,
  },
  Login: {
    title: '登入',
    path: '/login',
    component: Login,
  },
  EmailVerification: {
    title: '首次登入',
    path: '/emailVerification',
    component: EmailVerification,
  },
  EmailVerificationSent: {
    title: '首次登入',
    path: '/emailVerificationSent',
    component: EmailVerificationSent,
  },
  EmailVerificationResult: {
    title: '驗證信箱',
    path: '/emailVerificationResult/:accountId/:token',
    component: EmailVerificationResult,
  },
}

// 產生 route mapping
export const routeMap: Route<RouteKey> = {
  Home: {
    title: '首頁',
    path: '/',
    component: () => <Redirect to='/patients' />,
  },
  Error: {
    title: '錯誤',
    path: '/error',
    component: ErrorPage,
  },
  Setting: {
    title: '個人設定',
    path: '/setting',
    component: Setting,
  },
  StageDetail: {
    title: '報告詳情',
    path: '/stages/:stageId',
    component: StageDetail,
  },
  StageDetailPreview: {
    title: '報告預覽',
    path: '/stages/:stageId/preview',
    isLayoutVisible: false,
    component: StageDetailPreview,
  },
  StageModel: {
    title: '報告 viewer',
    path: '/viewer',
    isLayoutVisible: false,
    component: StageModel,
  },
  InvoiceList: {
    title: '出貨列表',
    path: '/invoices',
    component: InvoiceList,
  },
  InvoiceDetail: {
    title: '出貨詳情',
    path: '/invoices/:invoiceId',
    component: () => null,
  },
  OrderList: {
    title: '訂單列表',
    path: '/orders',
    component: OrderList,
  },
  OrderDetail: {
    title: '訂單詳情',
    path: '/orders/:orderId',
    component: OrderDetail,
  },
  AppointmentCalendar: {
    title: '約診中心',
    path: '/appointments',
    component: AppointmentCalendar,
  },
  NotificationList: {
    title: '通知中心',
    path: '/notifications',
    component: NotificationIndex,
  },
  AccountCreateFromEmail: {
    title: '從信箱新增帳戶',
    path: '/accounts/createFromEmail',
    component: AccountCreateFromEmail,
  },
  ConversationList: {
    title: '病患討論間',
    path: '/conversations',
    component: ConversationList,
  },
  AccountDetail: {
    title: '個人設定',
    path: '/settings',
    component: AccountDetail,
  },
  ...authRouteMap,
  ...patientWithMenuRoutes,
  ...patientWithoutMenuRoutes,
}
