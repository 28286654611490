import Icon from '@ant-design/icons'
import React from 'react'

const Svg = () => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      // eslint-disable-next-line max-len
      d='M18.3565 4.53414C17.3004 3.49142 15.8968 2.91288 14.3991 2.91064H14.3834C13.6637 2.91064 12.9619 3.04518 12.2937 3.30979C11.6009 3.5856 10.9776 3.99148 10.4439 4.51844L5.22198 9.68046C4.61884 10.2769 4.28925 11.073 4.29149 11.9229C4.29373 12.7705 4.62781 13.5665 5.23319 14.1653C5.83857 14.764 6.64126 15.0936 7.49776 15.0959H7.50449C8.35874 15.0959 9.15919 14.7685 9.76009 14.1765L14.343 9.64682C14.4955 9.49658 14.5785 9.29701 14.5785 9.08622C14.5785 8.87319 14.4955 8.67362 14.343 8.52562C14.1928 8.37538 13.991 8.29465 13.778 8.29465C13.565 8.29465 13.3632 8.37762 13.213 8.52562L8.63004 13.0553C8.33184 13.3513 7.93049 13.5127 7.50224 13.5127H7.5C7.06727 13.5105 6.66144 13.3445 6.36099 13.0463C6.0583 12.7458 5.89014 12.3467 5.89014 11.9206C5.8879 11.4968 6.05158 11.0999 6.35202 10.8039L11.574 5.63965C12.3184 4.9019 13.3184 4.49602 14.3856 4.49602H14.3968C15.4686 4.49826 16.4753 4.91086 17.2309 5.65534C17.9843 6.40206 18.4013 7.39545 18.4036 8.45386C18.4058 9.51228 17.9955 10.5034 17.2489 11.2412L11.7085 16.7238C10.6009 17.8137 9.12107 18.4146 7.5426 18.4146H7.52691C5.94171 18.4101 4.45292 17.8002 3.33634 16.6969C2.21975 15.5937 1.60316 14.1227 1.60091 12.5552C1.59643 10.9878 2.20629 9.52125 3.31391 8.42695L10.4664 1.35441C10.6188 1.20417 10.7018 1.0046 10.7018 0.793811C10.7018 0.580783 10.6188 0.381209 10.4664 0.23321C10.3139 0.0829689 10.1121 0 9.8991 0C9.6861 0 9.4843 0.0829689 9.33408 0.230968L2.18163 7.30575C1.46863 8.00987 0.917059 8.83731 0.540378 9.76118C0.179392 10.6537 -0.00222164 11.5955 2.05085e-05 12.5597C0.00226266 13.5262 0.188361 14.468 0.556073 15.3582C0.934996 16.2821 1.48881 17.1095 2.20629 17.8181C2.9193 18.5245 3.75786 19.0739 4.69283 19.4506C5.58969 19.8116 6.54261 19.9955 7.52242 20H7.5426C8.5157 20 9.45964 19.8206 10.352 19.4641C11.2847 19.0941 12.1211 18.5492 12.8341 17.8428L18.3744 12.3624C18.9058 11.8354 19.3184 11.221 19.5964 10.5326C19.8654 9.86658 20.0022 9.16695 20 8.44714C19.9932 6.96715 19.4103 5.57686 18.3565 4.53414Z'
      fill='black'
      fillOpacity='0.45'
    />
  </svg>
)

const Clip = (props) => <Icon {...props} component={Svg} />

export default Clip
