import { gql } from '@apollo/client'
import { Dropdown, Menu } from 'antd'
import { MenuItemProps } from 'antd/lib/menu/MenuItem'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  OrderButtonFragment,
  OrderType,
  PatientStatus,
} from '../../../../../codegen/types'
import CreateOrderIcon from '../../../../icon/CreateOrderIcon'
import { ActionButtonContainer } from '../'
import HintPopover from './HintPopover'

const Container = styled.div`
  display: flex;
  /** 
   * @todo: 修改此處樣式
   * 目前實作的病患表格和設計稿上的不一樣，這邊的樣式先做一些微調，等實作完設計稿上的病患表格之後，這邊的樣式要改成和設計稿一致
   */
  flex-direction: row;
  justify-content: space-between;
`

const CustomizedOrderActionButtonMenu = styled(Menu)`
  padding: 2px 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px -1px 6px rgba(0, 0, 0, 0.12), 0px 2px 5px rgba(0, 0, 0, 0.15);
`

const CustomizedOrderActionButtonMenuListItem = styled(Menu.Item)`
  padding: 8px;
  line-height: 24px;
  font-size: 14px;
`

interface OrderActionButtonMenuListProps {
  orderButtonCLickHandler: (orderType: OrderType) => MenuItemProps['onClick']

  patientStatus: PatientStatus
}

const OrderActionButtonMenuList = (props: OrderActionButtonMenuListProps) => {
  const { orderButtonCLickHandler, patientStatus } = props
  const isNewPatient = patientStatus === PatientStatus.Initial
  const isOnEval = patientStatus === PatientStatus.OnEval
  const isOnPrint = patientStatus === PatientStatus.OnPrint
  const { t } = useTranslation()

  return (
    <CustomizedOrderActionButtonMenu>
      {(isOnPrint || isOnEval) && (
        <CustomizedOrderActionButtonMenuListItem
          onClick={() => {
            orderButtonCLickHandler(OrderType.NormalPrint)
          }}
        >
          {t(`order.type.${OrderType.NormalPrint}`)}
        </CustomizedOrderActionButtonMenuListItem>
      )}
      {isOnPrint && (
        <CustomizedOrderActionButtonMenuListItem
          onClick={() => {
            orderButtonCLickHandler(OrderType.Remodel)
          }}
        >
          {t(`order.type.${OrderType.Remodel}`)}
        </CustomizedOrderActionButtonMenuListItem>
      )}
      {(isNewPatient || isOnEval) && (
        <CustomizedOrderActionButtonMenuListItem
          onClick={() => {
            orderButtonCLickHandler(OrderType.Report)
          }}
        >
          {t(`order.type.${OrderType.Report}`)}
        </CustomizedOrderActionButtonMenuListItem>
      )}
    </CustomizedOrderActionButtonMenu>
  )
}

type OrderButtonProps = OrderActionButtonMenuListProps & {
  latestOrderDisplayId?: string
  patientId: string
  latestOrder?: OrderButtonFragment
  canCreateOrder: boolean
}

const OrderButton = (props: OrderButtonProps) => {
  const {
    orderButtonCLickHandler,
    latestOrder,
    patientStatus,
    latestOrderDisplayId,
    canCreateOrder,
  } = props
  const latestOrderId = latestOrder?.id
  const isCompletedOrInactive =
    patientStatus === PatientStatus.Completed ||
    patientStatus === PatientStatus.Inactive
  const canShowActionButtonMenuList = canCreateOrder && !isCompletedOrInactive
  const overlay = canShowActionButtonMenuList ? (
    <OrderActionButtonMenuList
      orderButtonCLickHandler={orderButtonCLickHandler}
      patientStatus={patientStatus}
    />
  ) : (
    <HintPopover
      orderId={latestOrderId}
      displayId={latestOrderDisplayId}
      isCompletedOrInactive={isCompletedOrInactive}
    />
  )

  return (
    <Container>
      <Dropdown overlay={overlay}>
        <ActionButtonContainer isButtonDisabled={!canShowActionButtonMenuList}>
          <CreateOrderIcon style={{ color: 'rgba(0, 0, 0, 0.65)' }} />
          <span>下單</span>
        </ActionButtonContainer>
      </Dropdown>
    </Container>
  )
}

OrderButton.fragments = {
  OrderButton: gql`
    fragment OrderButton on Order {
      id
      displayId
    }
  `,
}

export default OrderButton
