import { gql } from '@apollo/client'

const patientsSelectQuery = gql`
  query PatientsSelectQuery(
    $query: PatientsQuery
    $page: Int
    $limit: Int
    $sort: String
  ) {
    patients(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        id
        name
      }
    }
  }
`

const doctorsSelectQuery = gql`
  query DoctorsSelectQuery(
    $query: DoctorsQuery
    $page: Int
    $limit: Int
    $sort: String
  ) {
    doctors(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        id
        name
      }
    }
  }
`

const employeesSelectQuery = gql`
  query EmployeesSelectQuery(
    $query: EmployeesQuery
    $page: Int
    $limit: Int
    $sort: String
  ) {
    employees(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        id
        name
        role
        taskOwner
      }
    }
  }
`

const clinicsSelectQuery = gql`
  query ClinicsSelectQuery(
    $query: ClinicsQuery
    $page: Int
    $limit: Int
    $sort: String
  ) {
    clinics(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        id
        name
      }
    }
  }
`

export {
  clinicsSelectQuery,
  doctorsSelectQuery,
  employeesSelectQuery,
  patientsSelectQuery,
}
