import { Modal } from 'antd'
import React from 'react'

interface HintModalProps {
  isModalVisible: boolean
  hintModalBody: React.ReactNode
  modalFooter: JSX.Element[] | null
}

const HintModal = (props: HintModalProps) => {
  const { modalFooter, isModalVisible, hintModalBody } = props

  return (
    <Modal
      bodyStyle={{
        padding: 0,
      }}
      closable={false}
      footer={modalFooter}
      visible={isModalVisible}
      width={480}
    >
      {hintModalBody}
    </Modal>
  )
}

export default HintModal
