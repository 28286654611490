import React, { useEffect, useState } from 'react'
import { useLocation, useRouteMatch } from 'react-router-dom'

import Page from '../../../components/page'
import AccountMenu from './components/AccountMenu'
import AppDescription from './components/AppDescription'
import { TabKey, isTabkey, tabKeys } from './components/TabConfig'
import TabContent from './components/TabContent'

interface RouteProps {
  accountId: string
}

const AccountDetail = () => {
  const match = useRouteMatch<RouteProps>()
  const accountId = match.params.accountId
  const { hash } = useLocation()
  const [tabKey, setTabKey] = useState<TabKey>(tabKeys[0])

  useEffect(() => {
    if (hash === '') {
      setTabKey(tabKeys[0])
    } else {
      const key = hash.slice(1)
      if (isTabkey(key)) {
        setTabKey(key)
      }
    }
  }, [hash])

  return (
    <div>
      <Page
        header={<AccountMenu accountId={accountId} selectedKeys={[tabKey]} />}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ flex: 1 }}>
            <TabContent accountId={accountId} tabKey={tabKey} />
          </div>
          <AppDescription />
        </div>
      </Page>
    </div>
  )
}

export default AccountDetail
