import Icon from '@ant-design/icons'
import React from 'react'

const Svg = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      // eslint-disable-next-line max-len
      d='M6.5 5V7H17.5V5H6.5ZM19 7V4.5C19 3.94772 18.5523 3.5 18 3.5H6C5.44772 3.5 5 3.94772 5 4.5V7H4C2.89543 7 2 7.89543 2 9V16C2 16.9319 2.63739 17.715 3.5 17.937C3.65981 17.9781 3.82735 18 4 18H5V22C5 22.5523 5.44772 23 6 23H18C18.5523 23 19 22.5523 19 22V18H20C20.1726 18 20.3402 17.9781 20.5 17.937C21.3626 17.715 22 16.9319 22 16V9C22 7.89543 21.1046 7 20 7H19ZM19 16.5H20C20.2761 16.5 20.5 16.2761 20.5 16V9C20.5 8.72386 20.2761 8.5 20 8.5H19H5H4C3.72386 8.5 3.5 8.72386 3.5 9V16C3.5 16.2761 3.72386 16.5 4 16.5H5L5 15C5 14.4477 5.44772 14 6 14L18 14C18.5523 14 19 14.4477 19 15V16.5ZM17.5 21.5L17.5 15.5L6.5 15.5L6.5 21.5H17.5ZM16 17.5C16 17.2239 15.7761 17 15.5 17L8.5 17C8.22386 17 8 17.2239 8 17.5C8 17.7761 8.22386 18 8.5 18L15.5 18C15.7761 18 16 17.7761 16 17.5ZM15.5 19C15.7761 19 16 19.2239 16 19.5C16 19.7761 15.7761 20 15.5 20H8.5C8.22386 20 8 19.7761 8 19.5C8 19.2239 8.22386 19 8.5 19L15.5 19ZM18 12C18 12.5523 17.5523 13 17 13C16.4477 13 16 12.5523 16 12C16 11.4477 16.4477 11 17 11C17.5523 11 18 11.4477 18 12Z'
      fill='black'
      fillOpacity='0.45'
    />
  </svg>
)

const Printer = (props) => <Icon {...props} component={Svg} />

export default Printer
