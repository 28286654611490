import { DownOutlined } from '@ant-design/icons'
import { Dropdown, Menu } from 'antd'
import { MenuProps } from 'antd/lib/menu'
import { includes, keys, map } from 'ramda'
import React from 'react'
import styled from 'styled-components'

import {
  Black,
  ClearBlue,
  DividerGrey,
  LightBlue,
  Secondary,
} from '../constant/color'
import { MeshColor, MeshColorKey } from '../constant/meshColor'

interface ColorBlockProps {
  color: MeshColor
  width?: string
}

const ColorBlock = styled.div<ColorBlockProps>`
  background-color: ${(props) => props.color};
  border: ${(props) =>
    props.color === MeshColor.White ? `1px solid ${Black}` : 'none'};
  width: 16px;
  height: 16px;
`
const Label = styled.div`
  color: ${Secondary};
  line-height: 1.57;
  margin-left: 4px;
`

const CustomStyledMenu = styled(Menu)<MenuProps>`
  border: 1px solid ${DividerGrey};
  border-radius: 4px;
  padding: 0;
  width: 80px;

  /** antd drop down menu 的 classname 有兩種可能 */
  /** 這邊需要寫成這樣權重才有辦法蓋過 ant design 原本的 menu item style */
  li.ant-menu-item[role='menuitem'],
  li.ant-dropdown-menu-item[role='menuitem'] {
    align-items: center;
    border-bottom: 1px solid ${DividerGrey};
    display: inline-flex;
    flex-direction: row;
    height: 100%;
    margin: 0;
    padding-left: 8px;
    padding-right: 0;
    width: 100%;
  }
`

type ColorSelectMenuProps = MenuProps

const ColorSelectMenu = (props: ColorSelectMenuProps) => {
  return (
    /**
     * Dropdown 的 children component 若定義在使用 Dropdown component 以外地方的時候
     * 需要額外傳入 ant design component 傳下來的 props (...props)
     * 否則 Dropdown 相關 event handler (例如： visible close, hover style)無法正常觸發
     */
    <CustomStyledMenu {...props}>
      {map(
        (colorKey: MeshColorKey) => {
          return (
            <Menu.Item key={MeshColor[colorKey]}>
              <ColorBlock color={MeshColor[colorKey]} />
              <Label>{colorKey}</Label>
            </Menu.Item>
          )
        },
        keys(MeshColor).filter((x) =>
          includes(x, ['White', 'Purple', 'Grey', 'Green', 'Yellow'])
        )
      )}
    </CustomStyledMenu>
  )
}

const SelectedOptionWrapper = styled.div`
  align-items: center;
  border: 1px solid ${DividerGrey};
  border-radius: 4px;
  display: inline-flex;
  justify-content: space-evenly;
  width: 40px;

  &:hover {
    border: 1px solid ${ClearBlue};
  }

  &:active {
    border: 1px solid ${ClearBlue};
    box-shadow: 0 0 0 2px ${LightBlue};
  }
`

const ColorBlockWrapper = styled.div`
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  padding: 3px 0;
  width: 58px;
`

const IconImageWrapper = styled.div`
  align-items: center;
  display: flex;
  font-size: 10px;
  height: 100%;
  justify-content: center;
  width: 22px;
`

interface SelectedOptionProps {
  color: MeshColor
}

const SelectedOption = (props: SelectedOptionProps) => {
  const { color, ...restProps } = props
  /**
   * Dropdown 的 children component 若定義在使用 Dropdown component 以外地方的時候
   * 需要額外傳入 ant design component 傳下來的 props (...restProps)
   * 否則 Dropdown 相關 event handler (例如： visible open)無法正常觸發
   */
  return (
    <SelectedOptionWrapper {...restProps}>
      <ColorBlockWrapper>
        <ColorBlock color={color} />
      </ColorBlockWrapper>
      <IconImageWrapper>
        <DownOutlined />
      </IconImageWrapper>
    </SelectedOptionWrapper>
  )
}

interface ColorSelectProps {
  color?: MeshColor
  disabled?: boolean
  handleChangeColor: (color: MeshColor) => void
}

const ColorSelect = (props: ColorSelectProps) => {
  const { color = MeshColor.White, disabled = false, handleChangeColor } = props

  const handleClick = (key) => {
    const color = key as MeshColor
    handleChangeColor(color)
  }

  return (
    <Dropdown
      overlay={() => (
        <ColorSelectMenu
          onClick={({ key, domEvent }) => {
            handleClick(key)
            domEvent.stopPropagation()
          }}
        />
      )}
      disabled={disabled}
    >
      <SelectedOption color={color} />
    </Dropdown>
  )
}

export default ColorSelect
