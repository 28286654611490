import { ApolloQueryResult, gql, useMutation } from '@apollo/client'
import { Button, Col, Row, message } from 'antd'
import moment from 'moment'
import React from 'react'

import {
  AppointmentFragment,
  AppointmentsQueryQuery,
  AppointmentsQueryQueryVariables,
  RemoveAppointmentMutation,
  RemoveAppointmentMutationVariables,
} from '../../codegen/types'
import EntityName from '../../components/EntityName'
import { removeAppointmentMutation } from './AppointmentCalendar'

interface Props {
  className?: string
  appointment: AppointmentFragment
  handleSelect: (AppointmentFragment) => void
  reset: () => void
  refetch: (
    variables?: AppointmentsQueryQueryVariables | undefined
  ) => Promise<ApolloQueryResult<AppointmentsQueryQuery>>
}

const AppointmentCard = (props: Props) => {
  const { className, appointment, handleSelect, reset, refetch } = props

  const [removeAppointment] = useMutation<
    RemoveAppointmentMutation,
    RemoveAppointmentMutationVariables
  >(removeAppointmentMutation)

  const handleRemove = async (id) => {
    await removeAppointment({
      variables: {
        id,
      },
      update: async (_cache, { data }) => {
        if (data) {
          reset()
          await refetch()
          await message.info('已刪除約診')
        }
      },
    })
  }

  return (
    <div className={className}>
      <div className='appointment-card-header'>
        <div className='name'>{appointment.patient.name}</div>
        <div className='date'>
          {moment(appointment.startDate).isSame(
            moment(appointment.startDate).startOf('d')
          )
            ? '-'
            : `${moment(appointment.startDate).format('HH:mm')} ~ ${moment(
                appointment.endDate
              ).format('HH:mm')}`}
        </div>
        <Button
          danger
          type='primary'
          size='small'
          onClick={() => handleRemove(appointment.id)}
          style={{ marginRight: 8 }}
        >
          刪除
        </Button>
        <Button
          type='default'
          size='small'
          onClick={() => handleSelect(appointment)}
        >
          編輯
        </Button>
      </div>
      <div className='appointment-card-body'>
        <Row>
          <div className='field'>
            <span className='field-title'>約診診所</span>
            <span className='field-value'>
              {appointment.patient.clinic.name}
            </span>
          </div>
          <div className='field'>
            <span className='field-title'>約診醫生</span>
            <span className='field-value'>
              {appointment.patient.doctor.name}
            </span>
          </div>
        </Row>
        <Row>
          <Col>
            <div className='field'>
              <span className='field-title'>備註</span>
              <span className='field-value'>{appointment.description}</span>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

AppointmentCard.fragment = {
  Appointment: gql`
    fragment Appointment on Appointment {
      id
      startDate
      endDate
      description
      isAttended
      patient {
        id
        name
        clinic {
          id
          name
        }
        doctor {
          id
          name
        }
      }
      creator {
        ...EntityName
      }
    }
    ${EntityName.fragments.EntityName}
  `,
}

export default AppointmentCard
