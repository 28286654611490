import { Button } from '@sov/ui'
import { Space } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
`

const CustomizedButton = styled(Button)`
  width: 100%;
`

interface ButtonListProps {
  handleContactUsButtonClick: ButtonProps['onClick']
  handleOrderEditButtonClick: ButtonProps['onClick']
}

const ButtonList = (props: ButtonListProps) => {
  const { handleContactUsButtonClick, handleOrderEditButtonClick } = props

  return (
    <Container>
      <Space direction='vertical' size={16} style={{ width: '100%' }}>
        <CustomizedButton
          buttonType='secondary'
          onClick={handleOrderEditButtonClick}
        >
          編輯訂單
        </CustomizedButton>
        <CustomizedButton onClick={handleContactUsButtonClick}>
          聯絡 SOV
        </CustomizedButton>
      </Space>
    </Container>
  )
}

export default ButtonList
