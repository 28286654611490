import { EditOutlined } from '@ant-design/icons'
import { gql, useMutation, useQuery } from '@apollo/client'
import { ErrorHandling } from '@sov/common'
import { Button, GqlUpload, MultiLine } from '@sov/ui'
import { Card, Col, Form, Row, Space, message } from 'antd'
import { evolve, pick, pipe } from 'ramda'
import React, { FC, MouseEventHandler, useState } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import {
  PatientDetailFormQuery,
  PatientDetailFormQueryVariables,
  UpdatePatientInput,
  UpdatePatientMutation,
  UpdatePatientMutationVariables,
} from '../../../codegen/types'
import PatientInfoCard from '../../../components/card/Patient'
import PatientConversationDrawer from '../../../components/conversation/ConversationDrawer'
import useDrawer from '../../../components/conversation/useDrawer'
import { payloadTransformer } from '../../../components/form/patient'
import PatientFormDoctorInstruction from '../../../components/form/patient/DoctorInstruction'
import PatientFormIntraOral from '../../../components/form/patient/IntraOral'
import PatientFormMalocclusions from '../../../components/form/patient/Malocclusions'
import PatientFormPhoto from '../../../components/form/patient/Photo'
import PatientFormProfile from '../../../components/form/patient/Profile'
import PatientFormSymptoms from '../../../components/form/patient/Symptoms'
import Page from '../../../components/page'
import BreadcrumbCreator from '../../../components/page/BreadcrumbCreator'
import Title from '../../../components/page/Title'
import { secondaryText } from '../../../utils/color'
import NoteEditModal, {
  FormFields as NoteEditModalFormFields,
} from './NoteEditModal'

const fragment = gql`
  fragment PatientDetail on Patient {
    id
    photos {
      frontFace {
        id
        filename
        path
        thumbnailPath
      }
      frontFaceWithTeeth {
        id
        filename
        path
        thumbnailPath
      }
      sideFace45 {
        id
        filename
        path
        thumbnailPath
      }
      sideFace45WithTeeth {
        id
        filename
        path
        thumbnailPath
      }
      sideFace {
        id
        filename
        path
        thumbnailPath
      }
      sideFaceWithTeeth {
        id
        filename
        path
        thumbnailPath
      }
      frontInside {
        id
        filename
        path
        thumbnailPath
      }
      leftInside {
        id
        filename
        path
        thumbnailPath
      }
      rightInside {
        id
        filename
        path
        thumbnailPath
      }
      upperJawInside {
        id
        filename
        path
        thumbnailPath
      }
      lowerJawInside {
        id
        filename
        path
        thumbnailPath
      }
      pano {
        id
        filename
        path
        thumbnailPath
      }
      ceph {
        id
        filename
        path
        thumbnailPath
      }
    }
    profile {
      mandibularMidlineToFacialMidline {
        offset
        instruction
      }
      occulusalPlane {
        occulusalPlaneStatus
        instruction
      }
      estheticLine {
        lipsEstheticLineRelation
        instruction
      }
      cephAnalysis
      faceRatio
      occulusalMuscle
      hasGummySmile
      smileLine
      hasBuccalCorridor
    }
    intraOral {
      teethStatus {
        position
        type
      }
      missingTeeth
      teethDontMove
      teethWillExtract
      temporomandibularJointDisordersTreatStatus
      periodontalDiseaseTreatStatus
      residualRoot {
        hasResidualRoot
        instruction
      }
      impactedTooth {
        hasImpactedTooth
        instruction
      }
      uneruptedWisdomTooth {
        hasUneruptedWisdomTooth
        instruction
      }
      maxillarMidlineToFacialMidline {
        offset
        instruction
      }
      mandibularMidlineToMaxillarMidline {
        offset
        instruction
      }
    }
    symptoms {
      leftMolarRelationship
      rightMolarRelationship
      anteriorCrossbite
      anteriorCrowding
      bimaxillaryProtrusion
      deepBite
      highCanine
      openBite
      posteriorCrossbite
      posteriorCrowding
      vShapeArch
    }
    doctorInstruction {
      note
      ipr
      tads
      otherOrthdontalTools
      functionalAppliance
      maintainSpace {
        isMaintainingSpace
        note
      }
      changeProsthesisToTemp {
        need
        instruction
      }
      extractToothWhenNoSpace {
        intention
        toothType
      }
    }
    chiefComplaint
    ...PatientInfoCard
    ...PatientConversation
  }
  ${PatientInfoCard.fragment}
  ${PatientConversationDrawer.fragment.PatientConversation}
`

export const patientDetailFormQuery = gql`
  query PatientDetailForm($id: ID!) {
    patient(id: $id) {
      ...PatientDetail
    }
  }
  ${fragment}
`

const updatePatientMutation = gql`
  mutation UpdatePatient($id: ID!, $payload: UpdatePatientInput!) {
    updatePatient(id: $id, payload: $payload) {
      ...PatientDetail
    }
  }
  ${fragment}
`

const CustomizedButton = styled(Button)`
  height: 38px;
`

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledEditIcon = styled(EditOutlined)`
  font-size: 24px;

  &:hover {
    color: #40a9ff;
  }
`

interface EditIconProps {
  onClick: MouseEventHandler
}

const EditIcon: FC<EditIconProps> = (props) => {
  const { onClick } = props

  return <StyledEditIcon onClick={onClick} />
}

const CardSubtitle = styled.div`
  color: ${secondaryText};
  margin-bottom: 8px;
`

interface RouterProps {
  patientId: string
}

const PatientDetailForm = () => {
  const [form] = Form.useForm()

  const drawer = useDrawer()
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const match = useRouteMatch<RouterProps>()
  const {
    params: { patientId },
  } = match
  const { data, loading, refetch } = useQuery<
    PatientDetailFormQuery,
    PatientDetailFormQueryVariables
  >(patientDetailFormQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    fetchPolicy: 'no-cache',
    variables: {
      id: patientId,
    },
  })

  const [updatePatient] = useMutation<
    UpdatePatientMutation,
    UpdatePatientMutationVariables
  >(updatePatientMutation)

  const [isModalVisible, setIsModalVisible] = useState(false)

  const patient = data?.patient

  if (loading) {
    return <Page loading={loading} />
  }

  if (!patient) {
    return (
      <Page>
        <>無病患資料</>
      </Page>
    )
  }

  const photosInitialValues = pipe(
    pick(['photos']),
    evolve<any>({
      photos: {
        frontFace: GqlUpload.initialValueTransformer,
        sideFace45: GqlUpload.initialValueTransformer,
        sideFace: GqlUpload.initialValueTransformer,
        frontFaceWithTeeth: GqlUpload.initialValueTransformer,
        sideFace45WithTeeth: GqlUpload.initialValueTransformer,
        sideFaceWithTeeth: GqlUpload.initialValueTransformer,
        rightInside: GqlUpload.initialValueTransformer,
        frontInside: GqlUpload.initialValueTransformer,
        leftInside: GqlUpload.initialValueTransformer,
        upperJawInside: GqlUpload.initialValueTransformer,
        lowerJawInside: GqlUpload.initialValueTransformer,
        ceph: GqlUpload.initialValueTransformer,
        pano: GqlUpload.initialValueTransformer,
      },
    })
  )(patient)

  const handleModalClose = () => {
    setIsModalVisible(false)
  }

  const handleDoctorInstructionNoteEdit = () => {
    setIsModalVisible(true)
  }

  const handleDoctorInstructionNoteUpdate = async () => {
    try {
      const patientFormValues =
        (await form.validateFields()) as NoteEditModalFormFields
      const payload: UpdatePatientInput = {
        doctorInstruction: {
          ...patient.doctorInstruction,
          note: patientFormValues.doctorInstruction?.note ?? '',
        },
      }
      const id = patientId
      await updatePatient({
        variables: {
          id,
          payload,
        },
        update: (cache, { data }) => {
          if (data) {
            refetch()
            message.info('已更新醫生備忘')
          }
        },
      })
    } catch (e) {
      message.error(`更新醫生備忘失敗: ${e.message}`)
    } finally {
      setIsModalVisible(false)
    }
  }

  const handleSubmit = async () => {
    try {
      // 檢查欄位
      await form.validateFields()
      // 只獲取有更動的欄位
      const values = form.getFieldsValue(true, ({ touched }) => touched)
      const payload: UpdatePatientInput = {
        ...payloadTransformer(values),
        doctorInstruction: {
          ...payloadTransformer(values).doctorInstruction,
          note: patient.doctorInstruction?.note ?? '',
        },
      }
      const id = patientId
      await updatePatient({
        variables: {
          id,
          payload,
        },
        update: (cache, { data }) => {
          if (data) {
            message.info('已更新病患詳情')
          }
        },
      })
    } catch (e) {
      message.error(`更新失敗: ${e.message}`)
    }
  }

  const header = (
    <HeaderContainer>
      <div>
        <BreadcrumbCreator
          routes={[
            { key: 'Home' },
            { key: 'PatientList' },
            {
              key: 'PatientDetail',
              render: () => (
                <Link to={`/patients/${patient.id}`}>{patient.name}</Link>
              ),
            },
            {
              key: 'PatientDetailForm',
              render: () => (
                <Link to={`/patients/${patient.id}/form`}>
                  {patient.name}詳情
                </Link>
              ),
            },
          ]}
        />
        <Title
          route={{
            key: 'PatientDetailForm',
            render: () => {
              return <>{patient.name}詳情</>
            },
            renderSubtitle: () => {
              return <>以醫師提供給 SOV 的資料為主</>
            },
          }}
        />
      </div>
      <CustomizedButton buttonType='primary' onClick={handleSubmit}>
        更新儲存
      </CustomizedButton>
    </HeaderContainer>
  )

  return (
    <Page header={header} isPageHeaderFixed>
      <Row style={{ display: 'flex' }}>
        <Col style={{ flex: 1 }}>
          <Card title='照片'>
            <PatientFormPhoto form={form} initialValues={photosInitialValues} />
          </Card>
          <Row style={{ marginTop: 16 }} />
          <Card title='顏面觀'>
            <PatientFormProfile form={form} initialValues={patient} />
          </Card>
          <Row style={{ marginTop: 16 }} />
          <Card title='口內觀'>
            <PatientFormIntraOral form={form} initialValues={patient} />
          </Card>
          <Row style={{ marginTop: 16 }} />
          <Card title='矯正症狀'>
            <PatientFormMalocclusions form={form} initialValues={patient} />
          </Card>
          <Row style={{ marginTop: 16 }} />
          <Card title='症狀'>
            <PatientFormSymptoms form={form} initialValues={patient} />
          </Card>
          <Row style={{ marginTop: 16 }} />
          <Card title='治療指示'>
            <PatientFormDoctorInstruction
              form={form}
              initialValues={patient}
              page='detailForm'
            />
          </Card>
        </Col>
        <Col style={{ maxWidth: 320, minWidth: 320, marginLeft: 16 }}>
          <Space direction='vertical' size={16} style={{ display: 'flex' }}>
            <Card title='基本資料'>
              <PatientInfoCard patient={patient} />
            </Card>
            <Card
              title='備註'
              extra={<EditIcon onClick={handleDoctorInstructionNoteEdit} />}
            >
              <CardSubtitle>醫生備忘:</CardSubtitle>
              <MultiLine text={patient.doctorInstruction.note} />
              <NoteEditModal
                form={form}
                initialValues={patient}
                onCancel={handleModalClose}
                onOk={handleDoctorInstructionNoteUpdate}
                visible={isModalVisible}
              />
            </Card>
            <a href='mailto:info@sov.dental'>對資料有疑問？請聯繫 SOV 舒服美</a>
          </Space>
        </Col>
      </Row>
      <PatientConversationDrawer patient={patient} drawer={drawer} />
    </Page>
  )
}

export default PatientDetailForm
