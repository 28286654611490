import React from 'react'
import styled from 'styled-components'

import VerticalLine from './VerticalLine'

const dotMargin = 4
const dotHeight = 8

const DotWithLineContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2px 12px;
`

const DotIconContainer = styled.div`
  margin: ${dotMargin}px 0;
`

const DotIcon = styled.div`
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  height: ${dotHeight}px;
  width: ${dotHeight}px;
`

const DotWithLine = () => {
  return (
    <DotWithLineContainer>
      <VerticalLine />
      <DotIconContainer>
        <DotIcon />
      </DotIconContainer>
      <VerticalLine />
    </DotWithLineContainer>
  )
}

export { dotHeight, dotMargin }

export default DotWithLine
