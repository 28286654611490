import { FormQuery, MyStringParam } from '@sov/common'
import { Empty } from 'antd'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import Page from '../../components/page'
import BreadcrumbCreator from '../../components/page/BreadcrumbCreator'
import Title from '../../components/page/Title'
import LatestMessageDrawer from './LatestMessageDrawer'
import LatestMessageList from './LatestMessageList'

const MessageIndexContainer = styled.div`
  display: flex;
  padding: 16px;
  background-color: white;
`

const queryString = {
  conversationId: MyStringParam,
}

const MessageIndex = () => {
  const { formQuery } = FormQuery.useFormQuery(queryString)
  const { conversationId } = formQuery

  const [selectedConversation, setSelectedConversation] = useState('')

  const handleBlockClick = (conversation: string) => {
    setSelectedConversation(conversation)
  }

  useEffect(() => {
    if (conversationId) {
      setSelectedConversation(conversationId)
    }
  }, [conversationId])
  return (
    <Page
      header={
        <>
          <BreadcrumbCreator
            routes={[{ key: 'Home' }, { key: 'ConversationList' }]}
          />
          <Title route={{ key: 'ConversationList' }} />
        </>
      }
    >
      <MessageIndexContainer>
        <LatestMessageList
          selectedConversation={selectedConversation}
          handleBlockClick={handleBlockClick}
        />
        {selectedConversation ? (
          <LatestMessageDrawer selectedConversation={selectedConversation} />
        ) : (
          <Empty
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: '200px',
            }}
            description='尚無聊天訊息，請至病患頁面新增'
          />
        )}
      </MessageIndexContainer>
    </Page>
  )
}

export default MessageIndex
