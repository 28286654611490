import { useRequest } from '@sov/ui'
import { pathOr } from 'ramda'
import React, { useEffect, useState } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'

import Layout from '../components/Layout'

const EmailVerificationResult = () => {
  const match = useRouteMatch()

  const request = useRequest()

  const accountId = pathOr('', ['params', 'accountId'], match)
  const token = pathOr('', ['params', 'token'], match)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isInit, setIsInit] = useState(false)
  useEffect(() => {
    // 進到頁面先檢查 token
    const checkToken = async () => {
      const res = await request.post(`/auth/confirmVerificationEmailToken`, {
        accountId,
        token,
      })

      if (res.status === 200) {
        setIsSuccess(true)
      } else if (res.status === 404) {
        setIsSuccess(false)
      }
      setIsInit(true)
    }
    checkToken()
  }, [])
  if (!isInit) {
    return null
  }
  return isSuccess ? (
    <Layout
      title='驗證成功'
      description={<div>您的信箱驗證成功，請重新登入</div>}
      backLink='/login'
    />
  ) : (
    <Layout
      title='驗證失敗'
      description={
        <div>
          驗證網址已經失效，請
          <Link to='/emailVerification'>重新寄送驗證信</Link>
        </div>
      }
      backLink='/login'
    />
  )
}

export default EmailVerificationResult
