import { ReloadOutlined } from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'
import { ErrorHandling, TableQuery } from '@sov/common'
import { Button } from 'antd'
import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'

import {
  PatientTracksQueryQuery,
  PatientTracksQueryQueryVariables,
} from '../../../codegen/types'
import TrackTableList from '../../../components/table/patient/TrackList'
import {
  PhotoCategory,
  PhotoMapType,
} from '../../../components/table/patient/TrackList/PhotoThumbnailList'
import NoScrollContainer from '../../../utils/NoScrollContainer'

/* ----- styled components ----- */
const TableTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 24px;
  display: inline-block;
`
const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
`

/* ----- query string ----- */
const patientTracksQuery = gql`
  query PatientTracksQuery(
    $id: ID!
    $query: TracksQuery = {}
    $page: Int = 1
    $limit: Int = 100
    $sort: String = "-updated"
  ) {
    patient(id: $id) {
      id
      tracks(query: $query, page: $page, limit: $limit, sort: $sort) {
        docs {
          ...TrackTableList
        }
        limit
        total
        page
      }
    }
  }
  ${TrackTableList.fragment}
`

/* ----- types and component ----- */
export type PatientTrackSorterField = 'created'

export interface TrackLightboxState {
  visible: boolean
  photoCategory: PhotoCategory
  photoMap: PhotoMapType
  initialIndex?: number
}

export type HandleOpenLightboxParams = Omit<TrackLightboxState, 'visible'>

interface Params {
  patientId: string
}

type Props = {
  isSidebarCollapsed: boolean
} & RouteComponentProps<Params>

const PatientTrackList = (props: Props) => {
  const { patientId } = props.match.params
  const { tableQuery, paginateQuery, handleTableChange, handleTableReset } =
    TableQuery.useTableQuery<any, PatientTrackSorterField>({ limit: 100 })
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { data, loading } = useQuery<
    PatientTracksQueryQuery,
    PatientTracksQueryQueryVariables
  >(patientTracksQuery, {
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      id: patientId,
      query: {},
      ...paginateQuery,
    },
  })

  const tracks = data?.patient?.tracks?.docs || []
  const antdPagination = {
    page: data?.patient?.tracks?.page || 1,
    total: data?.patient?.tracks?.total || 0,
    limit: data?.patient?.tracks?.limit || 0,
  }

  return (
    <NoScrollContainer>
      <TitleRow>
        <TableTitle>追蹤列表</TableTitle>
        <Button onClick={handleTableReset}>
          <ReloadOutlined /> 重新整理
        </Button>
      </TitleRow>
      <TrackTableList
        loading={loading}
        data={tracks}
        antdPagination={antdPagination}
        sortInfo={tableQuery.sort}
        handleTableChange={handleTableChange}
      />
    </NoScrollContainer>
  )
}

export default PatientTrackList
