import { AlertOutlined, LoadingOutlined } from '@ant-design/icons'
import { gql, useMutation } from '@apollo/client'
import { Auth, getEntityTitle, isEmptyOrNil } from '@sov/common'
import { EmptyConversation, ImageCard } from '@sov/ui'
import { Avatar, Button, Dropdown, Menu, Modal, Spin, message } from 'antd'
import moment, { Moment } from 'moment'
import { includes, map } from 'ramda'
import React, { forwardRef, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import {
  AllPrivilege,
  MessageInfoFragment,
  Patient,
  Role,
} from '../../codegen/types'
import { authContext } from '../../utils/context'
import { MessageNodesGroup } from './Conversation'
import { isNotEmpty } from './utils'

const { getAuthPrivileges, isItemOwner } = Auth.utils
const { confirm } = Modal

interface ContentProps {
  isMyMessage: boolean
}

interface ContainerProps {
  scrollSmooth: boolean
}

const ConversationContentContainer = styled.div<ContainerProps>`
  padding: 0px 12px;
  overflow: scroll;
  overscroll-behavior: none;
  scroll-behavior: ${(props) => (props.scrollSmooth ? 'smooth' : 'auto')};
  flex-grow: 1;
  min-height: 0;
`

const MessageRowContainer = styled.div`
  margin: 15px 0px;
  display: flex;
`

const CreatorTitle = styled.div`
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  font-size: 12px;
  align-items: center;
`

const ContentContainer = styled.div`
  margin-top: 8px;
`

/** @todo styled-component 升到 v5 有機會移除 */
const TextContentContainer = styled(ContentContainer as any)<ContentProps>`
  padding: 8px 16px;
  border-radius: 0px 15px 15px 15px;
  max-width: 350px;
  font-size: 14px;
  background-color: ${(props) =>
    props.isMyMessage ? '#1890ff' : 'rgba(0, 0, 0, 0.05)'};
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-break: break-word;
`

const ContentCol = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  color: ${(props) => (props.isMyMessage ? 'white' : 'rgba(0, 0, 0, 0.65)')};
`

const MessageTimeStamp = styled.div`
  margin-top: 8px;
  align-self: flex-end;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
`

const DateRow = styled.div`
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
`

const NewMessageButtonContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-top: 20px;
`

interface NewMessageButtonProps {
  handleOnClick: (e: any) => void
}

const NewMessageButton = ({ handleOnClick }: NewMessageButtonProps) => (
  <NewMessageButtonContainer>
    <Button
      style={{ margin: 'auto' }}
      shape='round'
      icon={<AlertOutlined />}
      onClick={handleOnClick}
    >
      有新訊息
    </Button>
  </NewMessageButtonContainer>
)

const removeMessageMutation = gql`
  mutation RemoveMessageMutation($id: ID!) {
    removeMessage(id: $id) {
      id
      message
    }
  }
`

interface MessageRowProps {
  node: MessageInfoFragment
  onReload: () => void
}

const MessageRow = (props: MessageRowProps) => {
  const { node, onReload } = props
  const { id, creator, created } = node
  const auth = useContext(authContext)
  const [removeMessage] = useMutation(removeMessageMutation)
  const { t } = useTranslation()

  const { id: creatorId, name } = creator
  const isMyMessage = isItemOwner(creatorId, auth)
  const hasMessageDeletePrivilege = includes(
    AllPrivilege.MessageDelete,
    getAuthPrivileges(auth) ?? []
  )
  const handleRemove = async () => {
    try {
      await removeMessage({
        variables: {
          id,
        },
        update: async (cache, { data }) => {
          if (data) {
            message.success(data.removeMessage.message)
            onReload()
          }
        },
      })
    } catch (error) {
      message.error(error)
    }
  }

  const onClickRemove = () => {
    confirm({
      title: '刪除訊息',
      content: '將會永久刪除該訊息，是否要繼續？',
      cancelText: '取消',
      okText: '確定',
      onOk: handleRemove,
    })
  }
  const RemoveMenu = () => (
    <Menu>
      <Menu.Item key='0' onClick={onClickRemove}>
        <div>移除訊息</div>
      </Menu.Item>
    </Menu>
  )

  return (
    <MessageRowContainer>
      <Avatar
        size={36}
        style={{
          marginRight: '12px',
          flexGrow: 0,
          flexShrink: 0,
          backgroundColor: '#1890ff',
        }}
      >
        {creator.__typename === 'Employee' && creator.role !== Role.Sales
          ? '技'
          : creator.name.slice(0, 1)}
      </Avatar>
      <ContentCol isMyMessage={isMyMessage}>
        <CreatorTitle>
          {creator.__typename === 'Employee' && creator.role !== Role.Sales
            ? '舒服美技師'
            : `${getEntityTitle(creator, t)} ${name}`}
        </CreatorTitle>
        <Dropdown
          overlay={RemoveMenu}
          trigger={['contextMenu']}
          disabled={!hasMessageDeletePrivilege}
        >
          <>
            {node.__typename === 'TextMessage' && (
              <TextContentContainer isMyMessage={isMyMessage}>
                {node.content}
              </TextContentContainer>
            )}
            {node.__typename === 'ImageMessage' && (
              <ContentContainer>
                <ImageCard imageFile={node.image} />
              </ContentContainer>
            )}
          </>
        </Dropdown>
        <MessageTimeStamp>
          <div>{moment(created).locale('en').format('LT')}</div>
        </MessageTimeStamp>
      </ContentCol>
    </MessageRowContainer>
  )
}

interface MessageGroupProps {
  date?: Moment | string
  nodes: MessageInfoFragment[]
  onReload: () => void
}

const MessageGroup = ({ nodes, date, onReload }: MessageGroupProps) => {
  return (
    <div>
      <DateRow>{isNotEmpty(date) && moment(date).format('YYYY-MM-DD')}</DateRow>
      {map((node) => {
        return <MessageRow key={uuidv4()} node={node} onReload={onReload} />
      }, nodes)}
    </div>
  )
}

interface ConversationContentProps {
  patient: Pick<Patient, 'name'>
  scrollSmooth: boolean
  onScroll: (e: any) => void
  messageNodesGroup: MessageNodesGroup[]
  newMessageButtonVisible: boolean | undefined
  handleNewMessageNotificationClick: () => void
  loading: boolean
  onReload: () => void
}
export const ConversationContent = forwardRef(
  (props: ConversationContentProps, ref: React.Ref<HTMLDivElement>) => {
    const {
      patient,
      scrollSmooth,
      onScroll,
      messageNodesGroup,
      newMessageButtonVisible,
      handleNewMessageNotificationClick,
      loading,
      onReload,
    } = props

    return (
      <ConversationContentContainer
        ref={ref}
        scrollSmooth={scrollSmooth}
        onScroll={onScroll}
      >
        {!isEmptyOrNil(messageNodesGroup) ? (
          <div style={{ paddingTop: '12px', position: 'relative' }}>
            {newMessageButtonVisible && (
              <NewMessageButton
                handleOnClick={handleNewMessageNotificationClick}
              />
            )}
            {loading && (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                style={{ display: 'block', marginBottom: '10px' }}
              />
            )}
            {messageNodesGroup.map((group, index) => (
              <MessageGroup
                key={index}
                date={group.date}
                nodes={group.nodes}
                onReload={onReload}
              />
            ))}
          </div>
        ) : (
          <div
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <EmptyConversation patient={patient} />
          </div>
        )}
      </ConversationContentContainer>
    )
  }
)
