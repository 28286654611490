/* eslint max-len: 'off' */
import React from 'react'

const PartTeeth = () => {
  return (
    <svg
      width='36px'
      height='36px'
      viewBox='0 0 36 36'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>齒</title>
      <desc>Created with Sketch.</desc>
      <defs />
      <g id='齒' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M26.9909195,10.8666224 C28.0259195,14.7784081 26.5479909,19.4436224 25.6852766,23.2930509 C25.3104909,24.9657652 24.9967766,26.6789795 24.1359909,28.1864795 C22.7744195,30.5734081 21.6886338,27.4568367 21.2611338,26.1389795 C20.7352766,24.5183367 20.5642766,22.4258367 18.9455623,21.4621938 C17.3332766,20.5017652 15.8373481,21.4834081 14.9032766,22.8494795 C13.9569909,24.2341938 13.5982766,25.8426224 12.9978481,27.3822652 C12.7201338,28.0945509 12.4141338,28.8068367 11.6613481,29.1256938 C10.8622766,29.1256938 10.3074909,27.6657652 10.0137052,26.9566938 C8.4117052,23.0886224 7.91927663,18.7056224 7.30084805,14.5939081 C6.86563377,11.7010509 7.80356234,6.44633666 10.8082766,5.38112237 C12.4411338,4.80255094 14.6776338,5.33612237 16.3124195,5.81762237 C17.2516338,6.0946938 17.0787052,6.00405094 18.0147052,5.67940808 C18.0867052,5.65433666 18.1574195,5.62090808 18.2307052,5.5906938 C20.2332052,4.76205094 22.7949909,4.75562237 24.5577052,5.88897951 C25.7469909,6.65462237 26.6469909,9.56612237 26.9909195,10.8666224 Z'
          id='Shape'
          fill='#E7ECED'
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}

export default PartTeeth
