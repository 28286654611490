import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const LinkContainer = styled.div`
  margin-top: 48px;
`

interface Props {
  backLink?: string | ReactNode
}

const BackLink = (props: Props) => {
  const { backLink } = props
  if (!backLink) {
    return null
  }

  return (
    <LinkContainer>
      {typeof backLink === 'string' ? (
        <Link to={backLink}>{'<返回'}</Link>
      ) : (
        backLink
      )}
    </LinkContainer>
  )
}

export default BackLink
