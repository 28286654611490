import { blue } from '@ant-design/colors'
import { FileTextOutlined } from '@ant-design/icons'
import { exhaustiveCheck } from '@sov/common'
import { Popover, Row, Space, Typography } from 'antd'
import { isNil } from 'ramda'
import React, { useState } from 'react'
import styled from 'styled-components'

import InstructionCardPreview from '../../InstructionCardPreview'
import MultiLine from '../../MultiLine'
import { MeshColor } from '../constant/meshColor'
import checkPointActiveImage from '../images/check-point-active.png'
import checkPointImage from '../images/check-point.png'
import designStageActiveImage from '../images/design-stage-active.png'
import designStageImage from '../images/design-stage.png'
import eyeClosedImage from '../images/eye-closed.png'
import eyeOpenedImage from '../images/eye-opened.png'
import moldStageActiveImage from '../images/mold-stage-active.png'
import moldStageImage from '../images/mold-stage.png'
import ColorSelect from './ColorSelect'
import { ViewerItem } from '..'

export enum ItemType {
  CP = 'CP',
  MOLD = 'MOLD',
  STEP = 'STEP',
}

const Wrapper = styled.div<{
  isHover: boolean
  isActive: boolean
  disabled: boolean
}>`
  min-height: 40px;
  padding: 8px;
  border: 1px solid
    ${(props) =>
      props.isHover && !props.disabled ? blue.primary : 'transparent'};
  border-radius: 4px;
  cursor: pointer;
  ${(props) => props.isActive && `border-left: 4px solid ${blue.primary}`}
`
const StyledTitle = styled(Space)<{ isActive: boolean }>`
  color: ${(props) => (props.isActive ? blue.primary : 'black')};
  ${(props) => !props.isActive && 'opacity: 0.25;'}
`
const StyledImage = styled.div<{ image: string }>`
  width: 16px;
  height: 16px;
  background-image: url(${(props) => props.image});
  background-size: contain;
`

const getImage = (type: ItemType, isActive: boolean) => {
  switch (type) {
    case ItemType.CP:
      return isActive ? checkPointActiveImage : checkPointImage
    case ItemType.MOLD:
      return isActive ? moldStageActiveImage : moldStageImage
    case ItemType.STEP:
      return isActive ? designStageActiveImage : designStageImage
    default:
      exhaustiveCheck(type)
  }
}

export const getName = (type: ItemType, number: number, isFinal?: boolean) => {
  switch (type) {
    case ItemType.CP:
      return isFinal ? 'Final' : `CP ${number}`
    case ItemType.MOLD:
      return `M${number}`
    case ItemType.STEP:
      return `Step${number}`
    default:
      exhaustiveCheck(type)
  }
}

interface SelectableItemProps {
  item: ViewerItem
  isActive: boolean
  disabled?: boolean
  handleSelect: () => void
  handleChangeColor: (color: MeshColor) => void
}

const SelectableItem = (props: SelectableItemProps) => {
  const {
    item,
    isActive,
    disabled = false,
    handleSelect,
    handleChangeColor,
  } = props
  const { type, info, percent, color } = item
  const number = info.serialNumber

  const [isHover, setIsHover] = useState(false)

  const image = getImage(type, isActive)
  const name = getName(type, number, 'goalStep' in info && info.isFinal)

  const instructionCard =
    'instructionCard' in info ? info.instructionCard : undefined

  const handleEnter = () => !disabled && setIsHover(true)
  const handleLeave = () => !disabled && setIsHover(false)
  const handleClick = () => !disabled && handleSelect()

  return (
    <Wrapper
      isHover={isHover}
      disabled={disabled}
      isActive={isActive}
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
      onClick={handleClick}
    >
      <Space direction='vertical' style={{ width: '100%' }}>
        <Row justify='space-between' style={{ width: '100%' }}>
          <StyledTitle align='center' isActive={isActive}>
            <StyledImage image={image} />
            <span>{name}</span>
          </StyledTitle>
          <Space size='small' align='center'>
            {instructionCard && (
              <Popover
                content={
                  <InstructionCardPreview instructionItem={instructionCard} />
                }
              >
                <FileTextOutlined />
              </Popover>
            )}
            {isNil(percent) && (
              <Typography.Text type='secondary'>未載入</Typography.Text>
            )}
            {!isNil(percent) && percent < 100 && (
              <Typography.Text type='secondary'>{percent}%</Typography.Text>
            )}
            {!isNil(percent) && percent === 100 && (
              <ColorSelect
                color={color}
                disabled={disabled}
                handleChangeColor={handleChangeColor}
              />
            )}
            {isActive ? (
              <StyledImage image={eyeOpenedImage} />
            ) : (
              <StyledImage image={eyeClosedImage} />
            )}
          </Space>
        </Row>
        {type !== ItemType.MOLD && (
          <div style={{ marginLeft: '24px' }}>
            <Space direction='vertical' size={4}>
              <Typography.Text type='secondary' style={{ fontSize: 12 }}>
                上顎
              </Typography.Text>
              {'step' in info && <MultiLine text={info.step.upperStep} />}
              {'upper' in info && <MultiLine text={info.upper} />}
              <Typography.Text type='secondary' style={{ fontSize: 12 }}>
                下顎
              </Typography.Text>
              {'step' in info && <MultiLine text={info.step.lowerStep} />}
              {'lower' in info && <MultiLine text={info.lower} />}
            </Space>
          </div>
        )}
      </Space>
    </Wrapper>
  )
}

export default SelectableItem
