import { gql } from '@apollo/client'
import React from 'react'
import { Link } from 'react-router-dom'

import { PatientLinkFragment } from '../../codegen/types'

export interface PatientLinkProps {
  item: PatientLinkFragment
  to?: string
  target?: string
  style?: React.CSSProperties
  className?: string
  children?: React.ReactNode
}

const PatientLink = (props: PatientLinkProps) => {
  const { item, target = '_self', to, style, className, children } = props
  return (
    <Link
      className={className}
      target={target}
      style={style}
      to={to || `/patients/${item.id}`}
    >
      {children || item.name}
    </Link>
  )
}

PatientLink.fragment = gql`
  fragment PatientLink on Patient {
    id
    name
  }
`

export default PatientLink
