import { gold } from '@ant-design/colors'
import { CheckOutlined } from '@ant-design/icons'
import { exhaustiveCheck } from '@sov/common'
import React from 'react'

import { AuxiliaryType } from '../../codegen/types'

interface Props {
  className?: string
  instruction: AuxiliaryType | 'retrieve'
}

const CheckIcon = () => <CheckOutlined style={{ color: gold.primary }} />

const InstructionTag = (props: Props) => {
  const { className, instruction } = props

  switch (instruction) {
    case AuxiliaryType.Attachment:
      return (
        <div className={className} key={AuxiliaryType.Attachment}>
          <CheckIcon /> Attachment
        </div>
      )
    case AuxiliaryType.Button:
      return (
        <div className={className} key={AuxiliaryType.Button}>
          <CheckIcon /> Button
        </div>
      )
    case AuxiliaryType.Tad:
      return (
        <div className={className} key={AuxiliaryType.Tad}>
          <CheckIcon /> Tad
        </div>
      )
    case AuxiliaryType.StrippingHalf:
    case AuxiliaryType.StrippingAll:
      return (
        <div className={className} key='IPR'>
          <CheckIcon /> IPR
        </div>
      )
    case 'retrieve':
      return (
        <div className={className} key='retrieve'>
          <CheckIcon /> 重取模
        </div>
      )

    default:
      exhaustiveCheck(instruction)
      return <div className={className} />
  }
}

export default InstructionTag
