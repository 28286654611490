import { gql } from '@apollo/client'
import React from 'react'
import styled from 'styled-components'

import { ConversationPatientInfoFragment } from '../../codegen/types'
import PatientInfoCard from '../../components/card/Patient'
import { BlockTitle } from './BlockTitle'

const ConversationPatientInfoContainer = styled.div`
  /* padding: 8px 24px; */
  margin-left: 5px;
  width: 300px;
  height: calc(100vh - 232px);
  border-left: 1px solid #e8e8e8;
  display: flex;
  flex-direction: column;
`

const ScollableContainer = styled.div`
  overflow: scroll;
  overscroll-behavior: none;
`

interface ConversationPatientInfoProps {
  patient?: ConversationPatientInfoFragment
}
const ConversationPatientInfo = (props: ConversationPatientInfoProps) => {
  const { patient } = props
  if (!patient) {
    return null
  }

  return (
    <ConversationPatientInfoContainer>
      <BlockTitle title='基本資料' />
      <br />
      <ScollableContainer>
        <PatientInfoCard patient={patient} />
      </ScollableContainer>
    </ConversationPatientInfoContainer>
  )
}

ConversationPatientInfo.fragment = gql`
  fragment ConversationPatientInfo on Patient {
    ...PatientInfoCard
  }
  ${PatientInfoCard.fragment}
`

export default ConversationPatientInfo
