import { map } from 'ramda'
import React from 'react'

import { Auxiliary, AuxiliaryType } from '../../../codegen/types'
import Attachment from './Attachment'
import AuxiliaryButton from './AuxiliaryButton'
import Stripping from './Stripping'
import Tad from './Tad'

interface Props {
  items: Auxiliary[]
  handleRemoveStripping: (item: Auxiliary) => void
}

const Auxiliaries = (props: Props) => {
  const { items, handleRemoveStripping } = props
  return (
    <>
      {map((item) => {
        const { type, toothPosition, auxiliaryPosition } = item

        switch (type) {
          case AuxiliaryType.Attachment:
            return <Attachment item={item} key={`normal-${toothPosition}`} />
          case AuxiliaryType.Button:
            return (
              <AuxiliaryButton
                item={item}
                key={`auxiliary-button-${toothPosition}`}
              />
            )
          case AuxiliaryType.StrippingAll:
            // 根據是不同的 attachmentType 給予不同大小
            return (
              <Stripping
                item={item}
                handleRemoveStripping={handleRemoveStripping}
                key={`stripping-all-${toothPosition}-${auxiliaryPosition}`}
              />
            )
          case AuxiliaryType.StrippingHalf:
            return (
              <Stripping
                item={item}
                handleRemoveStripping={handleRemoveStripping}
                key={`stripping-half-${toothPosition}-${auxiliaryPosition}`}
              />
            )
          case AuxiliaryType.Tad:
            return <Tad item={item} key={`tad-${toothPosition}`} />
          default:
            return null
        }
      }, items)}
    </>
  )
}

export default Auxiliaries
