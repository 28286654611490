import { QuestionCircleFilled } from '@ant-design/icons'
import { Tooltip } from 'antd'
import React from 'react'

interface TooltipTextProps {
  textList: string[]
}

const TooltipText = (props: TooltipTextProps) => {
  const { textList } = props

  return (
    <>
      {textList.map((text, index) => {
        return <div key={`tooltip-text-${index}`}>{text}</div>
      })}
    </>
  )
}

interface TooltipIconProps {
  textList: string[]
}

const TooltipIcon = (props: TooltipIconProps) => {
  const { textList } = props

  return (
    <Tooltip placement='right' title={<TooltipText textList={textList} />}>
      <QuestionCircleFilled style={{ color: 'rgba(0, 0, 0, 0.2)' }} />
    </Tooltip>
  )
}

export default TooltipIcon
