import React from 'react'

import Layout from '../components/Layout'

const RegisterComplete = () => (
  <Layout
    title='期待與您的合作'
    description={
      <>
        <div>我們已經收到您資訊</div>
        <div>SOV專人將會盡快與您聯繫</div>
        <div>祝您有個美好的一天</div>
      </>
    }
    backLink='/'
  />
)

export default RegisterComplete
