import { Button, Space } from 'antd'
import React, { FC, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { OrderType } from '../../../codegen/types'
import CreateOrderIcon from '../../../components/icon/CreateOrderIcon'
import OrderModal from '../../../components/modal/OrderModal'
import successImage from './create-patient-success.png'

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  margin-top: 40px;
  color: rgba(0, 0, 0, 0.65);
`

const Title = styled.div`
  margin: 24px 0 16px;
  font-weight: 500;
  font-size: 20px;
`

const Content = styled.div`
  margin-bottom: 24px;
  line-height: 24px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`

const StyledButton = styled(Button)`
  width: 310px;
  font-size: 14px;
`

/** @todo styled-component 升到 v5 有機會移除 */
const CreateorderButton = styled(StyledButton as any)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const WhiteCreateOrderIcon = styled(CreateOrderIcon)`
  color: white;
`

interface Props {
  newPatientId: string
}

const FinishStep: FC<Props> = (props) => {
  const { newPatientId } = props
  const history = useHistory()
  const [modalVisible, setModalVisible] = useState(true)

  const refreshPage = () => history.go(0)

  const handleModalOpen = () => setModalVisible(true)
  const handleModalClose = () => setModalVisible(false)

  return (
    <Wrapper>
      <img src={successImage} />
      <Title>成功新增病患資料</Title>
      <Content>
        <div>
          若病患<b>已預約</b>下次約診看報告的時間，請接著 <b>點選下單</b> 。
        </div>
      </Content>
      <Space direction='vertical'>
        <CreateorderButton
          size='large'
          type='primary'
          icon={<WhiteCreateOrderIcon />}
          onClick={handleModalOpen}
        >
          下單
        </CreateorderButton>
        <StyledButton size='large'>
          <Link to='/patients'>返回病患列表</Link>
        </StyledButton>
        <StyledButton size='large' onClick={refreshPage}>
          繼續新增病患資料
        </StyledButton>
      </Space>
      <OrderModal
        patientId={newPatientId}
        orderType={OrderType.Report}
        shouldFetchPatientOrders={modalVisible}
        handleQueryStop={handleModalClose}
      />
    </Wrapper>
  )
}

export default FinishStep
