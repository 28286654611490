import styled, { css } from 'styled-components'

const pageTopStyle = css`
  padding-top: 54px;
`

interface PageTopProps {
  /** 在網頁預覽時可否看到預覽樣式 */
  previewInHTML: boolean
}

const PageTop = styled.div<PageTopProps>`
  @media screen {
    ${(props) => props.previewInHTML && pageTopStyle}
  }
`

export default PageTop
