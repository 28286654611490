import { gql } from '@apollo/client'
import React from 'react'
import styled from 'styled-components'

import { EmptyInstructionCardFragment } from '../../codegen/types'
import { OnceButton } from '../Button'
import PatientLink from '../Link/Patient'
import StageLink from '../Link/Stage'

const EmptyStyledPreview = styled.div`
  width: 105mm;
  text-align: center;
`

interface EmptyInstructionCardProps {
  handleRemove?: () => void
  instructionItem: EmptyInstructionCardFragment
}

const EmptyInstructionCard = (props: EmptyInstructionCardProps) => {
  const { handleRemove, instructionItem } = props

  return (
    <EmptyStyledPreview>
      {handleRemove ? (
        <>
          <div style={{ color: 'red' }}>內容空白表示不出，請確認是否要刪除</div>
          <div>
            (所屬 Step/病患：
            <StageLink item={instructionItem.stage} />/
            <PatientLink item={instructionItem.patient} />)
          </div>
          <OnceButton
            danger
            style={{ marginTop: '16px' }}
            onClick={handleRemove}
          >
            刪除指示卡
          </OnceButton>
        </>
      ) : (
        <div>該 Step 沒有任何臨床指示</div>
      )}
    </EmptyStyledPreview>
  )
}

EmptyInstructionCard.fragments = {
  EmptyInstructionCard: gql`
    fragment EmptyInstructionCard on InstructionCard {
      patient {
        ...PatientLink
      }
      stage {
        ...StageLink
      }
    }
    ${PatientLink.fragment}
    ${StageLink.fragment}
  `,
}

export default EmptyInstructionCard
