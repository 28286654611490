/* eslint max-len: 'off' */
import React from 'react'

const TopJaw = () => {
  return (
    <svg
      width='36px'
      height='36px'
      viewBox='0 0 36 36'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>上顎</title>
      <desc>Created with Sketch.</desc>
      <defs />
      <g id='上顎' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='001-teeth-1-copy-2'
          transform='translate(2.000000, 1.000000)'
          fillRule='nonzero'
        >
          <path
            d='M8.28082759,4.37574316 C12.6868966,4.37574316 13.7897931,4.54749108 14.9445517,8.15638526 C15.785931,10.7862069 15.9751724,14.0811415 16,16.8537218 C16.0115862,18.1850416 14.8342069,20.8334602 13.2546207,21.875434 C10.6372414,23.6016647 7.06206897,23.0147681 4.08551724,21.9897503 C2.17710345,21.3328419 0.4,19.473698 0.562206897,16.8531748 C0.741517241,13.953698 1.44496552,10.3163615 2.26041379,7.51971463 C3.05986207,4.77831153 5.75724138,4.37574316 8.28082759,4.37574316 Z'
            id='Shape'
            fill='#E7ECED'
          />
          <path
            d='M23.7191724,4.37574316 C19.3131034,4.37574316 18.2102069,4.54749108 17.0554483,8.15638526 C16.214069,10.7867539 16.0242759,14.0816885 16,16.8542687 C15.9884138,18.1855886 17.1657931,20.8340071 18.7453793,21.875981 C21.3627586,23.6022117 24.937931,23.0153151 27.9144828,21.9902973 C29.8228966,21.3333888 31.6,19.4742449 31.4377931,16.8537218 C31.2584828,13.9542449 30.5550345,10.3169084 29.7395862,7.52026159 C28.9401379,4.77831153 26.2427586,4.37574316 23.7191724,4.37574316 Z'
            id='Shape'
            fill='#E7ECED'
          />
          <path
            d='M27.0008276,0 L4.99917241,0 C2.23834483,0 0,2.21904875 0,4.9560761 L0,10.2862782 C0,10.9492033 0.541793103,11.4863258 1.21048276,11.4863258 L1.30317241,11.4863258 C1.58013793,10.0931986 1.90786207,8.72796671 2.25986207,7.52080856 C3.05986207,4.77831153 5.75724138,4.37574316 8.28082759,4.37574316 C12.6868966,4.37574316 13.7897931,4.54749108 14.9445517,8.15638526 C15.2744828,9.18741974 15.5017931,10.3218312 15.6606897,11.4863258 L16.3393103,11.4863258 C16.4982069,10.3223781 16.7255172,9.18796671 17.0554483,8.15638526 C18.2102069,4.54749108 19.3131034,4.37574316 23.7191724,4.37574316 C26.2427586,4.37574316 28.9406897,4.77831153 29.7401379,7.52080856 C30.0921379,8.72796671 30.4198621,10.0931986 30.6968276,11.4863258 L30.7895172,11.4863258 C31.4582069,11.4863258 32,10.9492033 32,10.2862782 L32,4.9560761 C32,2.21904875 29.7616552,0 27.0008276,0 Z'
            id='Shape'
            fill='#ED9893'
          />
        </g>
      </g>
    </svg>
  )
}

export default TopJaw
