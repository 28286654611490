import { gql } from '@apollo/client'

import { ConversationInfo } from '../../../components/conversation/fragments'

export const updateConversationReadMessageRecordOfMemberMutation = gql`
  mutation UpdateConversationReadMessageRecordOfMemberMutation(
    $id: ID!
    $entity: ID!
  ) {
    updateConversationReadMessageRecordOfMember(id: $id, entity: $entity) {
      ...conversationInfo
    }
  }
  ${ConversationInfo}
`
