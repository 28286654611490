import React from 'react'
import styled from 'styled-components'

import { RouteKey, routeMap } from '../../../utils/routes'

const StyledContainer = styled.div`
  display: flex;
`

const StyledTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
`

const StyledSubTitle = styled.div`
  display: flex;
  align-items: flex-end;
  height: 28px;
  margin-left: 12px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.45);
`

interface Props {
  route: {
    key: RouteKey
    render?: () => JSX.Element | string
    renderSubtitle?: () => JSX.Element | string
  }
}

const Title = (props: Props) => {
  const { route } = props
  const { key, render, renderSubtitle } = route

  return (
    <StyledContainer>
      <StyledTitle>{render ? render() : routeMap[key].title}</StyledTitle>
      <StyledSubTitle>
        {renderSubtitle ? renderSubtitle() : routeMap[key].subTitle}
      </StyledSubTitle>
    </StyledContainer>
  )
}

export default Title
