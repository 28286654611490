import { gql, useQuery } from '@apollo/client'
import {
  DateIntervalParam,
  ErrorHandling,
  FormQuery,
  MyStringParam,
  TableQuery,
  getDateIntervalQueryString,
} from '@sov/common'
import { Button, Form, Input } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React from 'react'

import {
  OrdersQuery,
  OrdersQueryQuery,
  OrdersQueryQueryVariables,
} from '../../codegen/types'
import RangePicker from '../../components/form/RangePicker'
import Page from '../../components/page'
import BreadcrumbCreator from '../../components/page/BreadcrumbCreator'
import Title from '../../components/page/Title'
import OrderTable from '../../components/table/order/OrderTable'
import NoScrollContainer from '../../utils/NoScrollContainer'

/* ----- query string ----- */
const ordersQuery = gql`
  query OrdersQuery(
    $query: OrdersQuery
    $page: Int
    $limit: Int
    $sort: String
  ) {
    orders(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        ...OrderTable
      }
      limit
      total
      page
    }
  }
  ${OrderTable.fragment}
`

/* ----- type and component ----- */
export type OrderListFilter = Pick<OrdersQuery, 'status'>
export type OrderListSorterField = 'appointment'

const OrderFormInput = {
  patientName: MyStringParam,
  oid: MyStringParam,
  appointmentInterval: DateIntervalParam,
}

interface OrderFormFields {
  patientName?: string
  payment?: string
}

interface OrderFormProps {
  form: FormInstance<OrderFormFields>
  formQuery: OrderFormFields
  handleSearch: (x: FormInstance<OrderFormFields>) => void
}

const OrderForm = (props: OrderFormProps) => {
  const { form, formQuery, handleSearch } = props

  const initialValues = formQuery

  return (
    <Form
      layout='inline'
      form={form}
      initialValues={initialValues}
      style={{ marginBottom: '32px' }}
    >
      <Form.Item name='patientName' label='病患姓名'>
        <Input.Search
          style={{ width: 150 }}
          placeholder='病患姓名'
          allowClear
          onSearch={() => handleSearch(form)}
        />
      </Form.Item>
      <Form.Item name='oid' label='訂單編號'>
        <Input.Search
          style={{ width: 150 }}
          placeholder='訂單編號'
          allowClear
          onSearch={() => handleSearch(form)}
        />
      </Form.Item>
      <Form.Item name='appointmentInterval' label='預計約診日期:'>
        <RangePicker
          defaultPickerType='date'
          onChange={() => handleSearch(form)}
        />
      </Form.Item>
      <Button type='primary' ghost onClick={() => handleSearch(form)}>
        搜尋
      </Button>
    </Form>
  )
}

const OrderList = () => {
  const [form] = Form.useForm()
  const { formQuery, handleFormChange } = FormQuery.useFormQuery(OrderFormInput)
  const { tableQuery, paginateQuery, handleTableChange } =
    TableQuery.useTableQuery<OrderListFilter, OrderListSorterField>({
      limit: 10,
      sort: '-created',
    })
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { data, loading } = useQuery<
    OrdersQueryQuery,
    OrdersQueryQueryVariables
  >(ordersQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      query: {
        patientName: formQuery.patientName,
        oid: formQuery.oid,
        appointmentInterval: getDateIntervalQueryString(
          formQuery.appointmentInterval
        ),
        status: tableQuery.filters?.status,
      },
      ...paginateQuery,
      sort: '-created',
    },
  })

  const handleSearch = (form: FormInstance<OrderFormFields>) => {
    handleFormChange(form.getFieldsValue())
  }

  const orderTableData = data?.orders ? data.orders.docs : []
  const antdPagination = {
    page: data?.orders?.page || 1,
    total: data?.orders?.total || 0,
    limit: data?.orders?.limit || 0,
  }

  const header = (
    <>
      <BreadcrumbCreator routes={[{ key: 'Home' }, { key: 'OrderList' }]} />
      <Title
        route={{
          key: 'OrderList',
          renderSubtitle: () => <>統計所有向 SOV 的療程訂單項目</>,
        }}
      />
    </>
  )

  return (
    <Page header={header}>
      <NoScrollContainer>
        <OrderForm
          form={form}
          formQuery={formQuery}
          handleSearch={handleSearch}
        />
        <OrderTable
          loading={loading}
          data={orderTableData}
          filterInfo={tableQuery.filters}
          sortInfo={tableQuery.sort}
          antdPagination={antdPagination}
          handleTableChange={handleTableChange}
        />
      </NoScrollContainer>
    </Page>
  )
}

export default OrderList
