import React from 'react'
import { Link } from 'react-router-dom'

import { ActionButtonContainer } from '../'
import viewDetailImage from './view-detail.svg'

interface ViewDetailButtonProps {
  patientId: string
}

const ViewDetailButton = (props: ViewDetailButtonProps) => {
  const { patientId } = props
  return (
    <Link to={`/patients/${patientId}`}>
      <ActionButtonContainer isButtonDisabled={false}>
        <img src={viewDetailImage} />
        <span>檢視詳情</span>
      </ActionButtonContainer>
    </Link>
  )
}

export default ViewDetailButton
