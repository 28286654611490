export const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

const lightGray = '#F5F5F5'
export const subFormItemLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 12 },
  style: {
    backgroundColor: lightGray,
    marginTop: '-24px',
  },
}
