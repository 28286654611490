import { gql, useQuery } from '@apollo/client'
import { ErrorHandling, TableQuery } from '@sov/common'
import React, { useState } from 'react'
import { useRouteMatch } from 'react-router-dom'

import {
  AppointmentFragment,
  PatientAppointmentsQueryQuery,
  PatientAppointmentsQueryQueryVariables,
} from '../../codegen/types'
import Page from '../../components/page'
import AppointmentsTable from '../../components/table/patient/AppointmentTable'
import NoScrollContainer from '../../utils/NoScrollContainer'
import AppointmentCard from '../appointment/AppointmentCard'
import AppointmentModal from '../appointment/AppointmentModal'

const patientAppointmentsQuery = gql`
  query PatientAppointmentsQuery(
    $id: ID!
    $query: AppointmentsQuery = {}
    $page: Int = 1
    $limit: Int = 100
    $sort: String = "-updated"
  ) {
    patient(id: $id) {
      id
      appointments(query: $query, page: $page, limit: $limit, sort: $sort) {
        docs {
          ...Appointment
        }
        limit
        total
        page
      }
    }
  }
  ${AppointmentCard.fragment.Appointment}
`

/* ----- types and component ----- */
export type PatientTrackSorterField = 'created'

interface RouterProps {
  patientId: string
}

// 病患事件列表
const PatientTracksList = () => {
  const match = useRouteMatch<RouterProps>()
  const patientId = match.params.patientId

  const [selectedAppointment, setSelectedAppointment] =
    useState<AppointmentFragment>()
  const [modalVisible, setModalVisible] = useState<boolean>(false)

  const { tableQuery, paginateQuery, handleTableChange } =
    TableQuery.useTableQuery<any, PatientTrackSorterField>({ limit: 10 })
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { data, loading, refetch } = useQuery<
    PatientAppointmentsQueryQuery,
    PatientAppointmentsQueryQueryVariables
  >(patientAppointmentsQuery, {
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      id: patientId,
      query: {},
      ...paginateQuery,
    },
  })
  const reset = () => {
    setSelectedAppointment(undefined)
    setModalVisible(false)
  }
  const appointments = data?.patient?.appointments?.docs || []
  const antdPagination = {
    page: data?.patient?.appointments?.page || 1,
    total: data?.patient?.appointments?.total || 0,
    limit: data?.patient?.appointments?.limit || 0,
  }

  if (loading) return <Page loading />

  return (
    <NoScrollContainer>
      <AppointmentsTable
        loading={loading}
        data={appointments}
        antdPagination={antdPagination}
        sortInfo={tableQuery.sort}
        handleTableChange={handleTableChange}
        handleSelect={(appointment) => {
          setSelectedAppointment(appointment)
          setModalVisible(true)
        }}
      />
      <AppointmentModal
        item={selectedAppointment}
        visible={modalVisible}
        reset={reset}
        refetch={refetch}
      />
    </NoScrollContainer>
  )
}

export default PatientTracksList
