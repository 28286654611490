import { DoctorLevel } from './types'

export const getBadgeImageByLevel = (Images: any, level: DoctorLevel) => {
  let badgeImage: string
  switch (level) {
    case DoctorLevel.Level1:
      badgeImage = Images.Badge1
      break
    case DoctorLevel.Level2:
      badgeImage = Images.Badge2
      break
    case DoctorLevel.Level3:
      badgeImage = Images.Badge3
      break
    case DoctorLevel.Level4:
      badgeImage = Images.Badge4
      break
    case DoctorLevel.Level5:
      badgeImage = Images.Badge5
      break
    default:
      badgeImage = Images.Badge0
      break
  }

  return badgeImage
}

export const getDecoratedBadgeImageByLevel = (
  Images: any,
  level: DoctorLevel
) => {
  let decoratedBadgeImage: string
  switch (level) {
    case DoctorLevel.Level1:
      decoratedBadgeImage = Images.BadgeDecorated1
      break
    case DoctorLevel.Level2:
      decoratedBadgeImage = Images.BadgeDecorated2
      break
    case DoctorLevel.Level3:
      decoratedBadgeImage = Images.BadgeDecorated3
      break
    case DoctorLevel.Level4:
      decoratedBadgeImage = Images.BadgeDecorated4
      break
    case DoctorLevel.Level5:
      decoratedBadgeImage = Images.BadgeDecorated5
      break
    default:
      decoratedBadgeImage = Images.BadgeDecorated0
      break
  }

  return decoratedBadgeImage
}
