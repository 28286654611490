import { QuestionCircleOutlined } from '@ant-design/icons'
import { gql } from '@apollo/client'
import { TableQuery, getEmptyText } from '@sov/common'
import { InstructionPopover } from '@sov/ui'
import { Tooltip } from 'antd'
import Table, { ColumnProps, TableProps } from 'antd/lib/table'
import moment from 'moment'
import React from 'react'

import { StageStatus, StageTableFragment } from '../../../codegen/types'

interface StageTableProps {
  loading: boolean
  data: StageTableFragment[]
  antdPagination: {
    page: number
    total: number
    limit: number
  }
  handleTableChange: TableProps<StageTable_PrintStage_Fragment>['onChange']
}

type StageTable_PrintStage_Fragment = Extract<
  StageTableFragment,
  { __typename: 'PrintStage' }
>

const StageTable = (props: StageTableProps) => {
  const { loading, data, antdPagination, handleTableChange } = props

  const printStages = data.filter(
    (stage): stage is StageTable_PrintStage_Fragment =>
      stage.__typename === 'PrintStage'
  )

  const columns: ColumnProps<StageTable_PrintStage_Fragment>[] = [
    {
      title: '編號',
      width: 60,
      key: 'serialNumber',
      dataIndex: 'serialNumber',
      render: (_text, record) => <div>Step {record.serialNumber}</div>,
    },

    {
      title: '治療內容',
      width: 200,
      key: 'step',
      dataIndex: 'step',
      render: (_text, record) => (
        <div>
          <div>
            上顎：
            {record.status === StageStatus.Completed
              ? record.designStage?.step?.upperStep ?? getEmptyText()
              : '進行中'}
          </div>
          <div>
            下顎：
            {record.status === StageStatus.Completed
              ? record.designStage?.step?.lowerStep ?? getEmptyText()
              : '進行中'}
          </div>
        </div>
      ),
    },
    {
      title: '指示卡',
      width: 30,
      key: 'instruction',
      dataIndex: 'instruction',
      render: (_text, record) =>
        record.status === StageStatus.Completed ? (
          record?.instructionCard ? (
            <InstructionPopover instructionItem={record.instructionCard} />
          ) : (
            getEmptyText('該 Step 沒有指示卡')
          )
        ) : (
          '尚未產生指示'
        ),
    },
    {
      title: (
        <>
          開始配戴日&nbsp;
          <Tooltip title='顯示病患端 APP 登錄的開始配戴日'>
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      width: 100,
      key: 'subStage',
      dataIndex: 'subStage',
      render: (_text, record) => (
        <div>
          {record.subStage?.[0]?.startDate
            ? moment(record.subStage[0].startDate).format('YYYY-MM-DD')
            : getEmptyText()}
        </div>
      ),
    },
  ]

  return (
    <Table<StageTable_PrintStage_Fragment>
      rowKey='id'
      columns={columns}
      dataSource={printStages}
      loading={loading}
      onChange={handleTableChange}
      pagination={TableQuery.getAntdPagination(antdPagination)}
    />
  )
}

StageTable.fragment = gql`
  fragment StageTable on Stage {
    id
    __typename
    status
    ... on PrintStage {
      serialNumber
      instructionCard {
        ...InstructionPopover
      }
      subStage {
        startDate
      }
      designStage {
        id
        step {
          upperStep
          lowerStep
        }
      }
    }
  }
  ${InstructionPopover.fragments.InstructionPopover}
`

export default StageTable
