import {
  BellOutlined,
  CalendarOutlined,
  ProfileOutlined,
  SettingOutlined,
  UnorderedListOutlined,
  UserAddOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Layout } from 'antd'
import { any, propEq, reduce } from 'ramda'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import sovIcon from '../../../static/images/entrance/sov_icon.png'
import { RouteKey, routeMap } from '../../../utils/routes'

const { Sider } = Layout

interface BarItem {
  route: RouteKey
  title?: string
  icon: React.ReactElement
}

const topItems: BarItem[] = [
  {
    route: 'PatientCreate',
    icon: <UserAddOutlined className='sider-bar-item-icon' />,
  },
  {
    route: 'PatientList',
    icon: <UserOutlined className='sider-bar-item-icon' />,
  },
  {
    route: 'OrderList',
    icon: <ProfileOutlined className='sider-bar-item-icon' />,
  },
  {
    route: 'InvoiceList',
    icon: <UnorderedListOutlined className='sider-bar-item-icon' />,
  },
  {
    route: 'AppointmentCalendar',
    icon: <CalendarOutlined className='sider-bar-item-icon' />,
  },
  {
    route: 'NotificationList',
    icon: <BellOutlined className='sider-bar-item-icon' />,
  },
]

const bottomItems: BarItem[] = [
  {
    route: 'AccountDetail',
    icon: <SettingOutlined className='sider-bar-item-icon' />,
  },
]

const BarItem = styled((props) => {
  const { className, route, active, icon } = props

  return (
    <Link
      className={`${className} ${active ? 'active' : ''}`}
      to={routeMap[route].path}
    >
      {icon}
      <div className='sider-bar-item-title'>{routeMap[route].title}</div>
    </Link>
  )
})`
  display: block;
  height: 68px;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 8px;
  border-left: 2px solid white;

  .sider-bar-item-icon {
    color: rgba(0, 0, 0, 0.65);
    font-size: 18px;
    margin-top: 10px;
  }

  .sider-bar-item-title {
    color: rgba(0, 0, 0, 0.45);
    margin-top: 6px;
  }

  &:hover,
  &:active,
  &.active {
    border-left: 2px solid #1890ff;
    .sider-bar-item-icon,
    .sider-bar-item-title {
      color: #159bff;
    }
  }
`

const Bar = styled((props) => {
  const { className } = props
  const { pathname } = useLocation()

  const topItemPropsList = reduce<BarItem, BarItem[]>((list, item) => {
    let active = pathname.startsWith(routeMap[item.route].path)

    /** only one sideBar can be active */
    if (any(propEq('active', true), list)) {
      active = false
    }
    return [...list, { ...item, active }]
  }, [])(topItems)

  return (
    <div className={className}>
      <div>
        {topItemPropsList.map((prop) => (
          <BarItem key={prop.route} {...prop} />
        ))}
      </div>
      <div className='extended-blank' />
      <div>
        {bottomItems.map((item, index) => {
          const active = pathname.startsWith(routeMap[item.route].path)
          return <BarItem key={index} active={active} {...item} />
        })}
      </div>
    </div>
  )
})`
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  z-index: 1000;

  .extended-blank {
    min-height: 152px;
  }
`

const Logo = styled.div`
  height: 32px;
  font-size: 28px;
  color: #781b65;
  margin: 24px;
  text-align: center;
  background-image: url(${sovIcon});
  background-size: cover;
  cursor: pointer;
`

const StyledSider = styled(Sider)`
  overflow: auto;
  height: 100vh;
  position: fixed;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  z-index: 999;
`

const LayoutSider = ({ collapsed }) => {
  return (
    <StyledSider width={80} trigger={null} collapsible collapsed={collapsed}>
      <Link to='/'>
        <Logo />
      </Link>
      <Bar />
    </StyledSider>
  )
}

export default LayoutSider
