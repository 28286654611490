import { Form } from '@ant-design/compatible'
import {
  ValidationRule,
  WrappedFormUtils,
} from '@ant-design/compatible/lib/form/Form'
import { Checkbox, DatePicker, Input } from 'antd'
import { FormItemProps } from 'antd/lib/form'
import moment from 'moment'
import { isNil } from 'ramda'
import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'

import { AppointmentFragment } from '../../../codegen/types'
import { authContext } from '../../../utils/context'
import EntityName from '../../EntityName'
import { PatientSelect } from '../../select'

interface Props {
  form: WrappedFormUtils<InitialValue>
  item?: AppointmentFragment
}

interface InitialValue {
  patient?: string
  startDate: moment.Moment
  endDate: moment.Moment
  description?: string
  isAttended: boolean | false
  creator?: string
}

/** @todo migrate到 FORM */
const HideableFormItem = styled(Form.Item)<{ hidden: boolean } & FormItemProps>`
  ${(props) => props.hidden && 'display: none;'}
`

const AppointmentForm = (props: Props) => {
  const auth = useContext(authContext)
  const { form, item } = props
  const { getFieldDecorator, setFieldsValue } = form

  const initialValue: InitialValue = isNil(item)
    ? {
        startDate: moment(),
        endDate: moment(),
        isAttended: false,
        creator: auth?.entity.id,
      }
    : {
        patient: item.patient.id,
        startDate: moment(item.startDate),
        endDate: moment(item.endDate),
        description: item.description,
        isAttended: item.isAttended,
        creator: item.creator.id,
      }

  const rules: { [K in keyof InitialValue]?: ValidationRule[] } = {
    patient: [{ required: true, message: '必填欄位' }],
    startDate: [{ required: true, message: '必填欄位' }],
    endDate: [{ required: true, message: '必填欄位' }],
  }

  useEffect(() => {
    getFieldDecorator('creator', {
      initialValue: initialValue.creator,
    })
    getFieldDecorator('isAttended', {
      initialValue: initialValue.isAttended,
    })
  }, [])

  return (
    <Form labelCol={{ span: 6 }} wrapperCol={{ span: 12 }}>
      <Form.Item label='病患姓名'>
        {getFieldDecorator('patient', {
          initialValue: initialValue.patient,
          rules: rules.patient,
        })(isNil(item) ? <PatientSelect /> : <div>{item.patient.name}</div>)}
      </Form.Item>
      <Form.Item label='約診日期'>
        {getFieldDecorator('startDate', {
          initialValue: initialValue.startDate,
          rules: rules.startDate,
        })(
          <DatePicker
            disabledDate={(date) =>
              (date && date < moment().startOf('d')) ?? false
            }
            format='YYYY-MM-DD HH:mm'
            showTime={{ format: 'HH:mm' }}
            onChange={(val) =>
              setFieldsValue({ endDate: val ? moment(val).add(30, 'm') : null })
            }
          />
        )}
        <div>至</div>
        {getFieldDecorator('endDate', {
          initialValue: initialValue.endDate,
          rules: rules.endDate,
        })(
          <DatePicker
            disabledDate={(date) =>
              (date && date < moment().startOf('d')) ?? false
            }
            format='YYYY-MM-DD HH:mm'
            showTime={{ format: 'HH:mm' }}
          />
        )}
      </Form.Item>
      <Form.Item label='約診備註'>
        {getFieldDecorator('description', {
          initialValue: initialValue.description,
        })(<Input.TextArea />)}
      </Form.Item>
      <HideableFormItem label='是否到診' hidden={isNil(item)}>
        {getFieldDecorator('isAttended', {
          valuePropName: 'checked',
          initialValue: initialValue.isAttended,
        })(<Checkbox />)}
      </HideableFormItem>
      <HideableFormItem label='約診建立者' hidden={isNil(item)}>
        {getFieldDecorator('creator', {
          initialValue: initialValue.creator,
        })(<EntityName entity={item?.creator} />)}
      </HideableFormItem>
    </Form>
  )
}

export default AppointmentForm
