import React from 'react'

import { Auxiliary, AuxiliaryType } from '../../../codegen/types'
import { getOffsetX, getOffsetY, isTopTeeth } from '../../InstructionUtils'
import config from '../../InstructionUtils/config'

interface Props {
  item: Auxiliary
}

/* 顯示 button */
const AuxiliaryButton = (props: Props) => {
  const { item } = props
  const { type, toothPosition, auxiliaryPosition } = item

  // type 不對或者資料有問題則回傳 null
  if (type !== AuxiliaryType.Button) {
    return null
  }
  const radius = 4
  const xOffset = getOffsetX(toothPosition, auxiliaryPosition, radius * 2)
  const yOffset = getOffsetY(toothPosition, auxiliaryPosition, radius * 2)
  const cx = config.tooth[toothPosition].centerＸ + xOffset
  const cy = isTopTeeth(toothPosition)
    ? config.top.cy + yOffset
    : config.bottom.cy + yOffset
  return <circle cx={cx} cy={cy} r={4} className='brown' />
}

export default AuxiliaryButton
