import { Form } from '@ant-design/compatible'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { ErrorHandling } from '@sov/common'
import { useRequest } from '@sov/ui'
import { Button, Input } from 'antd'
import { pathOr } from 'ramda'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useRouteMatch } from 'react-router-dom'

import Layout from '../components/Layout'

interface ResetPasswordEmailFormInput {
  email: string
}

type ResetPasswordFormProps = {
  handleSubmit: (x: FormComponentProps['form']) => void
  loading: boolean
} & FormComponentProps<ResetPasswordEmailFormInput>

const FormItem = Form.Item

const ResetPasswordEmailForm = Form.create<ResetPasswordFormProps>()(
  ({ form, handleSubmit, loading }: ResetPasswordFormProps) => {
    const { getFieldDecorator } = form
    const { t } = useTranslation()
    return (
      <Form>
        <FormItem>
          {getFieldDecorator('password', {
            rules: [
              {
                pattern: new RegExp('^[a-zA-Z0-9]'),
                message: t(`validate.password.required`),
              },
              {
                max: 20,
                message: t(`validate.password.max`),
              },
              {
                min: 4,
                message: t(`validate.password.min`),
              },
              {
                required: true,
                message: t(`validate.required`),
              },
            ],
          })(<Input.Password placeholder='新密碼' type='password' />)}
        </FormItem>
        <FormItem>
          {getFieldDecorator('confirmPassword', {
            rules: [
              {
                validator: (rule, value, cb) => {
                  if (value && value !== form.getFieldValue('password')) {
                    cb('輸入密碼不同')
                  } else {
                    cb()
                  }
                },
              },
              {
                required: true,
                message: t(`validate.required`),
              },
            ],
          })(<Input.Password placeholder='重新輸入新密碼' type='password' />)}
        </FormItem>
        <FormItem style={{ marginTop: '24px' }}>
          <Button
            type='primary'
            style={{ width: '100%' }}
            loading={loading}
            onClick={() => handleSubmit(form)}
          >
            確定送出
          </Button>
        </FormItem>
      </Form>
    )
  }
)

const ResetPassword = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const accountId = pathOr('', ['params', 'accountId'], match)
  const token = pathOr('', ['params', 'token'], match)
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const [loading, setLoading] = useState(false)
  const [init, setInit] = useState(false)
  const request = useRequest()

  useEffect(() => {
    // 進到頁面先檢查 token
    const checkToken = async () => {
      const res = await request.post(`/auth/confirmResetPasswordEmailToken`, {
        accountId,
        token,
      })

      if (res.status === 200) {
        setInit(true)
      } else if (res.status === 404) {
        history.replace('/invalidResetPasswordUrl')
      } else {
        toErrorPage(res.message)
      }
    }
    checkToken()
  }, [])

  const resetPassword = async (password: string) => {
    const res = await request.post('/auth/resetPassword', {
      accountId,
      token,
      plainNewPassword: password,
    })
    if (res.status === 200) {
      history.replace('/resetPasswordComplete')
    } else {
      toErrorPage(res.message)
    }
  }

  const handleSubmit = async (form: FormComponentProps['form']) => {
    const { getFieldValue, validateFields } = form
    validateFields(async (err) => {
      if (err) {
        return null
      }
      setLoading(true)
      const password = getFieldValue('password')
      await resetPassword(password)
    })
  }

  return (
    <>
      {init && (
        <Layout
          title='重設密碼'
          description='請重新設定您的新密碼'
          content={
            <ResetPasswordEmailForm
              handleSubmit={handleSubmit}
              loading={loading}
            />
          }
        />
      )}
    </>
  )
}

export default ResetPassword
