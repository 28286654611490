import { getEmptyText } from '@sov/common'
import React, { CSSProperties } from 'react'

interface Props {
  className?: string
  style?: CSSProperties
  text?: string
  emptyText?: string
}

const renderText = (text: string) => {
  const breakByLine = text.split('\n')
  return breakByLine.map((line, index) => (
    <div style={{ width: '100%', minHeight: 21 }} key={index}>
      {line}
    </div>
  ))
}

const MultiLine = (props: Props) => {
  const { className, style, text, emptyText } = props
  return (
    <div className={className} style={style}>
      {text ? renderText(text) : getEmptyText(emptyText)}
    </div>
  )
}

export default MultiLine
