import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    backend: {
      /** use static files in crm server */
      loadPath: `${process.env.API_HOST}/public/locales/{{lng}}/{{ns}}.json`,
    },
    fallbackLng: 'zh-TW',
    load: 'currentOnly',
    // debug: true,
    interpolation: {
      /* not needed for react as it escapes by default */
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  })

export { i18n }

export default i18n
