import moment from 'moment'
import React, { FC } from 'react'

interface DateTimeIntervalProps {
  className?: string
  start: moment.Moment
  end?: moment.Moment
  dateFormat?: string
  timeFormat?: string
}

const DateTimeInterval: FC<DateTimeIntervalProps> = (props) => {
  const {
    className,
    start,
    end,
    dateFormat = 'YYYY-MM-DD',
    timeFormat = 'HH:mm',
  } = props

  /**
   * 僅傳入 start
   * => DATE:TIME~TIME
   */
  if (!end) {
    return (
      <div className={className}>
        {start.format(`${dateFormat} ${timeFormat}`)}~
        {start.endOf('D').format(timeFormat)}
      </div>
    )
  }

  /**
   * start 與 end 同一天
   * => DATE:TIME~TIME
   */
  if (start.isSame(end, 'D')) {
    return (
      <div className={className}>
        {start.format(`${dateFormat} ${timeFormat}`)}~{end.format(timeFormat)}
      </div>
    )
  }

  /**
   * start 與 end 不同天
   * => DATE:TIME~DATE:TIME
   */
  return (
    <div className={className}>
      {start.format(`${dateFormat} ${timeFormat}`)}~
      {end.format(`${dateFormat} ${timeFormat}`)}
    </div>
  )
}

export default DateTimeInterval
