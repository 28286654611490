/* eslint max-len: 'off' */
import React from 'react'

const Split = () => {
  return (
    <svg
      width='36px'
      height='36px'
      viewBox='0 0 36 36'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>split</title>
      <desc>Created with Sketch.</desc>
      <defs>
        <polygon id='path-1' points='0 0 27 0 27 13 0 13' />
        <polygon id='path-3' points='0 0 27 0 27 13 0 13' />
      </defs>
      <g
        id='split'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g id='Group-6' transform='translate(5.000000, 20.000000)'>
          <mask id='mask-2' fill='white'>
            <use href='#path-1' />
          </mask>
          <g id='Clip-5' />
          <path
            d='M23.5752729,0 C22.5633547,5.22880117 18.4371549,9.14395194 13.5000447,9.14395194 C8.56284515,9.14395194 4.4366453,5.22880117 3.42472711,0 L0,0 C1.03067893,7.36666433 6.68203701,13 13.5000447,13 C20.317963,13 25.9693211,7.36666433 27,0 L23.5752729,0 Z'
            id='Fill-4'
            fill='#ED9893'
            mask='url(#mask-2)'
          />
        </g>
        <g id='Group-3' transform='translate(5.000000, 3.000000)'>
          <mask id='mask-4' fill='white'>
            <use href='#path-3' />
          </mask>
          <g id='Clip-2' />
          <path
            d='M3.38177496,13 C4.26769017,7.68163644 8.46058835,3.65362181 13.5,3.65362181 C18.5395899,3.65362181 22.732399,7.68163644 23.618225,13 L27,13 C26.0546256,5.65022272 20.371147,0 13.5,0 C6.62885301,0 0.945374406,5.65022272 0,13 L3.38177496,13 Z'
            id='Fill-1'
            fill='#ED9893'
            mask='url(#mask-4)'
          />
        </g>
      </g>
    </svg>
  )
}

export default Split
