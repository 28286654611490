import React from 'react'
import styled from 'styled-components'

import BraceIcon from './BraceIcon'
import { getHasStepInstruction } from './getHasStepInstruction'
import InstructionStep from './InstructionStep'
import { StepInfo } from './UpcomingStepListTimeline'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const IconContainer = styled.div`
  margin-bottom: 16px;
`

const FirstStepInstructionDescription = styled.div`
  margin-bottom: 24px;
`

const FirstStepInstructionLabel = styled.div`
  margin-bottom: 8px;
`

const FirstStepInstructionContainer = styled.div`
  display: flex;

  & > :not(:first-child) {
    margin-left: 8px;
  }
`

interface FirstStepInstructionProps {
  stepInfo: StepInfo
}

const FirstStepInstruction = (props: FirstStepInstructionProps) => {
  const { stepInfo } = props
  const hasStepInstruction = getHasStepInstruction(stepInfo)

  return (
    <Container>
      <IconContainer>
        <BraceIcon imageWidth={40} />
      </IconContainer>
      <FirstStepInstructionDescription>
        建立病患的第一個牙套訂單
      </FirstStepInstructionDescription>
      <FirstStepInstructionLabel>Step 1 臨床操作</FirstStepInstructionLabel>
      <div>
        {hasStepInstruction && (
          <FirstStepInstructionContainer>
            {stepInfo?.ipr && <InstructionStep label='IPR' />}
            {stepInfo?.attachment && <InstructionStep label='ATTA' />}
            {stepInfo?.button && <InstructionStep label='BUTTON' />}
            {stepInfo?.retrieve && <InstructionStep label='重取模' />}
          </FirstStepInstructionContainer>
        )}
      </div>
    </Container>
  )
}

export default FirstStepInstruction
