import { exhaustiveCheck } from './exhaustiveCheck'
import { NotificationCategory, NotificationType } from './types'

export const getNotificationTypesFromCategory = (
  category: NotificationCategory
): NotificationType[] => {
  switch (category) {
    case NotificationCategory.Task:
      return [
        NotificationType.TaskAssigned,
        NotificationType.TaskRejected,
        NotificationType.StageExpectedShippingDateUpdated,
      ]
    case NotificationCategory.Order:
      return [
        NotificationType.OrderCreated,
        NotificationType.OrderInProgress,
        NotificationType.OrderCompleted,
        NotificationType.OrderDeleted,
      ]
    case NotificationCategory.Patient:
      return [
        NotificationType.PatientCreated,
        NotificationType.PatientUpdated,
        NotificationType.AppointmentCreated,
        NotificationType.AppointmentUpdated,
      ]
    case NotificationCategory.Report:
      return [NotificationType.CurrentEvalSelected]
    case NotificationCategory.System:
      return [
        NotificationType.RegistrationRequested,
        NotificationType.RegistrationApproved,
      ]
    case NotificationCategory.Track:
      return [NotificationType.TrackCreated, NotificationType.TrackReplied]
    default:
      exhaustiveCheck(category)
      return []
  }
}

export const getCategoryFromNotificationTypes = (
  notificationType: NotificationType
): NotificationCategory => {
  switch (notificationType) {
    case NotificationType.PatientCreated:
    case NotificationType.PatientUpdated:
    case NotificationType.AppointmentCreated:
    case NotificationType.AppointmentUpdated:
    case NotificationType.Appointment:
      return NotificationCategory.Patient
    case NotificationType.TaskAssigned:
    case NotificationType.TaskRejected:
    case NotificationType.StageExpectedShippingDateUpdated:
      return NotificationCategory.Task
    case NotificationType.OrderCreated:
    case NotificationType.OrderInProgress:
    case NotificationType.OrderCompleted:
    case NotificationType.OrderDeleted:
      return NotificationCategory.Order
    case NotificationType.CurrentEvalSelected:
    case NotificationType.CurrentEvalUpdated:
      return NotificationCategory.Report
    case NotificationType.TrackCreated:
    case NotificationType.TrackReplied:
      return NotificationCategory.Track
    case NotificationType.RegistrationRequested:
    case NotificationType.RegistrationApproved:
      return NotificationCategory.System
  }
}
