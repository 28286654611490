import React from 'react'
import styled from 'styled-components'

import inProgressImage from '../../../../../assets/order/in-progress.png'

const Container = styled.div`
  display: flex;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px -1px 6px rgba(0, 0, 0, 0.12), 0px 2px 5px rgba(0, 0, 0, 0.15);
  padding: 16px;
  width: fit-content;
`

const Image = styled.img`
  width: 56px;
  height: 56px;
`

const TextContainer = styled.div`
  color: rgba(0, 0, 0, 0.65);
  display: flex;
  flex-direction: column;
  margin-left: 14px;
`

const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
`

const Content = styled.div`
  font-size: 14px;
`

interface HintPopoverProps {
  orderId?: string
  displayId?: string
  isCompletedOrInactive: boolean
}

const HintPopover = (props: HintPopoverProps) => {
  const { orderId, displayId, isCompletedOrInactive } = props

  return (
    <Container>
      {isCompletedOrInactive ? (
        <TextContainer>
          <Content>若您需要新增訂單</Content>
          <Content>請於討論室留言告知。</Content>
        </TextContainer>
      ) : (
        <>
          <Image src={inProgressImage} />
          <TextContainer>
            <Title>
              訂單{' '}
              {orderId && displayId ? (
                <a href={`/orders/${orderId}`}>#{displayId}</a>
              ) : (
                '訂單'
              )}{' '}
              正在進行中
            </Title>
            <Content>若您需要編輯或刪除訂單詳情，</Content>
            <Content>請於討論室留言告知。</Content>
          </TextContainer>
        </>
      )}
    </Container>
  )
}

export default HintPopover
