import { map, values } from 'ramda'
import React from 'react'
import { Route, Switch } from 'react-router-dom'

import EntranceLayout from '../../components/layout/Entrance'
import Login from '../../pages/auth/Login/Login'
import { authRouteMap } from '.'

export default () => {
  return (
    <EntranceLayout>
      <Switch>
        {map((props) => {
          const { path, component: Component } = props
          return (
            <Route
              exact
              key={path}
              path={path}
              render={(props: any) => <Component {...props} />}
            />
          )
        }, values(authRouteMap))}
        <Route path='/' component={Login} />
      </Switch>
    </EntranceLayout>
  )
}
