import { gql, useQuery } from '@apollo/client'
import { map, values } from 'ramda'
import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import {
  PatientConversationQueryQuery,
  PatientConversationQueryQueryVariables,
} from '../../codegen/types'
import PatientConversationDrawer from '../../components/conversation/ConversationDrawer'
import useDrawer from '../../components/conversation/useDrawer'
import MainLayout from '../../components/layout/Main'
import PatientMenu from '../../components/menu/PatientMenu'
import Page from '../../components/page'
import PatientAppointmentList from '../../pages/patient/PatientAppointmentList'
import PatientCreate from '../../pages/patient/PatientCreate'
import PatientDetail from '../../pages/patient/PatientDetail'
import PatientDetailForm from '../../pages/patient/PatientDetailForm'
import PatientInvoiceList from '../../pages/patient/PatientInvoiceList'
import PatientIndex from '../../pages/patient/PatientList'
import PatientOrderList from '../../pages/patient/PatientOrderList'
import PatientStageList from '../../pages/patient/PatientStageList'
import PatientTrackList from '../../pages/patient/PatientTrackList'
import { Route as RouteMap } from '../routes'

export const patientRouteWithMenuKeys = [
  'PatientDetail',
  'PatientInvoiceList',
  'PatientStageList',
  'PatientTrackList',
  'PatientOrderList',
  'PatientAppointmentList',
] as const

export const patientRouteWithoutMenuKeys = [
  'PatientList',
  'PatientCreate',
  'PatientDetailForm',
] as const

export type PatientWithMenuRouteKey = typeof patientRouteWithMenuKeys[number]
export type PatientWithoutMenuRouteKey =
  typeof patientRouteWithoutMenuKeys[number]

export const patientWithMenuRoutes: RouteMap<PatientWithMenuRouteKey> = {
  PatientDetail: {
    title: '病患詳情',
    path: '/patients/:patientId',
    component: PatientDetail,
  },
  PatientStageList: {
    title: '病患療程列表',
    path: '/patients/:patientId/stages',
    component: PatientStageList,
  },
  PatientInvoiceList: {
    title: '病患出貨單列表',
    path: '/patients/:patientId/invoices',
    component: PatientInvoiceList,
  },
  PatientOrderList: {
    title: '病患訂單列表',
    path: '/patients/:patientId/orders',
    component: PatientOrderList,
  },
  PatientTrackList: {
    title: '病患追蹤列表',
    path: '/patients/:patientId/tracks',
    component: PatientTrackList,
  },
  PatientAppointmentList: {
    title: '病患約診列表',
    path: '/patients/:patientId/appointments',
    component: PatientAppointmentList,
  },
}

export const patientWithoutMenuRoutes: RouteMap<PatientWithoutMenuRouteKey> = {
  PatientList: {
    title: '病患列表',
    subTitle: '列出您的所有病患',
    path: '/patients',
    component: PatientIndex,
  },
  PatientCreate: {
    title: '病患新增',
    path: '/patients/create',
    component: PatientCreate,
  },
  PatientDetailForm: {
    title: '病患病例詳情',
    path: '/patients/:patientId/form',
    component: PatientDetailForm,
  },
}

const patientConversationQuery = gql`
  query PatientConversationQuery($id: ID!) {
    patient(id: $id) {
      id
      ...PatientConversation
    }
  }
  ${PatientConversationDrawer.fragment.PatientConversation}
`

interface Params {
  patientId: string
}

const Conversation = () => {
  const match = useRouteMatch<Params>()
  const { patientId } = match.params

  const drawer = useDrawer()
  const { data } = useQuery<
    PatientConversationQueryQuery,
    PatientConversationQueryQueryVariables
  >(patientConversationQuery, {
    variables: {
      id: patientId,
    },
  })

  return data?.patient ? (
    <PatientConversationDrawer patient={data.patient} drawer={drawer} />
  ) : null
}

const PatientRoutes = () => (
  <Switch>
    {map(
      ({ path, component: Component }) => (
        <Route exact key={path} path={path}>
          {(props: any) => (
            <MainLayout>
              <Component {...props} />
            </MainLayout>
          )}
        </Route>
      ),
      values(patientWithoutMenuRoutes)
    )}
    <Route path='/patients/:patientId'>
      <MainLayout>
        <Page header={<PatientMenu />}>
          <Switch>
            {map(
              ({ path, component: Component }) => (
                <Route exact key={path} path={path}>
                  {(props: any) => <Component {...props} />}
                </Route>
              ),
              values(patientWithMenuRoutes)
            )}
          </Switch>
        </Page>
      </MainLayout>
      <Conversation />
    </Route>
  </Switch>
)

export default PatientRoutes
