import { gql, useApolloClient, useMutation } from '@apollo/client'
import { ConversationInput } from '@sov/ui'
import { Moment } from 'moment'
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'

import {
  ConversationMemberInfoFragment,
  CreateMessageMutation,
  CreateMessageMutationVariables,
  Entity,
  MessageInfoFragment,
  MessageType,
  Patient,
} from '../../codegen/types'
import { ConversationContent } from './ConversationContent'
import { TeethSelectionContext } from './ConversationDrawer'
import {
  ConversationInfo,
  ConversationMemberInfo,
  MessageInfo,
} from './fragments'

export enum ScrollType {
  Subscription = 'subscription',
  Query = 'query',
}

enum ConversationScrollPosition {
  BOTTOM = 'bottom',
  TOP = 'top',
  MID = 'mid',
}

export interface HandleSubmitPayload {
  content?: string
  image?: string
}

export interface MessageNodesGroup {
  date: string | Moment
  nodes: MessageInfoFragment[]
}

export const MessageTitleContainer = styled.div`
  padding: 0px 20px;
  color: black;
  border: solid 1px #e0e0e0;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  min-height: 60px;
`

const createMessage = gql`
  mutation createMessage($conversationId: ID!, $payload: CreateMessage!) {
    createMessage(conversationId: $conversationId, payload: $payload) {
      ...messageInfo
    }
  }
  ${MessageInfo}
`

interface Props {
  patient: Pick<Patient, 'name'>
  conversationTitle?: React.ReactNode
  members?: ConversationMemberInfoFragment[]
  loading: boolean
  conversationId: string
  scrollSmooth: boolean
  hasNewMessage?: boolean
  messageNodesGroup: MessageNodesGroup[]
  newMessageShouldScrollState: { type: ScrollType }
  handleNewMessageNotificationOnClick: () => void
  height?: number | string
  scrollToBottomCallback?: () => void
  scrollToTopCallback: (e: any) => void
  entity: Entity
  extraInfo?: JSX.Element
  onReload: () => void
}

const Conversation = (props: Props) => {
  const {
    patient,
    conversationTitle,
    messageNodesGroup,
    newMessageShouldScrollState,
    handleNewMessageNotificationOnClick,
    conversationId,
    loading,
    scrollToTopCallback,
    scrollToBottomCallback,
    scrollSmooth,
    height,
    hasNewMessage,
    entity,
    extraInfo,
    onReload,
  } = props
  const conversationRef = useRef<HTMLDivElement>(null)
  const TeethSelection = useContext(TeethSelectionContext)
  const apolloClient = useApolloClient()

  const [conversationScrollPosition, setConversationScrollPosition] =
    useState<ConversationScrollPosition>(ConversationScrollPosition.BOTTOM)
  const [newMessageNotificationVisible, setNewMessageNotificationVisible] =
    useState(false)
  const [sendMessageThrottling, setSendMessageThrottling] = useState(false)

  const [addMessage] = useMutation<
    CreateMessageMutation,
    CreateMessageMutationVariables
  >(createMessage)

  // scrolling helper functions
  const autoScrollToBottom = (node) => {
    node.scrollTop = node.scrollHeight
    setConversationScrollPosition(ConversationScrollPosition.BOTTOM)
  }
  const autoScrollToTop = (node) => {
    // need a better calculation: heights are not the same for every page now
    node.scrollTop = node.clientHeight
    setConversationScrollPosition(ConversationScrollPosition.MID)
  }

  // new message should be marked as read if position is at bottom
  useEffect(() => {
    if (
      hasNewMessage &&
      conversationScrollPosition === ConversationScrollPosition.BOTTOM &&
      scrollToBottomCallback
    ) {
      scrollToBottomCallback()
    }
  }, [hasNewMessage])

  useLayoutEffect(() => {
    if (conversationRef?.current) {
      switch (newMessageShouldScrollState.type) {
        case ScrollType.Subscription:
          autoScrollToBottom(conversationRef.current)
          break
        case ScrollType.Query:
          autoScrollToTop(conversationRef.current)
          break
      }
    }
  }, [newMessageShouldScrollState])

  // When new data come, scroll to bottom if originally at bottom
  useLayoutEffect(() => {
    if (conversationRef?.current) {
      switch (conversationScrollPosition) {
        case ConversationScrollPosition.BOTTOM:
          autoScrollToBottom(conversationRef.current)
          break
        // @TODO 應該由這邊處理，需要研究一下作法
        // case ConversationScrollPosition.TOP:
        //   autoScrollToTop(conversationRef.current)
        //   break
      }
    }
  }, [messageNodesGroup])

  // run callback when scrolled to bottom
  useEffect(() => {
    if (conversationScrollPosition === ConversationScrollPosition.BOTTOM) {
      if (scrollToBottomCallback) {
        scrollToBottomCallback()
      }
    }
  }, [conversationScrollPosition])

  // when user scroll, set different state
  const handleConversationScroll = (e: any) => {
    const conversation = e.target
    const scrollbarAtBottomOfConversation =
      conversation.scrollHeight - conversation.clientHeight ===
      conversation.scrollTop
    const scrollbarAtTopOfConversation = conversation.scrollTop === 0
    if (scrollbarAtBottomOfConversation) {
      setConversationScrollPosition(ConversationScrollPosition.BOTTOM)
      setNewMessageNotificationVisible(false)
    } else if (scrollbarAtTopOfConversation) {
      setConversationScrollPosition(ConversationScrollPosition.TOP)
      setNewMessageNotificationVisible(true)
      scrollToTopCallback(e)
    } else {
      setConversationScrollPosition(ConversationScrollPosition.MID)
      setNewMessageNotificationVisible(true)
    }
  }

  const handleInputResize = () => {
    if (
      conversationScrollPosition === ConversationScrollPosition.BOTTOM &&
      conversationRef.current
    ) {
      autoScrollToBottom(conversationRef.current)
    }
  }
  const handleSubmit = async (payload: HandleSubmitPayload) => {
    if (sendMessageThrottling) {
      return
    }
    setSendMessageThrottling(true)
    const { content, image } = payload
    if (image) {
      await addMessage({
        variables: {
          conversationId,
          payload: {
            type: MessageType.Image,
            creator: entity.id,
            image,
          },
        },
        update: async (cache, { data }) => {
          if (data) {
            if (conversationRef && conversationRef.current) {
              autoScrollToBottom(conversationRef.current)
            }
          }
          setSendMessageThrottling(false)
        },
      })
    }
    if (content) {
      await addMessage({
        variables: {
          conversationId,
          payload: {
            type: MessageType.Text,
            creator: entity.id,
            content,
          },
        },
        update: async (cache, { data }) => {
          if (data) {
            if (conversationRef && conversationRef.current) {
              autoScrollToBottom(conversationRef.current)
            }
          }
          setSendMessageThrottling(false)
        },
      })
    }
  }

  const handleNewMessageNotificationClick = () => {
    handleNewMessageNotificationOnClick()
    if (scrollToBottomCallback) {
      scrollToBottomCallback()
    }
  }

  return (
    <div
      style={{
        height: height || '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {conversationTitle}
      {extraInfo}
      <ConversationContent
        patient={patient}
        ref={conversationRef}
        scrollSmooth={scrollSmooth}
        onScroll={handleConversationScroll}
        messageNodesGroup={messageNodesGroup}
        handleNewMessageNotificationClick={handleNewMessageNotificationClick}
        newMessageButtonVisible={newMessageNotificationVisible && hasNewMessage}
        loading={loading}
        onReload={onReload}
      />
      <ConversationInput
        handleInputResize={handleInputResize}
        handleSubmit={handleSubmit}
        uploadConfig={{
          apolloClient,
          namePrefix: `conversation/${conversationId}/`,
        }}
        /** @todo 待 conversation 元件全部搬到 ui 後，ConversationInput 應直接用 context 取得 teethSelection */
        teethSelection={TeethSelection}
      />
    </div>
  )
}

Conversation.fragments = {
  conversationMemberInfo: ConversationMemberInfo,
  conversationInfo: ConversationInfo,
}

export default Conversation
