import './index.less'

import { gql, useQuery, useSubscription } from '@apollo/client'
import { Auth, ErrorHandling } from '@sov/common'
import { MessageInbox } from '@sov/ui'
import { isNil } from 'ramda'
import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  MessageInboxQueryQuery,
  MessageInboxQueryQueryVariables,
  OnMessageAddedSubscriptionSubscription,
  OnMessageAddedSubscriptionSubscriptionVariables,
  SubscriptionType,
} from '../../../../codegen/types'
import { onMessageAddedSubscription } from '../../../../graphql/message/subscription/onMessageAdded'
import { authContext } from '../../../../utils/context'

const { getUserEntityIdFromAuth } = Auth.utils

const messageInboxQuery = gql`
  query MessageInboxQuery(
    $query: ConversationsWithLatestMessageQuery = {}
    $first: Int
    $after: String
  ) {
    conversationsWithLatestMessage(
      query: $query
      first: $first
      after: $after
    ) {
      ...MessageInbox
    }
  }
  ${MessageInbox.fragment}
`
const MessageInboxIcon = () => {
  const auth = useContext(authContext)
  // 在聊天室列表頁面不需要提示新訊息
  const [isPopoverVisible, setIsPopoverVisible] = useState(false)

  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const history = useHistory()

  const {
    data: conversationsWithLatestMessageData,
    refetch,
    error,
  } = useQuery<MessageInboxQueryQuery, MessageInboxQueryQueryVariables>(
    messageInboxQuery,
    {
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'none',
      variables: {
        first: 10,
      },
      skip: !auth,
    }
  )

  useSubscription<
    OnMessageAddedSubscriptionSubscription,
    OnMessageAddedSubscriptionSubscriptionVariables
  >(onMessageAddedSubscription, {
    variables: {
      payload: {
        subscriptionType: SubscriptionType.Self,
        subscriber: getUserEntityIdFromAuth(auth),
      },
    },
    // fetchPolicy: 'network-only',
    onSubscriptionData: (data) => {
      if (data?.subscriptionData?.data?.onMessageAdded) {
        refetch()
      }
    },
  })

  if (error) {
    toErrorPage(error.message)
  }

  const messageEdges =
    conversationsWithLatestMessageData?.conversationsWithLatestMessage?.edges ??
    []

  const handleMessageOnClick = (conversationId: string) => {
    setIsPopoverVisible(false)
    history.push(`/conversations?conversationId=${conversationId}`)
  }

  const handleTogglePopover = (visible?: boolean) =>
    setIsPopoverVisible((state) => (isNil(visible) ? !state : visible))

  return (
    <MessageInbox
      messageEdges={messageEdges}
      isPopoverVisible={isPopoverVisible}
      handleTogglePopover={handleTogglePopover}
      handleMessageOnClick={handleMessageOnClick}
    />
  )
}

export default MessageInboxIcon
