import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import React from 'react'
import styled from 'styled-components'

const Indicator = <LoadingOutlined style={{ fontSize: 24 }} spin />

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const LoadingIconContainer = styled.div`
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Text = styled.div`
  color: rgba(0, 0, 0, 0.65);
`

const LoadingModalBody = () => {
  return (
    <Container>
      <LoadingIconContainer>
        <Spin indicator={Indicator} />
        <Text>資料傳輸中，請勿重新整理或離開頁面</Text>
      </LoadingIconContainer>
    </Container>
  )
}

export default LoadingModalBody
