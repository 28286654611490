import { gql } from '@apollo/client'
import { Auth, getEntityTitle } from '@sov/common'
import { Badge } from 'antd'
import moment from 'moment'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  ImageMessage,
  LatestMessageBlockFragment,
  MessageType,
  TextMessage,
} from '../../../codegen/types'
import { MessageInfo } from '../../../components/conversation/fragments'
import { authContext } from '../../../utils/context'

const { isItemOwner } = Auth.utils

const LatestMessageBlockContainer = styled.div`
  padding: 0 12px;
`

const LatestMessageBlockRow = styled.div`
  padding: 12px 0;
  border-bottom: 1px solid #e8e8e8;
`

const PatientNameContainer = styled.div`
  font-size: 17px;
  width: 60px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 500;
  color: black;
`

const BadgeContainer = styled.div`
  margin-left: auto;
`

interface ContentContainerProps {
  hasUreadMessages: boolean
}

const ContentContainer = styled.div<ContentContainerProps>`
  font-size: 14px;
  width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: normal;
  color: ${(props) => (props.hasUreadMessages ? 'black' : '#d9d9d9')};
`

interface LatestMessageBlockProps {
  node: LatestMessageBlockFragment
  handleClick: (patient: string) => void
  selectedConversation: string
}

const LatestMessageBlock = ({
  node,
  handleClick,
  selectedConversation,
}: LatestMessageBlockProps) => {
  const auth = useContext(authContext)
  if (!auth) {
    return null
  }

  const {
    id,
    latestMessage,
    numberOfUnreadMessages,
    patient: { name: patientName },
  } = node

  const { type, content, created, creator } = latestMessage as TextMessage &
    ImageMessage

  const { t } = useTranslation()
  const { id: creatorId, name: creatorName } = creator
  const isSelected = selectedConversation === id
  const hasUreadMessages = numberOfUnreadMessages !== 0
  const renderCreator = isItemOwner(creatorId, auth)
    ? '你'
    : `${getEntityTitle(creator, t)} ${creatorName}`

  return (
    <LatestMessageBlockContainer
      onClick={() => handleClick(id)}
      style={{ borderLeft: isSelected ? '4px solid #1890ff' : '' }}
    >
      <LatestMessageBlockRow>
        <div style={{ padding: '0 12px' }}>
          <div style={{ display: 'flex', width: '100%', padding: '10px 0' }}>
            <PatientNameContainer>{patientName}</PatientNameContainer>
            <div
              style={{
                color: '#bfbfbf',
                marginLeft: '12px',
                fontWeight: 'normal',
                fontSize: '14px',
              }}
            >
              {moment(created).fromNow()}
            </div>
            <BadgeContainer>
              <Badge dot={hasUreadMessages} />
            </BadgeContainer>
          </div>
          <ContentContainer
            hasUreadMessages={hasUreadMessages}
          >{`${renderCreator} : ${
            type === MessageType.Image ? '傳送了一張圖片' : content
          }`}</ContentContainer>
        </div>
      </LatestMessageBlockRow>
    </LatestMessageBlockContainer>
  )
}

LatestMessageBlock.fragment = gql`
  fragment LatestMessageBlock on ConversationsWithLatestMessageNode {
    id
    latestMessage {
      ...messageInfo
    }
    numberOfUnreadMessages
    patient {
      id
      name
    }
  }
  ${MessageInfo}
`

export default LatestMessageBlock
