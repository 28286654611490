import { gql, useMutation } from '@apollo/client'
import { Button, Card, Row, Spin, message } from 'antd'
import Form from 'antd/lib/form'
import { always, cond, equals } from 'ramda'
import React, { FC, createRef, useState } from 'react'
import styled from 'styled-components'

import {
  AnglesClassification,
  CephAnalysis,
  CreatePatientInput,
  CreatePatientMutation,
  CreatePatientMutationVariables,
  FaceRatio,
  Gender,
  ImpactedToothInstruction,
  Instruction,
  LipsEstheticLineRelation,
  Need,
  OcculusalMuscle,
  OcculusalPlaneStatus,
  Offset,
  PatientBrand,
  ResidualRootInstruction,
  SmileLine,
  ToothType,
  TreatStatus,
  UneruptedWisdomToothInstruction,
} from '../../../codegen/types'
import { payloadTransformer } from '../../../components/form/patient'
import PatientFormDoctorInstruction from '../../../components/form/patient/DoctorInstruction'
import PatientFormGeneral from '../../../components/form/patient/General'
import PatientFormIntraOral from '../../../components/form/patient/IntraOral'
import PatientFormMalocclusions from '../../../components/form/patient/Malocclusions'
import PatientFormPhoto from '../../../components/form/patient/Photo'
import PatientFormProfile from '../../../components/form/patient/Profile'
import PatientFormSymptoms from '../../../components/form/patient/Symptoms'
import Page from '../../../components/page/index'
import { useLoadingLayer } from '../../../utils/hooks/general'
import FinishStep from './FinishStep'
import StepBar from './StepBar'

const createPatientMutation = gql`
  mutation CreatePatient($payload: CreatePatientInput!) {
    createPatient(payload: $payload) {
      id
    }
  }
`

const SectionCard = styled(Card)`
  width: 820px;
  margin: 0 auto 16px;
  &:last-child {
    margin-bottom: 0;
  }
`

const Step = styled.div<{ visible: boolean }>`
  display: ${(props) => (props.visible ? 'initial' : 'none')};
`
const generalInitialValues = {
  gender: Gender.Female,
  payment: {
    brand: PatientBrand.Unknown,
  },
  treatArches: ['upper', 'lower'],
}

const profileInitialValues = {
  profile: {
    mandibularMidlineToFacialMidline: {
      offset: Offset.Centered,
      instruction: Instruction.Idealize,
    },
    occulusalPlane: {
      occulusalPlaneStatus: OcculusalPlaneStatus.Level,
      instruction: Instruction.Idealize,
    },
    estheticLine: {
      lipsEstheticLineRelation: LipsEstheticLineRelation.In,
      instruction: Instruction.Idealize,
    },
    cephAnalysis: CephAnalysis.Normal,
    faceRatio: FaceRatio.Normal,
    occulusalMuscle: OcculusalMuscle.Normal,
    hasGummySmile: false,
    smileLine: SmileLine.Great,
    hasBuccalCorridor: true,
  },
}

const intraOralInitialValues = {
  intraOral: {
    teethStatus: [],
    temporomandibularJointDisordersTreatStatus: TreatStatus.No,
    periodontalDiseaseTreatStatus: TreatStatus.No,
    residualRoot: {
      hasResidualRoot: false,
      instruction: ResidualRootInstruction.Maintain,
    },
    impactedTooth: {
      hasImpactedTooth: false,
      instruction: ImpactedToothInstruction.DontMove,
    },
    uneruptedWisdomTooth: {
      hasUneruptedWisdomTooth: false,
      instruction: UneruptedWisdomToothInstruction.DontDistalize,
    },
    maxillarMidlineToFacialMidline: {
      offset: Offset.Centered,
      instruction: Instruction.Idealize,
    },
    mandibularMidlineToMaxillarMidline: {
      offset: Offset.Centered,
      instruction: Instruction.Idealize,
    },
  },
}

const malocclusionsInitialValues = {
  symptoms: {
    leftMolarRelationship: AnglesClassification.Class1,
    rightMolarRelationship: AnglesClassification.Class1,
  },
}

const symptomsInitialValues = {
  symptoms: {
    anteriorCrowding: false,
    posteriorCrowding: false,
    openBite: false,
    deepBite: false,
    highCanine: false,
    anteriorCrossbite: false,
    posteriorCrossbite: false,
    bimaxillaryProtrusion: false,
    vShapeArch: false,
  },
}

const doctorInstructionInitialValues = {
  doctorInstruction: {
    ipr: Need.OnlyIfNeeded,
    tads: Need.OnlyIfNeeded,
    otherOrthdontalTools: Need.OnlyIfNeeded,
    functionalAppliance: Need.OnlyIfNeeded,
    maintainSpace: {
      isMaintainingSpace: false,
    },
    changeProsthesisToTemp: {
      need: Need.OnlyIfNeeded,
    },
    extractToothWhenNoSpace: {
      intention: Need.OnlyIfNeeded,
      toothType: ToothType.Wisdom,
    },
    note: '',
  },
}

interface BottomSectionProps {
  isFirst: boolean
  isLast: boolean
  handleNextStep: () => void
  handlePrevStep: () => void
  handleSubmit: () => void
}

const BottomSection = (props: BottomSectionProps) => {
  const { isFirst, isLast, handlePrevStep, handleNextStep, handleSubmit } =
    props

  return (
    <SectionCard>
      <Row justify='space-between'>
        {isFirst ? (
          <div />
        ) : (
          <Button type='link' onClick={handlePrevStep}>
            上一步
          </Button>
        )}
        {isLast ? (
          <Button type='primary' onClick={handleSubmit}>
            完成
          </Button>
        ) : (
          <Button type='primary' onClick={handleNextStep}>
            下一步
          </Button>
        )}
      </Row>
    </SectionCard>
  )
}

const FIRST_STEP = 0
const LAST_STEP = 2
const FINISH_STEP = 3

const FIRST_STEP_FIELDS = [
  'name',
  'gender',
  'payment.brand',
  'clinic',
  'doctor',
  'accountManager',
  'technician',
  'sales',
  'customerService',
  'treatArches',
  'photos',
]
const SECOND_STEP_FIELDS = ['profile', 'intraOral']
const THIRD_STEP_FIELDS = ['symptoms', 'doctorInstruction', 'chiefComplaint']

const PatientCreate: FC = () => {
  const [currentStep, setCurrentStep] = useState(FIRST_STEP)
  const [newPatientId, setNewPatientId] = useState<string>()
  const pageContent = createRef<HTMLDivElement>()

  const { loading, tip, setLoadingLayer } = useLoadingLayer()
  const [form] = Form.useForm()
  const [create] = useMutation<
    CreatePatientMutation,
    CreatePatientMutationVariables
  >(createPatientMutation)

  const isFinished = currentStep === FINISH_STEP

  const handleNextStep = async () => {
    try {
      pageContent.current?.scrollIntoView({ block: 'start' })
      const validateFields = cond<number, string[]>([
        [equals(0), always(FIRST_STEP_FIELDS)],
        [equals(1), always(SECOND_STEP_FIELDS)],
        [equals(2), always(THIRD_STEP_FIELDS)],
      ])(currentStep)
      await form.validateFields(validateFields)

      setCurrentStep((step) => (step < LAST_STEP ? step + 1 : step))
    } catch (error) {
      form.scrollToField(error.errorFields[0].name)
      message.error(`缺少 ${error.errorFields[0].name}`)
    }
  }
  const handlePrevStep = () => {
    pageContent.current?.scrollIntoView({ block: 'start' })
    setCurrentStep((step) => (step > FIRST_STEP ? step - 1 : step))
  }
  const handleSubmit = async () => {
    try {
      const formValues = await form.validateFields([
        ...FIRST_STEP_FIELDS,
        ...SECOND_STEP_FIELDS,
        ...THIRD_STEP_FIELDS,
      ])
      const payload = payloadTransformer(formValues) as CreatePatientInput

      setLoadingLayer({ loading: true, tip: '新增中...' })

      await create({
        variables: { payload },
        update: (cache, { data }) => {
          if (data?.createPatient) {
            message.info('已新增病患')
            setCurrentStep(FINISH_STEP)
            setNewPatientId(data.createPatient.id)
          }
        },
      })
    } catch (error) {
      if (error.errorFields) {
        /** form errors */
        message.error(`缺少 ${error.errorFields[0].name}`)
      }
      if (error?.message) {
        /** graphQL errors */
        message.error(error.message)
      }
    }
    setLoadingLayer({ loading: false, tip: '' })
  }

  if (loading) {
    return (
      <Page header={<StepBar step={currentStep} />}>
        <Row justify='center'>
          <Spin tip={tip} />
        </Row>
      </Page>
    )
  }

  return (
    <Page header={<StepBar step={currentStep} />}>
      <div ref={pageContent}>
        <Step visible={currentStep === 0}>
          <SectionCard title='基本資料'>
            <PatientFormGeneral
              form={form}
              initialValues={generalInitialValues}
            />
          </SectionCard>
          <SectionCard title='上傳照片'>
            <PatientFormPhoto form={form} />
          </SectionCard>
        </Step>
        <Step visible={currentStep === 1}>
          <SectionCard title='顏面觀'>
            <PatientFormProfile
              form={form}
              initialValues={profileInitialValues}
            />
          </SectionCard>
          <SectionCard title='口內觀'>
            <PatientFormIntraOral
              form={form}
              initialValues={intraOralInitialValues}
            />
          </SectionCard>
        </Step>

        <Step visible={currentStep === 2}>
          <SectionCard title='矯正症狀'>
            <PatientFormMalocclusions
              form={form}
              initialValues={malocclusionsInitialValues}
            />
          </SectionCard>
          <SectionCard title='症狀'>
            <PatientFormSymptoms
              form={form}
              initialValues={symptomsInitialValues}
            />
          </SectionCard>
          <SectionCard title='醫生主要治療指示'>
            <PatientFormDoctorInstruction
              form={form}
              initialValues={doctorInstructionInitialValues}
              page='create'
            />
          </SectionCard>
        </Step>

        {currentStep === FINISH_STEP && newPatientId && (
          <FinishStep newPatientId={newPatientId} />
        )}
      </div>

      {!isFinished && (
        <BottomSection
          isFirst={currentStep === FIRST_STEP}
          isLast={currentStep === LAST_STEP}
          handleNextStep={handleNextStep}
          handlePrevStep={handlePrevStep}
          handleSubmit={handleSubmit}
        />
      )}
    </Page>
  )
}

export default PatientCreate
