import { map } from 'ramda'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import AuthIcon from '../../../components/icon/AuthIcon'

const platforms = ['facebook', 'google', 'line'] as const
type PlatformTuple = typeof platforms
type Platform = PlatformTuple[number]

const InformationBlock = styled.div`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  margin-top: 24px;
  text-align: left;
`

const ExtraInformation = () => {
  const getSocialLoginUrl = (platform: Platform) => {
    const redirect = window.location.origin + window.location.pathname
    const url = `${process.env.API_HOST}/auth/${platform}?redirect=${redirect}`
    return url
  }

  return (
    <InformationBlock>
      <div>
        <span style={{ marginRight: '15px' }}>或你可用其他方式登入</span>
        {map((platform) => {
          return (
            <a key={platform} href={getSocialLoginUrl(platform)}>
              <AuthIcon name={platform} style={{ marginRight: '10px' }} />
            </a>
          )
        }, platforms)}
      </div>
      <div style={{ marginTop: '15px' }}>
        <span>
          還不是 SOV 認證的醫師夥伴嗎，歡迎 <Link to='/register'>申請註冊</Link>
        </span>
      </div>
    </InformationBlock>
  )
}

export default ExtraInformation
