import { includes } from 'ramda'

import AccountInfoForm from '../form/account-info'
import AccountSettingsForm from '../form/account-settings'
import NotificationSettingsForm from '../form/notification-settings'

export const tabKeys = [
  'account-info',
  'account-settings',
  'notification-settings',
] as const

export type TabKey = typeof tabKeys[number]

interface FormProps {
  accountId: string
  tabKey: TabKey
}

type Route<T extends string> = Record<
  T,
  {
    readonly text: string
    readonly component: (props: FormProps) => JSX.Element | null
  }
>

export const accountSubRoutes: Route<TabKey> = {
  'account-info': {
    text: '基本資料',
    component: AccountInfoForm,
  },
  'account-settings': {
    text: '帳戶設置',
    component: AccountSettingsForm,
  },
  'notification-settings': {
    text: '接收通知',
    component: NotificationSettingsForm,
  },
}

export function isTabkey(key: string | TabKey): key is TabKey {
  return includes(key, tabKeys)
}
