import React from 'react'

import { Content, ModalBody, Title } from '../../..'
import unsetImage from './unset.png'

const WithoutCurrentReportModalBody = () => {
  return (
    <ModalBody>
      <div>
        <img src={unsetImage} />
      </div>
      <Title>尚未設定主要報告</Title>
      <Content>
        <div>提醒您，要先請醫生設定主要報告，</div>
        <div> 才能進行後續療程的牙套製作。</div>
      </Content>
    </ModalBody>
  )
}

export default WithoutCurrentReportModalBody
