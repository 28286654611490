import moment from 'moment'
import { includes, isEmpty, propEq, split, values } from 'ramda'
import { Box3, Group, Matrix4, Mesh } from 'three'

import {
  DentalModelViewerCheckPoints,
  DentalModelViewerDesignStageInlineFragment,
  DentalModelViewerDocs,
  DentalModelViewerEvalStageInlineFragment,
  DentalModelViewerMoldStageInlineFragment,
  JawPosition,
  StageType,
} from '../../codegen/types'
import { Mode, ViewerItem } from '.'
import { MESH_LOCATION, MESH_TYPE } from './constant/controller'
import { MeshColor } from './constant/meshColor'
import { ItemType } from './Sidebar/SelectableItem'
import { MeshInfo } from './Viewer/Mesh'

export const getMeshType = (mesh: MeshInfo): MESH_TYPE =>
  includes(mesh.position, values(JawPosition)) ? MESH_TYPE.JAW : MESH_TYPE.TEETH

export const getMeshLocation = (mesh: MeshInfo): MESH_LOCATION => {
  if (getMeshType(mesh) === 'TEETH') {
    /** 'FDI_1_1' 中間數字為象限，1,2 象限為 Upper，3,4 象限為 Lower */
    return Number(split('_', mesh.position)[1]) < 3
      ? MESH_LOCATION.UPPER
      : MESH_LOCATION.LOWER
  } else {
    return mesh.position as unknown as MESH_LOCATION
  }
}

export const getCenteredMeshes = (meshes: MeshInfo[]) => {
  const group = new Group()
  group.add(...meshes.map((mesh) => new Mesh(mesh.geometry)))
  const center = new Box3().setFromObject(group).getCenter(group.position)
  const translation = [-center.x, -center.y, -center.z] as const
  return meshes.map((mesh) => ({
    ...mesh,
    geometry: mesh.geometry?.applyMatrix(
      new Matrix4().makeTranslation(...translation)
    ),
  }))
}

/** 將 stage 的 file 資料轉型成 mesh 資料 */
const covertFilesToMeshs = (files: ViewerItemInfo['files']): MeshInfo[] => [
  ...(files?.teeth ?? []).map((tooth) => ({
    position: tooth.position,
    url: tooth.file.path,
  })),
  ...(files?.upperJawModel
    ? [
        {
          position: JawPosition.Upper,
          url: files.upperJawModel.path,
        },
      ]
    : []),
  ...(files?.lowerJawModel
    ? [
        {
          position: JawPosition.Lower,
          url: files.lowerJawModel.path,
        },
      ]
    : []),
]

export const getCheckPointId = (
  evalStageId: string,
  checkPointSerialNumber: number
) => `${evalStageId}-${checkPointSerialNumber}`

export type ViewerItemInfo =
  | HasFileStageFragment
  | DentalModelViewerCheckPoints
  | DentalModelViewerCheckPoints

const stageNormalize = (stage: HasFileStageFragment): ViewerItem => ({
  id: stage.id,
  meshes: covertFilesToMeshs(stage.files),
  type: stage.type === StageType.Mold ? ItemType.MOLD : ItemType.STEP,
  color: MeshColor.White,
  info: stage,
})
const cpNormalize = (
  evalStage: HasCheckPointStageFragment,
  checkPoint: DentalModelViewerCheckPoints
): ViewerItem => ({
  id: getCheckPointId(evalStage.id, checkPoint.serialNumber),
  meshes: covertFilesToMeshs(checkPoint?.files),
  type: ItemType.CP,
  color: MeshColor.White,
  info: checkPoint,
})

type HasFileStageFragment =
  | DentalModelViewerMoldStageInlineFragment
  | DentalModelViewerDesignStageInlineFragment
type HasCheckPointStageFragment = DentalModelViewerEvalStageInlineFragment

export const initializeItems = (
  mode: Mode,
  stages: DentalModelViewerDocs[],
  currentEvalStageId?: string
): ViewerItem[] => {
  if (isEmpty(stages)) return []

  let items: ViewerItem[] = []

  /** 「看療程」僅顯示「建模、設計」工單 */
  if (mode === 'treatment') {
    const moldStages = stages.filter(
      propEq('type', StageType.Mold)
    ) as DentalModelViewerMoldStageInlineFragment[]
    const designStages = stages.filter(
      propEq('type', StageType.Design)
    ) as DentalModelViewerDesignStageInlineFragment[]

    /** Step1. 排入所有「設計」單，並依「serialNumber」排序 */
    items = designStages
      .sort((x, y) => (x.serialNumber > y.serialNumber ? 1 : -1))
      .map(stageNormalize)

    /** Step2. 依「出貨日」插入所有「建模」單 */
    moldStages.forEach((moldStage) => {
      // 找出最後一筆，出貨日比建模單還早的設計單
      const findLastEarlierDesignIndex = items.reduce((pre, cur, index) => {
        const currentItemShippingDate =
          'shippingDate' in cur.info ? cur.info.shippingDate : undefined
        return moment(moldStage.shippingDate).isSameOrAfter(
          moment(currentItemShippingDate)
        )
          ? index
          : pre
      }, -1)
      // 插在此設計單後面
      items.splice(findLastEarlierDesignIndex + 1, 0, stageNormalize(moldStage))
    })

    const currentEvalStageItem = stages.find(
      (stage) => stage.id === currentEvalStageId
    ) as DentalModelViewerEvalStageInlineFragment

    if (currentEvalStageItem) {
      const checkPoints = currentEvalStageItem.checkPoints
      /** Step 3. 排入下一個「還未完成的 CP」及「final CP」到最後 */
      const normalizedcheckPoints = checkPoints
        .filter((checkPoint) => checkPoint.isFinal || !checkPoint.isCompleted)
        .map((checkPoint) => cpNormalize(currentEvalStageItem, checkPoint))
      items = [...items, ...normalizedcheckPoints]
    }
  } else {
    /** 「看報告」僅顯示「建模、CP」 */
    /** Step1. 排入所有「建模」單，並依「serialNumber」排序 */
    const moldStages = stages.filter(
      propEq('type', StageType.Mold)
    ) as DentalModelViewerMoldStageInlineFragment[]
    items = moldStages
      .sort((x, y) => (x.serialNumber > y.serialNumber ? 1 : -1))
      .map(stageNormalize)

    const selectedEvalStageId = mode
    const selectedEvalStageItem = stages.find(
      (stage) => stage.id === selectedEvalStageId
    ) as DentalModelViewerEvalStageInlineFragment

    if (selectedEvalStageItem) {
      const checkPoints = selectedEvalStageItem.checkPoints
      /** Step 2. 排入所有「還未完成的 cp」及「final CP」到最後 */
      const normalizedcheckPoints = checkPoints
        .filter((checkPoint) => checkPoint.isFinal || !checkPoint.isCompleted)
        .map((checkPoint) => cpNormalize(selectedEvalStageItem, checkPoint))
      items = [...items, ...normalizedcheckPoints]
    }
  }
  return items.filter((item) => !isEmpty(item.meshes))
}
