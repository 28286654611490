import { gold } from '@ant-design/colors'
import { EnvironmentOutlined } from '@ant-design/icons'
import { gql } from '@apollo/client'
import { Space, Typography } from 'antd'
import React from 'react'
import styled from 'styled-components'

import { PreviewCardFragment } from '../../codegen/types'
import MultiLine from '../MultiLine'
import InstructionTag from './InstructionTag'

const Wrapper = styled(Space)`
  border-left: solid 7px ${gold.primary};
  padding: 8px 0 8px 16px;
  width: 100%;
`

interface Props {
  className?: string
  isForInternal?: boolean
  checkPoint: PreviewCardFragment
}

const PreviewCard = (props: Props) => {
  const { className, checkPoint, isForInternal = false } = props
  const cpName = checkPoint
    ? checkPoint.isFinal
      ? 'Final'
      : `CP${checkPoint.serialNumber}`
    : ''

  return (
    <Wrapper className={className} size='small' direction='vertical'>
      <Space>
        <EnvironmentOutlined
          style={{ color: gold.primary, fontSize: '24px' }}
        />
        <Typography.Title level={3} type='warning' style={{ margin: 0 }}>
          {cpName}
        </Typography.Title>
      </Space>
      <Typography.Text type='secondary'>上顎</Typography.Text>
      <MultiLine text={checkPoint.upper || ' '} />
      <Typography.Text type='secondary'>下顎</Typography.Text>
      <MultiLine text={checkPoint.lower || ' '} />
      {isForInternal && (
        <>
          <Typography.Text type='secondary'>內部備註</Typography.Text>
          <MultiLine text={checkPoint.note || ' '} />
        </>
      )}
      <Typography.Text type='secondary'>臨床指示</Typography.Text>
      <Space>
        {checkPoint.instruction.map((item) => (
          <InstructionTag key={item} instruction={item} />
        ))}
      </Space>
    </Wrapper>
  )
}

PreviewCard.fragments = {
  PreviewCard: gql`
    fragment PreviewCard on CheckPoint {
      serialNumber
      goalStep
      upper
      lower
      note
      isFinal
      instruction
    }
  `,
}

export default PreviewCard
