import { gql } from '@apollo/client'
import React from 'react'
import styled from 'styled-components'

import { AnalysisPreviewFragment } from '../../codegen/types'
import Analysis from './Analysis'
import CheckPointList from './CheckPointList'
import Disclaimer from './Disclaimer'
import { PageBottom, PageBreak, PageTop } from './Page'
import Photos from './Photos'
import Profile from './Profile'
import StepList from './StepList'

const Background = styled.div`
  @media screen {
    display: flex;
    justify-content: center;
    background-color: #ececec;
    padding-top: 12px;
    padding-bottom: 75px;
  }
  @media print {
    display: block;
    @page {
      size: A4;
      margin: 10mm 0 10mm 0;
    }
  }
`
const Container = styled.div`
  width: 994px;
  background-color: white;
`

interface Props {
  isShowingPhotos: boolean
  stage: AnalysisPreviewFragment
}

const AnalysisPreview = (props: Props) => {
  const { isShowingPhotos, stage } = props

  return (
    <Background>
      <Container>
        <PageTop previewInHTML />
        <Profile stage={stage} />
        {isShowingPhotos && (
          <>
            <Photos patient={stage.patient} />
            <PageBreak previewInHTML />
          </>
        )}
        {stage.isCPMode && stage.shouldShowCheckPoint && (
          <>
            <CheckPointList stage={stage} />
            <PageBreak previewInHTML />
          </>
        )}
        <Analysis stage={stage} />
        {!stage.isCPMode && <StepList stage={stage} />}
        <Disclaimer />
        <PageBottom previewInHTML />
      </Container>
    </Background>
  )
}

AnalysisPreview.fragment = gql`
  fragment AnalysisPreview on EvalStage {
    isCPMode
    shouldShowCheckPoint
    ...AnalysisPreviewProfile
    ...AnalysisPreviewAnalysis
    ...StepList
    ...CheckPointList
    patient {
      ...AnalysisPreviewPhotos
    }
  }
  ${Profile.fragment}
  ${Photos.fragment}
  ${Analysis.fragment}
  ${StepList.fragment}
  ${CheckPointList.fragments.CheckPointList}
`

export default AnalysisPreview
