import { gql, useMutation, useQuery, useSubscription } from '@apollo/client'
import { getNotificationTypesFromCategory } from '@sov/common'
import { Notification } from '@sov/ui'
import { flatten, isNil, map, prop } from 'ramda'
import React, { useContext, useState } from 'react'

import {
  NotificationCategory,
  NotificationCreatedSubscription,
  NotificationCreatedSubscriptionVariables,
  NotificationIconQuery,
  NotificationIconQueryVariables,
  NotificationType,
  Platform,
  ReadAllNotificationMutationMutation,
  ReadAllNotificationMutationMutationVariables,
  ReadNotificationMutationMutation,
  ReadNotificationMutationMutationVariables,
  UnreadNotificationCountQuery,
  UnreadNotificationCountQueryVariables,
} from '../../../codegen/types'
import { authContext } from '../../../utils/context'

/* ----- query string ----- */
const notificationIconQuery = gql`
  query NotificationIcon($query: NotificationsQuery = {}, $first: Int = 10) {
    notifications(query: $query, first: $first) {
      edges {
        node {
          ...NotificationItem
        }
      }
    }
  }
  ${Notification.fragment}
`
const unreadNotificationCountQuery = gql`
  query UnreadNotificationCount(
    $receiver: ID!
    $type: [NotificationType!]!
    $platform: Platform!
  ) {
    unreadNotificationCount(
      receiver: $receiver
      type: $type
      platform: $platform
    )
  }
`
export const readNotificationMutation = gql`
  mutation ReadNotificationMutation($id: ID!) {
    readNotification(id: $id) {
      id
    }
  }
`
export const readAllNotificationMutation = gql`
  mutation ReadAllNotificationMutation($receiver: ID!) {
    readAllNotification(receiver: $receiver) {
      statusCode
      message
    }
  }
`
export const notificationCreatedSubscription = gql`
  subscription NotificationCreated(
    $receiver: ID!
    $type: [NotificationType!]!
    $platform: Platform!
  ) {
    notificationCreated(receiver: $receiver, type: $type, platform: $platform) {
      id
    }
  }
`

/* ----- type and component ----- */
interface NotificationBellProps {
  entityId: string
}

const visibleNotificationCategories = [
  NotificationCategory.Patient,
  NotificationCategory.Order,
  NotificationCategory.Report,
  NotificationCategory.Track,
]
const visibleNotificationTypes = flatten<NotificationType>(
  visibleNotificationCategories.map((category) =>
    getNotificationTypesFromCategory(category)
  )
)

const NotificationBell = (props: NotificationBellProps) => {
  const { entityId } = props

  const [visible, setVisible] = useState(false)
  const { loading, data, refetch } = useQuery<
    NotificationIconQuery,
    NotificationIconQueryVariables
  >(notificationIconQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    variables: {
      query: {
        type: visibleNotificationTypes,
        receiver: [entityId],
        platform: Platform.Crm,
      },
    },
  })
  const { data: countQuery, refetch: refetchCount } = useQuery<
    UnreadNotificationCountQuery,
    UnreadNotificationCountQueryVariables
  >(unreadNotificationCountQuery, {
    variables: {
      receiver: entityId,
      type: visibleNotificationTypes,
      platform: Platform.Crm,
    },
  })
  const [readAllNotification] = useMutation<
    ReadAllNotificationMutationMutation,
    ReadAllNotificationMutationMutationVariables
  >(readAllNotificationMutation)
  const [readNotification] = useMutation<
    ReadNotificationMutationMutation,
    ReadNotificationMutationMutationVariables
  >(readNotificationMutation)

  const handleRefetch = () => {
    refetch()
    refetchCount()
  }

  useSubscription<
    NotificationCreatedSubscription,
    NotificationCreatedSubscriptionVariables
  >(notificationCreatedSubscription, {
    variables: {
      receiver: entityId,
      type: visibleNotificationTypes,
      platform: Platform.Crm,
    },
    onSubscriptionData: () => {
      handleRefetch()
    },
  })

  const notificationData = map(prop('node'), data?.notifications?.edges || [])
  const unReadCount = countQuery?.unreadNotificationCount ?? 0

  const handleTogglePopover = (visible?: boolean) =>
    setVisible((state) => (isNil(visible) ? !state : visible))

  const handleRead = async (id: string, read: boolean) => {
    if (read) return
    try {
      await readNotification({
        variables: { id },
        update: (cache, { data }) => {
          if (data) handleRefetch()
        },
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleReadAll = async () => {
    if (!entityId) return
    try {
      await readAllNotification({
        variables: { receiver: entityId },
        update: (cache, { data }) => {
          if (data && data.readAllNotification?.statusCode === '200') {
            handleTogglePopover()
            handleRefetch()
          }
        },
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Notification.Popover
      loading={loading}
      unReadCount={unReadCount}
      isPopoverVisible={visible}
      listPagePath='/notifications'
      notificationList={notificationData}
      handleTogglePopover={handleTogglePopover}
      handleRead={handleRead}
      handleReadAll={handleReadAll}
    />
  )
}

export default () => {
  const auth = useContext(authContext)
  return auth ? (
    <NotificationBell entityId={auth.entity.id} />
  ) : (
    <Notification.Icon />
  )
}
