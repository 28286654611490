import { QuestionCircleFilled } from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'
import { DisplayPatientBrand, useAuth } from '@sov/ui'
import { Checkbox, Form, Input, Radio, Tooltip } from 'antd'
import { FormInstance, FormProps } from 'antd/lib/form'
import { includes, reduce } from 'ramda'
import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'

import {
  CreatePatientInput,
  EntityType,
  Gender,
  PatientBrand,
  SelectClinicInfoQueryQuery,
  SelectClinicInfoQueryQueryVariables,
} from '../../../codegen/types'
import { ClinicSelect, DoctorSelect, SelectItem } from '../../select'
import { formItemLayout } from './layouts'

const QuestionIcon = styled(QuestionCircleFilled)`
  color: rgba(0, 0, 0, 0.2);
  margin-left: 4px;
`

const selectClinicInfoQuery = gql`
  query SelectClinicInfoQuery($id: ID!) {
    clinic(id: $id) {
      id
      accountManagers {
        id
        name
      }
      technicians {
        id
        name
      }
      sales {
        id
        name
      }
      customerService {
        id
        name
      }
    }
  }
`

const SPECIALIST_ENABLE_CLINICS = [
  '5cbe95a8f67d180feb11ac78',
  '5ec4f693ddee4655d4beb2ed',
]

const treatArchOptions = [
  {
    label: '上排',
    value: 'upper',
  },
  {
    label: '下排',
    value: 'lower',
  },
]

export const treatArchesTransformer: (
  list: string[]
) => CreatePatientInput['treatArches'] = reduce(
  (treatArches, arch) => {
    if (arch === 'upper') {
      return { ...treatArches, upper: true }
    }
    if (arch === 'lower') {
      return { ...treatArches, lower: true }
    }
    return treatArches
  },
  { upper: true, lower: true }
)

export type FormGeneralFields = Pick<
  CreatePatientInput,
  | 'name'
  | 'payment'
  | 'gender'
  | 'clinic'
  | 'doctor'
  | 'accountManager'
  | 'technician'
  | 'sales'
  | 'customerService'
> & {
  treatArches: string[]
}
type FormGeneralInitialValues = Partial<FormGeneralFields>

interface Props extends FormProps {
  form: FormInstance<FormGeneralFields>
  initialValues?: FormGeneralInitialValues
}

const PatientFormGeneral: FC<Props> = (props) => {
  const { form, initialValues } = props

  const auth = useAuth()
  const user = auth.user
  const entityType = user?.entityType

  const [selectedClinic, setSelectedClinic] = useState<SelectItem>()
  const [selectedDoctor, setSelectedDoctor] = useState<SelectItem>()

  useQuery<SelectClinicInfoQueryQuery, SelectClinicInfoQueryQueryVariables>(
    selectClinicInfoQuery,
    {
      skip: !selectedClinic?.id,
      variables: {
        id: selectedClinic?.id || '',
      },
      onCompleted: (data) => {
        if (data?.clinic?.accountManagers) {
          form.setFieldsValue({
            accountManager: data.clinic.accountManagers?.[0]?.id,
          })
        }
        if (data?.clinic?.technicians) {
          form.setFieldsValue({ technician: data.clinic.technicians?.[0]?.id })
        }
        if (data?.clinic?.sales) {
          form.setFieldsValue({ sales: data.clinic.sales?.[0]?.id })
        }
        if (data?.clinic?.customerService) {
          form.setFieldsValue({
            customerService: data.clinic.customerService?.id,
          })
        }
      },
    }
  )

  const resetBrand = () =>
    form.setFieldsValue({ payment: { brand: PatientBrand.Unknown } })
  const showBrandField = () =>
    includes(selectedClinic?.id, SPECIALIST_ENABLE_CLINICS)
  const handleSelectClinic = (clinicItem: SelectItem) => {
    setSelectedClinic(clinicItem)
    form.setFieldsValue({ clinic: clinicItem.id })
    resetBrand()
  }
  const handleSelectDoctor = (doctorItem: SelectItem) => {
    setSelectedDoctor(doctorItem)
    form.setFieldsValue({ doctor: doctorItem.id })
  }

  useEffect(() => {
    /** 根據登入使用者身份，自動帶入相關欄位 */
    if (auth.user) {
      if (user?.entityType === EntityType.Clinic) {
        handleSelectClinic({
          id: auth.user.entity.id,
          name: auth.user.entity.name,
        })
      }
      if (user?.entityType === EntityType.Doctor) {
        handleSelectDoctor({
          id: auth.user.entity.id,
          name: auth.user.entity.name,
        })
      }
    }
  }, [auth.user])

  const ControlledClinicSelect = (props) => (
    /** ...props 必須放在最前面，controlled field 才能正常運作 */
    <ClinicSelect
      {...props}
      value={selectedClinic?.id}
      disabled={entityType === EntityType.Clinic}
      query={{ doctor: selectedDoctor?.id }}
      handleItemSelect={handleSelectClinic}
    />
  )
  const ControlledDoctorSelect = (props) => (
    /** ...props 必須放在最前面，controlled field 才能正常運作 */
    <DoctorSelect
      {...props}
      value={selectedDoctor?.id}
      disabled={entityType === EntityType.Doctor}
      query={{ clinic: selectedClinic?.id }}
      handleItemSelect={handleSelectDoctor}
    />
  )

  return (
    <Form {...formItemLayout} form={form} initialValues={initialValues}>
      <Form.Item
        name='name'
        label='病患姓名'
        rules={[{ required: true, message: '請輸入姓名' }]}
      >
        <Input style={{ width: '200px' }} />
      </Form.Item>
      <Form.Item
        name='gender'
        label='性別'
        rules={[{ required: true, message: '請選擇性別' }]}
      >
        <Radio.Group>
          <Radio value={Gender.Male}>男</Radio>
          <Radio value={Gender.Female}>女</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name='treatArches'
        label='治療之牙弓'
        rules={[{ required: true, message: '請選擇至少一個牙弓' }]}
      >
        <Checkbox.Group options={treatArchOptions} />
      </Form.Item>
      <Form.Item
        name={['payment', 'brand']}
        label='品牌'
        rules={[{ required: true, message: '請選擇品牌' }]}
        hidden={!showBrandField()}
      >
        <Radio.Group>
          <Radio value={PatientBrand.Sov}>
            <DisplayPatientBrand value={PatientBrand.Sov} />
          </Radio>
          <Radio value={PatientBrand.Smilux}>
            <DisplayPatientBrand value={PatientBrand.Smilux} />
            <Tooltip
              title='舒服美公司副品牌，特別針對簡單case'
              placement='right'
            >
              <QuestionIcon />
            </Tooltip>
          </Radio>
          <Radio value={PatientBrand.Unknown}>
            <DisplayPatientBrand value={PatientBrand.Unknown} />
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name='clinic'
        label='負責診所'
        rules={[{ required: true, message: '請選擇診所' }]}
      >
        <ControlledClinicSelect allowClear />
      </Form.Item>
      <Form.Item
        name='doctor'
        label='負責醫師'
        rules={[{ required: true, message: '請選擇醫師' }]}
      >
        <ControlledDoctorSelect allowClear />
      </Form.Item>
      <Form.Item name='accountManager' hidden />
      <Form.Item name='technician' hidden />
      <Form.Item name='sales' hidden />
      <Form.Item name='customerService' hidden />
    </Form>
  )
}

export default PatientFormGeneral
