import { Form } from '@ant-design/compatible'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { ReloadOutlined } from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'
import {
  ErrorHandling,
  FormQuery,
  MyStringParam,
  TableQuery,
} from '@sov/common'
import { DisplayPatientStatus } from '@sov/ui'
import { Button, Input, Select } from 'antd'
import { MenuItemProps } from 'antd/lib/menu/MenuItem'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import {
  OrderType,
  PatientStatus,
  PatientsQueryQuery,
  PatientsQueryQueryVariables,
} from '../../codegen/types'
import CurrentEvalStageModal from '../../components/modal/CurrentEvalStageModal'
import OrderModal from '../../components/modal/OrderModal'
import Page from '../../components/page'
import BreadcrumbCreator from '../../components/page/BreadcrumbCreator'
import Title from '../../components/page/Title'
import PatientTable from '../../components/table/patient'
import NoScrollContainer from '../../utils/NoScrollContainer'

const patientsQuery = gql`
  query PatientsQuery(
    $query: PatientsQuery = {}
    $page: Int = 1
    $limit: Int = 100
    $sort: String = "-updated"
  ) {
    patients(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        ...PatientTable
      }
      total
      limit
      page
    }
  }
  ${PatientTable.fragment.PatientTable}
`

const Search = Input.Search

interface SearchFormInput {
  name?: string
  clinicName?: string
  doctorName?: string
  status?: string
}

type SearchFormProps = {
  handleSearch: (x: FormComponentProps['form']) => void
  handleReset: (x: FormComponentProps['form']) => void
  formQuery: SearchFormInput
} & FormComponentProps<SearchFormInput>

const SearchForm = Form.create<SearchFormProps>()((props: SearchFormProps) => {
  const { formQuery, form, handleSearch, handleReset } = props
  const { getFieldDecorator } = form

  return (
    <Form layout='inline'>
      <Form.Item label='診所'>
        {getFieldDecorator('clinicName', {
          initialValue: formQuery.clinicName,
        })(
          <Search
            placeholder='輸入關鍵字'
            style={{ width: 150 }}
            onSearch={() => handleSearch(form)}
            onPressEnter={() => handleSearch(form)}
          />
        )}
      </Form.Item>
      <Form.Item label='醫師'>
        {getFieldDecorator('doctorName', {
          initialValue: formQuery.doctorName,
        })(
          <Search
            placeholder='輸入關鍵字'
            style={{ width: 150 }}
            onSearch={() => handleSearch(form)}
            onPressEnter={() => handleSearch(form)}
          />
        )}
      </Form.Item>
      <Form.Item label='病患'>
        {getFieldDecorator('name', {
          initialValue: formQuery.name,
        })(
          <Search
            placeholder='輸入關鍵字'
            style={{ width: 150 }}
            onSearch={() => handleSearch(form)}
            onPressEnter={() => handleSearch(form)}
          />
        )}
      </Form.Item>
      <Form.Item label='病患狀態'>
        {getFieldDecorator('status', {
          initialValue: formQuery.status ?? [],
        })(
          <Select allowClear style={{ width: 120 }}>
            {Object.values(PatientStatus).map((val) => {
              return (
                <Select.Option key={val} value={val}>
                  <DisplayPatientStatus value={val} />
                </Select.Option>
              )
            })}
          </Select>
        )}
      </Form.Item>
      <Form.Item>
        <Button type='primary' onClick={() => handleSearch(form)}>
          搜索
        </Button>
      </Form.Item>
      <Form.Item>
        <Button
          onClick={() => {
            handleReset(form)
          }}
        >
          <ReloadOutlined />
          重新整理
        </Button>
      </Form.Item>
    </Form>
  )
})

interface GetCurrentEvalStageClickHandlerArgs {
  patientId: string
}
export type GetOrderClickHandler = (
  patientId: string
) => (orderType: OrderType) => MenuItemProps['onClick']
export type GetCurrentEvalStageClickHandler = (
  args: GetCurrentEvalStageClickHandlerArgs
) => () => void
type Props = FormComponentProps & RouteComponentProps

const PatientList = (props: Props) => {
  const { location } = props
  const { tableQuery, paginateQuery, handleTableChange, handleTableReset } =
    TableQuery.useTableQuery()
  const { formQuery, handleFormChange, handleFormReset } =
    FormQuery.useFormQuery({
      name: MyStringParam,
      clinicName: MyStringParam,
      doctorName: MyStringParam,
      status: MyStringParam,
    })

  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { data, loading, refetch } = useQuery<
    PatientsQueryQuery,
    PatientsQueryQueryVariables
  >(patientsQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      query: {
        ...formQuery,
        status: formQuery.status
          ? [formQuery.status as PatientStatus]
          : undefined,
      },
      ...paginateQuery,
      limit: 30,
    },
  })

  useEffect(() => {
    refetch()
  }, [location])

  const handleSearch = (form: FormComponentProps<SearchFormInput>['form']) => {
    const { getFieldValue } = form
    const name = getFieldValue('name')
    const clinicName = getFieldValue('clinicName')
    const doctorName = getFieldValue('doctorName')
    const status = getFieldValue('status')
    handleFormChange({ name, clinicName, doctorName, status })
  }

  const handleReset = (form: FormComponentProps['form']) => {
    form.resetFields()
    handleTableReset()
    handleFormReset()
  }

  const [modalPatientId, setModalPatientId] = useState('')
  const [orderType, setOrderType] = useState<OrderType>(OrderType.NormalPrint)
  const [shouldFetchPatientOrders, setShouldFetchPatientOrders] =
    useState(false)
  const [isCurrentEvalStageModalVisible, setIsCurrentEvalStageModalVisible] =
    useState<boolean>(false)

  const handleQueryStop = () => {
    setShouldFetchPatientOrders(false)
  }

  const getOrderClickHandler: GetOrderClickHandler =
    (patientId) => (orderType) => {
      setModalPatientId(patientId)
      setOrderType(orderType)
      setShouldFetchPatientOrders(true)
      return undefined
    }

  const handleCurrentEvalStageModalClose = () => {
    setIsCurrentEvalStageModalVisible(false)
  }

  const orderAction = () => {
    setIsCurrentEvalStageModalVisible(false)
    getOrderClickHandler(modalPatientId)(OrderType.NormalPrint)
  }
  const getCurrentEvalStageClickHandler: GetCurrentEvalStageClickHandler =
    (getCurrentEvalStageClickHandlerArgs) => () => {
      const { patientId } = getCurrentEvalStageClickHandlerArgs

      setModalPatientId(patientId)
      setIsCurrentEvalStageModalVisible(true)
    }
  const patientTableData = data?.patients ? data.patients.docs : []
  const cursor = data?.patients
    ? {
        limit: data.patients.limit,
        page: data.patients.page,
        total: data.patients.total,
      }
    : {
        limit: 0,
        page: 1,
        total: 0,
      }

  const header = (
    <>
      <BreadcrumbCreator routes={[{ key: 'Home' }, { key: 'PatientList' }]} />
      <Title route={{ key: 'PatientList' }} />
    </>
  )

  return (
    <Page header={header}>
      <NoScrollContainer>
        <OrderModal
          handleQueryStop={handleQueryStop}
          orderType={orderType}
          patientId={modalPatientId}
          shouldFetchPatientOrders={shouldFetchPatientOrders}
        />

        <CurrentEvalStageModal
          isCurrentEvalStageModalVisible={isCurrentEvalStageModalVisible}
          handleCurrentEvalStageModalClose={handleCurrentEvalStageModalClose}
          patientId={modalPatientId}
          orderAction={orderAction}
        />

        <section
          style={{ display: 'flex', height: '40px', alignItems: 'center' }}
        >
          <SearchForm
            formQuery={formQuery}
            handleSearch={handleSearch}
            handleReset={handleReset}
          />
        </section>
        <section style={{ height: 'calc(100% - 60px)', marginTop: '20px' }}>
          <PatientTable
            cursor={cursor}
            data={patientTableData}
            getOrderClickHandler={getOrderClickHandler}
            getCurrentEvalStageClickHandler={getCurrentEvalStageClickHandler}
            handleTableChange={handleTableChange}
            loading={loading}
            sortInfo={tableQuery.sort}
          />
        </section>
      </NoScrollContainer>
    </Page>
  )
}

export default PatientList
