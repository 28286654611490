import { Form } from '@ant-design/compatible'
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form'
import { ErrorHandling } from '@sov/common'
import { useRequest } from '@sov/ui'
import { Spin } from 'antd'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import Layout from '../components/Layout'
import RegisterForm, { RegisterFormInput } from './form/register'

const ErrorText = styled.div`
  color: #ff0000;
`
const Register = (props: FormComponentProps) => {
  const { form } = props
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const history = useHistory()
  const [helpMessage, setHelpMessage] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const request = useRequest()

  const sendEmail = async (payload: RegisterFormInput) => {
    const res = await request.post('/auth/register', {
      ...payload,
    })

    return res
  }

  const handleFormOnFocus = () => {
    setHelpMessage(undefined)
  }

  const handleSubmit = async () => {
    const { validateFields } = form
    validateFields(async (error, values) => {
      if (error) {
        return null
      }
      setLoading(true)
      const res = await sendEmail({
        ...values,
        hostname: window.location.origin,
      })
      setLoading(false)
      if (res.status === 200) {
        history.push('/registerComplete')
      } else if (res.status === 409) {
        // 信箱已經存在
        setHelpMessage(res.message)
      } else {
        toErrorPage(res.message)
      }
    })
  }

  return (
    <Spin spinning={loading}>
      <ErrorText>{helpMessage}</ErrorText>
      <Layout
        title='合作註冊'
        description={
          <div style={{ textAlign: 'left' }}>
            想成為SOV認證醫師或合作夥伴嗎？請填妥以下資訊，專員會盡快與您聯繫
          </div>
        }
        content={
          <RegisterForm
            form={form}
            handleFormOnFocus={handleFormOnFocus}
            handleSubmit={handleSubmit}
          />
        }
        backLink='/'
      />
    </Spin>
  )
}

export default Form.create()(Register)
