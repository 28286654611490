import { Tag } from 'antd'
import { always, cond, equals } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { PatientStatus } from '../../codegen/types'

type Props = {
  mode?: 'tag' | 'text'
  value: PatientStatus
}

const DisplayPatientStatus = (props: Props) => {
  const { mode = 'tag', value } = props
  const { t } = useTranslation()

  if (mode === 'tag') {
    const color = cond<PatientStatus, string>([
      [equals<PatientStatus>(PatientStatus.Initial), always('red')],
      [equals<PatientStatus>(PatientStatus.OnEval), always('orange')],
      [equals<PatientStatus>(PatientStatus.OnPrint), always('blue')],
      [equals<PatientStatus>(PatientStatus.Completed), always('green')],
      [equals<PatientStatus>(PatientStatus.Inactive), always('')],
    ])(value)

    return (
      <Tag color={color} style={{ margin: 0 }}>
        {t(`patient.status.${value}`)}
      </Tag>
    )
  } else if (mode === 'text') {
    return <>{t(`patient.status.${value}`)}</>
  } else {
    return null
  }
}

export { DisplayPatientStatus }
