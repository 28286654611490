import { gql } from '@apollo/client'
import { Divider } from 'antd'
import React from 'react'
import styled from 'styled-components'

import { OrderDetailCardFragment, OrderStatus } from '../../../../codegen/types'
import { primary } from '../../../../utils/color'
import DiliveryInfo from './DiliveryInfo'
import ItemList from './ItemList'
import OrderDetail from './OrderDetail'
import PatientProfile from './PatientProfile'

const Container = styled.div`
  background-color: white;
  border-top: 4px solid ${primary};
  padding: 20px 24px 24px 24px;
`

interface OrderDetailCardProps {
  orderItem: OrderDetailCardFragment
}

const OrderDetailCard = (props: OrderDetailCardProps) => {
  const { orderItem } = props

  return (
    <Container>
      <PatientProfile orderItem={orderItem} />
      <Divider />
      <OrderDetail orderItem={orderItem} />
      {orderItem.status === OrderStatus.Completed && (
        <>
          <Divider />
          <ItemList orderItem={orderItem} />
          <Divider />
          <DiliveryInfo orderItem={orderItem} />
        </>
      )}
    </Container>
  )
}

OrderDetailCard.fragments = {
  OrderDetailCard: gql`
    fragment OrderDetailCard on Order {
      ...PatientProfile
      ...OrderDetail
      ...ItemList
      ...DiliveryInfo
    }
    ${PatientProfile.fragments.PatientProfile}
    ${OrderDetail.fragments.OrderDetail}
    ${ItemList.fragments.ItemList}
    ${DiliveryInfo.fragments.DiliveryInfo}
  `,
}

export default OrderDetailCard
