import { Form, Input, Radio } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { RadioGroupProps } from 'antd/lib/radio'
import React from 'react'

import {
  AnglesClassification,
  CreatePatientInput,
  SymptomsInput,
} from '../../../codegen/types'
import { formItemLayout } from './layouts'

const AnglesClassificationRadio = (props: RadioGroupProps) => {
  return (
    <Radio.Group {...props}>
      <Radio value={AnglesClassification.Class1}>Class1(正常)</Radio>
      <Radio value={AnglesClassification.Class2}>Class2(暴牙)</Radio>
      <Radio value={AnglesClassification.Class3}>Class3(戽斗)</Radio>
    </Radio.Group>
  )
}

export type FormMalocclusionsFields = {
  symptoms: Pick<
    SymptomsInput,
    'leftMolarRelationship' | 'rightMolarRelationship'
  >
} & { chiefComplaint: CreatePatientInput['chiefComplaint'] }
type FormMalocclusionsInitialValues = Partial<FormMalocclusionsFields>

interface Props {
  form: FormInstance<FormMalocclusionsFields>
  initialValues?: FormMalocclusionsInitialValues
  disabled?: boolean
}

const PatientFormMalocclusions = (props: Props) => {
  const { form, initialValues, disabled } = props

  return (
    <Form {...formItemLayout} form={form} initialValues={initialValues}>
      <Form.Item label='主訴' name='chiefComplaint'>
        <Input.TextArea
          autoSize={{ minRows: 3 }}
          placeholder='請填寫病患主要的療程目標'
        />
      </Form.Item>
      <Form.Item
        name={['symptoms', 'leftMolarRelationship']}
        label='左側臼齒安格斯分類'
      >
        <AnglesClassificationRadio disabled={disabled} />
      </Form.Item>
      <Form.Item
        name={['symptoms', 'rightMolarRelationship']}
        label='右側臼齒安格斯分類'
      >
        <AnglesClassificationRadio disabled={disabled} />
      </Form.Item>
    </Form>
  )
}

export default PatientFormMalocclusions
