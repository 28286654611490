import React from 'react'

type Props = {
  value: number | string
}

const Currency = (props: Props) => {
  const { value } = props
  const parsedValue =
    typeof value === 'string' ? Number.parseFloat(value) : value

  return (
    <span style={{ color: parsedValue < 0 ? 'red' : 'initial' }}>
      {new Intl.NumberFormat().format(parsedValue)}
    </span>
  )
}

export { Currency }
