import { map, values } from 'ramda'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import MainLayout from '../../components/layout/Main'
import PatientRoutes from './PatientRoutes'
import { routeMap } from '.'

const AppRoute = () => {
  return (
    <Switch>
      <Route path='/patients'>
        <PatientRoutes />
      </Route>
      {map((props) => {
        const { path, component: Component, isLayoutVisible = true } = props
        return (
          <Route
            exact
            key={path}
            path={path}
            render={(props) => {
              if (!isLayoutVisible) {
                return <Component {...props} />
              }

              return (
                <MainLayout>
                  <Component {...props} />
                </MainLayout>
              )
            }}
          />
        )
      }, values(routeMap))}
      <Route path='/'>
        <Redirect to='/patients' />
      </Route>
    </Switch>
  )
}

export default AppRoute
