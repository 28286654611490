import { Form } from '@ant-design/compatible'
import { gql } from '@apollo/client'
import { DisplayPatientBrand } from '@sov/ui'
import { Avatar, Tag } from 'antd'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Gender, PatientInfoCardFragment } from '../../codegen/types'
import EntityName from '../EntityName'

const StyledAvatar = styled.div`
  width: 160px;
  height: 160px;
  display: flex;
  margin: 0 auto 24px auto;
`

interface Props {
  patient: PatientInfoCardFragment
}

const PatientInfoCard = (props: Props) => {
  const { patient } = props
  const { t } = useTranslation()

  return (
    <>
      <StyledAvatar>
        <Avatar size={160} src={patient.photos.frontFace?.thumbnailPath} />
      </StyledAvatar>
      <Form
        layout='horizontal'
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item label='姓名'>{patient.name}</Form.Item>
        <Form.Item label='性別'>
          {patient.gender === Gender.Male ? '男' : '女'}
        </Form.Item>
        <Form.Item label='品牌'>
          <DisplayPatientBrand value={patient.payment.brand} />
          <Tag>{t(`patient.source.${patient.payment.source}`)}</Tag>
        </Form.Item>
        <Form.Item label='治療牙弓'>
          <span>{patient.treatArches.upper && '上顎'}</span>
          <span>
            {patient.treatArches.upper && patient.treatArches.lower && ' / '}
          </span>
          <span>{patient.treatArches.lower && '下顎'}</span>
        </Form.Item>
        <Form.Item label='主訴'>{patient.chiefComplaint}</Form.Item>
        <Form.Item label='建檔日期'>
          {moment(patient.created).format('YYYY-MM-DD')}
        </Form.Item>
        {patient.creator && (
          <Form.Item label='建檔人'>
            <EntityName entity={patient.creator} emptyText='SOV 人員' />
          </Form.Item>
        )}
      </Form>
    </>
  )
}

PatientInfoCard.fragment = gql`
  fragment PatientInfoCard on Patient {
    id
    payment {
      brand
      source
    }
    photos {
      frontFace {
        id
        thumbnailPath
      }
    }
    name
    gender
    treatArches {
      upper
      lower
    }
    chiefComplaint
    created
    creator {
      ...EntityName
    }
  }
  ${EntityName.fragments.EntityName}
`

export default PatientInfoCard
