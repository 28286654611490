import { isNil } from 'ramda'
import { useState } from 'react'

export default () => {
  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = (isOpen?: boolean) =>
    setIsOpen((state) => (isNil(isOpen) ? !state : isOpen))

  return { isOpen, handleToggle }
}
