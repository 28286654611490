import { DecodedValueMap, QueryParamConfigMap } from 'serialize-query-params'
import { UrlUpdateType, useQueryParams } from 'use-query-params'

export const useFormQuery = <QPCMap extends QueryParamConfigMap>(
  queryParams: QPCMap
) => {
  const [formQuery, setFormQuery] = useQueryParams<QPCMap>(queryParams)

  const handleFormChange = (
    changes: Partial<DecodedValueMap<QPCMap>>,
    updateType: UrlUpdateType = 'push'
  ) => {
    setFormQuery(changes, updateType)
  }

  const handleFormReset = () => {
    setFormQuery({}, 'push')
  }

  return {
    formQuery,
    handleFormChange,
    handleFormReset,
  }
}
