import { Button } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

import Layout from '../components/Layout'

const ToLoginPageButton = (
  <Link to='/'>
    <Button type='primary' style={{ width: '100%' }}>
      登入
    </Button>
  </Link>
)

const ResetPasswordComplete = () => (
  <Layout
    title='重設密碼'
    description='設定成功，現在您可以使用新密碼登入'
    backLink={ToLoginPageButton}
  />
)

export default ResetPasswordComplete
