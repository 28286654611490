import './NotificationPopover.less'

import { Popover } from 'antd'
import React from 'react'

import { NotificationItemFragment } from '../../codegen/types'
import BellIcon from './BellIcon'
import PopoverContent from './PopoverContent'
import PopoverTitle from './PopoverTitle'

interface NotificationPopoverProps {
  loading?: boolean
  unReadCount: number
  iconClassName?: string
  listPagePath?: string
  isPopoverVisible?: boolean
  notificationList: NotificationItemFragment[]
  handleTogglePopover?: () => void
  handleReadAll?: () => Promise<void>
  handleRead?: (id: string, read: boolean) => Promise<void>
}

const NotificationPopover = (props: NotificationPopoverProps) => {
  const {
    loading,
    unReadCount,
    isPopoverVisible = false,
    listPagePath,
    iconClassName,
    notificationList,
    handleTogglePopover,
    handleRead,
    handleReadAll,
  } = props

  return (
    <Popover
      overlayClassName='notification-popover'
      visible={isPopoverVisible}
      onVisibleChange={handleTogglePopover}
      placement='bottomRight'
      trigger='click'
      title={
        <PopoverTitle unReadCount={unReadCount} handleReadAll={handleReadAll} />
      }
      content={
        <PopoverContent
          loading={loading}
          listPagePath={listPagePath}
          notificationList={notificationList}
          handleRead={handleRead}
        />
      }
    >
      <BellIcon
        className={iconClassName}
        count={unReadCount}
        handleTogglePopover={handleTogglePopover}
      />
    </Popover>
  )
}

export default NotificationPopover
