import { UserOutlined } from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'
import { ErrorHandling } from '@sov/common'
import { Link as CustomLink, DisplayPatientBrand } from '@sov/ui'
import { Avatar, Menu, Skeleton, Tag } from 'antd'
import moment from 'moment'
import { find, length, map } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useRouteMatch } from 'react-router-dom'

import {
  LastCompletedStageFragment,
  PatientMenuQuery,
  PatientMenuQueryVariables,
  StageStatus,
  StageType,
} from '../../../codegen/types'
import BreadcrumbCreator from '../../../components/page/BreadcrumbCreator'
import {
  Container,
  ContainerWithAvatar,
  ContentRow,
  PatientInfo,
  PatientName,
  RowRightFields,
  TreatmentProgress,
  VerticalDivider,
  avatarSize,
} from './components'

const patientMenuQuery = gql`
  query PatientMenu(
    $id: ID!
    $query: StagesQuery = {}
    $page: Int = 1
    $limit: Int = 100
    $sort: String = "-updated"
  ) {
    patient(id: $id) {
      id
      name
      created
      status
      nextAppointmentDate
      payment {
        brand
        source
      }
      photos {
        frontFace {
          id
          thumbnailPath
        }
      }
      symptoms {
        leftMolarRelationship
        rightMolarRelationship
      }
      currentEvalStage {
        id
        serialNumber
        ...StageLink
        analysis {
          steps {
            upperStep
            lowerStep
            attachment
            ipr
            button
            retrieve
          }
        }
      }
      stages(query: $query, page: $page, limit: $limit, sort: $sort) {
        docs {
          ...LastCompletedStage
        }
      }
    }
  }

  fragment LastCompletedStage on Stage {
    id
    __typename
    status
    ... on PrintStage {
      serialNumber
    }
  }

  ${CustomLink.StageLink.fragment}
`

const getPatientMenuList = (patientId: string) => [
  {
    key: 'intro',
    label: '治療簡介',
    path: `/patients/${patientId}`,
  },
  {
    key: 'stages',
    label: '療程列表',
    path: `/patients/${patientId}/stages`,
  },
  {
    key: 'orders',
    label: '訂單列表',
    path: `/patients/${patientId}/orders`,
  },
  {
    key: 'invoices',
    label: '出貨列表',
    path: `/patients/${patientId}/invoices`,
  },
  {
    key: 'tracks',
    label: '追蹤列表',
    path: `/patients/${patientId}/tracks`,
  },
  {
    key: 'appointments',
    label: '約診列表',
    path: `/patients/${patientId}/appointments`,
  },
]

interface RouterProps {
  patientId: string
}

const PatientMenu = () => {
  const { toErrorPage } = ErrorHandling.useErrorHandling()

  const { t } = useTranslation()
  const match = useRouteMatch<RouterProps>()
  const { patientId } = match.params

  const latestCompletedStageQuery: Omit<PatientMenuQueryVariables, 'id'> = {
    query: {
      type: [StageType.Print],
      status: [StageStatus.Completed],
    },
    page: 1,
    limit: 1,
    sort: '-serialNumber',
  }

  const { data, loading } = useQuery<
    PatientMenuQuery,
    PatientMenuQueryVariables
  >(patientMenuQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    fetchPolicy: 'no-cache',
    variables: {
      id: patientId,
      ...latestCompletedStageQuery,
    },
  })

  const patient = data?.patient
  if (loading) {
    return <Skeleton active />
  }
  if (!patient) {
    return null
  }

  const patientStages = patient.stages?.docs
  const lastCompletedStage = patientStages
    ? patientStages.find(
        (
          stage
        ): stage is Extract<
          LastCompletedStageFragment,
          { __typename: 'PrintStage' }
        > => stage.__typename === 'PrintStage'
      )
    : undefined

  const patientMenuList = getPatientMenuList(patientId)
  const selectedKey = find(
    ({ path }) => window.location.pathname === path,
    patientMenuList
  )?.key

  return (
    <>
      <BreadcrumbCreator
        routes={[
          { key: 'Home' },
          { key: 'PatientList' },
          {
            key: 'PatientDetail',
            render: () => (
              <Link to={`/patients/${patient.id}`}>{patient.name}</Link>
            ),
          },
        ]}
      />
      <ContainerWithAvatar>
        <Avatar
          src={patient.photos.frontFace?.thumbnailPath}
          size={avatarSize}
          shape='circle'
          icon={<UserOutlined />}
        />
        <Container>
          <ContentRow>
            <PatientName>{patient.name}</PatientName>
            <DisplayPatientBrand value={patient.payment.brand} />
            <Tag>{t(`patient.source.${patient.payment.source}`)}</Tag>
            <Link to={`/patients/${patient.id}/form`}>檢視病例詳情</Link>
          </ContentRow>
          <ContentRow>
            <PatientInfo
              title='臨床狀況（左 / 右）'
              value={`${patient.symptoms.leftMolarRelationship} / ${patient.symptoms.rightMolarRelationship}`}
            />
            <PatientInfo
              title='報告'
              value={
                patient.currentEvalStage ? (
                  <CustomLink.StageLink
                    to={`/stages/${patient.currentEvalStage.id}`}
                    item={patient.currentEvalStage}
                  />
                ) : (
                  '--'
                )
              }
            />
            <PatientInfo
              title='療程進度'
              value={
                <TreatmentProgress
                  patientStatus={patient.status}
                  steps={length(
                    patient.currentEvalStage?.analysis?.steps || []
                  )}
                  finishedSteps={lastCompletedStage?.serialNumber || 0}
                />
              }
            />
            <RowRightFields>
              <PatientInfo
                title='最近出貨 Step'
                value={
                  lastCompletedStage
                    ? `Step ${lastCompletedStage.serialNumber}`
                    : '--'
                }
                emphasize
                dense
                rtl
              />
              <VerticalDivider />
              <PatientInfo
                title='下次約診'
                value={
                  patient.nextAppointmentDate
                    ? moment(patient.nextAppointmentDate).format(
                        'YYYY-MM-DD HH:mm'
                      )
                    : '無約診'
                }
                emphasize
                dense
                rtl
              />
            </RowRightFields>
          </ContentRow>
        </Container>
      </ContainerWithAvatar>
      <Menu
        style={{ marginBottom: '-24px' }}
        selectedKeys={selectedKey ? [selectedKey] : undefined}
        mode='horizontal'
      >
        {map(
          ({ key, label, path }) => (
            <Menu.Item key={key}>
              <Link to={path}>{label}</Link>
            </Menu.Item>
          ),
          patientMenuList
        )}
      </Menu>
    </>
  )
}

export default PatientMenu
