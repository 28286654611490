import { Menu } from 'antd'
import { keys, map } from 'ramda'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import BreadcrumbCreator from '../../../../components/page/BreadcrumbCreator'
import Title from '../../../../components/page/Title'
import { TabKey, accountSubRoutes } from './TabConfig'

// @TODO account migration
interface Props {
  accountId: string
  selectedKeys: TabKey[]
}

const AccountMenu = (props: Props) => {
  const { selectedKeys } = props
  const { pathname } = useLocation()

  return (
    <div className='components-menu-user'>
      <BreadcrumbCreator
        routes={[
          { key: 'Home' },
          { key: 'AccountDetail', render: () => <span>個人設定</span> },
        ]}
      />
      <Title route={{ key: 'AccountDetail' }} />
      <Menu
        style={{ marginBottom: '-24px' }}
        mode='horizontal'
        selectedKeys={selectedKeys}
      >
        {map(
          (key) => (
            <Menu.Item key={key}>
              <Link to={`${pathname}#${key}`}>
                {accountSubRoutes[key].text}
              </Link>
            </Menu.Item>
          ),
          keys(accountSubRoutes)
        )}
      </Menu>
    </div>
  )
}

export default AccountMenu
