import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { NotificationItemFragment } from '../../codegen/types'
import NotificationList from './NotificationList'

const ListWrapper = styled.div`
  width: 300px;
  max-height: 360px;
  overflow-y: scroll;
`

const PopoverFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 8px 24px;
`

interface PopoverContentProps {
  loading?: boolean
  listPagePath?: string
  notificationList: NotificationItemFragment[]
  handleRead?: (id: string, read: boolean) => Promise<void>
}

const PopoverContent = (props: PopoverContentProps) => {
  const { loading, listPagePath, notificationList, handleRead } = props

  return (
    <>
      <ListWrapper>
        <NotificationList
          popoverMode
          loading={loading}
          notificationList={notificationList}
          handleRead={handleRead}
        />
      </ListWrapper>
      {listPagePath && (
        <PopoverFooter>
          <Link to={listPagePath}>查看全部</Link>
        </PopoverFooter>
      )}
    </>
  )
}

export default PopoverContent
