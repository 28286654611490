import { gql } from '@apollo/client'
import { getEmptyText, TableQuery } from '@sov/common'
import { StageName } from '@sov/ui'
import { Space, Table } from 'antd'
import { ColumnProps, TableProps } from 'antd/lib/table'
import moment from 'moment'
import { map } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  HomeDeliveryType,
  PatientInvoiceTableFragment,
} from '../../../codegen/types'

interface Props {
  loading?: boolean
  data: PatientInvoiceTableFragment[]
  antdPagination: {
    page: number
    total: number
    limit: number
  }
  handleTableChange: TableProps<PatientInvoiceTableFragment>['onChange']
}

const InvoiceTableList = (props: Props) => {
  const { t } = useTranslation()
  const { data, loading, antdPagination, handleTableChange } = props

  const columns: ColumnProps<PatientInvoiceTableFragment>[] = [
    {
      title: '出貨編號',
      width: 60,
      key: 'stage',
      render: (text, record) => {
        return (
          record.__typename === 'InvoiceWithStage' && (
            <StageName
              item={record.stage}
              showType={record.stage.__typename !== 'PrintStage'}
              isExternal
            />
          )
        )
      },
    },
    {
      title: '出貨日期',
      width: 100,
      dataIndex: 'shippingDate',
      key: 'shippingDate',
      render: (text) => (
        <div>{text ? moment(text).format('YYYY-MM-DD') : getEmptyText()}</div>
      ),
    },
    {
      title: '出貨品項與數量',
      width: 180,
      key: 'products',
      dataIndex: 'products',
      render: (text, record) => (
        <div>
          {map(
            (item) => (
              <div key={item.productId}>
                {item.name} ( * {item.count})
              </div>
            ),
            record.products
          )}
        </div>
      ),
    },
    {
      title: '配送方式',
      width: 180,
      key: 'homeDelivery',
      render: (text, record) => {
        return (
          <Space direction='vertical'>
            <div>
              {t(`invoice.homeDeliveryType.${record.homeDeliveryType}`)}
            </div>
            {record.homeDeliveryType !== HomeDeliveryType.Self &&
              `宅配單號: ${record.homeDeliveryId}`}
          </Space>
        )
      },
    },
  ]
  return (
    <Table<PatientInvoiceTableFragment>
      rowKey='id'
      columns={columns}
      dataSource={data}
      loading={loading}
      onChange={handleTableChange}
      pagination={TableQuery.getAntdPagination(antdPagination)}
    />
  )
}

InvoiceTableList.fragment = gql`
  fragment PatientInvoiceTable on Invoice {
    id
    shippingDate
    payment
    homeDeliveryId
    homeDeliveryType
    products {
      productId
      serialNumber
      name
      spec
      cost
      count
    }
    ... on InvoiceWithStage {
      stage {
        __typename
        ...StageName
      }
    }
  }
  ${StageName.fragment}
`

export default InvoiceTableList
