import { Modal } from 'antd'
import React from 'react'

import {
  Content,
  CustomizedFooterButton,
  ModalBody,
  Title,
} from '../../../../components/modal'
import inDevelopmentImage from './in-development.png'

interface EditOrderModalProps {
  handleModalClose: () => void
  isModalVisible: boolean
}

const EditOrderModal = (props: EditOrderModalProps) => {
  const { handleModalClose, isModalVisible } = props

  return (
    <Modal
      bodyStyle={{
        padding: 0,
      }}
      closable={false}
      footer={[
        <CustomizedFooterButton key='close' onClick={handleModalClose}>
          我知道了
        </CustomizedFooterButton>,
      ]}
      visible={isModalVisible}
      onCancel={handleModalClose}
      width={480}
    >
      <ModalBody>
        <div>
          <img src={inDevelopmentImage} />
        </div>
        <Title>功能開發中</Title>
        <Content>
          <div>此功能目前仍在開發中，</div>
          <div>若您需要編輯或刪除訂單，請透過討論室與我們聯繫。</div>
        </Content>
      </ModalBody>
    </Modal>
  )
}

export default EditOrderModal
