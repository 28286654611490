import { Steps } from 'antd'
import React, { FC } from 'react'
import styled from 'styled-components'

interface Props {
  step: number
}

const Container = styled.div`
  width: 700px;
  margin: 8px auto;
`

const StepBar: FC<Props> = (props) => {
  const { step } = props

  return (
    <Container>
      <Steps current={step}>
        <Steps.Step title='基本信息' />
        <Steps.Step title='病患臨床資料' />
        <Steps.Step title='矯正狀況與醫囑' />
      </Steps>
    </Container>
  )
}

export default StepBar
